import React from "react"

import { Box, makeStyles } from "@material-ui/core"

import MultilineCopy from "framework/MultilineCopy"

const styles = makeStyles(
  (theme) => ({
    infobox: {
      background: "#fff",
      border: "1px solid rgba(4,96,169, 0.3)",
      padding: "40px 40px 20px",
      marginBottom: 40
    },
  }),
  { name: "InnerPageContainer" }
)

const StudyOverview = () => {
  const classes = styles()

  return (
    <Box>
      <MultilineCopy id="study-schedule.content-upper" />

      <Box className={ classes.infobox }>
        <MultilineCopy id="study-schedule.ofatumumab-content" context="study-schedule" />
      </Box>

      <Box className={ classes.infobox }>
        <MultilineCopy id="study-schedule.fingolimod-content" context="study-schedule" />
      </Box>

      <Box className={ classes.infobox }>
        <MultilineCopy id="study-schedule.ofatumumab-content" context="study-schedule" />
      </Box>

      <MultilineCopy id="study-schedule.content-lower" />
    </Box>
  )
}

export default StudyOverview;