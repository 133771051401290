import { ListItem, ListItemIcon, Box, Typography, Collapse, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import SidebarLink from "./SidebarLink"
import { v4 as uuid } from "uuid"
import useCopy from "framework/hooks/useCopy"
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(
  (_theme) => ({
    root: {
      fontSize: 11,
      lineHeight: 1,
      fontWeight: 600,
      letterSpacing: "0.38em",
      borderTop: "1px solid #F6F6F6",
      padding: "20px 20px 20px 10px"
    },
    collapseTitle: {
      fontWeight: 700,
      color: _theme.palette.primary.main,
      letterSpacing: 0,
      fontSize: 16,
      marginLeft: 16
    },
    collapseButton: {
      border: "1px solid #f6f6f6",
      display: "inline-block",
      marginLeft: "auto",
      width: 32,
      height: 32,
      lineHeight: 4,
      borderRadius: 16,
      textAlign: 'center'
    },
    collapseIcon: {
      color: _theme.palette.primary.main
    }
  }),
  { name: "SidebarSection" }
)

export type SidebarSectionStyleKeys = "root"

type Props = {
  section: SidebarSection,
  collapsable?: boolean
}

const SidebarSection = ({ section, collapsable }: Props) => {
  const classes = useStyles()
  const Icon = section.icon
  const copy = useCopy()

  const [menuOpen, setMenuOpen] = useState(true);

  return (
    <>
      <ListItem className={classes.root}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <Typography className={classes.collapseTitle}>{copy[section.text]}</Typography>
        <Box className={classes.collapseButton}>
          <a href="#" className={classes.collapseIcon} onClick={ (e) => { e.preventDefault(); setMenuOpen(!menuOpen) } }>
            { menuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          </a>
        </Box>
      </ListItem>
      
      <Box px={1}>
        <Collapse in={ menuOpen }>
          {section.items.map((item) => (
            <SidebarLink item={item} key={uuid()} level={2} />
          ))}
        </Collapse>
      </Box>

    </>
  )
}

export default SidebarSection
