import { ThemeOptions } from "@material-ui/core"
import { breakpoints } from "framework/styles"

export const brandColors = {
  primary: "#0460A9",
  secondary1: "#E84B22",
  secondary2: "#EBF2F8",
  grey: "#7C7B7B",
  black: "#202020",
}

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: brandColors.primary,
    },
    secondary: {
      main: brandColors.secondary1,
      light: brandColors.secondary2,
      contrastText: brandColors.secondary1,
    },
    action: {
      selected: brandColors.primary,
    },
    error: {
      main: "#FF0000",
    },
  },
  typography: {
    fontFamily: "Arial",
    h1: {},
    h2: {
      fontSize: "30px",
      fontWeight: 700,
      color: brandColors.primary
    },
    h3: {
      fontSize: "24px",
      marginBottom: "8px",
      fontWeight: 700,
      letterSpacing: 0,
    },
    h4: {
      fontSize: "20px",
      lineHeight: "26px",
      fontWeight: 700,
      letterSpacing: 0,
    },
    body1: {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 400
    },
    body2: {
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        borderRadius: 4,
      },
      button: {
        "&.Mui-selected": {
          color: "#ffffff",
        },
      },
    },
    Layout: {
      contentBackgroundPrimary: {},
    },
    LandingPageContainer: {
      "@global": {
        body: {
          backgroundColor: "#ffffff",
        },
      },
      background: {
        [breakpoints.up("md")]: {},
      },
      content: {
        color: brandColors.black,
        paddingTop: 64,
      },
    },
  },
}

export default theme
