import sidebar from "../sidebars/global"
import pages from "../pages/global"

const roRO: LocaleDefinition = {
  code: "ro-RO",
  label: "Romanian",
  country: "Romania",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bun venit la site-ul web Studiul NEOS",
    "welcome.message":
      "NEOS este un studiu clinic pentru copii și adolescenți cu scleroză multiplă (SM) cu vârste cuprinse între 10 și 17 ani. Vă rugăm să introduceți codul PIN care se găsește în materialul tipărit sau oferit dvs. de către echipa de la centru.",
    "welcome.pin_prompt": "INTRODUCEȚI PIN-UL MAI JOS",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "INTRODUCERE",
    "welcome.support": "Aveți nevoie de ajutor? Vă rugăm să contactați furnizorul local de servicii medicale.",
    "welcome.close": "închidere",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descriere",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videoclipuri",
    "sidebar.videos.trials": "Studii clinice",
    "sidebar.videos.neos": "Studiu NEOS",
    "sidebar.videos.medicines": "Cum să luați medicamentele de studiu",

    "sidebar.reads-title": "Informații rapide",
    "sidebar.reads.pediatric-ms": "Înțelegerea SM pediatrice",
    "sidebar.reads.study": "Prezentare generală a studiului",
    "sidebar.reads.schedule": "Programul vizitelor din cadrul studiului",

    "sidebar.resources-title": "Resurse",

    "sidebar.contact": "Contactați-ne",
    "sidebar.privacy": "Politică de confidențialitate",
    "sidebar.terms": "Termenele serviciului",
    "sidebar.copyright": "© Copyright 2021 Novartis. Toate drepturile rezervate",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Înțelegerea studiilor clinice",
    "understanding-trials.description": "Acest videoclip explică ce sunt studiile clinice.",
    "understanding-trials.video-id": "lQiuZundICI",

    "understanding-neos.title": "Înțelegerea studiului NEOS",
    "understanding-neos.description": "Acest videoclip furnizează informații despre studiul NEOS",
    "understanding-neos.video-id": "kbFdUvhNEdw",

    "understanding-ms.title": "Înțelegerea SM pediatrice",
    "understanding-ms.description": "Prezentare generală scurtă a înțelegerii SM la copii și adolescenți.",

    "study-medicines.title": "Cum să luați medicamentele de studiu",
    "study-medicines.description": "Acest video explicitează cum să luați medicamentele incluse in studiu",
    "study-medicines.video-id": "c-vHht7FMbM",

    "study-overview.title": "Prezentare generală a studiului",

    "schedule.title": "Programul vizitelor din cadrul studiului",

    "resources.title": "Resurse",

    "neos-poster.title": "Afiș recrutare studiu NEOS",
    "neos-poster.description": "Pentru copiii și adolescenții cu vârste cuprinse între 10 și 17 ani ce suferă de scleroză multiplă recurent-remisivă (SM).",
    "neos-poster.href": "/i18n/RO-RO/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Studiul NEOS – Flip chart pentru consimțământ informat",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/RO-RO/pdfs/consent.pdf"
    },
    {
      title: "Studiul NEOS – Flip chart acord informat",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/RO-RO/pdfs/assent.pdf"
    },
    {
      title: "Studiul NEOS – Pliant introductiv",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/RO-RO/pdfs/multifold.pdf"
    },
    {
      title: "Carte de benzi desenate despre înțelegerea SM și a studiului NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/RO-RO/pdfs/un ms.pdf"
    },
    {
      title: "Carte de benzi desenate despre înțelegerea studiilor clinice, pentru copii cu vârste cuprinse între 7-12 ani",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/RO-RO/pdfs/comic 7-12.pdf"
    },
    {
      title: "Carte de benzi desenate despre înțelegerea studiilor clinice, pentru copii cu vârste cuprinse între 13-17 ani",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/RO-RO/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Ce se întâmplă în SM?" },
      { type: "paragraph", content: "SM deteriorează teaca de mielină, care este membrana protectivă ce înconjoară nervii. Atunci când membrana ce acoperă nervul este deteriorată, nervii nu mai pot trimite semnale ca în mod normal." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Simptomele SM pot varia în funcție de locația deteriorării. Acestea pot include:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Oboseală", "Amorțeală"],
        ["Vedere încețoșată", "Slăbiciune"],
        ["Furnicături, de exemplu, la mâini sau picioare", "Durere"],
        ["Pierderea echilibrului", "Depresie"],
        ["Probleme intestinale sau cu vezica", "Anxietate"],
        ["Nevrită optică – inflamație a nervului optic, ce poate cauza pierderea vederii (dar vederea revine de obicei)", "Dificultăți de vorbire"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Ce este recurența?" },
      { type: "paragraph", content: "În timpul recurenței, simptomele pot dura mai multe săptămâni și apoi se pot ameliora, deoarece nervul se repară de unul singur. Recidivele sunt urmate de perioade fără simptome noi, numite remisiuni." },
      { type: "paragraph", content: "Nu există tratament pentru SM. Studiile clinice sunt efectuate pentru persoanele tinere cu SM pentru a testa noi terapii ce pot ajuta." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Ce este studiul NEOS?" },
      { type: "paragraph", content: "Studiul NEOS investighează trei medicamente diferite pentru a vedea dacă pot ajuta în siguranță copiii și adolescenții cu scleroză multiplă." },
      { type: "paragraph", content: "Participanții sunt eligibili dacă au vârste cuprinse între 10 și 17 ani." },

      { type: "header", content: "Cât durează studiul?" },
      { type: "paragraph", content: "Studiul durează 2 ani. Există o opțiune de a utiliza medicamentele de studiu pentru încă 2-5 ani." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Numărul de vizite va depinde de momentul administrării ultimei doze a medicamentului de studiu." },
      { type: "paragraph", content: "Studiu-deschis: Opusul studiului dublu-orb, înseamnă că participantul la studiu și doctorul studiului știu în ce grup de tratament se află participantul la studiu." },
      { type: "paragraph", content: "Dublu-orb: Acest lucru înseamnă că nici participantul la studiu, nici doctorul studiului nu știu în ce grup de tratament se află participantul la studiu." },

      { type: "header", content: "Ce medicamente de studiu sunt testate?" },
      { type: "paragraph", content: "Sunt testate trei medicamente de studiu:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab este un medicament injectabil care este aprobat în unele țări pentru adulții cu SM. Este administrat cu ajutorul unui stilou autoinjector similar cu cele utilizate de pacienții cu diabet care injectează insulină. Cercetătorii vor sa afle cât de bine funcționează ofatumumab la copii și adolescenți și cât de sigur este." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod este o tabletă cu administrare pe cale orală care este aprobată în multe țări pentru adulții cu SM. Cercetătorii vor sa afle cât de bine funcționează la copii și adolescenți și cât de sigur este. " },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod este o capsulă orală care este deja aprobată în multe țări pentru copiii și adolescenții cu SM. Cercetătorii vor sa afle dacă ofatumumab sau siponimod sunt cel puțin la fel de bune ca și fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Cercetătorii doresc să observe dacă ofatumumab sau siponimod sunt cel puțin la fel de bune ca fingolimod." },
      { type: "paragraph", content: "Fiecare participant este plasat într-unul dintre cele trei grupuri de tratament." },
      { type: "paragraph", content: "Fiecare grup primește toate cele 3 medicamente. Unul dintre medicamente va conține o substanță activă și 2 dintre acestea vor fi placebo. Un placebo arată ca un medicament de studiu, dar nu conține un medicament real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Ce trebuie să fac în timpul studiului?" },
      { type: "paragraph", content: "Vor avea loc evaluări medicale la vizitele din cadrul studiului clinic și în timpul apelurilor telefonice cu doctorul studiului. Este important să participați la toate vizitele din cadrul studiului și să completați eDiary (jurnalul electronic)." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Participarea la studiu este complet voluntară. Puteți renunța la studiu oricând." },
      { type: "header", content: "Cum să luați medicamentele de studiu" },
      { type: "paragraph", content: "Sunt testate trei medicamente de studiu:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tip", "Stilou autoinjector"],
        ["Cât de des", "La fiecare 4 sau 6 săptămâni (în funcție de greutate)"],
        ["Recipient", "Cutie cu un stilou autoinjector pentru o doză unică"],
        ["Depozitare", "A se păstra la frigider"],
        ["Note", "Scoateți de la frigider cu 15, 30 de minute înainte de injecție. Consultați instrucțiunile pentru mai multe detalii"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tip", "Tabletă"],
        ["Cât de des", "În fiecare zi"],
        ["Recipient", "Blister pentru primele 6 zile, apoi flacon"],
        ["Depozitare", "A se păstra la frigider"],
        ["Note", "Luați o tabletă dimineața sau seara, la aceeași oră în fiecare zi"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tip", "Capsule"],
        ["Cât de des", "În fiecare zi"],
        ["Recipient", "Flacon"],
        ["Depozitare", "A se păstra la frigider"],
        ["Note", "Luați o capsulă dimineața sau seara, la aceeași oră în fiecare zi"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Înregistrați fiecare doză luată din cele trei medicamente de studiu în eDiary (jurnalul electronic). Pentru injecții, includeți data, ora, partea corpului și persoana care a efectuat injecția. Dacă o doză este ratată, contactați doctorul studiului sau echipa studiului. Informați imediat doctorul studiului cu privire la orice efecte secundare." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default roRO
