import { ResourcesContext } from "framework/context/ResourcesProvider"
import { useContext } from "react"

const useResources = () => {
  const resources = useContext(ResourcesContext)

  if (!resources) {
    throw new Error("useResources must be used inside ResourcesProvider")
  }

  return resources
}

export default useResources
