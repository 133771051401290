import { Drawer, List, ListItem, ListItemText, makeStyles } from "@material-ui/core"
import useUI from "framework/hooks/useUI"
import React from "react"
import _ from "lodash"

const styles = makeStyles({
  root: {
  },
  paper: {
    top: 56,
  },
  backdrop: {
    top: 56,
    zIndex: 1099,
  },
  modal: {
    zIndex: 1099,
  },
  list: {
    paddingBottom: 80
  },
  subheader: {
    margin: "4px 8px"
  },
  nested: {
    padding: "0px 20px",
  }
})

const LanguageDrawer = () => {
  const { permittedLocales, languageMenuOpen, setLocale, toggleLanguageMenu } = useUI()
  const classes = styles()

  const localesByCountry = _.groupBy(permittedLocales, "country");

  const countries = Object.keys(localesByCountry).sort();


  return (
    <Drawer
      BackdropProps={{ className: classes.backdrop }}
      ModalProps={{ classes: { root: classes.modal } }}
      variant="temporary"
      anchor="top"
      open={languageMenuOpen}
      onClose={toggleLanguageMenu}
      className={classes.root}
      classes={{
        paperAnchorTop: classes.paper,
      }}
    >
      <List className={ classes.list }>
        {permittedLocales.map((option) => (
          <ListItem
            button
            key={option.code}
            onClick={() => {
              setLocale(option)
              toggleLanguageMenu()
            }}
          >
            <ListItemText>{option.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default LanguageDrawer
