import { ContentContext } from "framework/context/ContentProvider"
import { useCallback, useContext } from "react"

const useContent = () => {
  const ctx = useContext(ContentContext)
  
  if (!ctx) {
    throw new Error("useContent must be used inside ContentProvider")
  }

  const getItem = useCallback(
    (id: string) => {
      const item = ctx[id]

      if (!item) {
        throw new Error(`Unable to find content item with id: ${id}`)
      }

      return item
    },
    [ctx]
  )

  return {
    content: ctx,
    getItem,
  }
}

export default useContent
