import useUI from "framework/hooks/useUI"
import React from "react"
import WelcomePage from "../pages/WelcomePage"

type Props = {
  children: React.ReactNode
}

const RequiresWelcomePage = ({ children }: Props) => {
  const { welcomePageCompleted } = useUI()

  if (!welcomePageCompleted) {
    return <WelcomePage />
  }

  return <>{children}</>
}

export default RequiresWelcomePage
