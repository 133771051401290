import { MuiThemeProvider } from "@material-ui/core/styles"
import useUI from "framework/hooks/useUI"
import React from "react"

type Props = {
  children: React.ReactNode
}

const ThemesContainer = ({ children }: Props) => {
  const { theme } = useUI()

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemesContainer
