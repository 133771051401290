import { CssBaseline, makeStyles } from "@material-ui/core"
import Layout from "framework/Layout"
import Routes from "framework/Routes"
import React, { createContext, useEffect } from "react"
import { BrowserRouter as Router, useLocation, withRouter } from "react-router-dom"
import RequiresAuthentication from "../RequiresAuthentication"
import RequiresNoAuthentication from "../RequiresNoAuthentication"
import RequiresNoWelcomePage from "../RequiresNoWelcomePage"
import RequiresWelcomePage from "../RequiresWelcomePage"
import ThemesContainer from "framework/ThemesContainer"
import UiProvider from "framework/context/UiProvider"
import useUI from "framework/hooks/useUI"
import CopyProvider from "framework/context/CopyProvider"
import MultilineCopyProvider from "framework/context/MultilineCopyProvider"
import ResourcesProvider from "framework/context/ResourcesProvider"
import ContentProvider from "framework/context/ContentProvider"
import PagesProvider from "framework/context/PagesProvider"
import LocaleImageProvider from "framework/context/LocaleImageProvider"
import RtlContainer from "framework/RtlContainer"

export const WebsiteContext = createContext<WebsiteContext | undefined>(undefined)

const useStyles = makeStyles({
  "@global": {
    body: {
      margin: 0,
      scrollBehavior: "smooth",
    },
    a: {
      "&:visited": {
        color: "inherit",
      },
    },
  },
})

type Props = {
  website: Website
}

function _ScrollToTop(props : any) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


function _LanguageRedirector(props : any) {
    const { setCountry, setLocale } = useUI()
    const { pathname } = useLocation();
    const countryCode = window.location.pathname && window.location.pathname.substring(1).toUpperCase()

    useEffect(() => {
      if(Object.keys(props.website.i18n.countries).includes(countryCode)){
        setCountry(countryCode)
        //Select first locale
        const defaultLocaleCode = props.website.i18n.countries[countryCode][0]
        setLocale(props.website.i18n.locales[defaultLocaleCode])
        props.history.push('')
      }  
    }, []);
    return props.children
}
const LanguageRedirector = withRouter(_LanguageRedirector)


const Website = ({ website }: Props) => {
  const classes = useStyles()
  const { options, componentOverrides } = website

  const value: WebsiteContext = {
    options,
    componentOverrides,
  }

  const AuthContainer = website.auth.enabled ? RequiresAuthentication : RequiresNoAuthentication
  const WelcomeContainer = website.options.welcomePage ? RequiresWelcomePage : RequiresNoWelcomePage

  return (
    <WebsiteContext.Provider value={value}>
      <UiProvider website={website}>
        <LocaleImageProvider website={website}>
          <MultilineCopyProvider website={website}>
          <ResourcesProvider website={website}>
            <CopyProvider website={website}>
              <ContentProvider website={website}>
                <PagesProvider website={website}>
                  <RtlContainer>
                    <ThemesContainer>
                      <Router>
                        <LanguageRedirector website={website}>
                          <AuthContainer>
                            <WelcomeContainer>
                              <CssBaseline classes={{ "@global": classes["@global"] }} />
                                <ScrollToTop>
                                  <Layout>
                                    <Routes />
                                  </Layout>
                                </ScrollToTop>
                              </WelcomeContainer>
                            </AuthContainer>
                          </LanguageRedirector>
                        </Router>
                      </ThemesContainer>
                    </RtlContainer>
                  </PagesProvider>
                </ContentProvider>
              </CopyProvider>
            </ResourcesProvider>
          </MultilineCopyProvider>
        </LocaleImageProvider>
      </UiProvider>
    </WebsiteContext.Provider>
  )
}

export default Website
