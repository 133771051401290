import { createTheme } from "framework/styles"
import {
  detectLocale,
  getPermittedLocales,
  localeOptionToDefinition,
  rememberLocale,
} from "framework/utils/language"
import { recallAuthCode, rememberAuthCode } from "framework/utils/session"
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react"

const WELCOME_COMPLETED_KEY = "welcomePageCompleted"

export const UiContext = createContext<WebsiteUI | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const UiProvider = ({ website, children }: Props) => {
  const { theme: themeOptions } = website

  const [authCode, setAuthCode] = useState<AuthCode | undefined>(recallAuthCode(website))
  const [country, setCountry] = useState<string>('US')
  const [locale, setLocale] = useState<LocaleDefinition>(detectLocale(website, authCode, country))

  // UI
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false)
  const [background, setBackground] = useState<string>("primary")
  const [welcomePageCompleted, setWelcomePageCompleted] = useState<boolean>(
    !!window.sessionStorage.getItem(WELCOME_COMPLETED_KEY)
  )

  const authenticate = useCallback(
    (suppliedAuthCode: string) => {
      if (!website.auth.enabled) return true

      for (const code of website.auth.codes) {
        if (code.code === suppliedAuthCode) {
          setAuthCode(code)
          return true
        }
      }

      return false
    },
    [website]
  )

  useEffect(() => {
    // Recall PIN
    const sessionAuthCode = recallAuthCode(website)
    sessionAuthCode && authenticate(sessionAuthCode.code)
  }, [authenticate, setLocale, website])

  useEffect(() => {
    rememberAuthCode(authCode?.code)
    setLocale(detectLocale(website, authCode, country))
  }, [website, authCode, country])

  // Track locale changes
  useEffect(() => {
    gtag("set", {
      language: locale.code,
    })

    document.body.setAttribute("dir", locale.rtl ? "rtl" : "auto")
  }, [locale])

  // Track completion of the welcome page
  useEffect(() => {
    if (welcomePageCompleted) {
      window.sessionStorage.setItem(WELCOME_COMPLETED_KEY, "true")
    } else {
      window.sessionStorage.removeItem(WELCOME_COMPLETED_KEY)
    }
  }, [welcomePageCompleted])

  const isAuthenticated = useMemo(() => !!authCode, [authCode])

  const permittedLocales = useMemo(() => getPermittedLocales(website, authCode, country), [
    website,
    authCode,
    country
  ])

  const sidebar = useMemo(() => website.i18n.locales[locale.code].sidebar, [website, locale])

  const theme = useMemo(() => {
    themeOptions.direction = locale.rtl ? "rtl" : undefined
    return createTheme(themeOptions)
  }, [themeOptions, locale])

  const value: WebsiteUI = {
    authenticate,
    isAuthenticated,
    mobileMenuOpen,
    toggleMobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
    languageMenuOpen,
    toggleLanguageMenu: () => setLanguageMenuOpen(!languageMenuOpen),
    welcomePageCompleted,
    setWelcomePageCompleted,
    background,
    setBackground,
    authCode,
    locale,
    setLocale: (locale) => {
      rememberLocale(locale.code)
      setLocale(localeOptionToDefinition(locale, website))
    },
    setCountry,
    permittedLocales,
    theme,
    sidebar,
  }

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>
}

export default UiProvider
