import React from "react"
import ReactDOM from "react-dom"
import Website from "./framework/Website"
import website from "./website"

ReactDOM.render(
  <React.StrictMode>
    <Website website={website} />
  </React.StrictMode>,
  document.getElementById("root")
)
