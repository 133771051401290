import sidebar from "../sidebars/global"
import pages from "../pages/global"

const ptPT: LocaleDefinition = {
  code: "pt-PT",
  label: "Portuguese",
  country: "Portugal",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bem-vindo(a) ao website do Estudo NEOS",
    "welcome.message":
      "NEOS é um estudo clínico para crianças e adolescentes com esclerose múltipla (EM) entre os 10 e os 17 anos de idade. Insira o Código PIN que encontra no material impresso ou que lhe foi facultado pela equipa do estudo.",
    "welcome.pin_prompt": "INSERIR PIN ABAIXO",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRAR",
    "welcome.support": "Precisa de ajuda? Contacte o seu prestador de cuidados de saúde local.",
    "welcome.close": "Fechar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descrição",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Vídeos",
    "sidebar.videos.trials": "Ensaios clínicos",
    "sidebar.videos.neos": "Estudo NEOS",
    "sidebar.videos.medicines": "Como tomar os medicamentos do estudo",

    "sidebar.reads-title": "Leituras rápidas",
    "sidebar.reads.pediatric-ms": "Compreender a EM pediátrica",
    "sidebar.reads.study": "Visão geral do estudo",
    "sidebar.reads.schedule": "Calendário de consultas do estudo",

    "sidebar.resources-title": "Recursos",

    "sidebar.contact": "Contacte-nos",
    "sidebar.privacy": "Política de Privacidade",
    "sidebar.terms": "Termos do Serviço",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos os direitos reservados",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Compreender os ensaios clínicos",
    "understanding-trials.description": "Este vídeo explica o que são os ensaios clínicos.",
    "understanding-trials.video-id": "g3Ekj-iHayU",

    "understanding-neos.title": "Compreender o estudo NEOS",
    "understanding-neos.description": "Este vídeo dá informações sobre o estudo NEOS.",
    "understanding-neos.video-id": "M6QtgU0Ps-A",

    "understanding-ms.title": "Understanding Pediatric MS",
    "understanding-ms.description": "Breve visão geral sobre a compreensão da EM em crianças e adolescentes.",

    "study-medicines.title": "Como tomar os medicamentos do estudo",
    "study-medicines.description": "Este vídeo explica como tomar os medicamentos do estudo.",
    "study-medicines.video-id": "01xUbLGUQ48",

    "study-overview.title": "Visão geral do estudo",

    "schedule.title": "Calendário de consultas do estudo",

    "resources.title": "Recursos",

    "neos-poster.title": "Cartaz de recrutamento do estudo NEOS",
    "neos-poster.description": "Para crianças e adolescentes entre os 10 e os 17 anos de idade com esclerose múltipla (EM) do tipo surto-remissão.",
    "neos-poster.href": "/i18n/PT-PT/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudo NEOS - Flipchart de consentimento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/PT-PT/pdfs/consent.pdf"
    },
    {
      title: "Estudo NEOS - Flipchart de assentimento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/PT-PT/pdfs/assent.pdf"
    },
    {
      title: "Estudo NEOS - Introdução polivalente",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/PT-PT/pdfs/multifold.pdf"
    },
    {
      title: "Banda desenhada “Compreender a EM e o estudo NEOS”",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/PT-PT/pdfs/un ms.pdf"
    },
    {
      title: "Banda desenhada “Compreender os estudos clínicos” dos 7 a 12 anos",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/PT-PT/pdfs/comic 7-12.pdf"
    },
    {
      title: "Banda desenhada “Compreender os estudos clínicos” dos 13 aos 17 anos",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/PT-PT/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "O que acontece na EM?" },
      { type: "paragraph", content: "A EM danifica a bainha da mielina, que é o revestimento protetor que rodeia os nervos. Quando este revestimento dos nervos fica danificado, os nervos não conseguem enviar sinais como normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Os sintomas de EM variam dependendo de onde é a lesão. Podem incluir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansaço", "Dormência"],
        ["Visão turva", "Fraqueza"],
        ["Formigueiro, por exemplo, nas mãos ou pés", "Dor"],
        ["Perda de equilíbrio", "Depressão"],
        ["Problemas no intestino ou bexiga", "Ansiedade"],
        ["Neurite ótica – inflamação do nervo ótico, que pode causar perda de visão (mas normalmente a visão regressa)", "Dificuldade em falar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "O que é um surto?" },
      { type: "paragraph", content: "Durante um surto, os sintomas podem durar várias semanas e depois aliviar por si só à medida que o nervo se repara a si próprio. Os surtos são seguidos por períodos sem novos sintomas, chamados remissões." },
      { type: "paragraph", content: "Não existe cura para a EM. Estão a ser conduzidos estudos clínicos para jovens com EM de modo a testar novas terapêuticas que possam ajudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "O que é o estudo NEOS?" },
      { type: "paragraph", content: "O estudo NEOS está a analisar 3 medicamentos do estudo diferentes para ver se podem ajudar de forma segura crianças e adolescentes com esclerose múltipla." },
      { type: "paragraph", content: "Os participantes são elegíveis se tiverem entre 10 e 17 anos de idade." },

      { type: "header", content: "Quanto tempo dura o estudo?" },
      { type: "paragraph", content: "O estudo dura 2 anos. Existe uma opção de tomar o medicamento do estudo durante um período adicional de 2 a 5 anos." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*O número de consultas de seguimento irá depender de quando é tomada a última dose do medicamento do estudo." },
      { type: "paragraph", content: "Regime aberto: O oposto de dupla ocultação, significa que tanto o participante do estudo como o médico do estudo sabem em que grupo de tratamento se encontra o participante do estudo." },
      { type: "paragraph", content: "Dupla ocultação: Isto significa que nem o participante do estudo nem o médico do estudo sabem em que grupo de tratamento se encontra o participante do estudo." },

      { type: "header", content: "Que medicamentos do estudo estão a ser testados?" },
      { type: "paragraph", content: "Estão a ser testados três medicamentos do estudo:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab é uma terapêutica injetável que está aprovada em alguns países para adultos com EM. É administrado utilizando um auto-injetor semelhante ao utilizado por pessoas com diabetes para injetar insulina. Os investigadores querem saber quão bem o ofatumumab funciona em crianças e adolescentes e quão seguro é. " },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod é um comprimido oral que está aprovado em muitos países para adultos com EM. Os investigadores querem saber quão bem funciona em crianças e adolescentes e quão seguro é. " },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod é uma cápsula oral que já está aprovada em muitos países para crianças e adolescentes com EM. Os investigadores querem saber se ofatumumab ou siponimod são, pelo menos, tão bons como o fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Os investigadores querem saber se o ofatumumab ou o siponimod são, pelo menos, tão bons como o fingolimod." },
      { type: "paragraph", content: "Cada participante é atribuído a 1 de 3 grupos de tratamentos." },
      { type: "paragraph", content: "Cada grupo recebe todos os 3 medicamentos. Um irá conter medicamento ativo e 2 serão placebos. Um placebo parece o medicamento do estudo, mas não contém nenhum medicamento." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "O que tenho de fazer durante o estudo?" },
      { type: "paragraph", content: "Haverá exames e testes de saúde nas consultas na clínica do estudo e durante telefonemas com o médico do estudo. É importante vir a todas as consultas do estudo e preencher o diário eletrónico." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Estar no estudo é algo completamente voluntário. Pode escolher deixar o estudo a qualquer momento." },
      { type: "header", content: "Como tomar os medicamentos do estudo" },
      { type: "paragraph", content: "Estão a ser testados três medicamentos do estudo:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Caneta auto-injetora"],
        ["Quantas vezes", "A cada 4 ou 6 semanas (dependendo do peso)"],
        ["Recipiente", "Caixa com caneta auto-injetora de utilização única"],
        ["Conservação", "Guardar no frigorifico"],
        ["Notas", "Retire do frigorífico 15 a 30 minutos antes da injeção. Ver instruções para mais detalhes"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Comprimidos"],
        ["Quantas vezes", "Todos os dias"],
        ["Recipiente", "Embalagem blister durante os primeiros 6 dias, depois frasco"],
        ["Conservação", "Guardar no frigorifico"],
        ["Notas", "Tomar um comprimido de manhã ou à noite, sempre à mesma hora do dia"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Quantas vezes", "Todos os dias"],
        ["Recipiente", "Frasco"],
        ["Conservação", "Guardar no frigorifico"],
        ["Notas", "Tomar uma cápsula de manhã ou à noite, sempre à mesma hora do dia"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Cada uma das doses tomadas dos 3 medicamentos do estudo deverá ser registada no diário eletrónico." },
      { type: "paragraph", content: "Para as injeções, incluir a data, hora, parte do corpo e quem administrou a injeção." },
      { type: "paragraph", content: "Se alguma dose não for administrada, contactar o médico do estudo ou a equipa do estudo." },
      { type: "paragraph", content: "Informar imediatamente ao médico do estudo sobre quaisquer efeitos secundários." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default ptPT
