import sidebar from "../sidebars/global"
import pages from "../pages/global"

const ukUA: LocaleDefinition = {
  code: "uk-UA",
  label: "Українська",
  country: "Ukraine",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Ласкаво просимо на вебсайт дослідження NEOS",
    "welcome.message":
      "NEOS — це клінічне дослідження для дітей та підлітків із розсіяним склерозом (РС) у віці від 10 до 17 років. Введіть PIN-код, знайдений у Вашому друкованому матеріалі або наданий Вам персоналом дослідницького центру.",
    "welcome.pin_prompt": "ВВЕДІТЬ PIN-КОД НИЖЧЕ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ВВЕСТИ",
    "welcome.support": "Потрібна допомога? Будь ласка, зверніться до Вашого медичного працівника за місцем проживання.",
    "welcome.close": "Закрити",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Опис",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Відео",
    "sidebar.videos.trials": "Клінічні дослідження",
    "sidebar.videos.neos": "Дослідження NEOS",
    "sidebar.videos.medicines": "Як приймати досліджувані лікарські",

    "sidebar.reads-title": "Швидкий перегляд",
    "sidebar.reads.pediatric-ms": "Що таке РС у дітей",
    "sidebar.reads.study": "Короткий огляд дослідження",
    "sidebar.reads.schedule": "Графік візитів дослідження",

    "sidebar.resources-title": "Ресурси",

    "sidebar.contact": "Зв'язатися з нами",
    "sidebar.privacy": "Політика конфіденційності",
    "sidebar.terms": "Умови надання послуг",
    "sidebar.copyright": "© Авторське право 2021 Novartis. Усі права захищені",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Що таке клінічні випробування?",
    "understanding-trials.description": "У цьому відео пояснюється, що таке клінічні випробування.",
    "understanding-trials.video-id": "4XR5VufohhE",

    "understanding-neos.title": "Що таке дослідження NEOS",
    "understanding-neos.description": "Це відео містить інформацію про дослідження NEOS.",
    "understanding-neos.video-id": "H9cI5CGMFfY",

    "understanding-ms.title": "Що таке РС у дітей",
    "understanding-ms.description": "Короткий огляд РС у дітей та підлітків.",

    "study-medicines.title": "Как принимать лекарственные средства",
    "study-medicines.description": "У цьому відео пояснюється, як приймати досліджувані лікарські засоби.",
    "study-medicines.video-id": "wL7ZdbjBTpM",

    "study-overview.title": "Короткий огляд дослідження",

    "schedule.title": "Графік візитів дослідження",

    "resources.title": "Ресурси",

    "neos-poster.title": "Постер про набір у дослідження NEOS",
    "neos-poster.description": "Для дітей та підлітків у віці від 10 до 17 років із рецидивуючо-ремітуючим розсіяним склерозом (РС).",
    "neos-poster.href": "/i18n/UK-UA/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Перекидний буклет про інформовану згоду – дослідження NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/UK-UA/pdfs/consent.pdf"
    },
    {
      title: "Перекидний буклет про інформовану згоду – дослідження NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/UK-UA/pdfs/assent.pdf"
    },
    {
      title: "Дослідження NEOS — Вступна брошура",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/UK-UA/pdfs/multifold.pdf"
    },
    {
      title: "Що таке РС та дослідження NEOS. Книга коміксів",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/UK-UA/pdfs/un ms.pdf"
    },
    {
      title: "Що таке клінічні випробування. Книга коміксів для дітей віком від 7 до 12 років",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/UK-UA/pdfs/comic 7-12.pdf"
    },
    {
      title: "Що таке клінічні випробування. Книга коміксів для підлітків віком від 13 до 17 років",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/UK-UA/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Що відбувається при РС?" },
      { type: "paragraph", content: "РС пошкоджує мієлінову оболонку, яка є захисним покривом, що оточує нерви. Коли цей покрив нерва пошкоджений, нерви не можуть надсилати сигнали, як зазвичай." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Симптоми РС різняться залежно від місця ураження. Вони можуть включати:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Втомлюваність", "Оніміння"],
        ["Затуманений зір", "Слабкість"],
        ["Поколювання, наприклад, в руках або ногах", "Біль"],
        ["Втрата рівноваги", "Депресія"],
        ["Порушення функцій кишківника або сечового міхура", "Тривога"],
        ["Оптичний неврит — це запалення зорового нерва, яке може спричинити втрату зору (але зір зазвичай повертається)", "Порушення мовлення"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Що таке рецидив?" },
      { type: "paragraph", content: "Під час рецидиву симптоми можуть тривати протягом декількох тижнів, а потім самі по собі зменшуються, коли нерв відновлюється сам. Рецидиви супроводжуються періодами без нових симптомів, які називаються ремісіями." },
      { type: "paragraph", content: "Лікування від РС не існує. Клінічні дослідження проводяться для молодих людей із РС з метою тестування нових методів терапії, які можуть допомогти." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Що таке дослідження NEOS?" },
      { type: "paragraph", content: "Дослідження NEOS розглядає 3 різні досліджувані лікарські засоби, щоб з’ясувати, чи можуть вони безпечно допомогти дітям та підліткам, хворим на розсіяний склероз." },
      { type: "paragraph", content: "До участі допускаються діти у віці від 10 до 17 років." },

      { type: "header", content: "Скільки триває це дослідження?" },
      { type: "paragraph", content: "Дослідження триває 2 роки. Існує можливість приймати досліджуваний лікарський засіб протягом додаткових 2-5 років." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* Кількість візитів подальшого спостереження буде залежати від того, коли прийнято останню дозу досліджуваного лікарського засобу." },
      { type: "paragraph", content: "Відкрите: На відміну від подвійного сліпого дослідження, це означає, що як учасник дослідження, так і лікар-дослідник знають, в якій групі лікування перебуває учасник дослідження." },
      { type: "paragraph", content: "Подвійне сліпе: Це означає, що ні учасник дослідження, ні лікар-дослідник не знають, у якій групі лікування перебуває учасник дослідження." },

      { type: "header", content: "Які досліджувані лікарські засоби вивчаються?" },
      { type: "paragraph", content: "Вивчаються три досліджувані лікарські засоби:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Офатумумаб" },
      { type: "paragraph", content: "Офатумумаб — це ін’єкційний терапевтичний засіб, який у деяких країнах схвалено для застосування у дорослих із РС. Його вводять за допомогою ручки-автоінжектора, подібної до тієї, яку використовують люди з діабетом для введення ін’єкцій інсуліну. Дослідники хочуть знати, наскільки добре діє офатумумаб у дітей та підлітків та наскільки він безпечний." },
      { type: "divider" },

      { type: "header", content: "Сіпонімод" },
      { type: "paragraph", content: "Сипонімод — це таблетки для перорального застосування, які схвалено у багатьох країнах для застосування у дорослих із РС. Дослідники хочуть знати, наскільки ефективно працює цей препарат у дітей та підлітків та наскільки він безпечний. " },
      { type: "divider" },

      { type: "header", content: "Фінголімод" },
      { type: "paragraph", content: "Фінголімод — це капсули для перорального застосування, які вже схвалено у багатьох країнах для застосування у дітей та підлітків із РС. Дослідники хочуть перевірити, чи є офатумумаб або сипонімод принаймні такими ж ефективними, як і фінголімод." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Дослідники хочуть перевірити, чи є офатумумаб або сипонімод принаймні такими ж дієвими, як фінголімод." },
      { type: "paragraph", content: "Кожен учасник потрапляє до 1 з 3 груп лікування." },
      { type: "paragraph", content: "Учасники кожної групи отримують всі 3 лікарські засоби. Один із них міститиме активний препарат, а 2 з них будуть плацебо. Плацебо виглядає як досліджуваний лікарський засбі, але не містить активної речовини." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Що мені потрібно робити під час дослідження?" },
      { type: "paragraph", content: "Під час візитів дослідження, що проводяться у медичному закладі, та під час телефонних розмов із лікарем-дослідником, проводитимуться перевірки стану здоров’я та аналізи. Важливо приходити на всі візити дослідження та заповнювати електронний щоденник." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Участь у дослідженні є абсолютно добровільною. Ви можете вирішити вийти з дослідження в будь-який час." },
      { type: "header", content: "Як приймати досліджувані лікарські засоби" },
      { type: "paragraph", content: "Вивчаються три досліджувані лікарські засоби:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Офатумумаб" },
      { type: "table", rows: [
        ["Тип", "Ручка-автоінжектор"],
        ["Як часто", "Кожні 4 або 6 тижнів (залежно від ваги)"],
        ["Упаковка", "Коробка або ручка-автоінжектор"],
        ["Зберігання", "Зберігати в холодильнику"],
        ["Примітки", "Вийняти з холодильника за 15-30 хвилин до ін’єкції. Докладнішу інформацію див."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Сіпонімод" },
      { type: "table", rows: [
        ["Тип", "Таблетки"],
        ["Як часто", "Щодня"],
        ["Упаковка", "Блістерна упаковка на перші 6 днів, потім флакон"],
        ["Зберігання", "Зберігати в холодильнику"],
        ["Примітки", "Приймайте по одній таблетці вранці або ввечері, щодня в один і той же час"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Фінголімод" },
      { type: "table", rows: [
        ["Тип", "Капсули"],
        ["Як часто", "Щодня"],
        ["Упаковка", "Пляшка"],
        ["Зберігання", "Зберігати в холодильнику"],
        ["Примітки", "Приймайте по одній капсулі вранці або ввечері, щодня в один і той же час"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Записуйте кожну прийняту дозу всіх 3 досліджуваних лікарських засобів в електронний щоденник." },
      { type: "paragraph", content: "Для ін’єкцій вкажіть дату, час, частину тіла та хто зробив ін’єкцію." },
      { type: "paragraph", content: "Якщо якусь дозу пропущено, зв’яжіться із лікарем-дослідником або дослідницькою групою." },
      { type: "paragraph", content: "Негайно повідомте лікаря-дослідника про будь-які побічні ефекти." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default ukUA
