import React, { useState } from "react"
import useCopy from "framework/hooks/useCopy"
import useLocaleImages from "framework/hooks/useLocaleImages"
import { Box, Typography, Dialog, makeStyles } from "@material-ui/core"

import VideoWithText from "website/components/content/VideoWithText"
import UnderstandingMs from "website/components/content/UnderstandingMs"

const styles = makeStyles(
  (theme) => ({
    feature: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      cursor: "pointer",
      marginBottom: 52,
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row"
      },
    },

    subtitle: {
      fontSize: "14px",
      lineHeight: 1.4,
      marginBottom: "16px"
    },

    fullPoster: {
      width: 500,
      height: "auto",
      minHeight: 800
    },

    imageWrap: {
      [theme.breakpoints.up("lg")]: {
        marginRight: 20
      }
    },

    image: {
      display: "block",
      width: "100%",
      paddingTop: "56.25%",
      backgroundSize: "cover",
      backgroundPosition: "center top",
    },

    panel: {
      [theme.breakpoints.up("lg")]: {
        width: '50%',
        display: "flex",
        flexDirection: "column",
      },
    },
    sidebar: {
      color: "#FFFFFF",
      background: theme.palette.primary.main,
      padding: "15px 0 0",
      [theme.breakpoints.up("lg")]: {
        width: '50%',
        padding: "0 0 0 25px",
      },
    },

    modalContent: {
      width: 500,
      maxWidth: "80%",
      position:"relative"
    },

    root: {
      overflow: "visible"
    },

    paper: {
      overflow: "auto",
      borderRadius: 0,
      backgroundColor: theme.palette.secondary.light
    },

    close: {
      position: "absolute",
      fontSize: "12px",
      fontFamily: "Arial",
      color: "#ffffff",
      top:-30,
      left:0
    }


  })
)

export type LandingFeatureProps = {
  featureKey: string
}

const LandingFeature = ({ featureKey }: LandingFeatureProps) => {
  // { copy[`${ feature.featureKey }.title`] }

    const copy = useCopy()
    const classes = styles()
    const getLocaleImage = useLocaleImages()

    const [isOpen, setOpen] = useState(false);

    let dialogContent;

    if (featureKey === "understanding-ms") {
      dialogContent = <UnderstandingMs />
    } else if (featureKey === "understanding-trials") {
      dialogContent = <VideoWithText id="understanding-trials" showTitle={ false } />
    } else if (featureKey === "understanding-neos") {
      dialogContent = <VideoWithText id="understanding-neos" showTitle={ false } />
    } else if (featureKey === "neos-poster") {
      dialogContent = (
        <iframe src={ copy["neos-poster.href"] } className={classes.fullPoster} />

      )
      // dialogContent = (
      //   <img src={ `/images/neos-poster-full.png` }
      //     alt={ copy[`${ featureKey }.title`] } />
      // );
    }

    return (
      <Box>
        <Box onClick={ () => setOpen(true) } className={classes.feature}>
          <Box className={classes.panel}>
            <div className={classes.imageWrap}>
              <div className={classes.image}   style={{ backgroundImage: "url('" + (featureKey === 'understanding-ms' ? getLocaleImage('nerves1.png') :  copy[`${ featureKey }.title`] == "none" ? "" : `/images/${ featureKey }.png`) + "')" }} />
            </div>
          </Box>
           <Box className={classes.sidebar}>
              <Typography variant="h4">
                { copy[`${ featureKey }.title`] === "none" ? "" : copy[`${ featureKey }.title`] }
              </Typography>
              <Typography className={classes.subtitle}>
               { copy[`${ featureKey }.title`] === "none" ? "" : "Novartis"}
              </Typography>
              <Typography variant="body2">
                { copy[`${ featureKey }.description`] }
              </Typography>
          </Box>
        </Box>

        <Dialog classes={{root: classes.root, paper: classes.paper }} open={ isOpen } onClose={ () => setOpen(false) }
          onBackdropClick={ () => setOpen(false) }>

            <Box p={ featureKey === "neos-poster" ? 0 : 4 } className={classes.modalContent}>
              <a className={classes.close} onClick={ () => setOpen(false) }>&times; { copy[`welcome.close`] }</a>
              { dialogContent }
            </Box>
        </Dialog>
      </Box>
    )
}

export default LandingFeature
