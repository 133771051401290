import React from "react"

import VideoWithText from "website/components/content/VideoWithText"

const UnderstandingTrials = () => {
  return (
    <VideoWithText id="understanding-trials" showTitle={ true } />
  )
}

export default UnderstandingTrials;