import "fonts.css"
import { createOptions } from "framework/options"
import auth from "website/auth"
import theme from "website/styles"
import RelatedItems from "website/components/RelatedItems"
import ContentItemCard from "website/components/ContentItemCard"
import LandingPageContainer from "website/components/LandingPageContainer"
import { ReactComponent as Logo } from "assets/logo.svg"
import { ReactComponent as HorizontalLogo } from "assets/logo_horizontal.svg"
import enUS from "website/i18n/enUS"
import csCZ from "website/i18n/csCZ"
import enUK from "website/i18n/enUK"
import etEE from "website/i18n/etEE"
import hiIN from "website/i18n/hiIN"
import itIT from "website/i18n/itIT"
import ptPT from "website/i18n/ptPT"
import ruUA from "website/i18n/ruUA"
import trTR from "website/i18n/trTR"
import ukUA from "website/i18n/ukUA"
import esES from "website/i18n/esES"
import esMX from "website/i18n/esMX"
import frCA from "website/i18n/frCA"
import lvLV from "website/i18n/lvLV"
import nlBE from "website/i18n/nlBE"
import ruRU from "website/i18n/ruRU"
import zhTW from "website/i18n/zhTW"
import deDE from "website/i18n/deDE"
import frBE from "website/i18n/frBE"
import ruIL from "website/i18n/ruIL"
import hrHR from "website/i18n/hrHR"
import enCA from "website/i18n/enCA"
import enAU from "website/i18n/enAU"
import arIL from "website/i18n/arIL"
import heIL from "website/i18n/heIL"
import plPL from "website/i18n/plPL"
import roRO from "website/i18n/roRO"
import skSK from "website/i18n/skSK"
import bnIn from "website/i18n/bnIn"
import ltLT from "website/i18n/ltLt"
import esUS from "website/i18n/esUS"
import teIN from "website/i18n/teIN"
import esLA from "website/i18n/esLA"
import ptBR from "website/i18n/ptBR"
import ruEE from "website/i18n/ruEE"
import srRSc from "website/i18n/srRSc"
import srRSl from "website/i18n/srRSl"

const approvedList = {
  defaultLocale: "en-US",
  locales: {
    "en-US": enUS,
    "es-US": esUS,
    "es-LA": esLA,
    "ru-RU": ruRU,
    "it-IT": itIT,
    "sk-SK": skSK,
    "nl-BE": nlBE,
    "pt-BR": ptBR,
    "zh-TW": zhTW,
    "et-EE": etEE,
    "ru-EE": ruEE,
    "ar-IL": arIL,
    "he-IL": heIL,
    "ru-IL": ruIL,
    "fr-CA": frCA,
    "en-CA": enCA,
    "en-UK": enUK,
    "hr-HR": hrHR,
    "fr-BE": frBE,
  },
  countries: {
    "US": ["en-US", "es-US"],
    "RU": ["ru-RU"],
    "IT": ["it-IT"],
    "SK": ["sk-SK"],
    "BE": ["nl-BE", "fr-BE"],
    "BR": ["pt-BR"],
    "TW": ["zh-TW"],
    "EE": ["et-EE", "ru-EE"],
    "IL": ["he-IL", "ru-IL", "ar-IL"],
    "CA": ["en-CA", "fr-CA"],
    "LATAM": ["es-LA"],
    "GB": ["en-UK"],
    "HR": ["hr-HR"],
  }
}

const fullList = {
  defaultLocale: "en-US",
  locales: {
    "en-US": enUS,
    "cs-CZ": csCZ,
    "en-UK": enUK,
    "et-EE": etEE,
    "hi-IN": hiIN,
    "it-IT": itIT,
    "pt-PT": ptPT,
    "ru-UA": ruUA,
    "tr-TR": trTR,
    "uk-UA": ukUA,
    "es-ES": esES,
    "es-MX": esMX,
    "fr-CA": frCA,
    "lv-LV": lvLV,
    "nl-BE": nlBE,
    "ru-RU": ruRU,
    "zh-TW": zhTW,
    "de-DE": deDE,
    "fr-BE": frBE,
    "ru-IL": ruIL,
    "hr-HR": hrHR,
    "en-CA": enCA,
    "en-AU": enAU,
    "ar-IL": arIL,
    "he-IL": heIL,
    "pl-PL": plPL,
    "ro-RO": roRO,
    "sk-SK": skSK,
    "bn-IN": bnIn,
    "lt-LT": ltLT,
    "es-US": esUS,
    "te-IN": teIN,
    "es-LA": esLA,
    "pt-BR": ptBR,
    "ru-EE": ruEE,
    "sr-RSc": srRSc,
    "sr-RSl": srRSl,
  },
  countries: {
    "US": ["en-US", "es-US"],
    "CZ": ["cs-CZ"],
    "GB": ["en-UK"],
    "EE": ["et-EE", "ru-EE"],
    "IN": ["hi-IN", "bn-IN", "te-IN"],
    "IT": ["it-IT"],
    "PT": ["pt-PT"],
    "UA": ["ru-UA", "uk-UA"],
    "TR": ["tr-TR"],
    "ES": ["es-ES"],
    "MX": ["es-MX"],
    "CA": ["en-CA", "fr-CA"],
    "LV": ["lv-LV"],
    "BE": ["nl-BE", "fr-BE"],
    "RU": ["ru-RU"],
    "TW": ["zh-TW"],
    "DE": ["de-DE"],
    "IL": ["he-IL", "ru-IL", "ar-IL"],
    "HR": ["hr-HR"],
    "AU": ["en-AU"],
    "PL": ["pl-PL"],
    "RO": ["ro-RO"],
    "SK": ["sk-SK"],
    "LT": ["lt-LT"],
    "LATAM": ["es-LA"],
    "BR": ["pt-BR"],
    "RS": ["sr-RSc","sr-RSl"]
  }
}

export const isProduction = window.location.hostname == "www.neos-study.com" ||
  window.location.hostname == "neos-study.com" ||
  window.location.hostname === "neos-novartis-production.s3-website.us-east-2.amazonaws.com";
export const i18n: I18nOptions = isProduction ? approvedList : fullList

export const options = createOptions({
  googleAnalyticsId: "",
  welcomePage: false,
  disableLanguageSelection: false,
  logo: Logo,
  horizontalLogo: HorizontalLogo,
})

const website: Website = {
  auth,
  i18n,
  options,
  theme,
  componentOverrides: {
    RelatedItems,
    ContentItemCard,
    LandingPageContainer,
  },
}

export default website
