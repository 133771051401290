import sidebar from "../sidebars/global"
import pages from "../pages/global"

const skSK: LocaleDefinition = {
  code: "sk-SK",
  label: "Slovakian",
  country: "Slovakia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Vitajte na webovej stránke skúšania NEOS",
    "welcome.message":
      "NEOS je klinické skúšanie pre deti a dospievajúcich so sklerózou multiplex (SM) vo veku 10 až 17 rokov. Zadajte PIN kód, ktorý nájdete na tlačenom materiáli alebo ktorý vám poskytol tím pracoviska.",
    "welcome.pin_prompt": "ZADAJTE PIN NIŽŠIE",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ZADAŤ",
    "welcome.support": "Potrebujete pomoc? Kontaktujte svojho miestneho poskytovateľa zdravotnej starostlivosti.",
    "welcome.close": "Zatvoriť",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Opis",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videá",
    "sidebar.videos.trials": "Klinické skúšania",
    "sidebar.videos.neos": "Skúšania NEOS",
    "sidebar.videos.medicines": "Ako užívať skúšaný liek",

    "sidebar.reads-title": "Stručné články",
    "sidebar.reads.pediatric-ms": "Porozumenie detskej SM",
    "sidebar.reads.study": "Prehľad skúšania",
    "sidebar.reads.schedule": "Harmonogram návštev v rámci skúšania",

    "sidebar.resources-title": "Zdroje",

    "sidebar.contact": "Kontaktujte nás",
    "sidebar.privacy": "Zásady ochrany osobných údajov",
    "sidebar.terms": "Podmienky poskytovania služby",
    "sidebar.copyright": "© Copyright 2021 Novartis. Všetky práva vyhradené.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Porozumenie klinickým skúšaniam",
    "understanding-trials.description": "V tomto videu sa vysvetľuje, čo sú klinické skúšania.",
    "understanding-trials.video-id": "zNYbYXV9oZk",

    "understanding-neos.title": "Porozumenie skúšaniu NEOS",
    "understanding-neos.description": "V tomto videu sú uvedené informácie o skúšaní NEOS.",
    "understanding-neos.video-id": "TO6kCAE8LKI",

    "understanding-ms.title": "Porozumenie detskej SM",
    "understanding-ms.description": "Krátky prehľad pochopenia SM u detí a dospievajúcich.",

    "study-medicines.title": "Ako treba užívať skúšané lieky",
    "study-medicines.description": "V tomto videu sa vysvetľuje, ako sa užívajú skúšané lieky.",
    "study-medicines.video-id": "1m4vMxlslq0",

    "study-overview.title": "Prehľad skúšania",

    "schedule.title": "Harmonogram návštev v rámci skúšania",

    "resources.title": "Zdroje",

    "neos-poster.title": "Plagát zameraný na nábor do skúšania NEOS",
    "neos-poster.description": "Pre deti a dospievajúcich vo veku 10 až 17 rokov s relaps-remitujúcou sklerózou multiplex (SM).",
    "neos-poster.href": "/i18n/SK-SK/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Skúšanie NEOS – flipchart informovaného súhlasu",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/SK-SK/pdfs/consent.pdf"
    },
    {
      title: "Skúšanie NEOS – flipchart informovaného schválenia",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/SK-SK/pdfs/assent.pdf"
    },
    {
      title: "Skúšanie NEOS – úvodná brožúra",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/SK-SK/pdfs/multifold.pdf"
    },
    {
      title: "Porozumenie SM a skúšaniu NEOS – komiks",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/SK-SK/pdfs/un ms.pdf"
    },
    {
      title: "Porozumenie klinickým skúšaniam – komiks pre vek 7 – 12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/SK-SK/pdfs/comic 7-12.pdf"
    },
    {
      title: "Porozumenie klinickým skúšaniam – komiks pre vek 13 – 17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/SK-SK/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Čo sa deje pri SM?" },
      { type: "paragraph", content: "SM poškodzuje myelínovú pošvu, ktorá je ochranným obalom, ktorý obklopuje nervy. Keď je tento obal nervu poškodený, nervy nemôžu odosielať signály tak ako bežne." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Príznaky SM sa líšia podľa toho, kde sa poškodenie nachádza. Môžu to byť:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Únava", "Znecitlivenie"],
        ["Rozmazané videnie", "Slabosť"],
        ["Mravčenie, napríklad v rukách alebo na chodidlách", "Bolesť"],
        ["Strata rovnováhy", "Depresia"],
        ["Problémy s črevom alebo močovým mechúrom", "Úzkosť"],
        ["Očná neuritída – zápal očného nervu, ktorý môže spôsobovať stratu videnia (ale zrak sa zvyčajne vracia)", "Ťažkosti s rečou"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Čo je relaps?" },
      { type: "paragraph", content: "Počas relapsu môžu príznaky trvať niekoľko týždňov a potom sa zmiernia, pretože nerv sa sám opraví. Po relapsoch nasledujú obdobia bez nových príznakov, ktoré sa nazývajú remisie." },
      { type: "paragraph", content: "Na SM neexistuje liečba. Klinické skúšania sa vykonávajú na mladých ľuďoch so SM s cieľom otestovať nové liečby, ktoré môžu pomôcť." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Čo je skúšanie NEOS?" },
      { type: "paragraph", content: "Skúšanie NEOS skúma 3 rozličné skúšané lieky s cieľom zistiť, či môžu bezpečne pomôcť deťom a dospievajúcim so sklerózou multiplex." },
      { type: "paragraph", content: "Účastníci spĺňajú požiadavky, ak sú vo veku 10 až 17 rokov." },

      { type: "header", content: "Ako dlho skúšanie trvá?" },
      { type: "paragraph", content: "Skúšanie trvá 2 roky. Existuje možnosť užívať skúšaný liek ďalších 2 až 5 rokov." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Počet kontrolných návštev bude závisieť od toho, kedy je užitá posledná dávka skúšaného lieku." },
      { type: "paragraph", content: "Otvorené: Opak dvojito zaslepeného, to znamená, že účastník skúšania aj skúšajúci lekár vedia, v ktorej skupine liečby je účastník skúšania." },
      { type: "paragraph", content: "Dvojito zaslepené: To znamená, že ani účastník skúšania, ani skúšajúci lekár nevie, v ktorej skupine liečby je účastník skúšania." },

      { type: "header", content: "Aké skúšané lieky sa testujú?" },
      { type: "paragraph", content: "Testujú sa tri skúšané lieky:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab je injekčná liečba, ktorá je schválená v niektorých krajinách pre dospelých so SM. Podáva sa pomocou autoinjektora podobného tým, ktoré používajú ľudia s cukrovkou na vpichnutie inzulínu. Výskumníci chcú vedieť, ako ofatumumab účinkuje u detí a dospievajúcich a aký je bezpečný." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod je orálna tableta, ktorá je schválená v mnohých krajinách pre dospelých so SM. Výskumníci chcú vedieť, ako účinkuje u detí a dospievajúcich a aký je bezpečný." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod je orálna kapsula, ktorá je už schválená v mnohých krajinách pre deti a dospievajúcich so SM. Výskumníci chcú zistiť, či je ofatumumab alebo siponimod aspoň taký vhodný ako fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Výskumníci chcú zistiť, či ofatumumab alebo siponimod sú aspoň také dobré ako fingolimod." },
      { type: "paragraph", content: "Každý účastník je zaradený do 1 z 3 skupín liečby." },
      { type: "paragraph", content: "Každá skupina dostane všetky 3 liečby. Jeden z nich bude obsahovať aktívne liečivo a 2 z nich budú placebá. Placebo vyzerá rovnako ako skúšaný liek, ale neobsahuje žiadne skutočné liečivo." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Čo musím robiť počas skúšania?" },
      { type: "paragraph", content: "Pri návštevách kliniky v rámci skúšania a počas telefonátov so skúšajúcim lekárom budú prebiehať zdravotné kontroly a testy. Je dôležité prísť na všetky návštevy v rámci skúšania a vyplniť elektronický denník." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Účasť v skúšaní je úplne dobrovoľná. Kedykoľvek sa môžeš rozhodnúť, že ukončíš skúšanie." },
      { type: "header", content: "Ako treba užívať skúšané lieky" },
      { type: "paragraph", content: "Testujú sa tri skúšané lieky:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Typ", "Autoinjekčné pero"],
        ["Ako často", "Každé 4 alebo 6 týždňov (v závislosti od hmotnosti)"],
        ["Nádoba", "Box s jednorazovým autoinjekčným perom"],
        ["Skladovanie", "Uchovávať v chladničke"],
        ["Poznámky", "Vyberte z chladničky 15 – 30 minút pred injekciou. Ďalšie údaje si pozrite v pokynoch."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Typ", "Tableta"],
        ["Ako často", "Každý deň"],
        ["Nádoba", "Vytláčacie balenie na prvých 6 dní, potom liekovka"],
        ["Skladovanie", "Uchovávať v chladničke"],
        ["Poznámky", "Užite jednu tabletu ráno alebo večer, každý deň v rovnakom čase."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Typ", "Kapsula"],
        ["Ako často", "Každý deň"],
        ["Nádoba", "Liekovka"],
        ["Skladovanie", "Uchovávať v chladničke"],
        ["Poznámky", "Užite jednu kapsulu ráno alebo večer, každý deň v rovnakom čase."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Zaznamenajte každú užitú dávku všetkých 3 skúšaných liekov do elektronického denníka. V prípade injekcií uveďte dátum, čas, časť tela a osobu, ktorá vpichla injekciu. Ak je niektorá dávka vynechaná, kontaktujte skúšajúceho lekára alebo tím skúšania. Skúšajúcemu lekárovi hneď povedzte o akýchkoľvek vedľajších účinkoch." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default skSK
