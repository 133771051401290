import sidebar from "../sidebars/global"
import pages from "../pages/global"

const esLA: LocaleDefinition = {
  code: "es-LA",
  label: "Spanish",
  country: "Latin America",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Bienvenido",
    "welcome.heading": "Bienvenido al sitio web del Estudio NEOS",
    "welcome.message":
      "NEOS es un estudio clínico para niños y adolescentes con esclerosis múltiple (multiple sclerosis, MS) de entre 10 y 17 años. Ingrese el código PIN que figura en su material impreso o en el material que le entregó el equipo del centro.",
    "welcome.pin_prompt": "INGRESE EL PIN A CONTINUACIÓN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "INGRESAR",
    "welcome.support": "¿Necesita ayuda? Comuníquese con su proveedor de salud local.",
    "welcome.close": "Cerrar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descripción",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Ensayos clínicos",
    "sidebar.videos.neos": "Estudio NEOS",
    "sidebar.videos.medicines": "Cómo tomar los medicamentos",

    "sidebar.reads-title": "Lecturas rápidas",
    "sidebar.reads.pediatric-ms": "Acerca de la MS pediátrica",
    "sidebar.reads.study": "Información general sobre el estudio",
    "sidebar.reads.schedule": "Programa de visitas del estudio",

    "sidebar.resources-title": "Recursos",

    "sidebar.contact": "Contacto",
    "sidebar.privacy": "Política de privacidad",
    "sidebar.terms": "Términos del servicio",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos los derechos reservados.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Acerca de los ensayos clínicos",
    "understanding-trials.description": "Este video explica qué son los ensayos clínicos.",
    "understanding-trials.video-id": "VT72UimsBvY",

    "understanding-neos.title": "Acerca del Estudio NEOS",
    "understanding-neos.description": "Este video proporciona información sobre el estudio NEOS.",
    "understanding-neos.video-id": "OquTm09OpGk",

    "understanding-ms.title": "Acerca de la MS pediátrica",
    "understanding-ms.description": "Descripción general breve acerca de la EM en niños y adolescentes.",

    "study-medicines.title": "Cómo tomar los medicamentos del estudio",
    "study-medicines.description": "Este video explica cómo tomar los medicamentos del ensayo.",
    "study-medicines.video-id": "HHxwuGiLnHg",

    "study-overview.title": "Información general sobre el estudio",

    "schedule.title": "Programa de visitas del estudio",

    "resources.title": "Recursos",

    "neos-poster.title": "Cartel de reclutamiento del Estudio NEOS",
    "neos-poster.description": "Para niños y adolescentes de entre 10 y 17 años con esclerosis múltiple (MS) remitente-recurrente.",
    "neos-poster.href": "/i18n/ES-LA/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudio NEOS: Rotafolio del Consentimiento Informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/ES-LA/pdfs/consent.pdf"
    },
    {
      title: "Estudio NEOS: Rotafolio del Asentimiento Informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/ES-LA/pdfs/assent.pdf"
    },
    {
      title: "Estudio NEOS: Introducción Múltiple",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/ES-LA/pdfs/multifold.pdf"
    },
    {
      title: "Tira de cómic acerca de la MS y el Estudio NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/ES-LA/pdfs/un ms.pdf"
    },
    {
      title: "Tira de cómic acerca de los ensayos clínicos para niños de 7 a 12 años",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/ES-LA/pdfs/comic 7-12.pdf"
    },
    {
      title: "Tira de cómic acerca de los ensayos clínicos para adolescentes de 13 a 17 años",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/ES-LA/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "¿Qué sucede en la MS?" },
      { type: "paragraph", content: "La MS daña la vaina de mielina, que es el recubrimiento protector que rodea a los nervios. Cuando se daña el recubrimiento de los nervios, estos no pueden enviar las señales que enviarían normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Los síntomas de la MS varían según del lugar donde se produce el daño. Pueden incluir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansancio", "Entumecimiento"],
        ["Visión borrosa", "Debilidad"],
        ["Hormigueo, por ejemplo en las manos o los pies", "Dolor"],
        ["Pérdida de equilibrio", "Depresión"],
        ["Problemas en el intestino o la vejiga", "Ansiedad"],
        ["Neuritis óptica: inflamación del nervio óptico que provoca pérdida de la visión (pero generalmente regresa)", "Dificultad para hablar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "¿Qué es la recidiva?" },
      { type: "paragraph", content: "Durante una recaída, es posible que los síntomas duren varias semanas y luego desaparezcan, ya que el nervio se repara a sí mismo. Les siguen períodos sin síntomas nuevos, que se llaman remisiones." },
      { type: "paragraph", content: "No hay cura para la MS. Se llevan a cabo estudios clínicos en jóvenes con MS para probar nuevas terapias que puedan ayudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "¿Qué es el Estudio NEOS?" },
      { type: "paragraph", content: "El Estudio NEOS analiza 3 medicamentos diferentes para determinar si pueden ayudar de manera segura a niños y adolescentes con esclerosis múltiple." },
      { type: "paragraph", content: "Los participantes son elegibles si tienen entre 10 y 17 años." },

      { type: "header", content: "¿Cuánto dura el estudio?" },
      { type: "paragraph", content: "El estudio dura 2 años. Está la opción de tomar el medicamento del estudio durante 2 a 5 años adicionales." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*La cantidad de visitas de seguimiento dependerá del momento en que se tome la dosis final del medicamento del estudio." },
      { type: "paragraph", content: "Abierto: es lo contrario al doble ciego; significa que tanto el participante del estudio como el médico del estudio saben a qué grupo de tratamiento pertenece el participante." },
      { type: "paragraph", content: "Doble ciego: significa que ni el participante del estudio ni el médico del estudio saben a qué grupo de tratamiento pertenece el participante." },

      { type: "header", content: "¿Qué medicamentos del estudio se prueban?" },
      { type: "paragraph", content: "Se prueban tres medicamentos del estudio:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "es una terapia inyectable aprobada en algunos países para adultos con EM. Se administra mediante un autoinyector similar al que utilizan los diabéticos para inyectarse insulina. Los investigadores quieren saber la eficacia del ofatumumab en niños y adolescentes y su seguridad." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "es un comprimido oral aprobado en muchos países para los adultos con EM. Los investigadores quieren saber si funciona bien en niños y adolescentes y si es seguro." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "es una cápsula oral que ya está aprobada en muchos países para niños y adolescentes con EM. Los investigadores quieren comprobar si ofatumumab o siponimod son al menos tan buenos como fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Los investigadores quieren saber si ofatumumab o siponimod son al menos tan buenos como fingolimod." },
      { type: "paragraph", content: "Se colocará a cada participante en 1 de los 3 grupos de tratamiento." },
      { type: "paragraph", content: "Cada grupo recibe los 3 medicamentos del estudio. Uno contendrá el medicamento activo y 2 serán placebos. Un placebo se parece al medicamento del estudio, pero no contiene ningún medicamento real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "¿Qué debo hacer durante el estudio?" },
      { type: "paragraph", content: "Habrá pruebas y controles médicos durante las visitas a la clínica del estudio y durante las llamadas telefónicas con el médico del estudio. Es importante asistir a todas las visitas del estudio y completar el diario electrónico." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "La participación en el estudio es completamente voluntaria. Puede decidir abandonar el estudio en cualquier momento." },
      { type: "header", content: "Cómo tomar los medicamentos del estudio" },
      { type: "paragraph", content: "Se prueban tres medicamentos del estudio:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Bolígrafo autoinyector"],
        ["Frecuencia", "Cada 4 o 6 semanas (según el peso)"],
        ["Envase", "Caja con bolígrafo autoinyector de un solo uso"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Retire del refrigerador de 15 a 30 minutos antes de la inyección. Consulte las Instrucciones de uso para obtener más información."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Comprimido"],
        ["Frecuencia", "Todos los días"],
        ["Envase", "Blíster durante los primeros 6 días, luego frasco"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Tomar un comprimido a la mañana o a la noche, a la misma hora todos los días."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Frecuencia", "Todos los días"],
        ["Envase", "Frasco"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Tomar una cápsula a la mañana o a la noche, a la misma hora todos los días."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Registre cada dosis que tome de los 3 medicamentos del estudio en el diario electrónico. En el caso de las inyecciones, incluya la fecha, la hora, la parte del cuerpo y el nombre de la persona que administró la inyección. Si omite alguna dosis, comuníquese con el médico o el equipo del estudio. Infórmele de inmediato al médico del estudio si presenta algún efecto secundario." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default esLA
