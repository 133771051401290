import RelatedItems from "framework/RelatedItems"
import ContentItemCard from "framework/ContentItemCard"
import LandingPageContainer from "framework/LandingPageContainer"
import useWebsiteContext from "./useWebsiteContext"

const DEFAULT_COMPONENTS: ComponentOverrides = {
  ContentItemCard,
  LandingPageContainer,
  RelatedItems,
}

const useOverrideableComponent = <K extends keyof ComponentOverrides>(
  key: K
): ComponentOverrides[K] => {
  const { componentOverrides } = useWebsiteContext()

  if (componentOverrides && componentOverrides[key]) {
    return componentOverrides[key] as ComponentOverrides[K]
  }

  return DEFAULT_COMPONENTS[key]
}

export default useOverrideableComponent
