import sidebar from "../sidebars/global"
import pages from "../pages/global"

const deDE: LocaleDefinition = {
  code: "de-DE",
  label: "German",
  country: "Germany",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Willkommen",
    "welcome.heading": "Willkommen auf der Website der NEOS-Studie",
    "welcome.message":
      "NEOS ist eine klinische Studie für Kinder und Jugendliche mit Multipler Sklerose (MS) im Alter von 10 bis 17 Jahren (einschließlich 18. Geburtstag). Bitte geben Sie den PIN-Code ein, den Sie in Ihren ausgedruckten Unterlagen finden oder der Ihnen vom Standortteam mitgeteilt wurde.",
    "welcome.pin_prompt": "GEBEN SIE DIE PIN UNTEN EIN!",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "Eingeben",
    "welcome.support": "Brauchen Sie Hilfe? Bitte wenden Sie sich an Ihren lokalen Gesundheitsdienstleister.",
    "welcome.close": "Schließen",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Beschreibung",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Klinischen Studien",
    "sidebar.videos.neos": "NEOS-Studie",
    "sidebar.videos.medicines": "Wie die Studienpräparate einzunehmen sind",

    "sidebar.reads-title": "Schnell-Lektüre",
    "sidebar.reads.pediatric-ms": "Pädiatrische MS verstehen",
    "sidebar.reads.study": "Die Studie im Überblick",
    "sidebar.reads.schedule": "Zeitplan für Studienbesuche",

    "sidebar.resources-title": "Ressourcen",

    "sidebar.contact": "Kontakt",
    "sidebar.privacy": "Datenschutzrichtlinie",
    "sidebar.terms": "Nutzungsbedingungen",
    "sidebar.copyright": "© Copyright 2021 Novartis. Alle Rechte vorbehalten.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Klinische Studien verstehen",
    "understanding-trials.description": "Dieses Video erklärt, was klinische Studien sind.",
    "understanding-trials.video-id": "e3dOQ-Z2tR4",

    "understanding-neos.title": "Die NEOS-Studie verstehen",
    "understanding-neos.description": "Dieses Video liefert Informationen zu der NEOS-Studie.",
    "understanding-neos.video-id": "2GMG6XXvPKE",

    "understanding-ms.title": "Pädiatrische MS verstehen",
    "understanding-ms.description": "Kurzer Überblick zum Verständnis von MS bei Kindern und Jugendlichen.",

    "study-medicines.title": "Wie die Studienpräparate einzunehmen sind",
    "study-medicines.description": "In diesem Video wird die Einnahme der Studienmedikation erklärt.",
    "study-medicines.video-id": "GKnmpb3GBeA",

    "study-overview.title": "Die Studie im Überblick",

    "schedule.title": "Zeitplan für Studienbesuche",

    "resources.title": "Ressourcen",

    "neos-poster.title": "Rekrutierungs-Poster für die NEOS-Studie",
    "neos-poster.description": "Für Kinder und Jugendliche zwischen 10 und 17 Jahren mit schubförmig-remittierender Multipler Sklerose (MS).",
    "neos-poster.href": "/i18n/DE-DE/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS-Studie: Flipchart zur Einverständniserklärung",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/DE-DE/pdfs/consent.pdf"
    },
    {
      title: "NEOS-Studie: Flipchart zur Einverständniserklärung",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/DE-DE/pdfs/assent.pdf"
    },
    {
      title: "NEOS-Studie – Einführungsbroschüre",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/DE-DE/pdfs/multifold.pdf"
    },
    {
      title: "MS und die NEOS-Studie verstehen, Comic",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/DE-DE/pdfs/un ms.pdf"
    },
    {
      title: "Klinische Studien verstehen, Comic, Alter 7–12 Jahre",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/DE-DE/pdfs/comic 7-12.pdf"
    },
    {
      title: "Klinische Studien verstehen, Comic, Alter 13–17 Jahre",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/DE-DE/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Was passiert bei MS?" },
      { type: "paragraph", content: "MS schädigt die Myelinscheide, die schützende Hülle, die die Nerven umgibt. Wenn diese Nervenumhüllung beschädigt ist, können die Nerven keine Signale senden, wie sie es tun sollten." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Die Symptome von MS variieren, je nachdem, wo die Schädigung liegt. Dazu können gehören:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Müdigkeit", "Taubheit"],
        ["Verschwommenes Sehen", "Schwäche"],
        ["Kribbeln, zum Beispiel in den Händen oder Füßen", "Schmerzen"],
        ["Gleichgewichtsverlust", "Depressionen"],
        ["Probleme mit Darm oder Blase", "Angstzustände"],
        ["Sehnervenentzündung: Entzündung des Sehnervs, die zum Verlust des Sehvermögens führen kann (das Sehvermögen kehrt jedoch in der Regel zurück).", "Schwierigkeiten beim Sprechen"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Was ist ein Schub?" },
      { type: "paragraph", content: "Während eines Schubs können die Symptome mehrere Wochen lang anhalten und dann von selbst nachlassen, da sich der Nerv selbst repariert. Auf die Schübe folgen Perioden ohne neue Symptome, die Remissionen genannt werden." },
      { type: "paragraph", content: "MS ist nicht heilbar. Es werden klinische Studien für junge Menschen mit MS durchgeführt, um neue Therapien zu testen, die helfen könnten." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Was ist die NEOS-Studie?" },
      { type: "paragraph", content: "In der NEOS-Studie werden drei verschiedene Studienmedikamente daraufhin untersucht, ob sie Kindern und Jugendlichen mit Multipler Sklerose sicher helfen können." },
      { type: "paragraph", content: "Teilnahmeberechtigt sind Personen, die zwischen 10 und 17 Jahren alt sind." },

      { type: "header", content: "Wie lange dauert die Studie?" },
      { type: "paragraph", content: "Die Studie läuft über zwei Jahre. Es besteht die Möglichkeit, das Studienpräparat für weitere zwei bis fünf Jahre einzunehmen." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* Die Anzahl der Nachuntersuchungen hängt davon ab, wann die letzte Dosis des Studienpräparats eingenommen wurde." },
      { type: "paragraph", content: "Offene Verabreichung: Das Gegenteil von doppelblind bedeutet, dass sowohl der Studienteilnehmer als auch der Studienarzt wissen, in welcher Behandlungsgruppe sich der Studienteilnehmer befindet." },
      { type: "paragraph", content: "Doppelblind: Dies bedeutet, dass weder der Studienteilnehmer noch der Studienarzt wissen, in welcher Behandlungsgruppe sich der Studienteilnehmer befindet." },

      { type: "header", content: "Welche Studienpräparate werden getestet?" },
      { type: "paragraph", content: "Es werden drei Studienpräparate getestet:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Is an injectable therapy that is approved for adults with MS in some countries. It is given using an autoinjector, similar to ones used by people with diabetes to inject insulin. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Is an oral tablet that is approved for adults with MS in many countries. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "is an oral capsule that is already approved for children and adolescents with MS in many countries. Researchers want to see if ofatumumab or siponimod are at least as good as fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Die Forscher wollen sehen, ob Ofatumumab oder Siponimod mindestens so gut sind wie Fingolimod." },
      { type: "paragraph", content: "Jeder Teilnehmer wird einer von drei Behandlungsgruppen zugeteilt." },
      { type: "paragraph", content: "Jede Gruppe erhält alle drei Präparate. Eines enthält den Wirkstoff und zwei sind Placebos. Ein Placebo sieht aus wie das Studienpräparat, enthält aber keinen tatsächlichen Wirkstoff." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Was muss ich während der Studie tun?" },
      { type: "paragraph", content: "Es werden Gesundheitskontrollen und Tests bei Besuchen in der Studienklinik und bei Telefonaten mit dem Studienarzt durchgeführt. Es ist wichtig, dass Sie zu allen Studienbesuchen kommen und das eDiary ausfüllen." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Die Teilnahme an der Studie ist völlig freiwillig. Sie können die Studie jederzeit verlassen." },
      { type: "header", content: "Wie die Studienpräparate einzunehmen sind" },
      { type: "paragraph", content: "Es werden drei Studienpräparate getestet:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Typ", "Autoinjektionsstift"],
        ["Wie häufig", "Alle vier oder sechs Wochen (je nach Körpergewicht)"],
        ["Container", "Box mit Einweg-Autoinjektionsstift"],
        ["Lagerung", "Im Kühlschrank lagern"],
        ["Hinweise", "15 bis 30 Minuten vor der Injektion aus dem Kühlschrank nehmen. Siehe Anweisungen für weitere Details."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Typ", "Tablette"],
        ["Wie häufig", "Jeden Tag"],
        ["Container", "Blisterpackung für die ersten sechs Tage, dann Flasche."],
        ["Lagerung", "Im Kühlschrank lagern."],
        ["Hinweise", "Nehmen Sie eine Tablette morgens oder abends, täglich zur gleichen Zeit."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Typ", "Kapsel"],
        ["Wie häufig", "Jeden Tag"],
        ["Container", "Flasche"],
        ["Lagerung", "Im Kühlschrank lagern."],
        ["Hinweise", "Nehmen Sie eine Tablette morgens oder abends, täglich zur gleichen Zeit."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Halten Sie jede eingenommene Dosis aller drei Studienpräparate im eDiary fest." },
      { type: "paragraph", content: "Bei Injektionen geben Sie das Datum, die Uhrzeit, den Körperteil und die Person an, die die Injektion verabreicht hat." },
      { type: "paragraph", content: "Wenn eine Dosis vergessen wurde, kontaktieren Sie den Studienarzt oder das Studienteam." },
      { type: "paragraph", content: "Informieren Sie den Studienarzt sofort über jegliche Nebenwirkungen." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default deDE
