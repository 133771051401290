import { Button, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useCopy from "framework/hooks/useCopy"
import useOverrideableComponent from "framework/hooks/useOverrideableComponent"
import useUI from "framework/hooks/useUI"
import useLogo from "framework/hooks/useLogo"
import PinInput from "react-pin-input"

const styles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      width: 392,
      marginTop: 32
    },
  },
  formLabel: {
    display: "block",
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "168%",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: theme.palette.grey[50],
    marginBottom: 16
  },
  input: {
    width: "100%",
  },
  logo: {
    display: "block",
    padding: "8px 0 40px",
    width: 131,
    height: 47,
    [theme.breakpoints.up("lg")]: {},
  },
  message: {
    margin: "12px 24px 0"
  },
  cta: {
    marginTop: 60,
    width: "100%",
    fontSize: 18,
    lineHeight: "168%",
    letterSpacing: "0.1em",
    borderRadius: 4,
    fontWeight: 700
  },
  support: {
    color: theme.palette.grey[50],
    marginTop: 42,
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 8,
    display: 'block'
  }
}))

const SignInPage = () => {
  const logo = true
  const [pin, setPin] = useState<string>("")
  const copy = useCopy()
  const [error, setError] = useState<string | undefined>(undefined)
  const { authenticate } = useUI()
  const classes = styles()
  const LandingPageContainer = useOverrideableComponent("LandingPageContainer")
  const { Logo } = useLogo()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!authenticate(pin)) {
      setError(copy["welcome.incorrect_pin"])
    }
  }

  return (
    <LandingPageContainer>
      {logo && <Logo className={classes.logo} />}
      <Typography variant="h2">{copy["welcome.heading"]}</Typography>
      <Typography className={classes.message} variant="body2">{copy["welcome.message"]}</Typography>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <label className={classes.formLabel}>{copy["welcome.pin_prompt"]}</label>
        <PinInput
          length={6}
          type="custom"
          onChange={(value) => {
            setPin(value)
            setError("")
          }}
          inputStyle={{background: '#f6f6f6', borderRadius: 4, border: '1px solid #f6f6f6', fontWeight: 700, fontSize: 24, padding: "4px 0px", margin: "0 4px" }}
          inputFocusStyle={{border: '1px solid #0460A9'}}
          autoSelect={true}
          regexCriteria={/.*$/}
        />
        {error && <Typography className={classes.error}  variant="caption">{error}</Typography>}

        <Button
          variant="contained"
          className={classes.cta}
          type="submit"
        >
          {copy["welcome.cta"]}
        </Button>
      </form>
      {copy["welcome.support"] && (
        <Typography className={classes.support} variant="caption">
          {copy["welcome.support"]}
        </Typography>
      )}
      {copy["welcome.disclaimer"] && (
        <Typography className={classes.support} variant="caption">
          {copy["welcome.disclaimer"]}
        </Typography>
      )}
    </LandingPageContainer>
  )
}

export default SignInPage
