import sidebar from "../sidebars/global"
import pages from "../pages/global"

const hrHR: LocaleDefinition = {
  code: "hr-HR",
  label: "Croatian",
  country: "Croatia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Dobrodošli",
    "welcome.heading": "Dobrodošli na web mjesto ispitivanja NEOS",
    "welcome.message":
      "NEOS je kliničko ispitivanje za djecu i adolescente s multiplom sklerozom (MS) između 10 i 17 godina. Unesite PIN kod koji se nalazi u vašem tiskanom materijalu ili vam ga je dalo osoblje centra.",
    "welcome.pin_prompt": "ISPOD UNESITE PIN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "UNESITE",
    "welcome.support": "Trebate pomoć? Obratite se svom lokalnom zdravstvenom radniku.",
    "welcome.close": "Zatvori",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Opis",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videozapisi",
    "sidebar.videos.trials": "Klinička ispitivanja",
    "sidebar.videos.neos": "Ispitivanje NEOS",
    "sidebar.videos.medicines": "Kako uzimati ispitivane lijekove",

    "sidebar.reads-title": "Brza čitanja",
    "sidebar.reads.pediatric-ms": "Razumijevanje pedijatrijske MS",
    "sidebar.reads.study": "Pregled ispitivanja",
    "sidebar.reads.schedule": "Raspored posjeta u ispitivanju",

    "sidebar.resources-title": "Resursi",

    "sidebar.contact": "Obratite nam se",
    "sidebar.privacy": "Pravila privatnosti",
    "sidebar.terms": "Uvjeti pružanja usluge",
    "sidebar.copyright": "© Autorska prava 2021 Novartis. Sva prava pridržana",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Razumijevanje kliničkih ispitivanja",
    "understanding-trials.description": "Ovaj video objašnjava što su klinička ispitivanja.",
    "understanding-trials.video-id": "uqG5Zn05J0Q",

    "understanding-neos.title": "Razumijevanje ispitivanja NEOS",
    "understanding-neos.description": "TOvaj video navodi pojedinosti o ispitivanju NEOS.",
    "understanding-neos.video-id": "hF5bmvs97zY",

    "understanding-ms.title": "Razumijevanje pedijatrijske MS",
    "understanding-ms.description": "SKratak pregled razumijevanja MS u djece i adolescenata.",

    "study-medicines.title": "How to Take Your Study Medications",
    "study-medicines.description": "Ovaj video objašnjava kako se uzimaju ispitivani lijekovi.",
    "study-medicines.video-id": "egMII3m64g0",

    "study-overview.title": "Pregled ispitivanja",

    "schedule.title": "Raspored posjeta u ispitivanju",

    "resources.title": "Resursi",

    "neos-poster.title": "Plakat regrutiranja za ispitivanje NEOS",
    "neos-poster.description": "Za djecu i adolescente između 10 i 17 godina s recidivno-remitentnom multiplom sklerozom (MS).",
    "neos-poster.href": "/i18n/HR-HR/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Ispitivanje NEOS - Grafikon informiranog pristanka",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/HR-HR/pdfs/consent.pdf"
    },
    {
      title: "Ispitivanje NEOS - Grafikon informirane suglasnosti",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/HR-HR/pdfs/assent.pdf"
    },
    {
      title: "Ispitivanje NEOS - Višestruki uvod",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/HR-HR/pdfs/multifold.pdf"
    },
    {
      title: "Strip za razumijevanje kliničkih ispitivanja MS-a i NEOS-a",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/HR-HR/pdfs/un ms.pdf"
    },
    {
      title: "Strip za razumijevanje kliničkih ispitivanja za dob od 7-12 godina",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/HR-HR/pdfs/comic 7-12.pdf"
    },
    {
      title: "Strip za razumijevanje kliničkih ispitivanja za dob od 13-17 godina",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/HR-HR/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Što se događa kod MS-a?" },
      { type: "paragraph", content: "MS oštećuje mijelinsku ovojnicu, koja je zaštitni pokrov koji okružuje živce. Kad se ošteti ovaj živčani pokrov, živci ne mogu slati signale kako bi obično trebali." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Simptomi MS-a se razlikuju ovisno o tome gdje je nastalo oštećenje. Oni mogu uključivati:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["umor", "obamrlost"],
        ["zamagljen vid", "slabost"],
        ["utrnulost, na primjer, u rukama ili nogama", "bol"],
        ["gubitak ravnoteže", "depresiju"],
        ["probleme s crijevima ili mokraćnim mjehurom", "tjeskobu"],
        ["optički neuritis - upala vidnog živca, koja može uzrokovati gubitak vida (ali vid se obično vraća)", "poteškoće u govoru."]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Što je recidiv?" },
      { type: "paragraph", content: "Tijekom recidiva simptomi mogu trajati nekoliko tjedana, a zatim se sami smiruju dok se živac sam oporavlja. Nakon recidiva slijede razdoblja bez novih simptoma koja se nazivaju remisije." },
      { type: "paragraph", content: "Ne postoji lijek za MS. Provode se klinička ispitivanja za mlade ljude s MS-om kako bi se testirale nove terapije koje bi mogle pomoći." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Što je ispitivanje NEOS?" },
      { type: "paragraph", content: "Ispitivanje NEOS proučava 3 različita ispitivana lijeka kako bi se utvrdilo mogu li sigurno pomoći djeci i adolescentima s multiplom sklerozom." },
      { type: "paragraph", content: "Sudionici ispunjavaju uvjete ako imaju između 10 i 17 godina." },

      { type: "header", content: "Koliko traje ispitivanje?" },
      { type: "paragraph", content: "Ispitivanje traje 2 godine. Postoji mogućnost uzimanja ispitivanih lijekova tijekom dodatnih 2 do 5 godina." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Broj posjeta za praćenje ovisit će o tome kada se uzima posljednja doza ispitivanog lijeka." },
      { type: "paragraph", content: "Otvoreno: Za razliku od dvostruko slijepog, ovo znači da i sudionik ispitivanja i liječnik ispitivanja znaju u kojoj se skupini liječenja nalazi sudionik ispitivanja." },
      { type: "paragraph", content: "Dvostruko slijepo: To znači da niti sudionik ispitivanja niti liječnik ne znaju u kojoj se skupini liječi sudionik ispitivanja." },

      { type: "header", content: "Koji se lijekovi ispituju?" },
      { type: "paragraph", content: "Ispituju se tri ispitivana lijeka:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab je injekcijska terapija koja je u nekim zemljama odobrena za odrasle s MS-om. Daje se pomoću autoinjektora sličnog onom koji ljudi s dijabetesom koriste za ubrizgavanje inzulina. Ispitivači žele znati koliko dobro ofatumumab djeluje kod djece i adolescenata i koliko je siguran." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod je oralna tableta koja je u mnogim zemljama odobrena za odrasle s MS-om. Istraživači žele znati koliko dobro djeluje kod djece i adolescenata i koliko je siguran." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod je oralna kapsula koja je već odobrena u mnogim zemljama za djecu i adolescente s MS-om. Istraživači žele vidjeti jesu li ofatumumab ili siponimod barem toliko dobri kao fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Ispitivači žele vidjeti jesu li ofatumumab ili siponimod barem jednako dobri kao fingolimod." },
      { type: "paragraph", content: "Svaki sudionik dodijeljen je u 1 od 3 skupine liječenja." },
      { type: "paragraph", content: "Svaka skupina prima sva 3 lijeka. Jedan od njih sadržavat će aktivni lijek, a 2 će biti placebo. Placebo izgleda kao ispitivani lijek, ali u sebi nema nikakvog stvarnog lijeka." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Što se od mene traži tijekom ispitivanja?" },
      { type: "paragraph", content: "Tijekom posjeta u kliničkom ispitivanju i tijekom telefonskih poziva s liječnikom ispitivanja obavljat će se zdravstvene provjere i pretrage. Važno je doći na sve posjete u ispitivanju i popuniti eDnevnik." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Sudjelovanje u ispitivanju je potpuno dobrovoljno. Možete u bilo koje vrijeme prekinuti sudjelovanje u ispitivanju." },
      { type: "header", content: "Kako uzimati ispitivane lijekove" },
      { type: "paragraph", content: "Ispituju se tri ispitivana lijeka:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Vrsta", "Olovka za automatsku injekciju"],
        ["Koliko često", "Svakih 4 ili 6 tjedana (ovisno o težini)"],
        ["Spremnik", "Kutija s olovkom za automatsku injekciju za jednokratnu upotrebu"],
        ["Pohrana", "Držati u hladnjaku"],
        ["Napomene", "Izvadite iz hladnjaka 15-30 minuta prije injekcije. Za više pojedinosti pogledajte upute"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Vrsta", "Tableta"],
        ["Koliko često", "Svaki dan"],
        ["Spremnik", "Blister pakiranje prvih 6 dana, a zatim bočica"],
        ["Pohrana", "Držati u hladnjaku"],
        ["Napomene", "Uzmite jednu tabletu ujutro ili navečer, svakog dana u isto vrijeme"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Vrsta", "Kapsula"],
        ["Koliko često", "Svaki dan"],
        ["Spremnik", "Bočica"],
        ["Pohrana", "Držati u hladnjaku"],
        ["Napomene", "Uzmite jednu kapsulu ujutro ili navečer, svakog dana u isto vrijeme"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Zabilježite svaku uzetu dozu sva 3 ispitivana lijeka u eDnevnik." },
      { type: "paragraph", content: "Za injekcije navedite datum, vrijeme, dio tijela i tko je dao injekciju." },
      { type: "paragraph", content: "Ako propustite bilo koju dozu, obratite se liječniku ili timu u ispitivanju." },
      { type: "paragraph", content: "Odmah obavijestite liječnika ispitivača o bilo kakvim nuspojavama." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default hrHR
