import React from "react"

import VideoWithText from "website/components/content/VideoWithText"

const StudyMedicines = () => {
  return (
    <VideoWithText id="study-medicines" showTitle={ true } />
  )
}

export default StudyMedicines;