const LOCALE_KEY = "locale"

export const detectLocale = (website: Website, authCode?: AuthCode, country?: string): LocaleDefinition => {
  const browserLocale = window.navigator.language
  const sessionLocale = recallLocale(website, authCode, country)

  if (sessionLocale) {
    return sessionLocale
  }

  const source = getPermittedLocales(website, authCode, country)

  // Attempt to find a matching locale in the list of locales configured
  // for the website. The first pass is to find exact matches
  for (const locale of source) {
    if (locale.code === browserLocale) {
      return localeOptionToDefinition(locale, website)
    }
  }

  // If no match is found, run the 2nd time attempting to match the language portion only
  for (const locale of source) {
    if (locale.code.substr(0, 2) === browserLocale.substr(0, 2)) {
      return localeOptionToDefinition(locale, website)
    }
  }

  // If default locale is permitted, return it
  for (const locale of source) {
    if (locale.code === website.i18n.defaultLocale) {
      return localeOptionToDefinition(locale, website)
    }
  }

  // If all else failes, return the 1st permitted locale
  return localeOptionToDefinition(source[0], website)
}

/**
 * Returns the locale code stored in session, cross-checked against an AuthCode
 * and its permitted locales, if supplied
 * @param website
 * @param authCode optional
 */
export const recallLocale = (website: Website, authCode?: AuthCode, country?: string) => {
  const source = getPermittedLocales(website, authCode, country)
  const sessionLocale = window.sessionStorage.getItem(LOCALE_KEY)

  for (const locale of source) {
    if (sessionLocale === locale.code) return localeOptionToDefinition(locale, website)
  }

  return undefined
}

/**
 * Returns the list of locales that are currently available to be set
 * either automatically or through the UI
 * @param website
 * @param authCode if provided, the locales will be restricted to whatever the authCode allows
 */
export const getPermittedLocales = (website: Website, authCode?: AuthCode, country?: string): LocaleOption[] => {
  const locales = country ? website.i18n.countries[country].map(c => website.i18n.locales[c]) : Object.values(website.i18n.locales)

  return locales
}

export const rememberLocale = (locale: string) => {
  window.sessionStorage.setItem(LOCALE_KEY, locale)
}

export const localeOptionToDefinition = (
  option: LocaleOption,
  website: Website
): LocaleDefinition => {
  return localeToDefinition(option.code, website)
}

export const localeToDefinition = (locale: string, website: Website): LocaleDefinition => {
  return website.i18n.locales[locale]
}
