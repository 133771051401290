import sidebar from "../sidebars/global"
import pages from "../pages/global"

const frCA: LocaleDefinition = {
  code: "fr-CA",
  label: "Français",
  country: "France",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bienvenue sur le site Web de l’étude NEOS",
    "welcome.message":
      "NEOS est une étude clinique portant sur des enfants et des adolescents atteints de sclérose en plaques (SP) âgés de 10 à 17 ans. Veuillez entrer le code PIN qui se trouve dans vos documents imprimés ou qui vous a été remis par l’équipe du site.",
    "welcome.pin_prompt": "ENTREZ LE NIP CI-DESSOUS",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRER",
    "welcome.support": "Besoin d’aide? Veuillez communiquer avec votre fournisseur de soins de santé local.",
    "welcome.close": "Fermer",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Description",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Vidéos",
    "sidebar.videos.trials": "Essais cliniques",
    "sidebar.videos.neos": "Étude NEOS",
    "sidebar.videos.medicines": "Comment prendre le médicament à l’étude",

    "sidebar.reads-title": "Lectures rapides",
    "sidebar.reads.pediatric-ms": "Comprendre la SP pédiatrique",
    "sidebar.reads.study": "Aperçu de l’étude",
    "sidebar.reads.schedule": "Calendrier des visites d’étude",

    "sidebar.resources-title": "Ressources",

    "sidebar.contact": "Contactez-nous",
    "sidebar.privacy": "Politique de confidentialité",
    "sidebar.terms": "Conditions de service",
    "sidebar.copyright": "© Copyright 2021 Novartis. Tous droits réservés.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Comprendre les essais cliniques",
    "understanding-trials.description": "Cette vidéo explique ce que sont les essais cliniques.",
    "understanding-trials.video-id": "VWzI7g907y4",

    "understanding-neos.title": "Comprendre l’étude NEOS",
    "understanding-neos.description": "Cette vidéo fournit des informations sur l'étude NEOS.",
    "understanding-neos.video-id": "JTtO07jV-bI",

    "understanding-ms.title": "Comprendre la SP pédiatrique",
    "understanding-ms.description": "Survol de la compréhension de la SP chez les enfants et les adolescents.",

    "study-medicines.title": "Comment prendre les médicaments à l’étude",
    "study-medicines.description": "Cette vidéo explique comment prendre les médicaments de l'étude.",
    "study-medicines.video-id": "fCswQpXVtu8",

    "study-overview.title": "Aperçu de l’étude",

    "schedule.title": "Calendrier des visites d’étude",

    "resources.title": "Ressources",

    "neos-poster.title": "Affiche de recrutement de l'étude NEOS",
    "neos-poster.description": "Pour les enfants et les adolescents de 10 à 17 ans atteints de sclérose en plaques (SP) récurrente et rémittente.",
    "neos-poster.href": "/i18n/FR-CA/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Étude NEOS - Tableau de papier sur le consentement éclairé",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/FR-CA/pdfs/consent.pdf"
    },
    {
      title: "Étude NEOS - Tableau de papier sur l’assentiment éclairé",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/FR-CA/pdfs/assent.pdf"
    },
    {
      title: "L’étude NEOS - Introduction Plis multiples",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/FR-CA/pdfs/multifold.pdf"
    },
    {
      title: "Bande dessinée Comprendre la sclérose en plaques et l’étude NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/FR-CA/pdfs/un ms.pdf"
    },
    {
      title: "Bande dessinée Comprendre les essais cliniques pour les 7 à 12 ans",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/FR-CA/pdfs/comic 7-12.pdf"
    },
    {
      title: "Bande dessinée Comprendre les essais cliniques pour les 13 à 17 ans",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/FR-CA/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Que se passe-t-il lorsque l’on souffre de SP?" },
      { type: "paragraph", content: "La sclérose en plaques endommage la gaine de myéline, qui est le revêtement protecteur qui entoure les nerfs. Lorsque ce revêtement du nerf est endommagé, les nerfs ne peuvent pas envoyer les signaux comme ils le devraient normalement." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Les symptômes de la SP varient selon l’endroit où se trouvent les dommages. Ils peuvent comprendre :" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Fatigue", "Engourdissement"],
        ["Vision floue", "Faiblesse"],
        ["Picotements, par exemple, dans les mains ou les pieds", "Douleur"],
        ["Perte d’équilibre", "Dépression"],
        ["Problèmes d’intestin ou de vessie", "Anxiété"],
        ["Névrite optique — Inflammation du nerf optique qui peut causer une perte de vision (mais la vue revient habituellement)", "Difficulté à parler"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Qu'est-ce qu'une rechute?" },
      { type: "paragraph", content: "Au cours d’une rechute, les symptômes peuvent durer plusieurs semaines, puis se calmer d’eux-mêmes alors que le nerf se répare lui-même. Les rechutes sont suivies de périodes sans nouveaux symptômes appelées rémissions." },
      { type: "paragraph", content: "Il n’y a pas de remède à la sclérose en plaques. Des essais cliniques sont menés auprès de jeunes atteints de sclérose en plaques pour mettre à l’essai de nouvelles thérapies qui pourraient être utiles." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Qu’est-ce que l’étude NEOS?" },
      { type: "paragraph", content: "L’étude NEOS examine trois médicaments à l’étude différents pour voir s’ils peuvent aider en toute sécurité les enfants et les adolescents atteints de sclérose en plaques." },
      { type: "paragraph", content: "Les participants sont admissibles s’ils sont âgés de 10 à 17 ans." },

      { type: "header", content: "Quelle sera la durée de l’étude?" },
      { type: "paragraph", content: "L’étude dure deux ans. Il est possible de prendre le médicament à l’étude pendant 2 à 5 ans supplémentaires." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Le nombre de visites de suivi dépendra du moment de la prise de la dose finale du médicament à l’étude." },
      { type: "paragraph", content: "Sans insu : À l’opposé du double insu, cela signifie que le participant à l’étude et le médecin de l’étude savent dans quel groupe de traitement se trouve le participant à l’étude." },
      { type: "paragraph", content: "Double insu : Cela signifie que ni le participant à l’étude ni son médecin ne savent dans quel groupe de traitement il se trouve." },

      { type: "header", content: "Quels médicaments à l’étude sont testés?" },
      { type: "paragraph", content: "Trois médicaments à l’étude sont actuellement testés :" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab est une thérapie injectable approuvée dans certains pays pour les adultes atteints de sclérose en plaques. Il est administré au moyen d’un auto-injecteur semblable à celui utilisé par les diabétiques pour injecter de l’insuline. Les chercheurs veulent savoir si l’ofatumumab fonctionne bien chez les enfants et les adolescents et s’il est sûr." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod est un comprimé oral approuvé dans de nombreux pays pour les adultes atteints de sclérose en plaques. Les chercheurs veulent savoir s’il fonctionne bien chez les enfants et les adolescents et s’il est sécuritaire." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod est une capsule orale déjà approuvée dans de nombreux pays pour les enfants et les adolescents atteints de sclérose en plaques. Les chercheurs veulent voir si l’ofatumumab ou le siponimod sont au moins aussi bons que le fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Les chercheurs veulent voir si l’ofatumumab ou le siponimod sont au moins aussi bons que le fingolimod." },
      { type: "paragraph", content: "Chaque participant est placé dans un des trois groupes de traitement." },
      { type: "paragraph", content: "Chaque groupe reçoit les trois médicaments. L’un d’eux contiendra un médicament actif et deux autres seront des placebos. Un placebo ressemble au médicament à l’étude, mais sans médicament réel." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Que dois-je faire pendant l’étude?" },
      { type: "paragraph", content: "Il y aura des examens de santé et des tests au cours des visites à la clinique de l’étude et au cours des appels téléphoniques avec le médecin de l’étude. Il est important de venir à toutes les visites d’étude et de remplir l’E-journal." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Votre participation à l’étude est complètement volontaire. Vous pouvez choisir de vous retirer de l’étude en tout temps." },
      { type: "header", content: "Comment prendre les médicaments à l’étude" },
      { type: "paragraph", content: "Trois médicaments à l’étude sont actuellement testés :" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Type", "Stylo auto-injecteur"],
        ["Fréquence", "Toutes les 4 ou 6 semaines (selon le poids)"],
        ["Contenant", "Boîtier avec stylo auto-injecteur à usage unique"],
        ["Stockage", "Conserver au réfrigérateur"],
        ["Notes", "Sortir du réfrigérateur 15 à 30 minutes avant l'injection. Voir les instructions pour plus de détails"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Type", "Tablette"],
        ["Fréquence", "Tous les jours"],
        ["Contenant", "Emballage-coque pour les 6 premiers jours, puis bouteille"],
        ["Stockage", "Conserver au réfrigérateur"],
        ["Notes", "Prenez une tablette le matin ou le soir, à la même heure chaque jour."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Type", "Capsule"],
        ["Fréquence", "Tous les jours"],
        ["Contenant", "Bouteille"],
        ["Stockage", "Conserver au réfrigérateur"],
        ["Notes", "Prenez une capsule le matin ou le soir, à la même heure chaque jour."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Consignez chaque dose prise des trois médicaments à l’étude dans l’E-journal." },
      { type: "paragraph", content: "Pour les injections, indiquez la date, l’heure, la partie du corps et la personne qui a donné l’injection." },
      { type: "paragraph", content: "Si vous oubliez une dose, communiquez avec le médecin de l’étude ou l’équipe de l’étude." },
      { type: "paragraph", content: "Informez immédiatement le médecin de l’étude de tout effet secondaire secondaire." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default frCA
