import { CopyContext } from "framework/context/CopyProvider"
import { useContext } from "react"

const useCopy = () => {
  const copy = useContext(CopyContext)

  if (!copy) {
    throw new Error("useCopy must be used inside CopyProvider")
  }

  return copy
}

export default useCopy
