import { List } from "@material-ui/core"
import React from "react"
import SidebarSection from "./SidebarSection"
import SidebarLink from "./SidebarLink"
import { v4 as uuid } from "uuid"

type SidebarItemsProps = {
  items: SidebarItem[]
} & typeof defaultProps;

const defaultProps = {
  level: 1
};

const SidebarItems = ({ items, level }: SidebarItemsProps) => {
  if (items.length === 0) {
    return null
  }

  return (
    <List>
      {items.map((item) => {
        if ((item as SidebarSection).items) {
          return <SidebarSection section={item as SidebarSection} key={uuid()} />
        } else {
          return <SidebarLink item={item as SidebarLink} key={uuid()} level={level} />
        }
      })}
    </List>
  )
}
SidebarItems.defaultProps = defaultProps;

export default SidebarItems
