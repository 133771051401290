import useUI from "framework/hooks/useUI"
import React, { createContext, useMemo } from "react"

export const MultilineCopyContext = createContext<MultilineCopy | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const MultilineCopyProvider = ({ website, children }: Props) => {
  const { locale } = useUI()

  const value = useMemo(() => website.i18n.locales[locale.code].multilineCopy, [website, locale])

  return <MultilineCopyContext.Provider value={value}>{children}</MultilineCopyContext.Provider>
}

export default MultilineCopyProvider
