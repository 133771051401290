import { Box, makeStyles } from "@material-ui/core"
import useWebsiteOptions from "framework/hooks/useWebsiteOptions"
import React, { ReactNode } from "react"
import Lottie from "react-lottie"

const styles = makeStyles(
  (theme) => ({
    "@global": {
      body: {
        background: theme.palette.secondary.light,
      },
    },
    root: {},
    background: {
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: 264,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        height: "50vh",
      },
      [theme.breakpoints.up("lg")]: {
        height: "100vh",
        position: "absolute",
        right: 0,
        top: 0,
        width: "50%",
      },
    },
    animationContainer: {
      height: 500,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        height: 1024,
      },
      [theme.breakpoints.up("lg")]: {
        width: "100%",
        height: "100%",
      },
    },
    animation: {},
    container: {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "50%",
        minHeight: "100vh",
      },
    },
    content: {
      color: theme.palette.secondary.contrastText,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: "8px 16px 16px",
      [theme.breakpoints.up("md")]: {
        padding: "24px 100px 100px",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        padding: 0,
        width: 412,
      },
      [theme.breakpoints.up("xl")]: {
        width: 466,
      },
    },
  }),
  { name: "LandingPageContainer" }
)

export type LandingPageContainerStyleProps =
  | "@global"
  | "root"
  | "background"
  | "animationContainer"
  | "animation"
  | "container"
  | "content"

export type LandingPageContainerProps = {
  children: ReactNode
}

const BackgroundAnimation = () => {
  const { landingPage } = useWebsiteOptions()
  const classes = styles()

  if (!landingPage?.animation) {
    throw new Error("Landing page animation data is missing")
  }

  const options = {
    loop: true,
    autoplay: true,
    animationData: landingPage.animation,
    renderer: "svg",
    rendererSettings: {
      className: classes.animation,
    },
  }

  return (
    <Box className={classes.background}>
      <Box className={classes.animationContainer}>
        <Lottie options={options} width="100%" isClickToPauseDisabled={true} />
      </Box>
    </Box>
  )
}

const BackgroundImage = () => {
  const classes = styles()

  return <Box className={classes.background} />
}

const LandingPageContainer = ({ children }: LandingPageContainerProps) => {
  const classes = styles()
  const { landingPage } = useWebsiteOptions()
  let Background

  if (landingPage && landingPage.animation) {
    Background = BackgroundAnimation
  } else {
    Background = BackgroundImage
  }

  return (
    <Box className={classes.root}>
      <Background />
      <Box className={classes.container}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  )
}

export default LandingPageContainer
