import sidebar from "../sidebars/global"
import pages from "../pages/global"

const csCZ: LocaleDefinition = {
  code: "cs-CZ",
  label: "Czech",
  country: "Czech Republic",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Vítejte",
    "welcome.heading": "Vítáme Vás na na webových stránkách studie NEOS",
    "welcome.message":
      "NEOS je klinická studie pro děti a dospívající s roztroušenou sklerózou (RS) ve věku od 10 do 17 let.",
    "welcome.pin_prompt": "NÍŽE ZADEJTE PIN",
    "welcome.incorrect_pin": "Nesprávný PIN",
    "welcome.cta": "VSTOUPIT",
    "welcome.support": "Potřebujete pomoc? Kontaktujte prosím svého místního poskytovatele zdravotní péče.",
    "welcome.close": "Zavřít",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Popis",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videa",
    "sidebar.videos.trials": "Klinická hodnocení",
    "sidebar.videos.neos": "Studie NEOS",
    "sidebar.videos.medicines": "Jak užívat hodnocený přípravek",

    "sidebar.reads-title": "Stručný souhrn",
    "sidebar.reads.pediatric-ms": "Vysvětlení pediatrické RS",
    "sidebar.reads.study": "Přehled studie",
    "sidebar.reads.schedule": "Harmonogram studijních návštěv",

    "sidebar.resources-title": "Zdroje",

    "sidebar.contact": "Kontaktujte nás",
    "sidebar.privacy": "Zásady ochrany osobních údajů",
    "sidebar.terms": "Podmínky služby",
    "sidebar.copyright": "© Copyright 2021 Novartis. Všechna práva vyhrazena.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-ms.title": "Vysvětlení pediatrické RS",
    "understanding-ms.description": "Stručný přehled pro porozumění RS u dětí a dospívajících.",

    "understanding-trials.title": "Co jsou klinická hodnocení",
    "understanding-trials.description": "V tomto videu je vysvětleno, co jsou to klinická hodnocení.",
    "understanding-trials.video-id": "FIgHFVfnGjk",

    "understanding-neos.title": "Seznámení se studií NEOS",
    "understanding-neos.description": "V tomto videu se dozvíte informace o studii NEOS.",
    "understanding-neos.video-id": "R2w2Vv1-Iss",

    "study-medicines.title": "Jak užívat hodnocený přípravek",
    "study-medicines.description": "Video vysvětluje, jak užívat hodnocené přípravky.",
    "study-medicines.video-id": "mjDt_TCgMWU",

    "study-overview.title": "Přehled studie",

    "schedule.title": "Harmonogram studijních návště",

    "resources.title": "Zdroje",

    "neos-poster.title": "Leták pro nábor účastníků studie NEOS",
    "neos-poster.description": "Pro děti a dospívající ve věku mezi 10 a 17 lety s relabující-remitující roztroušenou sklerózou (RS).",
    "neos-poster.href": "/i18n/CS-CZ/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Co jsou klinická hodnocení, komiks pro věk 7–12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/CS-CZ/pdfs/comic 7-12.pdf"
    },
    {
      title: "Studie NEOS – Přehledná pomůcka – informovaný souhlas",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/CS-CZ/pdfs/consent.pdf"
    },
    {
      title: "Studie NEOS – Přehledná pomůcka – informované svolení",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/CS-CZ/pdfs/assent.pdf"
    },
    {
      title: "Studie NEOS – Úvodní leporelo",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/CS-CZ/pdfs/multifold.pdf"
    },
    {
      title: "Co jsou klinická hodnocení, komiks pro věk 13–17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/CS-CZ/pdfs/comic 13-17.pdf"
    },
    {
      title: "Vysvětlení RS a studie NEOS, komiks",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/CS-CZ/pdfs/un ms.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Co se děje při RS?" },
      { type: "paragraph", content: "RS poškozuje myelinovou pochvu, což je ochranný obal, který obklopuje nervy. Když je tento nervový obal poškozen, nervy nemohou vysílat signály tak, jak by normálně měly." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Příznaky RS se liší podle místa poškození. Mohou zahrnovat:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Únava", "Necitlivost"],
        ["Rozostřené vidění", "Slabost"],
        ["Mravenčení, například v rukou nebo nohách", "Bolest"],
        ["Ztráta rovnováhy", "Deprese"],
        ["Problémy se střevy nebo močovým měchýřem", "Úzkost"],
        ["Optická neuritida – zánět zrakového nervu, který může způsobit ztrátu zraku (obvykle dočasnou)", "Problémy s mluvou"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Co je relaps?" },
      { type: "paragraph", content: "Během relapsu mohou příznaky trvat několik týdnů a poté se samy zmírní, protože se nerv sám opraví. Po relapsech následují období bez nových příznaků nazývaná remise." },
      { type: "paragraph", content: "Neexistuje žádná léčba RS. Probíhají klinické studie pro mladé lidi s RS k testování nových terapií, které by mohly pomoci." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Co je studie NEOS?" },
      { type: "paragraph", content: "Studie NEOS zkoumá 3 různé hodnocené přípravky ke zjištění, zda mohou bezpečně pomáhat dětem a dospívajícím s roztroušenou sklerózou." },
      { type: "paragraph", content: "Účastníci jsou způsobilí, pokud jsou ve věku mezi 10 a 17 lety." },

      { type: "header", content: "Jak dlouho bude studie trvat?" },
      { type: "paragraph", content: "Studie potrvá 2 roky. Existuje možnost užívat hodnocený přípravek dalších 2 až 5 let." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Počet kontrolních návštěv bude záviset na datu podání poslední dávky hodnoceného přípravku." },
      { type: "paragraph", content: "Dvojitě zaslepené: Znamená to, že ani účastník studie, ani lékař studie neví, ve které léčebné skupině je účastník studie zařazen." },
      { type: "paragraph", content: "Nezaslepená: Opak dvojitě zaslepené. Znamená to, že účastník studie i zkoušející lékař vědí, ve které léčebné skupině je účastník studie zařazen." },

      { type: "header", content: "Jaké studijní léky jsou testovány?" },
      { type: "paragraph", content: "Testují se tři hodnocené přípravky:" },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Vědci chtějí zjistit, zda jsou ofatumumab nebo siponimod alespoň tak dobré jako fingolimod." },
      { type: "paragraph", content: "Každý účastník bude zařazen do 1 ze 3 léčebných skupin." },
      { type: "paragraph", content: "Každá skupina dostává všechny 3 léky. Jeden z nich bude obsahovat účinnou složku a 2 z nich budou placebo. Placebo vypadá jako hodnocený lék, ale ve skutečnosti neobsahuje žádnou účinnou látku." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab je injekční léčba, která je v některých zemích schválena pro dospělé s RS. Podává se pomocí automatického injekčního zařízení podobného těm, které používají lidé s diabetem k injekci inzulínu. Výzkumníci chtějí vědět, jak dobře ofatumumab funguje u dětí a dospívajících a jak bezpečný je." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod je perorální tableta, která je schválena v mnoha zemích pro dospělé s RS. Vědci chtějí vědět, jak dobře funguje u dětí a dospívajících a jak bezpečný je to lék." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod je perorální tobolka, která je již v mnoha zemích schválena pro děti a dospívající s RS. Vědci chtějí zjistit, zda jsou ofatumumab nebo siponimod alespoň tak dobré jako fingolimod." },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Co budu muset dělat během studie?" },
      { type: "paragraph", content: "Zdravotní kontroly a testy budou probíhat při návštěvách studijní kliniky a během telefonních hovorů se zkoušejícím lékařem. Je důležité chodit na všechny studijní návštěvy a vyplňovat eDeník." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Účast ve studii je zcela dobrovolná. Svou účast ve studii můžeš kdykoli ukončit." },
      { type: "header", content: "Jak se užívá hodnocený přípravek?" },
      { type: "paragraph", content: "Testují se tři hodnocené přípravky:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Typ", "Autoinjektorové pero"],
        ["Jak často", "Každé 4 nebo 6 týdnů (v závislosti na hmotnosti)"],
        ["Obal", "Krabička s jednorázovým autoinjektorovým perem"],
        ["Skladování", "Uchovávejte v chladničce"],
        ["Poznámky", "Vyndejte z lednice 15-30 minut před podáním. Pro více informací si přečtěte Pokyny"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Typ", "Tableta"],
        ["Jak často", "Každý den"],
        ["Obal", "Blistr po dobu prvních 6 dnů, poté lahvička"],
        ["Skladování", "Uchovávejte v chladničce"],
        ["Poznámky", "Ranní a večerní dávku užívejte přednostně každý den ve stejnou dobu."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Typ", "Kapsle"],
        ["Jak často[", "Každý den"],
        ["Obal", "Lahev"],
        ["Skladování", "Uchovávejte v chladničce"],
        ["Poznámky", "Ranní a večerní kapsli užívejte přednostně každý den ve stejnou dobu."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Zaznamenejte každou užitou dávku všech 3 hodnocených přípravků do eDeníku." },
      { type: "paragraph", content: "U injekcí uveďte datum, čas, část těla a osobu, která injekci podala." },
      { type: "paragraph", content: "Pokud vynecháte dávky, kontaktujte zkoušejícího lékaře nebo člena týmu studie." },
      { type: "paragraph", content: "O jakýchkoli vedlejších účincích ihned řekněte zkoušejícímu lékaři." },
    ],

  },

  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default csCZ
