import { makeStyles } from "@material-ui/core"
import React from "react"

type Props = {
  image: Image
}

const styles = makeStyles((_theme) => ({
  root: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    paddingBottom: "100%",
  },
}))

const Thumbnail = ({ image }: Props) => {
  const classes = styles()

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${image.thumbnail})`,
      }}
    />
  )
}

export default Thumbnail
