import { Box, Button, makeStyles, MenuItem, MenuList, Popover, Typography, Menu } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import React, { useRef, useState } from "react"
import clsx from "clsx"
import useUI from "framework/hooks/useUI"
import NestedMenuItem from "material-ui-nested-menu-item"

import _ from "lodash"

const styles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 3,
    right: 8,
  },
  button: {
    color: theme.palette.primary.main,
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: 700,
    letterSpacing: "1px",
    position: "relative",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: 4,
    height:50,
    padding: "0 20px",
    whiteSpace: 'nowrap'
  },
  popoverPaper: {
    borderRadius: 0,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  menuItem: {
    padding: "4px 8px",
    textTransform: "none",
    letterSpacing: 1,
    fontSize: 16,
    borderRadius: 0,
    fontWeight: "normal",
    minHeight: 0,
    lineHeight: "21px",
    height: "29px"
  }
}))

const LanguageMenu = () => {
  const classes = styles()
  const { permittedLocales, locale, languageMenuOpen, toggleLanguageMenu, setLocale } = useUI()
  const anchorRef = useRef(null)



  const [menuPosition, setMenuPosition] = useState<any>(null);



  if (permittedLocales.length < 2) {
    return null
  }


  const openMenu = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };

  const localesByCountry = _.groupBy(permittedLocales, "country");

  const countries = Object.keys(localesByCountry).sort();

  return (
    <Box className={classes.root}>
      <Button
        variant="text"
        className={classes.button}
        ref={anchorRef}
        onClick={openMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {locale.label}
      </Button>

      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
          {permittedLocales.map((option) => (
            <MenuItem
              onClick={() => {
                setLocale(option)
                toggleLanguageMenu()
              }}
              key={option.code}
              dense
              className={clsx({
                [classes.active]: option.code === locale.code,
                [classes.menuItem]: true
              })}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>

    </Box>
  )
}

export default LanguageMenu
