import { Box, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: 6,
    height: "100%",
    right: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    zIndex: 999,
  },

  paginationBullet: {
    transform: "rotate(90deg)",
    transformOrigin: "center 0",
    height: 27,
    margin: 4,
    cursor: "pointer",
    background: "transparent",
    border: "0 none",
    padding: 0,
    outline: "none",
  },

  paginationBulletInner: {
    width: 24,
    height: 6,
    transform: "skew(26deg)",
    background: theme.palette.grey[200],

    "&.active": {
      background: theme.palette.common.black,
    },
  },
}))

type Props = {
  item: AnimatedItem
  currSegment: number
  setSegment: (arg0: number) => void
}

const Pagination = ({ item, currSegment, setSegment }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {Array.from(item.segments.keys()).map((index) => (
        <button
          className={classes.paginationBullet}
          key={`navigation-bullet${index}`}
          onClick={() => {
            setSegment(index)
          }}
        >
          <Box
            className={`${classes.paginationBulletInner} ${index === currSegment ? "active" : ""}`}
          />
        </button>
      ))}
    </Box>
  )
}

export default Pagination
