import React from "react"

import { Box, Typography, makeStyles } from "@material-ui/core"

import useCopy from "framework/hooks/useCopy"
import MultilineCopy from "framework/MultilineCopy"

const styles = makeStyles(
  () => ({
    media: {
      width: "100%",
      height: "auto"
    },
    tableWrap: {
      background: "#FFFFFF",
      border: "1px solid rgba(4,96,169, 0.3)",
      marginTop: 30,
      marginBottom: 40,
      padding: "30px 40px"
    }
  })
)

const UnderstandingMs = () => {
  const copy = useCopy()
  const classes = styles()

  return (
    <Box>      
      <MultilineCopy id="understanding-ms.content-upper" />
      <Box className={classes.tableWrap}>
        <MultilineCopy id="understanding-ms.content-middle" context="understanding-ms" />
      </Box>
      <MultilineCopy id="understanding-ms.content-bottom" />
    </Box>
  )
}

export default UnderstandingMs;