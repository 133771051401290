import React, { useEffect, useState } from "react"
import { Box, Container, Drawer, makeStyles } from "@material-ui/core"
import Sidebar from "framework/Sidebar"
import { isDesktop } from "framework/styles"
import TopBar from "framework/TopBar"
import LanguageMenu from "framework/LanguageMenu"
import clsx from "clsx"
import LanguageDrawer from "framework/LanguageDrawer"
import useWebsiteOptions from "framework/hooks/useWebsiteOptions"
import useUI from "framework/hooks/useUI"

export const drawerWidth = 256

const styles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: drawerWidth,
      },
    },
    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    drawerPaper: {
      border: "none",
      width: drawerWidth,
    },
    content: {
      background: theme.palette.secondary.light,
      minHeight: "100vh",
      padding: "80px 0 24px 0",
      [theme.breakpoints.up("md")]: {
        padding: "80px 100px 24px 80px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "24px 64px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "40px 64px",
      },
    },
    contentBackgroundPrimary: {
      backgroundColor: "#FAFAFA",
      color: "#000000",
    },
    contentBackgroundSecondary: {
      backgroundColor: theme.palette.common.white,
      backgroundImage: "url(images/bg-secondary.png)",
      color: theme.palette.primary.main,
    },
  }),
  { name: "Layout" }
)

export type LayoutStyleKeys =
  | "root"
  | "drawer"
  | "drawerPaper"
  | "content"
  | "contentBackgroundPrimary"
  | "contentBackgroundSecondary"

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const classes = styles()
  const { mobileMenuOpen, toggleMobileMenu, background } = useUI()
  const { disableLanguageSelection } = useWebsiteOptions()
  const [, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const LanguageSelector = isDesktop() ? LanguageMenu : LanguageDrawer

  return (
    <Box className={classes.root}>
      <Drawer
        variant={isDesktop() ? "permanent" : "temporary"}
        open={isDesktop() || mobileMenuOpen}
        onClose={toggleMobileMenu}
        anchor="left"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <Sidebar />
      </Drawer>
      <TopBar />

      <Box
        className={classes.content}
      >
        {!disableLanguageSelection && (
          <Container style={{ position: "relative" }} maxWidth={false}>
            <LanguageSelector />
          </Container>
        )}

        {children}
      </Box>
    </Box>
  )
}

export default Layout
