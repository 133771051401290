import { Box, Container, Typography, makeStyles, Grid } from "@material-ui/core"
import useContent from "framework/hooks/useContent"
import useCopy from "framework/hooks/useCopy"
import useOverrideableComponent from "framework/hooks/useOverrideableComponent"
import React, { useEffect } from "react"
import ContentItem from "../ContentItem"
import { Helmet } from "react-helmet"

const useStyles = makeStyles((theme) => ({
  top: {
    paddingBottom: 24,
    [theme.breakpoints.up("xl")]: {
      paddingBottom: 40,
    },
  },
  topHeading: {
    lineHeight: "24px",
    [theme.breakpoints.up("xl")]: {
      lineHeight: "40px",
    },
  },
}))

type Props = {
  id: string
}

const ContentPage = ({ id }: Props) => {
  const { getItem } = useContent()
  const contentItem = getItem(id)
  const classes = useStyles()
  const copy = useCopy()
  const RelatedItems = useOverrideableComponent("RelatedItems")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [contentItem])

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "content",
    })
  })

  return (
    <>
      <Helmet>
        <title>{copy[`content.${contentItem.id}.title`]}</title>
      </Helmet>
      <Box>
        <Container maxWidth={false}>
          <Box className={classes.top}>
            <Typography variant="h4" className={classes.topHeading}>
              {copy[`content.${contentItem.id}.title`]}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ContentItem item={contentItem} />
            </Grid>

            {contentItem.showRelated && <RelatedItems referenceItem={contentItem} />}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default ContentPage
