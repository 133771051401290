import useUI from "./useUI"
import useWebsiteOptions from "./useWebsiteOptions"

const useLogo = () => {
  const { logo, horizontalLogo } = useWebsiteOptions()
  const { locale } = useUI()

  return {
    Logo: locale.logo || logo,
    HorizontalLogo: locale.horizontalLogo || horizontalLogo,
  }
}

export default useLogo
