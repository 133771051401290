import sidebar from "../sidebars/global"
import pages from "../pages/global"

const zhTW: LocaleDefinition = {
  code: "zh-TW",
  label: "Traditional Chinese",
  country: "Taiwan",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "歡迎來到 NEOS 調查研究網站。",
    "welcome.message":
      "NEOS 是一項針對 10 至 17 歲 患有多發性硬化症 (MS) 兒童和青少年的臨床研究。請輸入在您相關資料上或駐點團隊提供您的 PIN 碼。",
    "welcome.pin_prompt": "輸入下方 PIN 碼",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "輸入",
    "welcome.support": "需要協助嗎？ 請聯絡您當地的醫療人員。",
    "welcome.close": "關閉",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "說明",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "影片",
    "sidebar.videos.trials": "臨床試驗",
    "sidebar.videos.neos": "NEOS 試驗",
    "sidebar.videos.medicines": "如何服用試驗藥物",

    "sidebar.reads-title": "快速閱讀",
    "sidebar.reads.pediatric-ms": "瞭解小兒 MS",
    "sidebar.reads.study": "試驗概述",
    "sidebar.reads.schedule": "試驗就診時程表e",

    "sidebar.resources-title": "資源",

    "sidebar.contact": "聯絡我們",
    "sidebar.privacy": "隱私權政策",
    "sidebar.terms": "服務條款",
    "sidebar.copyright": "© 2021 年諾華（Novartis）版權所有。保留所有權利",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "瞭解臨床試驗",
    "understanding-trials.description": "本視頻說明了此臨床試驗的內容。",
    "understanding-trials.video-id": "o7OnuytW1mo",

    "understanding-neos.title": "瞭解 NEOS 試驗",
    "understanding-neos.description": "視頻提供了有關 NEOS 研究的信息。",
    "understanding-neos.video-id": "lPlU1-M4rtw",

    "understanding-ms.title": "瞭解小兒 MS",
    "understanding-ms.description": "了解兒童和青少年 MS 的簡短概述。",

    "study-medicines.title": "如何服用試驗藥物",
    "study-medicines.description": "該影片介紹如何服用試驗藥物。",
    "study-medicines.video-id": "iNOkRWR0eAk",

    "study-overview.title": "試驗概述",

    "schedule.title": "試驗就診時程表",

    "resources.title": "資源",

    "neos-poster.title": "NEOS 研究招募海報",
    "neos-poster.description": "針對 10 至 17 歲患有復發-緩解型多發性硬化症 (MS) 的兒童和青少年。",
    "neos-poster.href": "/i18n/ZH-TW/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS 試驗 - 知情同意活頁圖表",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/ZH-TW/pdfs/consent.pdf"
    },
    {
      title: "NEOS 試驗 - 兒童知情同意活頁圖表",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/ZH-TW/pdfs/assent.pdf"
    },
    {
      title: "NEOS 試驗 - 簡介（多折頁）",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/ZH-TW/pdfs/multifold.pdf"
    },
    {
      title: "瞭解 MS 和 NEOS 試驗漫畫書",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/ZH-TW/pdfs/un ms.pdf"
    },
    {
      title: "瞭解臨床試驗漫畫書（年齡：7 - 12 歲）",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/ZH-TW/pdfs/comic 7-12.pdf"
    },
    {
      title: "瞭解臨床試驗漫畫書（年齡：13 - 17 歲）",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/ZH-TW/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "罹患 MS 時會有什麼症狀？" },
      { type: "paragraph", content: "MS 會損壞髓鞘，髓鞘是圍繞在神經周圍的保護層。當神經的保護層受到損壞，神經就無法正常地傳送訊號。" },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "MS 的症狀因損壞的地方而有所不同。症狀可能包含：" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["疲倦", "麻木"],
        ["視力模糊", "虛弱"],
        ["剌痛（例如手或腳）", "疼痛"],
        ["失去平衡", "沮喪"],
        ["腸道或膀胱問題", "焦慮"],
        ["視神經炎—視神經發炎，可導致視力喪失（但視力通常會恢復）", "說話困難"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "甚麼是復發？" },
      { type: "paragraph", content: "在復發期間，症狀可能會持續數週，然後隨著神經自我修復而自行緩解。復發之後會有一陣子沒有新症狀，這段時期稱為緩解期。" },
      { type: "paragraph", content: "MS 無法治愈。目前正針對患有 MS 的年輕人進行臨床研究，以測試可能有幫助的新療法。" },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "什麼是 NEOS 試驗？" },
      { type: "paragraph", content: "NEOS 試驗正在研究 3 種不同的研究藥物，看看它們是否可以安全地幫助患有多發性硬化症的兒童和青少年。" },
      { type: "paragraph", content: "年齡介於 10 至 17 歲的參與者都有資格參加。" },

      { type: "header", content: "試驗會持續多長時間？" },
      { type: "paragraph", content: "試驗持續 2 年。還可選擇額外服外試驗藥物 2 至 5 年。" },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*追蹤就診次數將取決於服用最後一劑試驗藥物的時間而定。" },
      { type: "paragraph", content: "開放性試驗：相對於雙盲試驗，這代表試驗的參與者或是試驗的醫生都知道試驗參與者加入的是哪一個治療群組。" },
      { type: "paragraph", content: "雙盲試驗：這代表不論是試驗的參與者或是試驗的醫生都不會知道試驗參與者加入的是哪一個治療群組。" },

      { type: "header", content: "要測試的試驗藥物是什麼？" },
      { type: "paragraph", content: "會測試 3 種試驗藥物：" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: " ofatumumab " },
      { type: "paragraph", content: "奧法木單抗（ofatumumab，一種在一些國家被批准用於患有 MS 的成人的注射療法。使用類似於糖尿病患者注射胰島素的自動注射器來注射。研究人員想要知道奧法木單抗（ofatumumab）在兒童和青少年上的療以及其安全性。" },
      { type: "divider" },

      { type: "header", content: " 美芬太膜衣錠（siponimod）" },
      { type: "paragraph", content: "西尼莫德（siponimod)，一種在許多國家被批准用於患有 MS 的成人的口服藥片。研究人員想要知道它在兒童與青少年身上的療效以及其安全性如何。" },
      { type: "divider" },

      { type: "header", content: "捷力能膠囊（fingolimod）" },
      { type: "paragraph", content: "捷力能膠囊（fingolimod，一種已在許多國家被批准用於患有 MS 的兒童和青少年的口服膠囊。研究人員想要瞭解奧法木單抗（ofatumumab）或西尼莫德（siponimod）的療效是否至少與捷力能膠囊（fingolimod）一致。" },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "試驗人員想瞭解ofatumumab或美芬太膜衣錠（siponimod）的效果是否至少與捷力能膠囊（fingolimod）一致。" },
      { type: "paragraph", content: "每位參與者將參與 3 個群組中的 1 個。" },
      { type: "paragraph", content: "每個群組都會收到全部 3 種藥物。其中一組會服用活性藥物，而另外 2 組會服用安慰劑。安慰劑的外觀與試驗藥物相同，但不含任何實際藥物。" },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "在試驗期間我要做些什麼？" },
      { type: "paragraph", content: "試驗醫師在試驗診間就診和電話訪談時，會進行健康檢查和測試。參加所有試驗就診並完成電子日記非常重要。" },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "參加試驗屬完全自願。您可以隨時選擇退出試驗。" },
      { type: "header", content: "如何服用試驗藥物" },
      { type: "paragraph", content: "會測試 3 種試驗藥物：" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "ofatumumab" },
      { type: "table", rows: [
        ["類型", "注射筆"],
        ["頻率", "每 4 或 6 週（依體重而定）"],
        ["容器", "盒裝拋棄式注射筆"],
        ["存放方式", "放置在冰箱內"],
        ["附註", "在注射的15-30分鐘前從冰箱中取出 請參閱說明獲取詳情"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: " 美芬太膜衣錠（siponimod）" },
      { type: "table", rows: [
        ["類型", "藥片"],
        ["頻率", "每天"],
        ["容器", "前 6 天為吸塑包裝，後續為瓶裝"],
        ["存放方式", "放置在冰箱內"],
        ["附註", "每天在早晨或夜晚同一時間服用一片"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "捷力能膠囊（fingolimod）" },
      { type: "table", rows: [
        ["類型", "膠囊"],
        ["頻率", "每天"],
        ["容器", "瓶裝"],
        ["存放方式", "放置在冰箱內"],
        ["附註", "每天在早晨或夜晚同一時間服用一顆"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "在電子日記中記錄所有 3 種試驗藥物的每次服用劑量。針對注射，還需包含日期、時間、身體部位和注射者。如果錯失任何一劑，請聯絡試驗醫生或試驗團隊。立即告知試驗醫生任何已產生的副作用。" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default zhTW
