import sidebar from "../sidebars/global"
import pages from "../pages/global"

const arIL: LocaleDefinition = {
  code: "ar-IL",
  label: "العربية",
  country: "Israel",
  rtl:true,
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "مرحبًا بك في موقع دراسة NEOS",
    "welcome.message":
      "NEOS هي دراسة سريرية للأطفال والمراهقين الذين يعانون من التصلب المتعدد (MS) والذين تتراوح أعمارهم بين 10 و17 عامًا (ويشمل ذلك المشاركين في يوم ميلادهم الثامن عشر). يُرجى إدخال رمز رقم التعريف الشخصي (PIN) الموجود في المادة المطبوعة أو الذي أعطاه لك فريق الموقع.",
    "welcome.pin_prompt": "أدخل رقم التعريف الشخصي أدناه",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "إدخال",
    "welcome.support": "هل تحتاج إلى مساعدة؟ يُرجى الاتصال بمقدم الرعاية الصحية المحلي المتابع لك.",
    "welcome.close": "إغلاق",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "الوصف",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "مقاطع الفيديو",
    "sidebar.videos.trials": "التجارب السريرية",
    "sidebar.videos.neos": "دراسة NEOS",
    "sidebar.videos.medicines": "كيفية تناول دواء الدراسة",

    "sidebar.reads-title": "قراءات سريعة",
    "sidebar.reads.pediatric-ms": "فهم التصلب المتعدد لدى الأطفال",
    "sidebar.reads.study": "نظرة عامة على الدراسة",
    "sidebar.reads.schedule": "جدول زيارات الدراسة",

    "sidebar.resources-title": "الموارد",

    "sidebar.contact": "اتصل بنا",
    "sidebar.privacy": "سياسة الخصوصية",
    "sidebar.terms": "شروط الخدمة",
    "sidebar.copyright": "حقوق الطبع والنشر © لعام 2021 محفوظة لشركة نوفارتس. جميع الحقوق محفوظة",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "فهم الدراسات السريرية",
    "understanding-trials.description": "يشرح هذا الفيديو ما هي التجارب السريرية.",
    "understanding-trials.video-id": "h4FEZgmnhLA",

    "understanding-neos.title": "فهم دراسة NEOS",
    "understanding-neos.description": "يقدم هذا الفيديو معلومات عن دراسة NEOS.",
    "understanding-neos.video-id": "ikAFfjDQ9FA",

    "understanding-ms.title": "فهم التصلب المتعدد لدى الأطفال",
    "understanding-ms.description": "نبذة قصيرة حول فهم مرض التصلب المتعدد لدى الأطفال والمراهقين.",

    "study-medicines.title": "كيفية تناول أدوية الدراسة",
    "study-medicines.description": "يوضح هذا الفيديو طريقة تناول أدوية الدراسة.",
    "study-medicines.video-id": "Bmu2po6vGfc",

    "study-overview.title": "نظرة عامة على الدراسة",

    "schedule.title": "جدول زيارات الدراسة",

    "resources.title": "الموارد",

    "neos-poster.title": "none",
   /*  "neos-poster.description": "مخصص للأطفال والمراهقين الذين تتراوح أعمارهم بين 10 و17 عامًا والمصابين بالتصلب المتعدد الانتكاسي-الهجوعي.",
    "neos-poster.href": "/i18n/AR-IL/pdfs/poster.pdf" */

  },

  resources: [
    {
      title: "دراسة NEOS - مخطط الموافقة المستنيرة",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/AR-IL/pdfs/consent.pdf"
    },
    {
      title: "دراسة NEOS - مخطط القبول المستنير",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/AR-IL/pdfs/assent.pdf"
    },
/*     {
      title: "دراسة NEOS - مقدمة تعددية",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/AR-IL/pdfs/multifold.pdf"
    }, */
    {
      title: "فهم التصلب المتعدد وكتاب مصور عن دراسة NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/AR-IL/pdfs/un ms.pdf"
    },
    {
      title: "كتاب مصور لفهم التجارب السريرية للأعمار من 7 إلى 12 عامًا",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/AR-IL/pdfs/comic 7-12.pdf"
    },
    {
      title: "كتاب مصور لفهم التجارب السريرية للأعمار من 13 إلى 17 عامًا",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/AR-IL/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "ماذا يحدث عند الإصابة بالتصلب المتعدد؟" },
      { type: "paragraph", content: "يتسبب التصلب المتعدد في تلف غمد المايلين، وهو الغطاء الواقي الذي يحيط بالأعصاب. عند تلف هذا الغطاء العصبي، لا تستطيع الأعصاب إرسال الإشارات كالمعتاد." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "تختلف أعراض التصلب المتعدد حسب مكان التلف. وقد تشمل:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["التعب", "الخدر"],
        ["تشوّش الرؤية", "الضعف"],
        ["وخز، على سبيل المثال، في اليدين أو القدمين", "الألم"],
        ["فقدان التوازن", "الاكتئاب"],
        ["مشكلات في الأمعاء أو المثانة", "القلق"],
        ["التهاب العصب البصري - التهاب العصب البصري، والذي يمكن أن يسبب فقدان البصر (ولكن عادة ما يعود البصر)", "صعوبة في التحدث"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "ما هو الانتكاس؟" },
      { type: "paragraph", content: "أثناء الانتكاس، قد تستمر الأعراض لعدة أسابيع ثم تهدأ من تلقاء نفسها حيث يقوم العصب بإصلاح نفسه. تتبع الانتكاسات فترات لا تظهر فيها أعراض جديدة تُسمى الهجوع." },
      { type: "paragraph", content: "لا يوجد علاج للتصلب المتعدد، ولكن يتم إجراء دراسات سريرية على الشباب المصابين به لاختبار علاجات جديدة قد تكون فعالة." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "ما هي دراسة NEOS؟" },
      { type: "paragraph", content: "تبحث دراسة NEOS في 3 أدوية دراسة مختلفة لمعرفة ما إذا كان يمكنها مساعدة الأطفال والمراهقين المصابين بالتصلب المتعدد بأمان." },
      { type: "paragraph", content: "يكون المشاركون مؤهلين إذا كانت أعمارهم تتراوح ما بين 10 و17 عامًا." },

      { type: "header", content: "لِكَم من الوقت ستدوم الدراسة؟" },
      { type: "paragraph", content: "ستستغرق الدراسة حوالي عامين. هناك خيار لتناول دواء الدراسة لمدة إضافية تتراوح بين سنتين و5 سنوات." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*سيعتمد عدد زيارات المتابعة على وقت تناول آخر جرعة من دواء الدراسة." },
      { type: "paragraph", content: "مفتوحة التسمية: عكس التعمية المزدوجة، وتعني أن كلاً من المشارك في الدراسة وطبيب الدراسة يعرفان مجموعة العلاج التي سيدخل فيها المشارك." },
      { type: "paragraph", content: "التعمية المزدوجة: هذا يعني عدم معرفة المشارك في الدراسة أو طبيب الدراسة بمجموعة العلاج التي سيدخل فيها المشارك." },

      { type: "header", content: "ما هي أدوية الدراسة التي يتم اختبارها؟" },
      { type: "paragraph", content: "يتم اختبار ثلاثة أدوية دراسة:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "أوفاتوموماب" },
      { type: "paragraph", content: "أوفاتوموماب  هو علاج قابل للحقن تمت الموافقة عليه في بعض الدول للبالغين المصابين بالتصلب المتعدد. ويتم إعطاؤه باستخدام حاقن ذاتي مشابه لذلك الذي يستخدمه مرضى السكري لحقن الأنسولين. يرغب الباحثون في معرفة مدى فعالية عقار أوفاتوموماب لدى الأطفال والمراهقين ومدى سلامته." },
      { type: "divider" },

      { type: "header", content: "سيبونيمود" },
      { type: "paragraph", content: " سيبونيمود  هو قرص فموي تمت الموافقة عليه في العديد من الدول للبالغين المصابين بالتصلب المتعدد. يرغب الباحثون في معرفة مدى فعاليته لدى الأطفال والمراهقين ومدى سلامته." },
      { type: "divider" },

      { type: "header", content: "فينجوليمود" },
      { type: "paragraph", content: " فينجوليمود  هو كبسولة فموية تمت الموافقة عليها بالفعل في العديد من البلدان للأطفال والمراهقين المصابين بالتصلب المتعدد. يرغب الباحثون في معرفة ما إذا كان أوفاتوموماب أو سيبونيمود بنفس جودة فينجوليمود على الأقل. " },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "يرغب الباحثون في معرفة ما إذا كان أوفاتوموماب أو سيبونيمود على مستوى جودة فينجوليمود على الأقل." },
      { type: "paragraph", content: "يتم وضع كل مشارك في واحدة من 3 مجموعات علاجية." },
      { type: "paragraph", content: "تحصل كل مجموعة على جميع الأدوية الثلاثة. ستحتوي إحداها على عقار نشط وسيحتوي الأخريان على دواءٍ وهمي. الدواء الوهمي يشبه دواء الدراسة ولكنه لا يحتوي على أي عقار فعلي." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "ما الذي يتعين عليّ فعله أثناء الدراسة؟" },
      { type: "paragraph", content: "ستكون هناك فحوصات واختبارات صحية في زيارات عيادة الدراسة وأثناء المكالمات الهاتفية مع طبيب الدراسة. من المهم الحضور إلى جميع زيارات الدراسة وإكمال المفكرة الإلكترونية." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "المشاركة في الدراسة طوعية تمامًا. يمكنك اختيار مغادرة الدراسة في أيّ وقت." },
      { type: "header", content: "كيفية تناول أدوية الدراسة" },
      { type: "paragraph", content: "يتم اختبار ثلاثة أدوية دراسة:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "أوفاتوموماب" },
      { type: "table", rows: [
        ["النوع", "قلم حقن ذاتي"],
        ["كم مرة", "كل 4 أو 6 أسابيع (حسب الوزن)"],
        ["الحاوية", "صندوق يحتوي على قلم حقن ذاتي للاستعمال لمرة واحدة"],
        ["التخزين", "يُحفظ في الثلاجة"],
        ["ملحوظات", "أخرج من الثلاجة قبل الحقن بـ 15-30 دقيقة. انظر التعليمات لمزيد من التفاصيل"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "سيبونيمود" },
      { type: "table", rows: [
        ["النوع", "قرص"],
        ["كم مرة", "كل يوم"],
        ["الحاوية", "شريط أقراص لأول 6 أيام، ثم زجاجة"],
        ["التخزين", "يُحفظ في الثلاجة"],
        ["ملحوظات", "تناول قرصًا واحدًا في الصباح أو المساء، في الوقت نفسه من كل يوم"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "فينجوليمود" },
      { type: "table", rows: [
        ["النوع", "كبسولة"],
        ["كم مرة", "كل يوم"],
        ["الحاوية", "زجاجة"],
        ["التخزين", "يُحفظ في الثلاجة"],
        ["ملحوظات", "تناول كبسولة واحدة في الصباح أو المساء، في الوقت نفسه من كل يوم"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "سجّل كل جرعة تم تناولها من جميع أدوية الدراسة الثلاثة في المفكرة الإلكترونية. بالنسبة للحقن، قم بتضمين التاريخ والوقت ومكان تلقي الحقنة في الجسم ومن الذي أعطى الحقنة. إذا فاتتك أي جرعة، فاتصل بطبيب الدراسة أو فريق عمل الدراسة. أخبر طبيب الدراسة على الفور بأي آثار جانبية." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default arIL
