import sidebar from "../sidebars/global"
import pages from "../pages/global"

const esUS: LocaleDefinition = {
  code: "es-US",
  label: "Español",
  country: "The United States",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Bienvenido",
    "welcome.heading": "Bienvenido al sitio web el Estudio NEOS",
    "welcome.message":
      "NEOS es un estudio clínico para niños y adolescentes con esclerosis múltiple (EM) de entre 10 y 17 años de edad. Ingrese el código PIN que aparece en sus materiales impresos o que le entregó el equipo del centro.",
    "welcome.pin_prompt": "INGRESE EL PIN A CONTINUACIÓN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRAR",
    "welcome.support": "¿Necesita ayuda? Comuníquese con su proveedor de salud local.",
    "welcome.close": "Cerrar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descripción",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Ensayos clínicos",
    "sidebar.videos.neos": "Estudio NEOS",
    "sidebar.videos.medicines": "Cómo tomar los medicamentos del estudio",

    "sidebar.reads-title": "Lectura rápida",
    "sidebar.reads.pediatric-ms": "Acerca de la EM pediátrica",
    "sidebar.reads.study": "Descripción general del estudio",
    "sidebar.reads.schedule": "Programa de visitas del estudio",

    "sidebar.resources-title": "Recursos",

    "sidebar.contact": "Contáctenos",
    "sidebar.privacy": "Política de privacidad",
    "sidebar.terms": "Términos de servicio",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos los derechos reservados.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Acerca de los ensayos clínicos",
    "understanding-trials.description": "Este video explica qué son los ensayos clínicos.",
    "understanding-trials.video-id": "Y1r1esJ9q34",

    "understanding-neos.title": "Acerca del Estudio NEOS",
    "understanding-neos.description": "Este video proporciona información sobre el estudio NEOS.",
    "understanding-neos.video-id": "-kp7ozSZcM0",

    "understanding-ms.title": "Acerca de la EM pediátrica",
    "understanding-ms.description": "Descripción general breve acerca de la EM en niños y adolescentes.",

    "study-medicines.title": "Cómo tomar los medicamentos del estudio",
    "study-medicines.description": "Este vídeo explica cómo tomar los medicamentos del estudio.",
    "study-medicines.video-id": "ezUnOHOd97Y",

    "study-overview.title": "Descripción general del estudio",

    "schedule.title": "Programa de visitas del estudio",

    "resources.title": "Recursos",

    "neos-poster.title": "Cartel de reclutamiento del Estudio NEOS",
    "neos-poster.description": "Para niños y adolescentes de entre 10 y 17 años de edad con esclerosis múltiple (EM) recidivante-remitente.",
    "neos-poster.href": "/i18n/ES-US/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudio NEOS - Rotafolio de consentimiento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/ES-US/pdfs/consent.pdf"
    },
    {
      title: "Estudio NEOS - Rotafolio de asentimiento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/ES-US/pdfs/assent.pdf"
    },
    {
      title: "Estudio NEOS - Folleto desplegable de introducción",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/ES-US/pdfs/multifold.pdf"
    },
    {
      title: "Historieta acerca de la EM y el Estudio NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/ES-US/pdfs/un ms.pdf"
    },
    {
      title: "Acerca de los ensayos clínicos: Historieta para niños de 7 a 12 años de edad",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/ES-US/pdfs/comic 7-12.pdf"
    },
    {
      title: "Acerca de los ensayos clínicos: Historieta para adolescentes de 13 a 17 años de edad",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/ES-US/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "¿Qué sucede en la EM?" },
      { type: "paragraph", content: "La EM daña la vaina de mielina, que es el recubrimiento protector que rodea los nervios. Cuando este recubrimiento nervioso se daña, los nervios no pueden enviar señales como lo harían normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Los síntomas de la EM varían según el lugar donde se encuentra el daño. Pueden incluir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansancio", "Entumecimiento"],
        ["Visión borrosa", "Debilidad"],
        ["Sensación de hormigueo, por ejemplo, en las manos o los pies", "Dolor"],
        ["Pérdida del equilibrio", "Depresión"],
        ["Problemas de los intestinos o la vejiga", "Ansiedad"],
        ["Neuritis óptica: inflamación del nervio óptico, que puede causar pérdida de la visión (pero la visión generalmente se recupera)", "Dificultades para hablar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "¿Qué es la recidiva?" },
      { type: "paragraph", content: "Durante una recidiva, los síntomas pueden durar varias semanas y luego aliviarse solos una vez que el nervio se repara por sí mismo. Las recidivas son seguidas de períodos sin nuevos síntomas, llamados remisiones." },
      { type: "paragraph", content: "No hay cura para la EM. Se están realizando estudios clínicos para jóvenes con EM para probar nuevas terapias que puedan ayudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "¿Qué es el Estudio NEOS?" },
      { type: "paragraph", content: "El Estudio NEOS examina 3 medicamentos de estudio distintos para ver si pueden ayudar de manera segura a niños y adolescentes con esclerosis múltiple." },
      { type: "paragraph", content: "Los participantes son elegibles si tienen entre 10 y 17 años de edad." },

      { type: "header", content: "Cuánto tiempo dura el estudio?" },
      { type: "paragraph", content: "El estudio dura 2 años. Existe la opción de tomar el medicamento del estudio por 2 a 5 años adicionales." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*La cantidad de visitas de seguimiento dependerá de cuándo se tome la última dosis de medicamento del estudio." },
      { type: "paragraph", content: "Abierto: Lo contrario de doble ciego; significa que tanto el participante en el estudio como el médico del estudio saben en qué grupo de tratamiento se encuentra el participante en el estudio." },
      { type: "paragraph", content: "Doble ciego: Esto significa que ni el participante del estudio ni el médico del estudio saben en qué grupo de tratamiento se encuentra el participante en el estudio." },

      { type: "header", content: "¿Qué medicamentos del estudio se están probando?" },
      { type: "paragraph", content: "Se están probando tres medicamentos de estudio:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "El ofatumumab  es una terapia inyectable que está aprobada en algunos países para adultos con EM. Se administra con un autoinyector similar al que usan las personas con diabetes para inyectarse insulina. Los investigadores quieren saber qué tan bien funciona el ofatumumab en niños y adolescentes y qué tan seguro es." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "El siponimod es un comprimido oral que está aprobado en muchos países para adultos con EM. Los investigadores quieren saber qué tan bien funciona en niños y adolescentes y qué tan seguro es." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "El fingolimod es una cápsula oral que ya está aprobada en muchos países para niños y adolescentes con EM. Los investigadores quieren saber si el ofatumumab o el siponimod son al menos igual de buenos que el fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Los investigadores quieren saber si el ofatumumab o el siponimod son al menos igual de buenos que el fingolimod." },
      { type: "paragraph", content: "Cada participante se encuentra en 1 de 3 grupos de tratamiento." },
      { type: "paragraph", content: "Cada grupo recibe los tres medicamentos. Uno de ellos contendrá el medicamento activo y 2 de ellos serán placebos. Un placebo se parece al medicamento del estudio, pero no contiene medicamento real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "¿Qué tengo que hacer durante el estudio?" },
      { type: "paragraph", content: "Habrá revisiones de salud y pruebas en las visitas a la clínica del estudio y durante las llamadas telefónicas con el médico del estudio. Es importante venir a todas las visitas del estudio y completar el diario electrónico (eDiary)." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Participar en el estudio es completamente voluntario. Puedes decidir abandonar el estudio en cualquier momento." },
      { type: "header", content: "Cómo tomar los medicamentos del estudio" },
      { type: "paragraph", content: "Se están probando tres medicamentos de estudio:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Pluma autoinyectora"],
        ["Frecuencia", "Cada 4 o 6 semanas (según el peso corporal)"],
        ["Envase", "Caja con pluma autoinyectora de uso único"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Retire del refrigerador de 15 a 30 minutos antes de la inyección. Consulte las Instrucciones de uso para obtener más información."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Comprimido"],
        ["Frecuencia", "Todos los días"],
        ["Envase", "Blíster por los primeros 6 días, luego frasco"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Tomar un comprimido por la mañana o por la noche, a la misma hora cada día."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Frecuencia", "Todos los días"],
        ["Envase", "Frasco"],
        ["Almacenamiento", "Mantener en el refrigerador"],
        ["Notas", "Tomar una cápsula por la mañana o por la noche, a la misma hora cada día."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Registrar cada dosis que se administra de los 3 medicamentos del estudio en el diario electrónico. Para las inyecciones, incluir la fecha, hora, parte del cuerpo y quién administró la inyección. Si se omite alguna dosis, comuníquese con el médico del estudio o el equipo del estudio. Informe al médico del estudio inmediatamente sobre cualquier efecto secundario." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default esUS
