import React, { useCallback, useEffect, useRef, useState } from "react"
import lottie, { AnimationItem } from "lottie-web"
import { useInView } from "react-intersection-observer"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((_theme) => ({
  root: {
    minHeight: "80vh",
  },
}))

type Props = {
  animationData: any
}

const AnimationFrame = ({ animationData }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
  })
  const [animation, setAnimation] = useState<AnimationItem>()
  const classes = useStyles()

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    if (!ref.current) {
      return
    }
    if (!animation) {
      setAnimation(
        lottie.loadAnimation({
          container: ref.current,
          loop: false,
          autoplay: inView,
          animationData,
          assetsPath: "/images/stop-the-spread/mobile/",
        })
      )
    }
  }, [animationData, ref, inView, animation])

  useEffect(() => {
    if (inView) {
      animation?.play()
    }
  }, [inView, animation])

  return <div ref={setRefs} className={classes.root}></div>
}

export default AnimationFrame
