import usePages from "framework/hooks/usePages"
import useContent from "framework/hooks/useContent"
import ContentPage from "framework/pages/ContentPage"
import InnerPageContainer from "framework/InnerPageContainer"
import React from "react"
import { Route, Switch } from "react-router-dom"

const Routes = () => {
  const { content } = useContent()
  const { pages } = usePages()
  const routes = []
      
  // This is the content item system from OLIE
  for (const [id, item] of Object.entries(content)) {
    routes.push(
      <Route exact path={item.path} key={id}>
        <ContentPage id={id} />
      </Route>
    )
  }

  // This is the page system from NEOS
  pages.forEach(({ id, path, component }) => {
    const PageComponent = component;
    routes.push(
      <Route exact path={path} key={id}>
        <InnerPageContainer id={ id }>
          <PageComponent />
        </InnerPageContainer>          
      </Route>
    )    
  })

  return <Switch>{routes}</Switch>
}

export default Routes
