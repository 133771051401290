import { create } from "jss"
import rtl from "jss-rtl"
import { StylesProvider, jssPreset } from "@material-ui/core/styles"
import useUI from "framework/hooks/useUI"
import React from "react"

type Props = {
  children: React.ReactNode
}

const RtlContainer = ({ children }: Props) => {
  const { locale } = useUI()
  
  if (!locale.rtl) {
    return <>{children}</>
  }

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

  return <StylesProvider jss={jss}>{children}</StylesProvider>
}

export default RtlContainer
