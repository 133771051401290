import useUI from "framework/hooks/useUI"
import React, { createContext, useMemo } from "react"

export const CopyContext = createContext<LocalizedCopy | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const CopyProvider = ({ website, children }: Props) => {
  const { locale } = useUI()

  const value = useMemo(() => website.i18n.locales[locale.code].copy, [website, locale])

  return <CopyContext.Provider value={value}>{children}</CopyContext.Provider>
}

export default CopyProvider
