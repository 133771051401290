import UnderstandingTrials from "website/components/content/UnderstandingTrials"
import UnderstandingMs from "website/components/content/UnderstandingMs"
import UnderstandingNeos from "website/components/content/UnderstandingNeos"
import StudyMedicines from "website/components/content/StudyMedicines"
import StudyOverview from "website/components/content/StudyOverview"
import StudySchedule from "website/components/content/StudySchedule"
import Resources from "website/components/content/Resources"

const usPages: LocalizedPages = [
  {
    id: "understanding-trials",
    path: "/",
    component: UnderstandingTrials
  },
  {
    id: "understanding-neos",
    path: "/understanding_neos",
    component: UnderstandingNeos
  },
  {
    id: "study-medicines",
    path: "/study_medicines",
    component: StudyMedicines
  },
  
  {
    id: "understanding-ms",
    path: "/understanding_ms",
    component: UnderstandingMs
  },
  {
    id: "study-overview",
    path: "/study_overview",
    component: StudyOverview
  },
  {
    id: "schedule",
    path: "/schedule",
    component: StudySchedule
  },

  {
    id: "resources",
    path: "/resources",
    component: Resources
  },
]

export default usPages
