import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import useCopy from "framework/hooks/useCopy"
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    backgroundColor: "transparent",
    transition: "all .5s",
    borderRadius: 8,
    overflow: "hidden",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.08)",
      [`& $icon`]: {
        opacity: 1,
      },
    },
  },
  background: {
    display: "block",
    paddingBottom: "56.25%",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "75%",
    },
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 0,
    transition: "all .5s",
    fontSize: 48,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -24,
    marginTop: -24,
  },
  active: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [`& $icon`]: {
      opacity: 1,
    },
  },
  title: {
    textAlign: "center",
    padding: 8,
  },
}))

export type ContentItemCardProps = {
  item: ContentItem
  isActive?: boolean
}

const ContentItemCard = ({ item, isActive = false }: ContentItemCardProps) => {
  const classes = useStyles()
  const copy = useCopy()

  const Icon = isActive ? PlayArrowOutlinedIcon : PlayCircleOutlineIcon

  return (
    <Link
      className={cx(classes.root, {
        [classes.active]: isActive,
      })}
      to={item.path}
    >
      <div className={classes.background} style={{ backgroundImage: `url(${item.thumbnail})` }}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.title}>
        <Typography variant="caption">{copy[`content.${item.id}.short_title`]}</Typography>
      </div>
    </Link>
  )
}

export default ContentItemCard
