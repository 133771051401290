import { makeStyles } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    paddingBottom: "56.25%",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "75%",
    },
  },
  active: {},
}))

export type ContentItemCardProps = {
  item: ContentItem
  isActive?: boolean
}

const ContentItemCard = ({ item, isActive = false }: ContentItemCardProps) => {
  const classes = useStyles()

  return (
    <Link
      className={cx(classes.root, {
        [classes.active]: isActive,
      })}
      to={item.path}
      style={{ backgroundImage: `url(${item.thumbnail})` }}
    ></Link>
  )
}

export default ContentItemCard
