import sidebar from "../sidebars/global"
import pages from "../pages/global"

const enUS: LocaleDefinition = {
  code: "et-EE",
  label: "Estonian",
  country: "Estonia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Tere tulemast uuringu NEOS veebisaidile",
    "welcome.message":
      "NEOS on kliiniline uuring hulgiskleroosiga (sclerosis multiplex, SM) lastele ja noorukitele vanuses 10 kuni 17 aastat.",
    "welcome.pin_prompt": "SISESTA ALLPOOL PIN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "SISESTA",
    "welcome.support": "Kas sul on abi vaja? Võta ühendust oma kohaliku tervishoiutöötajaga.",
    "welcome.disclaimer": "Tegemist on teadusliku uuringuga, millega võib kaasneda oht osaleja tervisele.",
    "welcome.close": "Sulge",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Kirjeldus",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videod",
    "sidebar.videos.trials": "Kliinilised uuringud",
    "sidebar.videos.neos": "Uuring NEOS",
    "sidebar.videos.medicines": "Kuidas käib uuringuravimite võtmine",

    "sidebar.reads-title": "Kiirülevaade",
    "sidebar.reads.pediatric-ms": "Milline on SM lastel",
    "sidebar.reads.study": "Uuringu ülevaade",
    "sidebar.reads.schedule": "Uuringuvisiitide ajakava",

    "sidebar.resources-title": "Lisateabe allikad",

    "sidebar.contact": "Võta meiega ühendust",
    "sidebar.privacy": "Andmekaitse põhimõtted",
    "sidebar.terms": "Teenuse kasutamise tingimused",
    "sidebar.copyright": "© Autoriõigus 2021 Novartis. Kõik õigused kaitstud.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Mis on kliinilised uuringud",
    "understanding-trials.description": "Selles videos selgitatakse, mis on kliinilised uuringud.",
    "understanding-trials.video-id": "ccFmM_wR4xU",

    "understanding-neos.title": "Mis on uuring NEOS",
    "understanding-neos.description": "Selles videos räägitakse, mis on uuring NEOS.",
    "understanding-neos.video-id": "XRI_O_2GSUI",

    "understanding-ms.title": "Milline on SM lastel",
    "understanding-ms.description": "Lühike ülevaade SM-ist lastel ja noorukitel.",

    "study-medicines.title": "Kuidas käib uuringuravimite võtmine",
    "study-medicines.description": "Selles videos kirjeldatakse, kuidas uuringuravimeid võtta.",
    "study-medicines.video-id": "2fh_zLu5cSg",

    "study-overview.title": "Uuringu ülevaade",

    "schedule.title": "Uuringuvisiitide ajakava",

    "resources.title": "Lisateabe allikad",

    "neos-poster.title": "Uuringu NEOS värbamisplakat",
    "neos-poster.description": "Ägenemiste ja remissioonidega hulgiskleroosiga (sclerosis multiplex, SM) lastele ja noorukitele vanuses 10 kuni 17 aastat.",
    "neos-poster.href": "/i18n/ET-EE/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Uuring NEOS. Teadev nõusolek – esitlustahvel",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/ET-EE/pdfs/consent.pdf"
    },
    {
      title: "Uuring NEOS. Alaealise teadev nõusolek – esitlustahvel",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/ET-EE/pdfs/assent.pdf"
    },
    {
      title: "Uuring NEOS. Sissejuhatus – paljunduskoopia",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/ET-EE/pdfs/multifold.pdf"
    },
    {
      title: "Mis on SM ja uuringu NEOS koomiks",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/ET-EE/pdfs/un ms.pdf"
    },
    {
      title: "Mis on kliinilised uuringud. Koomiks vanusele 7–12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/ET-EE/pdfs/comic 7-12.pdf"
    },
    {
      title: "Mis on kliinilised uuringud. Koomiks vanusele 13–17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/ET-EE/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Mis juhtub SM-i korral??" },
      { type: "paragraph", content: "SM kahjustab närvi ümbritsevat kaitsekihti ehk müeliintuppe. Kui närvi ümbris on kahjustatud, ei saa närv enam normaalselt signaale edastada." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Sõltuvalt sellest, kus kohas kahjustus asub, võivad SM-i sümptomid olla erinevad. Need võivad olla järgmised:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Väsimus", "Tuimus"],
        ["Ähmane nägemine", "Nõrkus"],
        ["Torkimine, näiteks käe- või jalalabades", "Valu"],
        ["Tasakaaluhäired", "Depressioon"],
        ["Soole- või põieprobleemid", "Ärevus"],
        ["Nägemisnärvi põletik, mis võib põhjustada nägemise kaotust (tavaliselt nägemine taastub)", "Kõnehäired"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Mis on ägenemine?" },
      { type: "paragraph", content: "Ägenemise ajal võivad sümptomid kesta mitu nädalat ja seejärel sedamööda, kuidas närv taastub, iseenesest leeveneda. Ägenemisele järgneb ilma uute sümptomiteta periood, mida nimetatakse remissiooniks." },
      { type: "paragraph", content: "SM ei ole välja ravitav. Noorte inimestega tehakse kliinilisi uuringuid, et katsetada uusi ravimeid, millest võib abi olla." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Mis on uuring NEOS?" },
      { type: "paragraph", content: "Uuringus NEOS uuritakse kolme erinevat uuringuravimit, et näha, kas need on hulgiskleroosiga laste ja noorukite jaoks ohutud ja kasulikud." },
      { type: "paragraph", content: "Uuringusse sobivad osalejad on vanuses 10 kuni 17 aastat." },

      { type: "header", content: "Kui kaua uuring kestab?" },
      { type: "paragraph", content: "Uuring kestab 2 aastat. Lisaks on võimalus võtta uuringuravimit veel 2 kuni 5 aastat." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* Järelkontrolli visiitide arv sõltub sellest, millal võetakse viimane uuringuravimi annus." },
      { type: "paragraph", content: "Avatud – vastupidi topeltpimedale tähendab see, et nii uuringus osaleja kui ka uuringuarst teavad, millisesse ravirühma uuringus osaleja kuulub." },
      { type: "paragraph", content: "Topeltpime – see tähendab, et ei uuringus osaleja ega uuringuarst ei tea, millisesse ravirühma uuringus osaleja kuulub." },

      { type: "header", content: "Milliseid uuringuravimeid katsetatakse?" },
      { type: "paragraph", content: "Katsetatakse kolme uuringuravimit." },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab on süstitav ravim, mis mõnes riigis on heaks kiidetud SM-i raviks täiskasvanutel. Seda manustatakse automaatsüstliga, mis sarnaneb sellega, mida diabeediga inimesed kasutavad insuliini süstimiseks. Uurijad tahavad teada, kui hästi ofatumumab lastel ja noorukitel toimib ja kui ohutu see on. " },
      { type: "divider" },

      { type: "header", content: "Siponimood" },
      { type: "paragraph", content: "Siponimood on suukaudne tablett, mis on paljudes riikides heaks kiidetud SM-i raviks täiskasvanutel. Uurijad tahavad teada, kui hästi see lastel ja noorukitel toimib ja kui ohutu see on. " },
      { type: "divider" },

      { type: "header", content: "Fingolimood" },
      { type: "paragraph", content: "Fingolimood on suukaudne kapsel, mis on paljudes riikides juba heaks kiidetud SM-i raviks lastel ja noorukitel. Uurijad tahavad näha, kas ofatumumab või siponimood on vähemalt sama head kui fingolimood." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Uurijad tahavad näha, kas ofatumumab või siponimood on vähemalt sama head kui fingolimood." },
      { type: "paragraph", content: "Iga osaleja määratakse ühte kolmest ravirühmast." },
      { type: "paragraph", content: "Iga rühm saab kõiki kolme ravimit. Üks neist sisaldab toimeainet ja kaks ülejäänut platseebot. Platseebo näeb välja nagu uuringuravim, aga tegelikult see ravimit ei sisalda." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Mida mul uuringu ajal teha tuleb?" },
      { type: "paragraph", content: "Visiitidel uuringukliinikusse tehakse tervisekontrolle ja analüüse, lisaks on telefonivestlused uuringuarstiga. Tähtis on käia kõigil uuringuvisiitidel ja täita e-päevikut." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Uuringus osalemine on täiesti vabatahtlik. Võid otsustada uuringust igal ajal lahkuda." },
      { type: "header", content: "Kuidas käib uuringuravimite võtmine?" },
      { type: "paragraph", content: "Katsetatakse kolme uuringuravimit." },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tüüp", "Automaatsüstel"],
        ["Kui tihti", "Iga 4 või 6 nädala järel (sõltub kehakaalust)"],
        ["Pakend", "Karp, milles on ühekordselt kasutatav automaatsüstel"],
        ["Säilitamine", "Hoida külmkapis"],
        ["Märkmed", "Võta 15–30 minutit enne süstimist külmkapist välja. Täpsemat teavet vt kasutusjuhendist"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimood" },
      { type: "table", rows: [
        ["Tüüp", "Tablett"],
        ["Kui tihti", "Iga päev"],
        ["Pakend", "Esimesel 6 päeval blister, edasi pudel"],
        ["Säilitamine", "Hoida külmkapis"],
        ["Märkmed", "Võta üks tablett kas hommikul või õhtul, iga päev samal kellaajal"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimood" },
      { type: "table", rows: [
        ["Tüüp", "Kapsel"],
        ["Kui tihti", "Iga päev"],
        ["Pakend", "Pudel"],
        ["Säilitamine", "Hoida külmkapis"],
        ["Märkmed", "Võta üks kapsel kas hommikul või õhtul, iga päev samal kellaajal"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Kõigi kolme uuringuravimi iga võetud annus tuleb e-päevikusse kirja panna." },
      { type: "paragraph", content: "Süsti puhul tuleb lisada kuupäev, kellaaeg, kehaosa ja süstija." },
      { type: "paragraph", content: "Kui mõni annus jääb vahele, võta ühendust uuringuarsti või uuringumeeskonnaga." },
      { type: "paragraph", content: "Kui tekivad ükskõik millised kõrvaltoimed, räägi sellest kohe uuringuarstile." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default enUS
