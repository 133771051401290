import sidebar from "../sidebars/global"
import pages from "../pages/global"

const teIN: LocaleDefinition = {
  code: "te-IN",
  label: "తెలుగు",
  country: "Telugu",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "నియోస్ (NEOS) అధ్యయన వెబ్‌సైట్‌కు స్వాగతం",
    "welcome.message":
      "NEOS అనేది మల్టిపుల్ స్క్లీరోసిస్ (ఎంఎస్) (MS) ఉన్న 10 నుండి 17 సంవత్సరాల మధ్య పిల్లలు మరియు కౌమారదశలో ఉన్నవారి కొరకైన ఒక వైద్య అధ్యయనము. దయచేసి మీ ముద్రిత మెటీరియల్‌లో కనిపించే లేదా సైట్ బృందం మీకు ఇచ్చిన పిన్ కోడ్‌ను నమోదు చేయండి.",
    "welcome.pin_prompt": "క్రింద పిన్‌ను నమోదు చేయండి",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "నమోదు చేయండి",
    "welcome.support": "సహాయం కావాలా? దయచేసి మీ స్థానిక ఆరోగ్య ప్రదాతని సంప్రదించండి.",
    "welcome.close": "క్లోజ్",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "వివరణ",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "వీడియోలు",
    "sidebar.videos.trials": "వైద్య శోధనలు",
    "sidebar.videos.neos": "NEOS అధ్యయనం",
    "sidebar.videos.medicines": "అధ్యయన మందు ఎలా తీసుకోవాలి",

    "sidebar.reads-title": "క్విక్ రీడ్స్",
    "sidebar.reads.pediatric-ms": "పిల్లలలో ఎంఎస్ ను అర్థం చేసుకొనడం",
    "sidebar.reads.study": "అధ్యయన అవలోకనం",
    "sidebar.reads.schedule": "అధ్యయన సందర్శన షెడ్యూల్",

    "sidebar.resources-title": "వనరులు",

    "sidebar.contact": "మమ్మల్ని సంప్రదించండి",
    "sidebar.privacy": "గోప్యతా విధానం",
    "sidebar.terms": "సేవా నిబంధనలు",
    "sidebar.copyright": "© కాపీరైట్ 2021 Novartis. అన్ని హక్కులూ పరిరక్షితమైనవి",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "వైద్య శోధనలు అర్థం చేసుకొనడం",
    "understanding-trials.description": "వైద్య శోధనలు అంటే ఏమిటి అని ఈ వీడియో వివరిస్తుంది.",
    "understanding-trials.video-id": "8CEUqrpBK0g",

    "understanding-neos.title": "NEOS అధ్యయనాన్ని అర్థం చేసుకొనడం",
    "understanding-neos.description": "ఈ వీడియో NEOS అధ్యయనంపై సమాచారాన్ని అందిస్తుంది.",
    "understanding-neos.video-id": "FzUQgZXxXRI",

    "understanding-ms.title": "పిల్లలలో ఎంఎస్ ను అర్థం చేసుకొనడం",
    "understanding-ms.description": "పిల్లలు మరియు కౌమారదశలో ఉన్నవారిలో ఎంఎస్ ను అర్థం చేసుకోవడం యొక్క ఒక క్లుప్తమైన అవలోకనం.",

    "study-medicines.title": "అధ్యయన మందులను ఎలా తీసుకోవాలి",
    "study-medicines.description": "అధ్యయన మందులు ఎలా తీసుకోవాలో ఈ వీడియో వివరిస్తుంది.",
    "study-medicines.video-id": "qnK3qR2Hgh4",

    "study-overview.title": "అధ్యయన అవలోకనం",

    "schedule.title": "అధ్యయన సందర్శన షెడ్యూల్",

    "resources.title": "వనరులు",

    "neos-poster.title": "NEOS అధ్యయన నియామక పోస్టర్",
    "neos-poster.description": "తిరగబెట్టే-తిరోగమించే మల్టిపుల్ స్క్లీరోసిస్ (MS) కలిగిన 10 మరియు 17 సంవత్సరాల మధ్య వయస్సు ఉన్న పిల్లలు మరియు కౌమారదశలో ఉన్నవారి కోసం.",
    "neos-poster.href": "/i18n/TE-IN/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS అధ్యయనం - సూచిత సమ్మతి ఫ్లిప్ చార్ట్",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/TE-IN/pdfs/consent.pdf"
    },
    {
      title: "NEOS అధ్యయనం - సూచిత సమ్మతి ఫ్లిప్ చార్ట్",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/TE-IN/pdfs/assent.pdf"
    },
    {
      title: "NEOS అధ్యయనం - పరిచయం పలువిధాలు",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/TE-IN/pdfs/multifold.pdf"
    },
    {
      title: "ఎంఎస్ మరియు NEOS అధ్యయన కామిక్ పుస్తకాన్ని అర్థం చేసుకొనడం",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/TE-IN/pdfs/un ms.pdf"
    },
    {
      title: "వైద్య శోధనలను అర్థం చేసుకొనడం కామిక్ పుస్తకం వయస్సులు 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/TE-IN/pdfs/comic 7-12.pdf"
    },
    {
      title: "వైద్య శోధనలను అర్థం చేసుకొనడం కామిక్ పుస్తకం వయస్సులు 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/TE-IN/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "ఎంఎస్ లో ఏమి జరుగుతుంది?" },
      { type: "paragraph", content: "ఎంఎస్ మయలిన్ తొడుగుకు నష్టం వాటిల్లేలా చేస్తుంది, ఇది నరాలకు చుట్టూ ఉండే రక్షణ తొడుగు. ఈ నరాల తొడుగు దెబ్బతిన్నప్పుడు, నరాలు మామూలుగా పంపునట్లుగా సంకేతాలను పంపలేవు." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "నష్టం ఎక్కడ జరిగింది అనేదాన్ని బట్టి ఎంఎస్ లక్షణాలు మారవచ్చు. వీటిలో ఇవి ఉండవచ్చు:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["అలసట", "తిమ్మిరి"],
        ["చూపు మసకబారడం", "బలహీనత"],
        ["జలదరింపు, ఉదాహరణకు, చేతులు లేదా పాదాలలో", "నొప్పి"],
        ["సమతుల్యత కోల్పోవడం", "మనోవ్యాకులత"],
        ["ప్రేగు లేదా మూత్రాశయంతో సమస్యలు", "ఆందోళన"],
        ["ఆప్టిక్ న్యూరైటిస్ — ఆప్టిక్ నరము యొక్క శోధము, ఇది దృష్టి కోల్పోయేలా చేయవచ్చు (కానీ దృష్టి సాధారణంగా తిరిగి వస్తుంది)", "మాట్లాడటంలో కష్టం"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "తిరగబెట్టడం అంటే ఏమిటి?" },
      { type: "paragraph", content: "తిరగబెట్టే సమయంలో, లక్షణాలు చాలా వారాల పాటు ఉండవచ్చు మరియు తరువాత నరము తనకు తాను మరమ్మతు చేసుకోవడంతో తగ్గిపోతాయి. తిరగబెట్టడాలు జరిగిన తరువాత కొత్త లక్షణాలేవీ లేని తిరోగమనాలు అనే పిలిచే అవధులు వస్తాయి." },
      { type: "paragraph", content: "ఎంఎస్ నయం కాదు. ఎంఎస్ ఉన్న యుక్త వయస్కులకు సహాయపడగల కొత్త చికిత్సలను పరీక్షించడానికి వైద్య అధ్యయనాలు నిర్వహించబడుతున్నాయి." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "NEOS అధ్యయనం అంటే ఏమిటి?" },
      { type: "paragraph", content: "మల్టిపుల్ స్క్లీరోసిస్‌ ఉన్న పిల్లలు మరియు కౌమారదశలో ఉన్నవారికి సురక్షితంగా సహాయం చేయగలవా అని తెలుసుకోవడానికి NEOS అధ్యయనం 3 వేర్వేరు అధ్యయన మందులను అధ్యయనం చేస్తుంది." },
      { type: "paragraph", content: "10 నుండి 17 సంవత్సరాల మధ్య వయస్సులో ఉంటే పాల్గొనేవారు అర్హులు అవుతారు." },

      { type: "header", content: "అధ్యయనం ఎంత కాలం ఉంటుంది?" },
      { type: "paragraph", content: "అధ్యయనం 2 సంవత్సరాలు ఉంటుంది. ఆధ్యయన ఔషధాన్ని అదనంగా 2 నుండి 5 సంవత్సరాల వరకు తీసుకునే ఎంపిక ఉంది." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*ఆధ్యయన మందు యొక్క చివరి మోతాదు ఎప్పుడు తీసుకుంటారు అనే దాన్ని బట్టి అనుసరణ సందర్శనల సంఖ్య ఆధారపడి ఉంటుంది." },
      { type: "paragraph", content: "ఓపెన్-లేబుల్: రెట్టింపు-రహస్యానికి వ్యతిరేకం, దీని అర్థం అధ్యయనంలో పాల్గొనేవారు మరియు అధ్యయన వైద్యుడు ఇరువురికీ అధ్యయనంలో పాల్గొనేవారు ఏ చికిత్సా సమూహంలో ఉన్నారో తెలుస్తుంది." },
      { type: "paragraph", content: "రెట్టింపు-రహస్యము: దీని అర్థం అధ్యయనంలో పాల్గొనేవారికి గానీ లేదా అధ్యయన వైద్యుడికి గానీ అధ్యయనంలో పాల్గొనేవారు ఏ చికిత్సా సమూహంలో ఉన్నారో తెలియదు." },

      { type: "header", content: "ఏ అధ్యయన మందులు పరీక్షించబడుతున్నాయి?" },
      { type: "paragraph", content: "మూడు అధ్యయన మందులు పరీక్షించబడుతున్నాయి:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "ఒఫాటుముమాబ్" },
      { type: "paragraph", content: "ఒఫాటుముమాబ్ అనేది ఇంజెక్షన్ వేసి చేసే చికిత్స, ఇది ఎంఎస్ ఉన్న పెద్దలకు కొన్ని దేశాలలో ఆమోదించబడింది. ఇన్సులిన్ ఇంజెక్ట్ చేయడానికి డయాబెటిస్ ఉన్నవారు ఉపయోగించే ఆటోఇంజెక్టర్ ఉపయోగించి ఇది ఇవ్వబడుతుంది. పిల్లలు మరియు కౌమారదశలో ఉన్నవారిలో ఒఫాటుముమాబ్ ఎంత బాగా పనిచేస్తుందో మరియు ఇది ఎంత సురక్షితమైనదో అనేది పరిశోధకులు తెలుసుకోవాలనుకుంటున్నారు." },
      { type: "divider" },

      { type: "header", content: "సిపోనిమాడ్" },
      { type: "paragraph", content: "సిపోనిమాడ్ అనేది నోటి ద్వారా తీసుకునే మాత్ర, ఇది ఎంఎస్ ఉన్న పెద్దలకు చాలా దేశాలలో ఆమోదించబడింది. పిల్లలు మరియు కౌమారదశలో ఉన్నవారిలో ఇది ఎంత బాగా పనిచేస్తుందో మరియు ఇది ఎంత సురక్షితమైనదో పరిశోధకులు తెలుసుకోవాలనుకుంటున్నారు." },
      { type: "divider" },

      { type: "header", content: "ఫింగోలిమాడ్" },
      { type: "paragraph", content: "ఫింగోలిమాడ్ అనేది నోటి ద్వారా తీసుకునే క్యాప్సూల్, ఇది ఇప్పటికే అనేక దేశాలలో ఎంఎస్ ఉన్న పిల్లలు మరియు కౌమారదశలో వారికి ఆమోదించబడింది. ఒఫాటుముమాబ్ లేదా సిపోనిమాడ్ కనీసం ఫింగోలిమాడ్ లాగా ఉన్నాయా అని పరిశోధకులు చూడాలనుకుంటున్నారు." }
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "పరిశోధకులు ఒఫాటుముమాబ్ లేదా సిపోనిమాడ్ లు కనీసం ఫింగోలిమాడ్ అంత బాగా అయినా పనిచేస్తాయా అని చూడాలనుకుంటున్నారు." },
      { type: "paragraph", content: "పాల్గొనేవారిలో ప్రతి ఒక్కరు చికిత్సా సమూహాలు 3 లో 1 దానిలో ఉంచబడతారు." },
      { type: "paragraph", content: "ప్రతి సమూహం మొత్తం 3 మందులను అందుకుంటుంది. వాటిలో ఒకటి క్రియాశీల ఔషధాన్ని కలిగి ఉంటుంది మరియు వాటిలో 2 ప్లేసిబోలుగా ఉంటాయి. ప్లేసిబో అధ్యయన మందు లాగా ఉంటుంది, కానీ దానిలో అసలు మందు ఉండదు." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "అధ్యయనం సమయంలో నేను ఏమి చేయాలి?" },
      { type: "paragraph", content: "అధ్యయన క్లినిక్ సందర్శనల వద్ద మరియు అధ్యయన వైద్యునితో ఫోన్ కాల్స్ సమయంలో ఆరోగ్య తనిఖీలు మరియు పరీక్షలు ఉంటాయి. అన్ని అధ్యయన సందర్శనలకు రావడం మరియు ఇడైరీ పూర్తి చేయడం ముఖ్యం." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "అధ్యయనంలో ఉండటం పూర్తిగా స్వచ్ఛందంగా ఉంటుంది. మీరు ఎప్పుడైనా అధ్యయనాన్ని వదిలివేయడానికి ఎంచుకోవచ్చు." },
      { type: "header", content: "అధ్యయన మందులను ఎలా తీసుకోవాలి" },
      { type: "paragraph", content: "మూడు అధ్యయన మందులను అధ్యయనము చేస్తున్నారు:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "ఒఫాటుముమాబ్" },
      { type: "table", rows: [
        ["రకం", "ఆటోఇంజెక్టర్ పెన్"],
        ["ఎంత తరచుగా", "ప్రతి 4 లేదా 6 వారాలు (బరువును బట్టి)"],
        ["కంటైనర్", "ఒకేసారి-వాడే ఆటోఇంజెక్టర్ పెన్‌ ఉండే పెట్టె"],
        ["నిల్వ", "రిఫ్రిజిరేటర్ లో ఉంచండి"],
        ["గమనికలు", "ఇంజెక్షన్ చేయడానికి 15-30 నిమిషాల ముందు రిఫ్రిజిరేటర్ నుండి తీయండి. మరిన్ని వివరాల కోసం సూచనలను చూడండి"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "సిపోనిమాడ్" },
      { type: "table", rows: [
        ["రకం", "మాత్ర"],
        ["ఎంత తరచుగా", "ప్రతిరోజు"],
        ["కంటైనర్", "మొదటి 6 రోజుల పాటు బ్లిస్టర్ ప్యాక్, తరువాత సీసా"],
        ["నిల్వ", "రిఫ్రిజిరేటర్ లో ఉంచండి"],
        ["గమనికలు", "ప్రతి రోజు ఉదయం లేదా సాయంత్రం ఒకే సమయంలో ఒక మాత్రను వేసుకోండి"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "ఫింగోలిమాడ్" },
      { type: "table", rows: [
        ["రకం", "క్యాప్సూల్"],
        ["ఎంత తరచుగా", "ప్రతిరోజు"],
        ["కంటైనర్", "సీసా"],
        ["నిల్వ", "రిఫ్రిజిరేటర్ లో ఉంచండి"],
        ["గమనికలు", "ప్రతి రోజు ఉదయం లేదా సాయంత్రం ఒకే సమయానికి ఒక క్యాప్సూల్ తీసుకోండి"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "మొత్తం 3 అధ్యయన మందులలో తీసుకున్న ప్రతి మోతాదును ఇడైరీలో నమోదు చేయండి. ఇంజెక్షన్ల కోసం, తేదీ, సమయం, శరీర భాగం మరియు ఎవరు ఇంజెక్షన్ చేశారు లను చేర్చండి. ఏదైనా మోతాదు తప్పినట్లయితే, అధ్యయన వైద్యుడు లేదా అధ్యయన బృందాన్ని సంప్రదించండి. ఏవైనా దుష్ప్రభావాల గురించి అధ్యయన వైద్యునికి వెంటనే చెప్పండి." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default teIN
