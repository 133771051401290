import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import _ from "lodash"

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true
    sm: false
    md: true
    lg: true
    xl: true,
    xxl: true
  }
}

export const defaultBreakpointValues = {
  xs: 0,
  md: 700, // Tablet portrait
  lg: 1024, // Tablet landscape
  xl: 1140, // Desktop
  xxl: 1440
}

export const breakpoints = createBreakpoints({
  values: defaultBreakpointValues,
})

const palette = {
  action: {
    active: "#f2f2f2",
    selected: "#2B91E1",
    hover: "rgba(0, 0, 0, 0.05)",
  },
  common: {
    white: "#fff",
    black: "#000",
  },
  primary: {
    light: "#2B91E1",
    main: "#5EC0D9",
  },
  secondary: {
    main: "#5E83D9",
    light: "#6D91CB",
  },
  grey: {
    50: "#7C7B7B",
    100: "#f2f2f2",
  },
  text: {
    primary: "#000000",
  },
}

const baseTheme: ThemeOptions = {
  breakpoints,
  palette,
  typography: {
    h1: {},
    h2: {
      fontSize: "2.125rem",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 400,
      letterSpacing: "0.1em",
    },
    body1: {
      lineHeight: "1.7em",
      letterSpacing: "0.04em",
    },
    caption: {
      fontSize: "0.875rem",
      letterSpacing: "0.04em",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        fontSize: "11px",
        lineHeight: "11px",
        letterSpacing: "0.38em",
        padding: "18px 8px",
      },
      contained: {
        backgroundColor: palette.common.black,
        boxShadow: "none",
        color: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.black,
          boxShadow: "none",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            boxShadow: "none",
            backgroundColor: palette.common.black,
          },
        },
      },
      containedPrimary: {
        backgroundColor: palette.common.black,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#f6f6f6",
        "&$focused": {
          backgroundColor: palette.common.white,
        },
        "&:hover": {
          backgroundColor: "transparent",
          "@media (hover: none)": {
            backgroundColor: "#fff",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: `1px solid ${palette.common.black}`,
        },
        "&:hover:before": {
          borderBottom: `1px solid ${palette.common.black}`,
        },
        "&:after": {
          borderBottom: `1px solid ${palette.common.black}`,
        },
      },
      input: {
        padding: "27px 16px 10px",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: ".8125rem",
      },
      contained: {
        marginLeft: 16,
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: palette.common.black,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "inherit",
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.common.black,
        letterSpacing: "0.04em",
      },
      filled: {
        transform: "translate(16px, 20px) scale(1)",
        "&$shrink": {
          transform: "translate(16px, 10px) scale(0.75)",
        },
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        minWidth: 40,
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        letterSpacing: ".04em",
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "11px",
        fontWeight: 600,
        textTransform: "uppercase",
        letterSpacing: "0.38em",
      },
    },
    MuiTypography: {
      root: {
        whiteSpace: "pre-wrap",
      },
    },
  },
}

export const createTheme = (theme: ThemeOptions) => {
  return createMuiTheme(_.merge({}, baseTheme, theme))
}

export const isPhone = () => window.innerWidth < breakpoints.values.md
export const isTablet = () => !isPhone() && window.innerWidth < breakpoints.values.lg
export const isDesktop = () => window.innerWidth >= breakpoints.values.lg

export default baseTheme
