import { makeStyles, Typography } from "@material-ui/core"
import useCopy from "framework/hooks/useCopy"
import React, { useEffect, useState } from "react"
import YouTube, { Options } from "react-youtube"

const styles = makeStyles((theme) => ({
  root: {},
  video: {
    width: "100%",
    paddingBottom: "56.25%",
    position: "relative",
  },
  label: {
    textAlign: "center",
    padding: 8,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  youtube: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
}))

const youtubeOptions: Options = {
  playerVars: {
    autoplay: 1,
    rel: 0,
  },
}

type Props = {
  item: Video
}

const Video = ({ item }: Props) => {
  const classes = styles()
  const [initialItem] = useState<ContentItem>(item)
  const [player, setPlayer] = useState<undefined | any>(undefined)
  const copy = useCopy()

  useEffect(() => {
    if (player && initialItem.id !== item.id) {
      player.playVideo()
    }
  }, [item, player, initialItem])

  return (
    <div className={classes.root}>
      <div className={classes.video}>
        <YouTube
          videoId={item.source}
          opts={youtubeOptions}
          onReady={(event) => {
            setPlayer(event.target)
          }}
          onPlay={() => {
            gtag("event", "content_viewed", {
              content_id: item.id,
            })
          }}
          className={classes.youtube}
        />
      </div>
      <div className={classes.label}>
        <Typography variant="caption">{copy[`content.${item.id}.short_title`]}</Typography>
      </div>
    </div>
  )
}

export default Video
