import sidebar from "../sidebars/global"
import pages from "../pages/global"

const enAU: LocaleDefinition = {
  code: "en-AU",
  label: "English",
  country: "Australia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Welcome to the NEOS Study website",
    "welcome.message":
      "NEOS is a clinical study for children and adolescents with multiple sclerosis (MS) between 10 and 17 years of age. Please enter the PIN Code found in your print material or given to you by the site team.",
    "welcome.pin_prompt": "Enter the pin",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "Enter",
    "welcome.support": "Need help? Please contact your local health provider.",
    "welcome.close": "Close",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Description",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Clinical Trials",
    "sidebar.videos.neos": "NEOS Study",
    "sidebar.videos.medicines": "How to Take Study Medicines",

    "sidebar.reads-title": "Quick Reads",
    "sidebar.reads.pediatric-ms": "Understanding Paediatric MS",
    "sidebar.reads.study": "Study Overview",
    "sidebar.reads.schedule": "Study Visit Schedule",

    "sidebar.resources-title": "Resources",

    "sidebar.contact": "Contact Us",
    "sidebar.privacy": "Privacy Policy",
    "sidebar.terms": "Terms of Service",
    "sidebar.copyright": "© Copyright 2021 Novartis. All Rights Reserved",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Understanding Clinical Trials",
    "understanding-trials.description": "This video explains what clinical trials are.",
    "understanding-trials.video-id": "MUmObniqIaw",

    "understanding-neos.title": "Understanding the NEOS Study",
    "understanding-neos.description": "This video provides information on the NEOS study.",
    "understanding-neos.video-id": "iX31oFjmD1s",

    "understanding-ms.title": "Understanding Paediatric MS",
    "understanding-ms.description": "Short overview of understanding MS in children and adolescents.",

    "study-medicines.title": "How to Take Your Study Medications",
    "study-medicines.description": "This video explains how to take the study medications.",
    "study-medicines.video-id": "c-vHht7FMbM",

    "study-overview.title": "Study Overview",

    "schedule.title": "Study Visit Schedule",

    "resources.title": "Resources",

    "neos-poster.title": "NEOS Study Recruitment Poster",
    "neos-poster.description": "For children and adolescents between 10 and 17 years of age with relapsing-remitting multiple sclerosis (MS).",
    "neos-poster.href": "/i18n/EN-AU/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS Study - Informed Consent Flip Chart",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/EN-AU/pdfs/consent.pdf"
    },
    {
      title: "NEOS Study - Informed Assent Flip Chart",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/EN-AU/pdfs/assent.pdf"
    },
    {
      title: "NEOS Study - Introduction Multifold",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/EN-AU/pdfs/multifold.pdf"
    },
    {
      title: "Understanding MS and the NEOS Study Comic Book",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/EN-AU/pdfs/un ms.pdf"
    },
    {
      title: "Understanding Clinical Trials Comic Book Ages 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/EN-AU/pdfs/comic 7-12.pdf"
    },
    {
      title: "Understanding Clinical Trials Comic Book Ages 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/EN-AU/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "What happens in MS?" },
      { type: "paragraph", content: "MS damages the myelin sheath, which is the protective covering that surrounds nerves. When this nerve covering is damaged, the nerves cannot send signals as they normally should." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "The symptoms of MS vary depending on where the damage is. They may include:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Tiredness", "Numbness"],
        ["Blurred vision", "Weakness"],
        ["Tingling, for example, in the hands or feet", "Pain"],
        ["Loss of balance", "Depression"],
        ["Problems with bowel or bladder", "Anxiety"],
        ["Optic neuritis—inflammation of the optic nerve, which can cause loss of vision (but sight usually returns)", "Difficulty Speaking"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "What is relapse?" },
      { type: "paragraph", content: "A relapse is an exacerbation of MS, commonly referred to as an attack or flare up of new symptoms or worsening of old symptoms. It can be very mild or severe enough to interfere with a person’s day to day function. During a relapse, symptoms may last for several weeks and then ease on their own as the nerve repairs itself. The relapses are followed by periods without new symptoms called remissions." },
      { type: "paragraph", content: "There is no cure for MS. Clinical studies are being conducted for young people with MS to test new therapies that might help." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "What is the NEOS Study?" },
      { type: "paragraph", content: "The NEOS Study is looking at 3 different study medicines to see if they can safely help children and adolescents with multiple sclerosis." },
      { type: "paragraph", content: "Participants are eligible if they are between 10 and 17 years of age." },

      { type: "header", content: "How long does the study last?" },
      { type: "paragraph", content: "The study lasts 2 years. There is an option to take the study medication for an additional 2 to 5 years." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*The number of follow-up visits will depend on when the last dose of study medicine is taken." },
      { type: "paragraph", content: "Open-label: The opposite of double-blind, it means that both the study participant and the study doctor know which treatment group the study participant is in." },
      { type: "paragraph", content: "Double-blind: This means that neither the study participant nor the study doctor knows which treatment group the study participant is in." },

      { type: "header", content: "What study medicines are being tested?" },
      { type: "paragraph", content: "Three study medicines are being tested:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Is an injectable therapy that is approved for adults with MS in some countries. It is given using an autoinjector, similar to ones used by people with diabetes to inject insulin. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Is an oral tablet that is approved for adults with MS in many countries. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "is an oral capsule that is already approved for children and adolescents with MS in many countries. Researchers want to see if ofatumumab or siponimod are at least as good as fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Researchers want to see if ofatumumab or siponimod are at least as good as fingolimod." },
      { type: "paragraph", content: "Each participant is placed in 1 of 3 treatment groups." },
      { type: "paragraph", content: "Each group receives all 3 medicines. One of them will contain active drug and 2 of them will be placebos. A placebo looks like the study medicine but doesn’t have any actual drug in it." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "What do I have to do during the study?" },
      { type: "paragraph", content: "There will be health checks and tests at study clinic visits and during phone calls with the study doctor. It’s important to come to all study visits and complete the eDiary." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Being in the study is completely voluntary. You can choose to leave the study at any time." },
      { type: "header", content: "How to Take the Study Medicines" },
      { type: "paragraph", content: "Three study medicines are being tested:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Type", "Autoinjector pen"],
        ["How Often", "Every 4 or 6 weeks (depending on weight)"],
        ["Container", "Box with single-use autoinjector pen"],
        ["Storage", "Keep in refrigerator"],
        ["Notes", "Remove from refrigerator 15 - 30 minutes before injection. See Instructions for more details"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Type", "Tablet"],
        ["How Often", "Every day"],
        ["Container", "Blister pack for first 6 days, then bottle"],
        ["Storage", "Keep in refrigerator"],
        ["Notes", "Take one tablet in the morning or evening, at the same time each day"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Type", "Capsule"],
        ["How Often", "Every day"],
        ["Container", "Bottle"],
        ["Storage", "Keep in refrigerator"],
        ["Notes", "Take one capsule in the morning or evening, at the same time each day"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Record each dose taken of all 3 study medicines in the eDiary" },
      { type: "paragraph", content: "For injections, include the date, time, part of the body, and who gave the injection." },
      { type: "paragraph", content: "If any dose is missed, contact the study doctor or study team." },
      { type: "paragraph", content: "Tell the study doctor right away about any side effects." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default enAU
