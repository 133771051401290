import { Grid, makeStyles } from "@material-ui/core"
import useContent from "framework/hooks/useContent"
import React from "react"
import { Link } from "react-router-dom"

const useStyles = makeStyles(
  (_theme) => ({
    root: {},
    link: {
      display: "block",
      paddingBottom: "75%",
      backgroundSize: "cover",
    },
  }),
  { name: "RelatedItems" }
)

export type RelatedItemsStyleKeys = "root" | "link"

export type RelatedItemsProps = {
  referenceItem: ContentItem
}

const RelatedItems = ({ referenceItem }: RelatedItemsProps) => {
  const classes = useStyles()
  const { content } = useContent()

  const items = Object.values(content).filter((ci) => {
    return ci.id !== referenceItem.id
  })

  return (
    <>
      {items.map((item) => {
        return (
          <Grid item xs={12} xl={3} key={item.id}>
            <Link
              className={classes.link}
              to={item.path}
              style={{ backgroundImage: `url(${item.thumbnail})` }}
            ></Link>
          </Grid>
        )
      })}
    </>
  )
}

export default RelatedItems
