import sidebar from "../sidebars/global"
import pages from "../pages/global"

const bnIn: LocaleDefinition = {
  code: "bn-IN",
  label: "বাংলা",
  country: "India",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "NEOS অধ্যয়নের ওয়েবসাইটে স্বাগত",
    "welcome.message":
      "NEOS হল, মাল্টিপল স্ক্লেরোসিসে আক্রান্ত 10 থেকে 17 বছর বয়সী শিশু এবং কিশোর-কিশোরীদের জন্য একটি নিদানিক অধ্যয়ন৷ দয়া করে আপনার মুদ্রিত উপাদানটিতে থাকা অথবা সাইট টিমের দ্বারা আপনাকে প্রদত্ত  পিন কোডটি এন্টার করুন৷",
    "welcome.pin_prompt": "নীচে পিন কোড এন্টার করুন",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "এন্টার করুন",
    "welcome.support": "সাহায্যের প্রয়োজন? দয়া করে আপনার স্থানীয় স্বাস্থ্য পরিচর্যা প্রদানকারীর সঙ্গে যোগাযোগ করুন৷",
    "welcome.close": "বন্ধ",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "বর্ণনা",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "ভিডিওসমূহ",
    "sidebar.videos.trials": "নিদানিক পরীক্ষা",
    "sidebar.videos.neos": "NEOS অধ্যয়ন",
    "sidebar.videos.medicines": "কিভাবে অধ্যয়নের ওষুধগুলি গ্রহণ করতে হবে",

    "sidebar.reads-title": "দ্রুত পাঠগুলি",
    "sidebar.reads.pediatric-ms": "শিশুদের MS সম্পর্কে বোধ গঠন করা",
    "sidebar.reads.study": "অধ্যয়ন পর্যালোচনা",
    "sidebar.reads.schedule": "অধ্যয়নসংক্রান্ত সাক্ষাৎকারের সময়তালিকা",

    "sidebar.resources-title": "সংস্থানসমূহ",

    "sidebar.contact": "আমাদের সঙ্গে যোগাযোগ করুন",
    "sidebar.privacy": "গোপনীয়তা নীতি",
    "sidebar.terms": "পরিষেবার শর্তাবলী",
    "sidebar.copyright": "© Copyright 2021 নোভার্টিস৷ সকল স্বত্ত্ব সংরক্ষিত",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "নিদানিক পরীক্ষা সম্পর্কে বোধ গঠন করা",
    "understanding-trials.description": "নিদানিক পরীক্ষাগুলি কি তা এই ভিডিওটি ব্যাখ্যা করে৷",
    "understanding-trials.video-id": "uo6WuPh8Jks",

    "understanding-neos.title": "বোধ গঠন করা NEOS অধ্যয়ন",
    "understanding-neos.description": "এই ভিডিওটি NEOS অধ্যয়ন সম্পর্কে তথ্য প্রদান করে৷",
    "understanding-neos.video-id": "txqzrCA3YLA",

    "understanding-ms.title": "শিশুদের MS সম্পর্কে বোধ গঠন করা",
    "understanding-ms.description": "শিশু এবং কিশোর-কিশোরীদের মধ্যে MS সম্পর্কে বোধ গঠনের সংক্ষিপ্ত পর্যালোচনা",

    "study-medicines.title": "কিভাবে অধ্যয়নের ওষুধগুলি গ্রহণ করতে হবে",
    "study-medicines.description": "কীভাবে সমীক্ষা ওষুধ নিতে হয় তা এই ভিডিওতে বলা আছে।",
    "study-medicines.video-id": "o6hIo4TMMA0",

    "study-overview.title": "অধ্যয়ন পর্যালোচনা",

    "schedule.title": "অধ্যয়নসংক্রান্ত সাক্ষাৎকারের সময়তালিকা",

    "resources.title": "সংস্থানসমূহ",

    "neos-poster.title": "NEOS অধ্যয়নে নিযুক্তির পোস্টার",
    "neos-poster.description": "পুনরাক্রমণ-উপশম হতে থাকা মাল্টিপল স্ক্লেরোসিস (MS)-এ আক্রান্ত 10 থেকে 17 বছর বয়সী শিশু এবং কিশোর-কিশোরীদের জন্য",
    "neos-poster.href": "/i18n/BN-IN/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS অধ্যয়ন - অবহিত সম্মতি ফ্লিপ চার্ট",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/BN-IN/pdfs/consent.pdf"
    },
    {
      title: "NEOS অধ্যয়ন - অবহিত অনুমোদন ফ্লিপ চার্ট",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/BN-IN/pdfs/assent.pdf"
    },
    {
      title: "NEOS অধ্যয়ন - ভুমিকার মাল্টিফোল্ড",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/BN-IN/pdfs/multifold.pdf"
    },
    {
      title: "MS এবং NEOS অধ্যয়নসংক্রান্ত কমিক বই-এর বিষয়ে বোধ গঠন করা",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/BN-IN/pdfs/un ms.pdf"
    },
    {
      title: "নিদানিক পরীক্ষাগুলির 7-12 বয়সের জন্য কমিক বইয়ের বিষয়ে বোধ গঠন করা",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/BN-IN/pdfs/comic 7-12.pdf"
    },
    {
      title: "নিদানিক পরীক্ষাগুলির 13-17 বয়সের জন্য কমিক বইয়ের বিষয়ে বোধ গঠন করা",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/BN-IN/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "MS-এ কি ঘটে?" },
      { type: "paragraph", content: "MS, স্নায়ুগুলিকে ঘিরে থাকা সুরক্ষামূলক আস্তরণ মায়লিন সিথকে ক্ষতিগ্রস্ত করে৷ যখন স্নায়ুর এই আস্তরণগুলি ক্ষতিগ্রস্ত হয়, তখন স্নায়ুগুলি সংকেত প্রেরণ করতে পারে না, যা তাদের দ্বারা স্বাভাবিক অবস্থায় করা উচিৎ৷" },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "ক্ষতির অঞ্চলটির উপর নির্ভর করে MS-এর উপসর্গগুলি পরিবর্তিত হয়৷ সেগুলির মধ্যে অন্তর্ভুক্ত হতে পারে:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["ক্লান্তিভাব", "অসাড়তা"],
        ["দৃষ্টিশক্তি ঝাপসা হয়ে যাওয়া", "দুর্বলতা"],
        ["ঝিঁঝিঁ ধরে যাওয়া, উদাহরণস্বরূপ, হাত এবং পায়ের পাতাগুলিতেতে", "যন্ত্রণা"],
        ["ভারসাম্য হারানো", "বিষণ্ণতা"],
        ["মল অথবা মূত্রত্যাগের ক্ষেত্রে সমস্যা", "উদ্বেগ"],
        ["অপটিক নিউরাইটিস - অপটিক স্নায়ুর প্রদাহ, যেটি দৃষ্টিশক্তি হানির কারণ ঘটাতে পারে (তবে সাধারণত দৃষ্টিশক্তি ফিরে আসে)", "কথা বলার ক্ষেত্রে অসুবিধা"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "পুনরাক্রমণ কি" },
      { type: "paragraph", content: "একটি পুনরাক্রমণের সময়ে, উপসর্গগুলি কয়েক সপ্তাহ পর্যন্ত স্থায়ী হতে পারে এবং তারপরে স্নায়ুগুলি যখন নিজেদের মেরামত করে নেয়, তখন নিজের থেকেই উপশম ঘটে৷ পুনরাক্রমণগুলির পরে একটি পর্যায় আসে, যখন নতুন করে কোনও উপসর্গ দেখা দেয় না, যাকে উপশম বলে৷" },
      { type: "paragraph", content: "MS-এর কোনও নিরাময় নাই৷ MS-এ আক্রান্ত কম বয়সী মানুষদের ক্ষেত্রে সাহায্য করতে পারে এমন নতুন চিকিৎসা ব্যবস্থাগুলির পরীক্ষার জন্য নিদানিক অধ্যয়নগুলি পরিচালিত হচ্ছে৷" },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "NEOS অধ্যয়ন কি?" },
      { type: "paragraph", content: "NEOS অধ্যয়ন, সেগুলি শিশু এবং কিশোর-কিশোরীদের মাল্টিপল স্ক্লেরোসিসের ক্ষেত্রে নিরাপদভাবে সাহায্য করতে পারে কিনা তা দেখার জন্য, 3টি ভিন্ন অধ্যয়ন ওষুধের পরীক্ষা করছে৷" },
      { type: "paragraph", content: "অংশগ্রহণকারীরা যদি 10 থেকে 17 বছর বয়সী হয়, তবেই তারা যোগ্য হবে৷" },

      { type: "header", content: "অধ্যয়নটি কত দিন স্থায়ী হয়?" },
      { type: "paragraph", content: "অধ্যয়নটি স্থায়ী হয় 2 বছর৷ অধ্যয়নের ওষুধটি অতিরিক্ত 2 থেকে 5 বছর গ্রহণ করার একটি বিকল্প থাকে৷" },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*ফলো-আপ সাক্ষাৎকারের সংখ্যা নির্ভর করবে কখন অধ্যয়ন ওষুধের সর্বশেষ ডোজটি নেওয়া হয়েছে তার উপর৷ " },
      { type: "paragraph", content: "অপেন-লেবেল: ডাবল-ব্লাইন্ড-এর বিপরীত, এর অর্থ হল যে অধ্যয়নটিতে অংশগ্রহণকারী এবং অধ্যয়নের চিকিৎসক উভয়েই জানেন যে অধ্যয়নটিতে অংশগ্রহণকারীরা কোন চিকিৎসা গোষ্ঠীটিতে আছেন৷" },
      { type: "paragraph", content: "ডাবল-ব্লাইন্ড: এর  অর্থ হল যে অধ্যয়নটিতে অংশগ্রহণকারী অথবা অধ্যয়নের চিকিৎসক, কেউই জানবেন না যে অধ্যয়নটিতে অংশগ্রহণকারী চিকিৎসার কোন গোষ্ঠীটিতে আছেন৷" },

      { type: "header", content: "কোন অধ্যয়নের ওষুধগুলির পরীক্ষা করা হচ্ছে?" },
      { type: "paragraph", content: "তিনটি অধ্যয়ননের ওষুধ পরীক্ষা  করা হচ্ছে:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: " ওফাটুমুম্যাব" },
      { type: "paragraph", content: "ওফাটুমুম্যাব হল একটি ইঞ্জেকশন দেওয়া ভিত্তিক চিকিৎসা ব্যবস্থা যেটি কিছু দেশে MS-এ আক্রান্ত প্রাপ্তবয়স্কদের জন্য অনুমোদিত হয়েছে৷ এটি দেওয়া হয়, ডায়াবেটিসে আক্রান্ত ব্যক্তিদের ইনসুলিন প্রয়োগ করার ইঞ্জেকশনের সঙ্গে সদৃশ একটি স্বয়ংক্রিয় ইঞ্জেক্টারের সাহায্যে৷ গবেষণাকারীরা জানতে চান যে ওফাটুমুম্যাব কিভাবে শিশু এবং কিশোর-কিশোরীদের ক্ষেত্রে কাজ করে এবং সেটি কতটা নিরাপদ৷" },
      { type: "divider" },

      { type: "header", content: " সিপোনিমোড" },
      { type: "paragraph", content: "সিপোনিমোড হল, বহু দেশে MS-এ আক্রান্ত প্রাপ্তবয়স্কদের জন্য অনুমোদিত একটি মুখের মাধ্যমে খাওয়ার ট্যাবলেট। গবেষণাকারীরা জানতে চান যে এটি কিভাবে শিশু এবং কিশোর-কিশোরীদের ক্ষেত্রে কাজ করে এবং সেটি কতটা নিরাপদ৷" },
      { type: "divider" },

      { type: "header", content: "ফিঙ্গোলিমোড" },
      { type: "paragraph", content: "ফিঙ্গোলিমোড হল, বহু দেশে MS-এ আক্রান্ত শিশু এবং কিশোর-কিশোরীদের জন্য ইতিমধ্যেই অনুমোদিত একটি মুখের মাধ্যমে খাওয়ার ক্যাপসুল৷ গবেষণাকারীরা জানতে চান যে ওফাটুমুম্যাব অথবা সিপোনিমোড, কমপক্ষে ফিঙ্গোলিমোড-এর মতই ভালো কিনা৷" },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "গবেষকরা দেখতে চান যে ওফাটুমুম্যাব অথবা সিপোনিমোড, কমপক্ষে ফিঙ্গোলিমোড-এর মত ভালো কিনা৷" },
      { type: "paragraph", content: "প্রত্যেক অংশগ্রহণকারীকে 3টি চিকিৎসা গোষ্ঠীর মধ্যে 1টিতে অন্তর্ভুক্ত করা হয়েছে৷" },
      { type: "paragraph", content: "প্রত্যেক গোষ্ঠী 3টি ওষুধই পান৷ সেগুলির মধ্যে একটিতে সক্রিয় ওষুধ থাকবে এবং 2টি হবে প্লাসিবো৷ একটি প্লাসিবো দেখতে সক্রিয় ওষুধের মতই হয়, কিন্তু সেটিতে  কোন প্রকৃত ওষুধ থাকে না৷" },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "অধ্যয়নটিচলাকালীন আমাকে কি করতে হবে?" },
      { type: "paragraph", content: "অধ্যয়নের চিকিৎসা কেন্দ্রে সাক্ষাৎকারের সময়ে এবং অধ্যয়নের চিকিৎসকের সঙ্গে ফোন কলের সময়ে স্বাস্থ্য যাচাই এবং পরীক্ষাগুলি করা হবে৷ অধ্যয়নসংক্রান্ত সকল সাক্ষাৎকারগুলিতে উপস্থিত হওয়া এবং  ইডায়েরিটি সম্পূর্ণ করা গুরুত্বপূর্ণ৷" },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "অধ্যয়নটিতে থাকা সম্পূর্ণরূপে ঐচ্ছিক৷ তুমি যেকোন সময়ে অধ্যয়নটি ছেড়ে যেতে পার৷" },
      { type: "header", content: "কিভাবে অধ্যয়নের ওষুধগুলি গ্রহণ করতে হবে" },
      { type: "paragraph", content: "তিনটি অধ্যয়ননের ওষুধ পরীক্ষা করা হচ্ছে:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "ওফাটুমুম্যাব" },
      { type: "table", rows: [
        ["প্রকার", "স্বয়ংক্রিয়ভাবে ইঞ্জেকশন প্রদানকারী পেন"],
        ["কত ঘন ঘন", "প্রত্যেক 4 অথবা 6 সপ্তাহে (ওজনের উপর নির্ভর করে)"],
        ["পাত্র", "বাক্সসহ একবার ব্যবহারযোগ্য স্বয়ংক্রিয়ভাবে ইঞ্জেকশন প্রদানকারী পেন"],
        ["সংরক্ষণ", "রেফ্রিজারেটারের মধ্যে রাখুন"],
        ["নোট", "ইঞ্জেকশন নেওয়ার 15-30 মিনিট আগে রেফ্রিজারেটার থেকে বার করুন৷ আরও বিবরণের জন্য নির্দেশাবলী দেখুন"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "সিপোনিমোড" },
      { type: "table", rows: [
        ["প্রকার", "ট্যাবলেট"],
        ["কত ঘন ঘন", "প্রত্যেক দিন"],
        ["পাত্র", "প্রথম 6 দিনের জন্য ব্লিস্টার প্যাক, তারপরে বোতল"],
        ["সংরক্ষণ", "রেফ্রিজারেটারের মধ্যে রাখুন"],
        ["নোট", "প্রত্যেক দিন একই সময়ে, সকাল অথবা সন্ধ্যায়, একটি করে ট্যাবলেট খান"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "ফিঙ্গোলিমোড" },
      { type: "table", rows: [
        ["প্রকার", "ক্যাপসুল"],
        ["কত ঘন ঘন", "প্রত্যেক দিন"],
        ["পাত্র", "বোতল"],
        ["সংরক্ষণ", "রেফ্রিজারেটারের মধ্যে রাখুন"],
        ["নোট", "প্রত্যেক দিন একই সময়ে, সকাল অথবা সন্ধ্যায়, একটি করে ক্যাপসুল খান"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "ইডায়েরিতে 3টি অধ্যয়নের ওষুধের প্রত্যেকটির ডোজগুলি গ্রহণ করা সম্পর্কে লিখুন৷ ইঞ্জেকশনের ক্ষেত্রে, তারিখ, সময়, শরীরের অংশ, এবং কে ইঞ্জেকশনটি দিয়েছেন সেগুলি অন্তর্ভুক্ত করুন৷ যদি কোনও ডোজ বাদ গিয়ে থাকে, তাহলে অধ্যয়নের চিকিৎসক অথবা অধ্যয়ন টিমের সঙ্গে যোগাযোগ করুন৷ যেকোন পার্শ্বপ্রতিক্রিয়ার সম্পর্কে অধ্যয়নের চিকিৎসককে তখনই জানান৷" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default bnIn
