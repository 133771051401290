import { AppBar, Box, IconButton, makeStyles, Toolbar } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined"
import React from "react"
import useWebsiteOptions from "framework/hooks/useWebsiteOptions"
import { isDesktop } from "framework/styles"
import useUI from "framework/hooks/useUI"
import useLogo from "framework/hooks/useLogo"

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  logo: {
    height: 40,
    width: 112,
  },
}))
const TopBar = () => {
  const classes = styles()
  const { logo, disableLanguageSelection } = useWebsiteOptions()
  const { HorizontalLogo } = useLogo()
  const { toggleMobileMenu, toggleLanguageMenu } = useUI()

  if (isDesktop()) {
    return null
  }

  return (
    <AppBar color="default" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton edge="start" onClick={toggleMobileMenu}>
          <MenuIcon />
        </IconButton>
        {logo && HorizontalLogo && <HorizontalLogo className={classes.logo} />}

        {disableLanguageSelection ? (
          <Box px={3} />
        ) : (
          <IconButton edge="end" onClick={toggleLanguageMenu}>
            <LanguageOutlinedIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
