import { Hidden } from "@material-ui/core"
import React from "react"
import DesktopAnimation from "./DesktopAnimation"
import MobileAnimation from "./MobileAnimation"

type Props = {
  item: AnimatedItem
}

const Animation = ({ item }: Props) => {
  if (item.mobileSource) {
    return (
      <>
        <Hidden xlUp>
          <MobileAnimation item={item} />
        </Hidden>

        <Hidden lgDown>
          <DesktopAnimation item={item} />
        </Hidden>
      </>
    )
  } else {
    return <DesktopAnimation item={item} />
  }
}

export default Animation
