import { Box, Typography, makeStyles } from "@material-ui/core"
import useCopy from "framework/hooks/useCopy"
import useLocaleImages from "framework/hooks/useLocaleImages"
import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

const styles = makeStyles(
  (theme) => ({
    "@global": {
      body: {
        background: theme.palette.secondary.light,
      },
    },
    wrapper: {
      padding: "0 20px",
      [theme.breakpoints.up("md")]: {
        padding:0
      }
    },
    header: {
      borderBottom: "1px solid rgba(4,96,169, 0.3)",
      paddingBottom: 40,
      marginBottom: 40,
    },
    headerText: {
      paddingTop: 12,
      color: "#000000"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.up("xxl")]: {
        flexDirection: "row"
      }
    },
    panel: {
      width: "100%"
    },
    sidebar: {
      width: "100%",
      marginTop: 20,
      paddingTop: 20,
      borderTop: "1px solid rgba(4,96,169, 0.3)",
      [theme.breakpoints.up("xxl")]: {
        width: 470,
        paddingLeft: 40,
        marginTop: 0,
        paddingTop: 0,
        borderTop: "none"
      },
    },
    feature: {
      display: "block",
      height: 110,
      width: "100%",
      cursor: "pointer",
      marginBottom: 20,
      paddingBottom: 20,
      textDecoration: "none",
      color: "#000",
      borderBottom: "1px solid rgba(4,96,169, 0.3)"
    },
    featureLast: {
      display: "block",
      height: 110,
      width: "100%",
      cursor: "pointer",
      marginBottom: 20,
      paddingBottom: 20,
      textDecoration: "none",
      color: "#000",
    },

    featureImage: {
      maxWidth: "160px",
      float: "left",
      marginRight: 16
    },

    title: {
      lineHeight: 1.3,
      paddingTop: 4,
      marginBottom: 2
    },
    subtitle: {
      fontSize: "14px",
      lineHeight: 1.4,
      marginBottom: "16px"
    },

  }),
  { name: "InnerPageContainer" }
)

export type InnerPageContainerProps = {
  id: string,
  children: ReactNode
}

const RIGHT_SIDEBAR = [
  {
    image: "understanding-trials-timestamp.png",
    title: "understanding-trials.title",
    href: "/"
  }, {
    image: "understanding-study-timestamp.png",
    title: "understanding-neos.title",
    href: "/understanding_neos"
  }, {
    image: "study-medicines-timestamp.png",
    title: "study-medicines.title",
    href: "/study_medicines"
  },
]

const InnerPageContainer = ({ id, children }: InnerPageContainerProps) => {
  const classes = styles()
  const copy = useCopy()

  const getLocaleImage = useLocaleImages()

  return (
    <Box className={classes.wrapper}>
      <Helmet>
        <title>{copy[`${ id }.title`]}</title>
      </Helmet>
      <Box className={classes.header}>
        <Typography variant="h2" className={classes.headerText}>
          {copy[`${ id }.title`]}
        </Typography>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.panel}>
          {children}
        </Box>
        <Box className={classes.sidebar}>
            {RIGHT_SIDEBAR.map((item, ind) => (
              <Link to={ item.href } className={ind === (RIGHT_SIDEBAR.length - 1) ? classes.featureLast : classes.feature} key={ item.href }>
                <Box>
                  <img className={classes.featureImage} src={ `/images/${ item.image }` }
                    alt={ copy[item.title] } />
                </Box>
                <Typography variant="body2" className={classes.title}>
                  <strong>{ copy[item.title] }</strong>
                </Typography>
                <Typography className={classes.subtitle}>
                  Novartis
                </Typography>
              </Link>
            ))}
        </Box>
      </Box>
    </Box>
  )
}

export default InnerPageContainer
