import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined"
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"

const usSidebar: LocalizedSidebar = {
  showLogo: true,
  mainItems: [
    {
      text: "sidebar.videos-title",
      collapsable: true,
      items: [
        {
          text: "sidebar.videos.trials",
          href: "/",
          icon: PlayCircleOutlineIcon,
        },
        {
          text: "sidebar.videos.neos",
          href: "/understanding_neos",
          icon: PlayCircleOutlineIcon,
        },
        {
          text: "sidebar.videos.medicines",
          href: "/study_medicines",
          icon: PlayCircleOutlineIcon,
        },
      ],
    },
  ],
  readItems: [
    {
      text: "sidebar.reads-title",
      collapsable: true,
      items: [
        {
          text: "sidebar.reads.pediatric-ms",
          href: "/understanding_ms",
        },
        {
          text: "sidebar.reads.study",
          href: "/study_overview",
        },
        {
          text: "sidebar.reads.schedule",
          href: "/schedule",
        },
      ],
    },
  ],
  
  topLevelItems: [
    {
      text: "sidebar.resources-title",
      href: "/resources"
    },
  ],

  bottomItems: [
    {
      text: "sidebar.contact",
      href: "https://www.novartis.com/our-company/contact-us",
      newTab: true,
      external: true,
    },
    {
      text: "sidebar.privacy",
      href: "https://www.novartis.com/privacy-policy#ui-id-1=0",
      newTab: true,
      external: true,
    },
    {
      text: "sidebar.terms",
      href: "https://www.novartis.com/terms-use",
      newTab: true,
      external: true,
    },
  ],
  
}

export default usSidebar
