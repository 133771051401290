import sidebar from "../sidebars/global"
import pages from "../pages/global"

const enUS: LocaleDefinition = {
  code: "ru-UA",
  label: "Русский",
  country: "Ukraine",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Добро пожаловать на веб-сайт исследования NEOS",
    "welcome.message":
      "NEOS — это клиническое исследование для детей и подростков с рассеянным склерозом (РС) в возрасте от 10 до 17 лет. Введите PIN-код, указанный в Ваших печатных материалах или предоставленный персоналом исследовательского центра.",
    "welcome.pin_prompt": "ВВЕДИТЕ PIN-КОД НИЖЕ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ВВЕСТИ",
    "welcome.support": "Нужна помощь? Обратитесь к своему медицинскому работнику по месту жительства.",
    "welcome.close": "Закрыть",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Описание",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Видео",
    "sidebar.videos.trials": "Клинические исследования",
    "sidebar.videos.neos": "Иследование NEOS",
    "sidebar.videos.medicines": "Как принимать лекарственные средства",

    "sidebar.reads-title": "Быстрый просмотр",
    "sidebar.reads.pediatric-ms": "Что такое РС у детей",
    "sidebar.reads.study": "Описание исследования",
    "sidebar.reads.schedule": "График визитов в рамках исследования",

    "sidebar.resources-title": "Источники",

    "sidebar.contact": "Связаться с нами",
    "sidebar.privacy": "Политика конфиденциальности",
    "sidebar.terms": "Условия предоставления услуг",
    "sidebar.copyright": "© Авторское право 2021 Novartis. Все права защищены",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Что такое клинические исследования",
    "understanding-trials.description": "В этом видео объясняется, что такое клинические исследования.",
    "understanding-trials.video-id": "juDdNFOW_YE",

    "understanding-neos.title": "Что такое исследование NEOS",
    "understanding-neos.description": "Это видео предоставляет информацию об исследовании NEOS.",
    "understanding-neos.video-id": "E1w2anyskhY",

    "understanding-ms.title": "Что такое РС у детей",
    "understanding-ms.description": "Краткий обзор рассеянного склероза у детей и подростков.",

    "study-medicines.title": "Как принимать лекарственные средства",
    "study-medicines.description": "Это видео объясняет, как принимать исследуемые препараты.",
    "study-medicines.video-id": "g7L_Oth-aKo",

    "study-overview.title": "Описание исследования",

    "schedule.title": "График визитов в рамках исследования",

    "resources.title": "Источники",

    "neos-poster.title": "Постер о наборе участников в исследование NEOS",
    "neos-poster.description": "Для детей и подростков в возрасте от 10 до 17 лет с рецидивирующим-ремитирующим рассеянным склерозом (РС).",
    "neos-poster.href": "/i18n/RU-UA/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Перекидной буклет об информированном согласии — исследование NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/RU-UA/pdfs/consent.pdf"
    },
    {
      title: "Перекидной буклет об информированном согласии — исследование NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/RU-UA/pdfs/assent.pdf"
    },
    {
      title: "Исследование NEOS — Брошюра с общей информацией",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/RU-UA/pdfs/multifold.pdf"
    },
    {
      title: "Что такое РС и исследования NEOS. Книга комиксов",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/RU-UA/pdfs/un ms.pdf"
    },
    {
      title: "Что такое клинические исследования. Книга комиксов для детей от 7 до 12 лет",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/RU-UA/pdfs/comic 7-12.pdf"
    },
    {
      title: "Что такое клинические исследования. Книга комиксов для подростков в возрасте от 13 до 17 лет",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/RU-UA/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Что происходит при РС?" },
      { type: "paragraph", content: "РС повреждает миелиновую оболочку, которая является защитным покровом, окружающим нервы. Когда этот покров нерва поврежден, нервы не могут посылать сигналы как обычно." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Симптомы РС зависят от места поражения. Они могут включать:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Утомляемость", "Онемение"],
        ["Затуманенное зрение", "Слабость"],
        ["Покалывание, например, в руках или ногах", "Боль"],
        ["Потеря равновесия", "Депрессия"],
        ["Нарушение функций кишечника и мочевого пузыря", "Тревожность"],
        ["Оптический неврит — это воспаление зрительного нерва, которое может привести к потере зрения (но зрение обычно восстанавливается)", "Нарушение речи"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Что такое рецидив?" },
      { type: "paragraph", content: "Во время рецидива симптомы могут продолжаться в течение нескольких недель, а затем сами по себе уменьшаются, когда нерв восстанавливается сам. Рецидивы сопровождаются периодами без появления новых симптомов, которые называются ремиссиями." },
      { type: "paragraph", content: "Лечение от РС не существует. Клинические исследования проводятся для молодых людей с РС с целью тестирования новых методов лечения, которые могут помочь." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Что такое исследование NEOS?" },
      { type: "paragraph", content: "Исследование NEOS рассматривает 3 различных лекарственных препарата, чтобы выяснить, могут ли они безопасно помочь детям и подросткам, больным рассеянным склерозом." },
      { type: "paragraph", content: "К участию допускаются дети в возрасте от 10 до 17 лет." },

      { type: "header", content: "Сколько длится это исследование?" },
      { type: "paragraph", content: "Исследование длится 2 года. Существует возможность принимать исследуемый препарат в течение дополнительных 2–5 лет." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* Количество дальнейших визитов будет зависеть от того, когда будет принята последняя доза исследуемого препарата." },
      { type: "paragraph", content: "Открытое: В отличие от двойного слепого исследования, это означает, что как участник исследования, так и врач-исследователь знают, в какой группе лечения находится участник исследования." },
      { type: "paragraph", content: "Двойное слепое: Это означает, что ни участник исследования, ни врач не знают, в какой группе лечения находится участник исследования." },

      { type: "header", content: "Какие лекарственные средства исследуются?" },
      { type: "paragraph", content: "Исследуются три лекарственных средства:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Офатумумаб" },
      { type: "paragraph", content: "Офатумумаб — это инъекционное лекарственное средство, которое в некоторых странах утверждено для лечения взрослых с РС. Его вводят с помощью ручки-автоинжектора, подобной той, которую используют люди с диабетом для введения инъекций инсулина. Исследователи хотят знать, насколько хорошо действует офатумумаб у детей и подростков и насколько он безопасен. " },
      { type: "divider" },

      { type: "header", content: "Сипонимод" },
      { type: "paragraph", content: "Сипонимод — это таблетки для перорального применения, одобренные во многих странах для лечения взрослых с РС. Исследователи хотят знать, насколько эффективно работает этот препарат у детей и подростков и насколько он безопасен." },
      { type: "divider" },

      { type: "header", content: "Финголимод" },
      { type: "paragraph", content: "Финголимод — это капсулы для перорального применения, которые уже одобрены во многих странах для лечения детей и подростков с РС. Исследователи хотят проверить, являются ли офатумумаб или сипонимод по крайней мере такими же эффективными, как и финголимод." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Исследователи хотят проверить, является ли офатумумаб или сипонимод по крайней мере такими же эффективными, как финголимод." },
      { type: "paragraph", content: "Каждый участник попадет в 1 из 3 групп лечения." },
      { type: "paragraph", content: "Участники каждой группы будут получать все 3 лекарственных средства. Одно из них будет содержать активный препарат, а 2 — плацебо. Плацебо выглядит как исследуемый препарат, но не содержит активного вещества." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Что мне нужно делать во время исследования?" },
      { type: "paragraph", content: "Во время визитов в рамках исследования, которые проводятся в медицинском учреждении, и во время телефонных разговоров с врачом-исследователем будут проводиться проверки состояния здоровья и анализы. Важно приходить на все визиты в рамках исследования и заполнять электронный дневник." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Участие в исследовании является абсолютно добровольным. Вы можете решить выйти из исследования в любое время." },
      { type: "header", content: "Как принимать лекарственные средства" },
      { type: "paragraph", content: "Исследуются три лекарственных средства:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Офатумумаб" },
      { type: "table", rows: [
        ["Тип", "Ручка-автоинжектор"],
        ["Как часто", "Каждые 4 или 6 недель (в зависимости от веса)"],
        ["Упаковка", "Коробка или ручка-автоинжектор"],
        ["Хранение", "Хранить в холодильнике"],
        ["Примечания", "Вынуть из холодильника за 15–30 минут до инъекции. См. Инструкции для получения более подробной информации."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Сипонимод" },
      { type: "table", rows: [
        ["Тип", "Таблетка"],
        ["Как часто", "Каждый день"],
        ["Упаковка", "Блистерная упаковка на первые 6 дней, затем флакон"],
        ["Хранение", "Хранить в холодильнике"],
        ["Примечания", "Принимайте по одной таблетке утром или вечером, ежедневно в одно и то же время"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Финголимод" },
      { type: "table", rows: [
        ["Тип", "Капсулы"],
        ["Как часто", "Каждый день"],
        ["Упаковка", "Флакон"],
        ["Хранение", "Хранить в холодильнике"],
        ["Примечания", "Принимайте по одной капсуле утром или вечером, ежедневно в одно и то же время"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Записывайте каждую принятую дозу всех 3 лекарственных средств в электронный дневник." },
      { type: "paragraph", content: "Для инъекций укажите дату, время, часть тела и кто сделал инъекцию." },
      { type: "paragraph", content: "Если какую-то дозу пропущено, свяжитесь с врачом или исследовательской группой." },
      { type: "paragraph", content: "Немедленно сообщите врачу-исследователю о любых побочных эффектах." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default enUS
