import useUI from "framework/hooks/useUI"
import React, { createContext, useMemo } from "react"

export const ContentContext = createContext<LocalizedContent | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const ContentProvider = ({ website, children }: Props) => {
  const { locale, authCode } = useUI()

  const translationContent = authCode?.code === "OLIE-19" ? `${locale.code}o19` : locale.code

  const value = useMemo(() => website.i18n.locales[translationContent].content, [website, translationContent])

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
}

export default ContentProvider
