import sidebar from "../sidebars/global"
import pages from "../pages/global"

const plPL: LocaleDefinition = {
  code: "pl-PL",
  label: "Polish",
  country: "Poland",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Witamy na stronie internetowej badania NEOS",
    "welcome.message":
      "NEOS to badanie kliniczne dla dzieci i młodzieży ze stwardnieniem rozsianym w wieku od 10 do 17 lat. Proszę wprowadzić kod PIN znajdujący się na wydruku albo przekazany przez zespół ośrodka.",
    "welcome.pin_prompt": "EPROSZĘ WPROWADZIĆ KOD PIN PONIŻEJ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "WEJDŹ",
    "welcome.support": "Potrzebna jest pomoc? Proszę skontaktować się z miejscowym pracownikiem służby zdrowia.",
    "welcome.close": "Zamknij",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Opis",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Materiały wideo",
    "sidebar.videos.trials": "Badania kliniczne",
    "sidebar.videos.neos": "Badanie NEOS",
    "sidebar.videos.medicines": "Jak przyjmować leki stosowane w badaniu",

    "sidebar.reads-title": "Krótkie informacje",
    "sidebar.reads.pediatric-ms": "Informacje dotyczące stwardnienia rozsianego u dzieci",
    "sidebar.reads.study": "Ogólne informacje na temat badania",
    "sidebar.reads.schedule": "Harmonogram wizyt w ramach badania",

    "sidebar.resources-title": "Zasoby",

    "sidebar.contact": "Kontakt z nami",
    "sidebar.privacy": "Polityka prywatności",
    "sidebar.terms": "Regulamin świadczenia usług",
    "sidebar.copyright": "© Copyright 2021 Novartis. Wszelkie prawa zastrzeżone",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Informacje dotyczące badań klinicznych",
    "understanding-trials.description": "W tym filmie wyjaśniono, czym są badania kliniczne.",
    "understanding-trials.video-id": "8AiQJ8RziYw",

    "understanding-neos.title": "Informacje dotyczące badania NEOS",
    "understanding-neos.description": "Ten film dostarcza informacji na temat badania NEOS.",
    "understanding-neos.video-id": "_cjYf0RUelQ",

    "understanding-ms.title": "Informacje dotyczące stwardnienia rozsianego u dzieci",
    "understanding-ms.description": "Ogólne informacje na temat stwardnienia rozsianego u dzieci i młodzieży.",

    "study-medicines.title": "Jak przyjmować leki stosowane w badaniu",
    "study-medicines.description": "W tym materiale wideo wyjaśniono, jak przyjmować leki stosowane w badaniu.",
    "study-medicines.video-id": "qrL28SddXfc",

    "study-overview.title": "Ogólne informacje na temat badania",

    "schedule.title": "Harmonogram wizyt w ramach badania",

    "resources.title": "Zasoby",

    "neos-poster.title": "Plakat rekrutacji do badania NEOS",
    "neos-poster.description": "Badanie dla dzieci i młodzieży w wieku od 10 do 17 lat z rzutowo-remisyjną postacią stwardnienia rozsianego.",
    "neos-poster.href": "/i18n/PL-PL/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Badanie NEOS – Flipchart dotyczący świadomej zgody",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/PL-PL/pdfs/consent.pdf"
    },
    {
      title: "Badanie NEOS – Flipchart dotyczący świadomej zgody osoby małoletniej",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/PL-PL/pdfs/assent.pdf"
    },
    {
      title: "Badanie NEOS – Wielostronicowa ulotka z wprowadzeniem",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/PL-PL/pdfs/multifold.pdf"
    },
    {
      title: "Komiks dotyczący stwardnienia rozsianego i badania NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/PL-PL/pdfs/un ms.pdf"
    },
    {
      title: "Komiks dotyczący badań klinicznych – 7–12 lat",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/PL-PL/pdfs/comic 7-12.pdf"
    },
    {
      title: "Komiks dotyczący badań klinicznych – 13–17 lat",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/PL-PL/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Na czym polega stwardnienie rozsiane?" },
      { type: "paragraph", content: "Stwardnienie rozsiane powoduje uszkodzenie osłonki mielinowej, czyli warstwy ochronnej otaczającej nerwy. Gdy ta warstwa ochronna zostaje uszkodzona, nerwy nie mogą wysyłać sygnałów w taki sposób, jak zazwyczaj." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Objawy stwardnienia rozsianego mogą się różnić w zależności od miejsca, w którym doszło do uszkodzenia. Mogą one obejmować:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["zmęczenie", "drętwienie"],
        ["nieostre widzenie", "osłabienie"],
        ["uczucie mrowienia i kłucia, na przykład w dłoniach lub stopach", "ból"],
        ["zaburzenia równowagi", "depresję"],
        ["problemy z jelitami albo pęcherzem", "zaburzenia lękowe"],
        ["zapalenie nerwu wzrokowego – stan zapalny nerwu wzrokowego, który może powodować utratę wzroku (ale zazwyczaj wzrok powraca)", "trudności z mówieniem"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Co to jest nawrót choroby?" },
      { type: "paragraph", content: "Podczas rzutu choroby objawy mogą utrzymywać się przez kilka tygodni, a następnie samoistnie ustępować w miarę naprawy nerwu. Po rzutach następują okresy zwane remisjami, podczas których nie występują nowe objawy." },
      { type: "paragraph", content: "Nie potrafimy wyleczyć stwardnienia rozsianego. Prowadzone są badania kliniczne z udziałem młodych ludzi ze stwardnieniem rozsianym, aby badać nowe metody leczenia, które mogą być pomocne." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Na czym polega badanie NEOS?" },
      { type: "paragraph", content: "Badanie NEOS ma na celu ocenę trzech różnych leków stosowanych w badaniu w celu sprawdzenia, czy są one bezpieczne i skuteczne w leczeniu dzieci i młodzieży ze stwardnieniem rozsianym." },
      { type: "paragraph", content: "Uczestnicy kwalifikują się do udziału w badaniu, jeżeli są w wieku od 10 do 17 lat." },

      { type: "header", content: "Jak długo potrwa badanie?" },
      { type: "paragraph", content: "Badanie trwa dwa lata. Istnieje możliwość przyjmowania leku stosowanego w badaniu przez dodatkowe dwa do pięciu lat." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Liczba wizyt kontrolnych będzie zależeć od tego, kiedy zostanie przyjęta ostatnia dawka leku stosowanego w badaniu." },
      { type: "paragraph", content: "Metoda otwartej próby: Jest to przeciwieństwo metody podwójnie ślepej próby – oznacza to, że zarówno uczestnik badania, jak i lekarz prowadzący badanie będzie wiedzieć, do której grupy leczenia należy uczestnik badania." },
      { type: "paragraph", content: "Metoda podwójnie ślepej próby: Oznacza to, że ani uczestnik badania, ani lekarz prowadzący badanie nie będzie wiedzieć, do której grupy leczenia należy uczestnik badania." },

      { type: "header", content: "Jakie leki stosowane w badaniu będą oceniane?" },
      { type: "paragraph", content: "Oceniane będą trzy leki stosowane w badaniu:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab – lek podawany we wstrzyknięciu, dopuszczony w niektórych krajach do stosowania u osób dorosłych ze stwardnieniem rozsianym. Podaje się go za pomocą automatycznego wstrzykiwacza podobnego do tych używanych przez osoby z cukrzycą do wstrzykiwania insuliny. Badacze chcą sprawdzić, jak skuteczne i bezpieczne jest stosowanie ofatumumabu u dzieci i młodzieży; " },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod – lek w postaci tabletek doustnych dopuszczony w wielu krajach do stosowania u osób dorosłych ze stwardnieniem rozsianym. Badacze chcą sprawdzić, jak skuteczne i bezpieczne jest jego stosowanie u dzieci i młodzieży; " },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod – lek w postaci kapsułek doustnych dopuszczony już w wielu krajach do stosowania u dzieci i młodzieży ze stwardnieniem rozsianym. Badacze chcą się dowiedzieć, czy ofatumumab albo siponimod są co najmniej tak samo skuteczne jak fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Badacze chcą się dowiedzieć, czy ofatumumab albo siponimod są co najmniej tak samo skuteczne jak fingolimod." },
      { type: "paragraph", content: "Każdy uczestnik zostanie przydzielony do jednej z trzech grup leczenia." },
      { type: "paragraph", content: "Uczestnicy w każdej grupie będą przyjmować wszystkie trzy leki. Jeden z nich będzie zawierać substancję czynną, a dwa pozostałe – placebo. Placebo wygląda jak lek stosowany w badaniu, ale nie zawiera żadnego prawdziwego leku." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Co będzie należało do moich obowiązków podczas badania?" },
      { type: "paragraph", content: "Podczas wizyt w ośrodku badawczym i podczas rozmów telefonicznych z lekarzem prowadzącym badanie przeprowadzane będą określone oceny stanu zdrowia i badania diagnostyczne. Ważne jest, aby stawiać się na wszystkie wizyty w ramach badania i wypełniać eDzienniczek." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Udział w tym badaniu jest całkowicie dobrowolny. W każdej chwili można zrezygnować z udziału w nim." },
      { type: "header", content: "Jak przyjmować leki stosowane w badaniu" },
      { type: "paragraph", content: "Oceniane będą trzy leki stosowane w badaniu:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Rodzaj", "Automatyczny wstrzykiwacz"],
        ["Jak często?", "Co cztery tygodnie albo sześć tygodni (w zależności od masy ciała)"],
        ["Pojemnik", "Pudełko zawierające jednorazowy automatyczny wstrzykiwacz"],
        ["Przechowywanie", "Przechowywać w lodówce"],
        ["Uwagi", "Wyjąć z lodówki 15–30 minut przed wstrzyknięciem. Więcej szczegółów w Instrukcji"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Rodzaj", "Tabletka"],
        ["Jak często?", "Codziennie"],
        ["Pojemnik", "Opakowanie blistrowe na pierwsze sześć dni, a następnie butelka"],
        ["Przechowywanie", "Przechowywać w lodówce"],
        ["Uwagi", "Przyjmować jedną tabletkę rano albo wieczorem, o tej samej porze każdego dnia."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Rodzaj", "Kapsułka"],
        ["Jak często?", "Codziennie"],
        ["Pojemnik", "Butelka"],
        ["Przechowywanie", "Przechowywać w lodówce"],
        ["Uwagi", "Przyjmować jedną kapsułkę rano albo wieczorem, o tej samej porze każdego dnia."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Odnotowywać w eDzienniczku każdą przyjętą dawkę wszystkich trzech leków stosowanych w badaniu." },
      { type: "paragraph", content: "W przypadku wstrzyknięć podać datę, godzinę, część ciała oraz dane osoby, która podała wstrzyknięcie." },
      { type: "paragraph", content: "W przypadku pominięcia jakiejkolwiek dawki skontaktować się z lekarzem prowadzącym badanie lub zespołem prowadzącym badanie." },
      { type: "paragraph", content: "Należy natychmiast poinformować lekarza prowadzącego badanie o wszelkich działaniach niepożądanych." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default plPL
