import sidebar from "../sidebars/global"
import pages from "../pages/global"

const ptBR: LocaleDefinition = {
  code: "pt-BR",
  label: "Portuguese",
  country: "Brazil",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bem-vindo(a) ao site do Estudo NEOS",
    "welcome.message":
      "O NEOS é um estudo clínico para crianças e adolescentes de 10 a 17 anos de idade com esclerose múltipla (EM). Insira o PIN fornecido em seu material impresso ou pela equipe do centro de pesquisa.",
    "welcome.pin_prompt": "INSIRA O PIN ABAIXO",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRAR",
    "welcome.support": "Precisa de ajuda? Entre em contato com seu médico.",
    "welcome.close": "Fechar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descrição",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Vídeos",
    "sidebar.videos.trials": "Estudos clínicos",
    "sidebar.videos.neos": "Estudo NEOS",
    "sidebar.videos.medicines": "Como tomar os medicamentos do estudo",

    "sidebar.reads-title": "Leituras rápidas",
    "sidebar.reads.pediatric-ms": "Entendendo a EM pediátrica",
    "sidebar.reads.study": "Visão geral do estudo",
    "sidebar.reads.schedule": "Cronograma de visitas do estudo",

    "sidebar.resources-title": "Materiais",

    "sidebar.contact": "Fale conosco",
    "sidebar.privacy": "Política de Privacidade",
    "sidebar.terms": "Termos de Serviço",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos os direitos reservados",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Entendendo os estudos clínicos",
    "understanding-trials.description": "Este vídeo explica o que são estudos clínicos.",
    "understanding-trials.video-id": "cvIiwyyZMp8",

    "understanding-neos.title": "Entendendo o Estudo NEOS",
    "understanding-neos.description": "Este vídeo fornece informações sobre o Estudo NEOS.",
    "understanding-neos.video-id": "ahXF9uGM1pw",

    "understanding-ms.title": "Entendendo a EM pediátrica",
    "understanding-ms.description": "Breve explicação sobre a EM em crianças e adolescentes.",

    "study-medicines.title": "Como tomar os medicamentos do estudo",
    "study-medicines.description": "Este estudo explica como tomar as medicações do estudo.",
    "study-medicines.video-id": "28KYvT4F7jc",

    "study-overview.title": "Visão geral do estudo",

    "schedule.title": "Cronograma de visitas do estudo",

    "resources.title": "Materiais",

    "neos-poster.title": "Cartaz de recrutamento para o Estudo NEOS",
    "neos-poster.description": "Para crianças e adolescentes de 10 a 17 anos de idade com esclerose múltipla (EM) recorrente-remitente.",
    "neos-poster.href": "/i18n/PT-BR/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudo NEOS – Flipchart do Termo de Consentimento Livre e Esclarecido",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/PT-BR/pdfs/consent.pdf"
    },
    {
      title: "Estudo NEOS – Flipchart do Termo de Assentimento",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/PT-BR/pdfs/assent.pdf"
    },
    {
      title: "Estudo NEOS – Folheto de apresentação",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/PT-BR/pdfs/multifold.pdf"
    },
    {
      title: "História em quadrinhos “Entendendo a EM e o Estudo NEOS”",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/PT-BR/pdfs/un ms.pdf"
    },
    {
      title: "História em quadrinhos “Entendendo os estudos clínicos” para crianças de 7 a 12 anos",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/PT-BR/pdfs/comic 7-12.pdf"
    },
    {
      title: "História em quadrinhos “Entendendo os estudos clínicos” para adolescentes de 13 a 17 anos",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/PT-BR/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "O que a EM faz?" },
      { type: "paragraph", content: "A EM provoca danos na bainha de mielina, uma proteção que cobre os nervos. Quando essa proteção dos nervos é danificada, eles perdem a capacidade de enviar sinais para o cérebro." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Os sintomas da EM variam dependendo de onde os danos ocorrem. Eles podem incluir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansaço", "Dormência"],
        ["Visão embaçada", "Fraqueza"],
        ["Formigamento, por exemplo, nas mãos ou nos pés", "Dor"],
        ["Perda de equilíbrio", "Depressão"],
        ["Problemas no intestino ou na bexiga", "Ansiedade"],
        ["Neurite óptica, uma inflamação do nervo óptico que pode causar perda de visão (mas a visão normalmente retorna)", "Dificuldade para falar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "O que é recaída?" },
      { type: "paragraph", content: "Em uma recaída, os sintomas podem durar várias semanas e depois diminuir sozinhos, porque o próprio nervo se recupera. As recaídas são seguidas por períodos sem novos sintomas, chamados de remissões." },
      { type: "paragraph", content: "Não existe cura para a EM. Porém, há estudos clínicos entre jovens com EM para testar novas terapias que talvez possam ajudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "O que é o Estudo NEOS?" },
      { type: "paragraph", content: "O Estudo NEOS avalia três medicamentos diferentes para verificar se eles são seguros para ajudar crianças e adolescentes com esclerose múltipla." },
      { type: "paragraph", content: "Para participar, é preciso ter entre 10 e 17 anos de idade." },

      { type: "header", content: "Quanto tempo dura o estudo?" },
      { type: "paragraph", content: "O estudo tem duração de dois anos. Existe a possibilidade de continuar tomando o medicamento do estudo por mais dois a cinco anos." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*O número de visitas de acompanhamento depende de quando a última dose do medicamento em estudo for tomada." },
      { type: "paragraph", content: "Tratamento aberto: o oposto do duplo-cego, ou seja, o participante e o médico do estudo sabem em qual grupo de tratamento o participante está." },
      { type: "paragraph", content: "Duplo-cego: significa que nem o participante nem o médico do estudo sabem em qual grupo de tratamento o participante está." },

      { type: "header", content: "Quais medicamentos estão sendo avaliados no estudo?" },
      { type: "paragraph", content: "Estão sendo avaliados três medicamentos diferentes:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "O Ofatumumab é uma terapia injetável aprovada em alguns países para adultos com EM. Ele é aplicado com um autoinjetor semelhante ao que pessoas com diabetes usam para a injeção de insulina. Os pesquisadores querem verificar a eficácia e a segurança do Ofatumumab para crianças e adolescentes. " },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "O Siponimod é um comprimido oral aprovado em vários países para adultos com EM. Os pesquisadores querem verificar sua eficácia e segurança para crianças e adolescentes. " },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "O Fingolimod é uma cápsula oral que já está aprovada em muitos países para crianças e adolescentes com EM. Os pesquisadores querem verificar se o Ofatumumab ou o Siponimod são no mínimo tão bons quanto o Fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Os pesquisadores querem descobrir se o ofatumumab ou o siponimod são pelo menos tão bons quanto o fingolimod." },
      { type: "paragraph", content: "Cada participante é colocado em um de três grupos de tratamento." },
      { type: "paragraph", content: "Cada grupo recebe os três medicamentos. Um deles será o medicamento ativo e os outros dois serão placebos. Um placebo se parece com o medicamento em estudo, mas não contém um medicamento real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "O que eu preciso fazer durante o estudo?" },
      { type: "paragraph", content: "Serão feitos exames e avaliações de saúde durante as visitas clínicas do estudo e por telefone com o médico responsável. É importante comparecer a todas as visitas e preencher o diário eletrônico eDiary." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "A participação no estudo é totalmente voluntária. Você pode deixar o estudo a qualquer momento." },
      { type: "header", content: "Como tomar os medicamentos do estudo" },
      { type: "paragraph", content: "Estão sendo avaliados três medicamentos diferentes:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Autoinjetor"],
        ["Frequência", "A cada 4 ou 6 semanas (dependendo do peso)"],
        ["Embalagem", "Caixa com autoinjetor descartável"],
        ["Armazenamento", "Manter na geladeira"],
        ["Anotações", "Retire da geladeira de 15 a 30 minutos antes da injeção. Veja as instruções para saber mais detalhes"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Comprimido"],
        ["Frequência", "Diariamente"],
        ["Embalagem", "Cartela para os primeiros 6 dias, depois frasco"],
        ["Armazenamento", "Manter na geladeira"],
        ["Anotações", "Tomar um comprimido pela manhã ou à noite, sempre no mesmo horário"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Frequência", "Diariamente"],
        ["Embalagem", "Frasco"],
        ["Armazenamento", "Manter na geladeira"],
        ["Anotações", "Tomar uma cápsula pela manhã ou à noite, sempre no mesmo horário"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Registrar no eDiary todas as doses dos medicamentos do estudo que forem tomadas. Para as injeções, incluir data, hora, parte do corpo e quem aplicou a injeção. Se alguma dose não for tomada, fale com o médico ou a equipe do estudo. Informe o médico do estudo imediatamente sobre qualquer efeito colateral." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default ptBR
