import sidebar from "../sidebars/global"
import pages from "../pages/global"

const itIT: LocaleDefinition = {
  code: "it-IT",
  label: "Italian",
  country: "Italy",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Benvenuto/a al sito web dello studio NEOS",
    "welcome.message":
      "NEOS è uno studio clinico per bambini e adolescenti di età compresa tra 10 e 17 anni affetti da sclerosi multipla. Inserire il Codice PIN trovato nel materiale cartaceo o comunicato dal personale del centro.",
    "welcome.pin_prompt": "INSERIRE IL PIN DI SEGUITO",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRA",
    "welcome.support": "Serve aiuto? Contattare l’operatore sanitario locale.",
    "welcome.close": "Chiudi",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descrizione",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Video",
    "sidebar.videos.trials": "Sperimentazioni cliniche",
    "sidebar.videos.neos": "Studio NEOS",
    "sidebar.videos.medicines": "Come assumere i farmaci dello studio",

    "sidebar.reads-title": "Letture rapide",
    "sidebar.reads.pediatric-ms": "Comprendere la sclerosi multipla pediatrica",
    "sidebar.reads.study": "Informazioni generali sullo studio",
    "sidebar.reads.schedule": "Programma delle visite dello studio",

    "sidebar.resources-title": "Risorse",

    "sidebar.contact": "Contattaci",
    "sidebar.privacy": "Informativa sulla privacy",
    "sidebar.terms": "Termini di servizio",
    "sidebar.copyright": "© Copyright 2021 Novartis. Tutti i diritti riservati",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Conoscenza degli studi clinici",
    "understanding-trials.description": "Il presente video spiega che cosa sono gli studi clinici.",
    "understanding-trials.video-id": "sXCs1w1dfvE",

    "understanding-neos.title": "Comprendere lo studio NEOS",
    "understanding-neos.description": "Questo video fornisce informazioni sullo studio NEOS.",
    "understanding-neos.video-id": "Q6Oinqd6n1E",

    "understanding-ms.title": "Comprendere la sclerosi multipla pediatrica",
    "understanding-ms.description": "Mostra informazioni generali di conoscenza sulla sclerosi multipla in bambini e adolescenti.",

    "study-medicines.title": "Come assumere i farmaci dello studio",
    "study-medicines.description": "Questo video spiega come assumere i farmaci sperimentali.",
    "study-medicines.video-id": "L73sQWwkOPw",

    "study-overview.title": "Informazioni generali sullo studio",

    "schedule.title": "Programma delle visite dello studio",

    "resources.title": "Risorse",

    "neos-poster.title": "Manifesto di reclutamento dello studio NEOS",
    "neos-poster.description": "Per bambini e adolescenti di età compresa tra 10 e 17 anni, affetti da sclerosi multipla recidivo-remittente.",
    "neos-poster.href": "/i18n/IT-IT/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Studio NEOS - Scheda capovolta di consenso informato",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/IT-IT/pdfs/consent.pdf"
    },
    {
      title: "Studio NEOS - Scheda capovolta di consenso informato",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/IT-IT/pdfs/assent.pdf"
    },
    {
      title: "Studio NEOS - PDF multiformi",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/IT-IT/pdfs/multifold.pdf"
    },
    {
      title: "Comprendere la sclerosi multipla e lo studio NEOS - Fumetto",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/IT-IT/pdfs/un ms.pdf"
    },
    {
      title: "Comprendere gli studi clinici - Fumetto età 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/IT-IT/pdfs/comic 7-12.pdf"
    },
    {
      title: "Comprendere gli studi clinici - Fumetto età 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/IT-IT/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Cosa succede nella sclerosi multipla?" },
      { type: "paragraph", content: "La sclerosi multipla danneggia la guaina mielinica, che è lo strato protettivo che avvolge i nervi. Quando questo strato nervoso viene danneggiato, i nervi non possono inviare i segnali come avviene normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "I sintomi della sclerosi multipla variano in base alla localizzazione del danno. Possono includere:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Stanchezza", "Intorpidimento"],
        ["Vista annebbiata", "Debolezza"],
        ["Formicolio, ad esempio alle mani o ai piedi", "Dolore"],
        ["Perdita di equilibrio", "Depressione"],
        ["Problemi di intestino o vescica", "Ansia"],
        ["Neurite ottica: infiammazione del nervo ottico, che può causare la perdita della vista (tuttavia, la vista di solito ritorna)", "Difficoltà nel parlare"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Che cos’è la recidiva?" },
      { type: "paragraph", content: "Durante una recidiva, i sintomi possono durare diverse settimane e poi attenuarsi da soli una volta che il nervo si sarà riparato. Le recidive sono seguite da periodi senza nuovi sintomi, chiamati remissioni." },
      { type: "paragraph", content: "Non esiste cura per la sclerosi multipla. Gli studi clinici vengono condotti per testare nuove terapie utili ai giovani affetti da sclerosi multipla." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Cos’è lo studio NEOS?" },
      { type: "paragraph", content: "Lo studio NEOS esamina 3 diversi farmaci dello studio per scoprire se sono in grado di aiutare in modo sicuro bambini e adolescenti affetti da sclerosi multipla." },
      { type: "paragraph", content: "I partecipanti sono idonei se hanno un’età compresa tra 10 e 17 anni." },

      { type: "header", content: "Quanto dura lo studio?" },
      { type: "paragraph", content: "Lo studio dura 2 anni. C’è la possibilità di assumere il farmaco sperimentale per altri 2-5 anni." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Il numero di visite di follow-up dipenderà da quando viene assunta l’ultima dose di farmaco dello studio." },
      { type: "paragraph", content: "In aperto: l’opposto del doppio cieco. Significa che sia il partecipante allo studio sia il medico dello studio conoscono gruppo di trattamento di appartenenza del partecipante allo studio." },
      { type: "paragraph", content: "Doppio cieco: significa che né il partecipante allo studio né il medico dello studio conoscono il gruppo di trattamento di appartenenza del partecipante allo studio." },

      { type: "header", content: "Quali farmaci dello studio vengono testati?" },
      { type: "paragraph", content: "Vengono testati tre farmaci dello studio:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab è una terapia iniettabile approvata in alcuni paesi per gli adulti affetti da sclerosi multipla. Viene somministrata tramite un autoiniettore simile a quelli utilizzati dai pazienti affetti da diabete per iniettare l’insulina. I ricercatori vogliono conoscere l’efficacia e la sicurezza di ofatumumab nei bambini e negli adolescenti." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod è una compressa da assumere per via orale approvata in molti paesi per gli adulti affetti da sclerosi multipla. I ricercatori vogliono conoscerne l’efficacia e la sicurezza nei bambini e negli adolescenti." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod è una capsula da assumere per via orale già approvata in molti paesi per bambini e adolescenti affetti da sclerosi multipla. I ricercatori vogliono scoprire se ofatumumab o siponimod siano efficaci almeno quanto fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "i ricercatori vogliono scoprire se Ofatumumab o Siponimod siano efficaci almeno quanto Fingolimod." },
      { type: "paragraph", content: "Ciascun partecipante viene inserito in uno di tre gruppi di trattamento." },
      { type: "paragraph", content: "Ciascun braccio riceve tutti e tre i farmaci. Uno conterrà il farmaco attivo e due saranno dei placebo. Un placebo ha lo stesso aspetto del farmaco dello studio ma non contiene alcun farmaco attivo." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Cosa dovrò fare durante lo studio?" },
      { type: "paragraph", content: "Verranno svolti controlli sanitari e test alle visite in clinica dello studio e durante le telefonate con il medico dello studio. È importante presentarsi a tutte le visite dello studio e completare il diario elettronico." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "La partecipazione allo studio è del tutto volontaria. Puoi scegliere di abbandonare lo studio in qualsiasi momento." },
      { type: "header", content: "Come assumere i farmaci dello studio" },
      { type: "paragraph", content: "Vengono testati tre farmaci dello studio:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Penna per autoiniezione"],
        ["Frequenza", "Ogni 4 o 6 settimane (in base al peso)"],
        ["Contenitore", "Scatola con penna per autoiniezione monouso"],
        ["Conservazione", "Tenere in frigo"],
        ["Note", "Rimuovere dal frigorifero 15-30 minuti prima dell’iniezione. Vedere le istruzioni per ulteriori dettagli"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Compressa"],
        ["Frequenza", "Ogni giorno"],
        ["Contenitore", "Blister per i primi 6 giorni, poi flacone"],
        ["Conservazione", "Tenere in frigo"],
        ["Note", "Assumere una compressa al mattino o alla sera, ogni giorno alla stessa ora"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Capsule"],
        ["Frequenza", "Ogni giorno"],
        ["Contenitore", "Flacone"],
        ["Conservazione", "Tenere in frigo"],
        ["Note", "Assumere una capsula al mattino o alla sera, ogni giorno alla stessa ora"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Registrare sul diario elettronico ogni dose assunta di tutti e 3 i farmaci dello studio." },
      { type: "paragraph", content: "Per le iniezioni, includere data, ora, parte del corpo e chi ha praticato l’iniezione." },
      { type: "paragraph", content: "Se viene saltata una dose, contattare il medico dello studio o il personale dello studio." },
      { type: "paragraph", content: "Riferire subito al medico dello studio eventuali effetti collaterali." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default itIT
