import sidebar from "../sidebars/global"
import pages from "../pages/global"

const lvLV: LocaleDefinition = {
  code: "lv-LV",
  label: "Latvian",
  country: "Latvia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Laipni lūdzam pētījuma NEOS tīmekļa vietnē",
    "welcome.message":
      "NEOS ir klīniskais pētījums bērniem un pusaudžiem vecumā no 10 līdz 17 gadiem ar multiplo sklerozi (MS). Lūdzu, ievadiet PIN kodu, kas atrodams uz Jūsu drukātā materiāla vai ko Jums iedeva pētījuma centra komanda.",
    "welcome.pin_prompt": "IEVADIET PIN KODU ZEMĀK",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "IEVADĪT",
    "welcome.support": "Nepieciešama palīdzība? Lūdzu, sazinieties ar savu vietējo veselības aprūpes pakalpojumu sniedzēju.",
    "welcome.close": "aizvērt",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Apraksts",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Video",
    "sidebar.videos.trials": "Klīniski pētījumi",
    "sidebar.videos.neos": "Pētījums NEOS",
    "sidebar.videos.medicines": "Kā lietot pētījuma zāles",

    "sidebar.reads-title": "Ātrai uzziņai",
    "sidebar.reads.pediatric-ms": "Izpratne par MS bērniem",
    "sidebar.reads.study": "Pētījuma pārskats",
    "sidebar.reads.schedule": "Pētījuma vizīšu grafiks",

    "sidebar.resources-title": "Resursi",

    "sidebar.contact": "Sazināties ar mums",
    "sidebar.privacy": "Privātuma politika",
    "sidebar.terms": "Pakalpojuma noteikumi",
    "sidebar.copyright": "© Autortiesības 2021 Novartis. Visas tiesības paturētas",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Izpratne par klīniskajiem pētījumiem",
    "understanding-trials.description": "Šajā video tiek izskaidrots, kas ir klīniskie pētījumi.",
    "understanding-trials.video-id": "27thAvaJrWo",

    "understanding-neos.title": "Izpratne par pētījumu NEOS",
    "understanding-neos.description": "Šis video sniedz informāciju par NEOS pētījumu.",
    "understanding-neos.video-id": "WR3vwInko7E",

    "understanding-ms.title": "Izpratne par MS bērniem",
    "understanding-ms.description": "Īss pārskats, lai izprastu MS bērniem un pieugušajiem.",

    "study-medicines.title": "Kā lietot pētījuma zāles",
    "study-medicines.description": "Šajā video ir paskaidrots, kā lietot pētāmās zāles.",
    "study-medicines.video-id": "WXH9WGvL4SI",

    "study-overview.title": "Pētījuma pārskats",

    "schedule.title": "Pētījuma vizīšu grafiks",

    "resources.title": "Resursi",

    "neos-poster.title": "NEOS pētījuma atlases plakāts",
    "neos-poster.description": "Bērniem un pusaudžiem vecumā no 10 līdz 17 gadiem ar recidivējoši remitējošu multiplo sklerozi (MS).",
    "neos-poster.href": "/i18n/LV-LV/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Pētījums NEOS - Informētās piekrišanas papīra tāfele",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/LV-LV/pdfs/consent.pdf"
    },
    {
      title: "Pētījums NEOS – Pusaudžu Informētās piekrišanas papīra tāfele",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/LV-LV/pdfs/assent.pdf"
    },
    {
      title: "Pētījuma NEOS - Salokāma ievadinformācija",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/LV-LV/pdfs/multifold.pdf"
    },
    {
      title: "Izpratne par MS un pētījumu NEOS, komiksu grāmata",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/LV-LV/pdfs/un ms.pdf"
    },
    {
      title: "Izpratne par klīniskajiem pētījumiem, komiksu grāmata, 7-12 gadi",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/LV-LV/pdfs/comic 7-12.pdf"
    },
    {
      title: "Izpratne par klīniskajiem pētījumiem, komiksu grāmata, 13-17 gadi",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/LV-LV/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Kas notiek MS laikā?" },
      { type: "paragraph", content: "MS bojā mielīna apvalku, kas ir aizsargapvalks, kas aptver nervus. Kad šis nervu apvalks ir bojāts, nervi nevar nosūtīt signālus, kā parasti vajadzētu." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "MS simptomi variējas atkarībā no tā, kurā vietā ir bojājums. Tas var ietvert sekojošo:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Nogurums", "Nejutīgums"],
        ["Neskaidra redze", "Vājums"],
        ["Tirpšana, piemēram, rokās vai kājās", "Sāpes"],
        ["Līdzsvara zudums", "Depresija"],
        ["Problēmas ar zarnām vai urīnpūsli", "Trauksme"],
        ["Optiskais neirīts–redzes nerva iekaisums, kas var izraisīt redzes zudumu (bet redze parasti atgriežas)", "Grūtības runāt"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Kas ir recidīvs?" },
      { type: "paragraph", content: "Recidīva laikā simptomi var ilgt vairākas nedēļas un pēc tam paši mazināties, jo nervs pats atjaunojas. Pēc recidīviem seko periodi bez jauniem simptomiem, ko sauc par remisijām." },
      { type: "paragraph", content: "MS nevar izārstēt. Tiek veikti klīniski pētījumi jauniešiem ar MS, lai pārbaudītu jaunas ārstēšanas iespējas, kas varētu palīdzēt." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Kas ir pētījums NEOS?" },
      { type: "paragraph", content: "Pētījums NEOS apskata 3 dažādas pētījuma zāles, lai noskaidrotu, vai tās var droši palīdzēt bērniem un pusaudžiem ar multiplo sklerozi." },
      { type: "paragraph", content: "Dalībnieki ir piemēroti, ja ir vecumā no 10 līdz 17 gadiem." },

      { type: "header", content: "Cik ilgi pētījums ilgs?" },
      { type: "paragraph", content: "Pētījums ilgst 2 gadus. Pastāv iespēja lietot pētījuma zāles papildus 2 līdz 5 gadus." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Apsekošanas vizīšu skaits būs atkarīgs no tā, kad tiks lietota pēdējā pētījuma zāļu deva." },
      { type: "paragraph", content: "Atklāts: pretēji dubultmaskētajam, tas nozīmē, ka gan pētījuma dalībnieks, gan pētījuma ārsts zinās, kurā grupā pētījuma dalībnieks ir." },
      { type: "paragraph", content: "Dubultmaskēta: tas nozīmē, ka ne pētījuma dalībnieks, ne pētījuma ārsts nezinās, kurā ārstēšanas grupā pētījuma dalībnieks ir." },

      { type: "header", content: "Kādas pētījuma zāles tiek pārbaudītas?" },
      { type: "paragraph", content: "Tiek pārbaudītas trīs pētījuma zāles:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumabs" },
      { type: "paragraph", content: "Ofatumumabs ir injicējama terapija, kas apstiprināta dažās valstīs pieaugušajiem ar MS. To ievada, izmantojot pašinjekcijas šļirci, kas līdzīga tām, ko cilvēki izmanto insulīna injicēšanai. Pētnieki vēlas uzzināt, cik labi ofatumumabs iedarbojas bērniem un pusaudžiem un cik drošs tas ir. " },
      { type: "divider" },

      { type: "header", content: "Siponimods" },
      { type: "paragraph", content: "Siponimods ir iekšķīgi lietojama tablete, kas apstiprināta daudzās valstīs pieaugušajiem ar MS. Pētnieki vēlas uzzināt, cik labi tas iedarbojas bērniem un pusaudžiem un cik drošas tas ir. " },
      { type: "divider" },

      { type: "header", content: "Fingolimods" },
      { type: "paragraph", content: "Fingolimods ir iekšķīgi lietojama kapsula, kas jau apstiprināta daudzās valstīs bērniem un pusaudžiem ar MS. Pētnieki vēlas noskaidrot, vai ofatumumabs vai siponimods ir vismaz tikpat labas kā fingolimods." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Pētnieki vēlas noskaidrot, vai ofatumumabs vai siponimods ir vismaz tikpat labas kā fingolimids." },
      { type: "paragraph", content: "Katrs dalībnieks būs iedalīts 1 no 3 grupām." },
      { type: "paragraph", content: "Katra grupa saņems visas 3 zāles. Vienas no tām saturēs aktīvās zāles un 2 no tām būs placebo. Placebo izskatās tāpat kā pētījuma zāles, bet nesatur faktiskas zāles." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Kas man būs jādara pētījuma laikā?" },
      { type: "paragraph", content: "Pētījuma klīnikas vizīšu laikā būs veselības pārbaudes un izmeklējumi, un būs tālruņa zvani ar pētījuma ārstu. Ir svarīgi ierasties uz visām vizītēm un aizpildīt eDienasgrāmatu." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Dalība pētījumā ir pilnībā brīvprātīga.  Jūs varat izvēlēties izstāties no pētījuma jebkurā laikā." },
      { type: "header", content: "Kā lietot pētījuma zāles" },
      { type: "paragraph", content: "Tiek pārbaudītas trīs pētījuma zāles:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumabs" },
      { type: "table", rows: [
        ["Veids", "Pašinjekcijas pildspalva"],
        ["Cik bieži", "Ik pēc 4 vai 6 nedēļām (atkarībā no svara)"],
        ["Konteiners", "Kastīte ar vienreiz lietojamu pašinjekcijas šļirci"],
        ["Uzglabāšana", "Turiet ledusskapī"],
        ["Piezīmes", "Izņemt no ledusskapja 15-30 minūtes pirms injekcijas. Lai uzzinātu vairāk informāciju, apskatiet norādījumus"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimods" },
      { type: "table", rows: [
        ["Veids", "Tablete"],
        ["Cik bieži", "Katru dienu"],
        ["Konteiners", "Blisteriepakojums pirmajām 6 dienām, tad pudele"],
        ["Uzglabāšana", "Turiet ledusskapī"],
        ["Piezīmes", "Lietojiet vienu tableti no rīta vai vakarā vienā un tai pašā laikā katru dienu"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimods" },
      { type: "table", rows: [
        ["Veids", "Kapsula"],
        ["Cik bieži", "Katru dienu"],
        ["Konteiners", "Pudele"],
        ["Uzglabāšana", "Turiet ledusskapī"],
        ["Piezīmes", "Lietojiet vienu kapsulu no rīta vai vakarā vienā un tai pašā laikā katru dienu"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Reģistrējiet visu 3 pētījuma zāļu katru lietoto devu eDienasgrāmatā." },
      { type: "paragraph", content: "Injekcijām iekļaujiet datumu, laiku, ķermeņa daļu un kas veica injekciju." },
      { type: "paragraph", content: "Ja kāda deva tiek izlaista, sazinieties ar pētījuma ārstu vai pētījuma komandu." },
      { type: "paragraph", content: "Uzreiz pastāstiet pētījuma ārstam par jebkādām blakusparādībām." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default lvLV
