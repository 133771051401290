import sidebar from "../sidebars/global"
import pages from "../pages/global"

const esES: LocaleDefinition = {
  code: "es-ES",
  label: "Spanish",
  country: "Spain",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bienvenido al sitio web del Estudio NEOS",
    "welcome.message":
      "NEOS es un estudio clínico para niños y adolescentes con esclerosis múltiple (EM) y una edad entre 10 y 17 años. Introduce el código PIN que se encuentra en los folletos o que te ha dado el personal del centro.",
    "welcome.pin_prompt": "INTRODUCE EL PIN A CONTINUACIÓN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ENTRAR",
    "welcome.support": "¿Necesitas ayuda? Ponte en contacto con tu profesional sanitario local.",
    "welcome.close": "Cerrar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descripción",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Ensayos clínicos",
    "sidebar.videos.neos": "Estudio NEOS",
    "sidebar.videos.medicines": "Cómo se toman los medicamentos del estudio",

    "sidebar.reads-title": "Lecturas breves",
    "sidebar.reads.pediatric-ms": "Información sobre la EM pediátrica",
    "sidebar.reads.study": "Descripción general del estudio",
    "sidebar.reads.schedule": "Calendario de visitas del estudio",

    "sidebar.resources-title": "Recursos",

    "sidebar.contact": "Contacto",
    "sidebar.privacy": "Política de privacidad",
    "sidebar.terms": "Términos de servicio",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos los derechos reservados.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Información sobre los ensayos clínicos",
    "understanding-trials.description": "Este vídeo explica qué son los ensayos clínicos.",
    "understanding-trials.video-id": "0SH2O0gN3Uw",

    "understanding-neos.title": "Información sobre el Estudio NEOS",
    "understanding-neos.description": "Este vídeo proporciona información sobre el Estudio NEOS.",
    "understanding-neos.video-id": "8kTBGVLykwc",

    "understanding-ms.title": "Información sobre la EM pediátrica",
    "understanding-ms.description": "Breve descripción general sobre la EM en niños y adolescentes.",

    "study-medicines.title": "Cómo se toman los medicamentos del estudio",
    "study-medicines.description": "Este vídeo explica cómo tomar los medicamentos del estudio.",
    "study-medicines.video-id": "oJZixr6onPM",

    "study-overview.title": "Descripción general del estudio",

    "schedule.title": "Calendario de visitas del estudio",

    "resources.title": "Recursos",

    "neos-poster.title": "Póster de reclutamiento para el Estudio NEOS",
    "neos-poster.description": "Para niños y adolescentes de entre 10 y 17 años de edad con esclerosis múltiple (EM) remitente-recurrente.",
    "neos-poster.href": "/i18n/ES-ES/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudio NEOS - Rotafolio del consentimiento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/EN-US/pdfs/consent.pdf"
    },
    {
      title: "Estudio NEOS - Rotafolio del asentimiento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/EN-US/pdfs/assent.pdf"
    },
    {
      title: "Estudio NEOS - Folleto de presentación",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/EN-US/pdfs/multifold.pdf"
    },
    {
      title: "Información sobre la EM y el Estudio NEOS - Cómic",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/EN-US/pdfs/un ms.pdf"
    },
    {
      title: "Información sobre los ensayos clínicos - Cómic - 7-12 años",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/EN-US/pdfs/comic 7-12.pdf"
    },
    {
      title: "Información sobre los ensayos clínicos - Cómic - 13-17 años",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/EN-US/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "¿Cómo funciona la EM?" },
      { type: "paragraph", content: "La EM causa daños en la vaina de mielina, que es la capa protectora que rodea el nervio. Si la capa protectora del nervio se daña, el nervio no puede enviar señales como lo haría normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Los síntomas de la EM varían dependiendo de dónde esté el daño. Pueden incluir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansancio", "Entumecimiento"],
        ["Visión borrosa", "Debilidad"],
        ["Hormigueo, por ejemplo, en las manos o los pies", "Dolor"],
        ["Pérdida de equilibrio", "Depresión"],
        ["Problemas en los intestinos o la vejiga", "Ansiedad"],
        ["Neuritis óptica: La inflamación del nervio óptico, que puede causar pérdidas de visión (pero normalmente se recupera la vista)", "Dificultad para hablar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "¿Qué es un brote?" },
      { type: "paragraph", content: "Durante unbrote, los síntomas pueden durar varias semanas y, después, mejorar por sí solos a medida que el nervio se repara. Los brotes van seguidos de periodos en los que no hay síntomas nuevos. Son lo que llamamos “remisiones”." },
      { type: "paragraph", content: "La EM no tiene cura. Se están realizando estudios clínicos con personas jóvenes que tienen EM para probar nuevos tratamientos que podrían ayudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "¿Qué es el Estudio NEOS?" },
      { type: "paragraph", content: "El Estudio NEOS está investigando 3 medicamentos diferentes para ver si pueden ayudar de forma segura a los niños y adolescentes con esclerosis múltiple." },
      { type: "paragraph", content: "Los participantes serán aptos si tienen entre 10 y 17 años." },

      { type: "header", content: "¿Cuánto tiempo dura el estudio?" },
      { type: "paragraph", content: "El estudio durará 2 años. Existe la opción de seguir tomando el medicamento del estudio durante 2 a 5 años adicionales." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* La cantidad de visitas de seguimiento dependerá de cuándo se haya tomado la última dosis del medicamento del estudio." },
      { type: "paragraph", content: "Abierto: Es lo contrario a “doble ciego”; significa que tanto el participante del estudio como el médico del estudio saben en qué grupo de tratamiento está el participante del estudio." },
      { type: "paragraph", content: "Doble ciego: Esto significa que ni el participante del estudio ni el médico del estudio saben en qué grupo de tratamiento está el participante del estudio." },

      { type: "header", content: "¿Qué medicamentos del estudio se están probando?" },
      { type: "paragraph", content: "Se están probando tres medicamentos del estudio:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab es un tratamiento inyectable que está aprobado en algunos países para adultos con EM. Se administra usando un autoinyector similar a los que utilizan los diabéticos para inyectarse la insulina. Los investigadores quieren averiguar si ofatumumab funciona bien en niños y adolescentes y si es seguro. " },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod es un comprimido oral que está aprobado en muchos países para tratar a adultos con EM. Los investigadores quieren averiguar si funciona bien en niños y adolescentes y si es seguro." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod es una cápsula oral que ya está aprobada en muchos países para tratar a niños y adolescentes con EM. Los investigadores quieren ver si ofatumumab o siponimod son al menos igual de buenos que fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Los investigadores quieren ver si ofatumumab o siponimod son por lo menos igual de buenos que fingolimod." },
      { type: "paragraph", content: "Cada participante es asignado a 1 de los 3 grupos de tratamiento." },
      { type: "paragraph", content: "Cada grupo recibe los 3 medicamentos. Uno de ellos tendrá medicamento activo y los otros dos serán placebos. El placebo tiene el mismo aspecto que el medicamento del estudio, pero no contiene ningún medicamento real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "¿Qué tendré que hacer durante el estudio?" },
      { type: "paragraph", content: "Te harán revisiones de salud y pruebas en las visitas al centro del estudio y durante las llamadas telefónicas con el médico del estudio. Es importante que vayas a todas las visitas del estudio y que rellenes el diario electrónico." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "La participación en el estudio es totalmente voluntaria. Puedes decidir dejar el estudio en cualquier momento." },
      { type: "header", content: "Cómo se toman los medicamentos del estudio" },
      { type: "paragraph", content: "Se están probando tres medicamentos del estudio:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Pluma de autoinyección"],
        ["Frecuencia de uso", "Cada 4 o 6 semanas (dependiendo del peso)"],
        ["Envase", "Caja con una pluma de autoinyección de un solo uso"],
        ["Conservación", "Conservar en la nevera"],
        ["Notas", "Sacar de la nevera 15-30 minutos antes de la inyección. Consultar las instrucciones para obtener más información"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Comprimido"],
        ["Frecuencia de uso", "Cada día"],
        ["Envase", "Blíster para los primeros 6 días, luego frasco"],
        ["Conservación", "Conservar en la nevera"],
        ["Notas", "Tomar un comprimido por la mañana o por la noche, a la misma hora cada día"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Frecuencia de uso", "Cada día"],
        ["Envase", "Frasco"],
        ["Conservación", "Conservar en la nevera"],
        ["Notas", "Tomar una cápsula por la mañana o por la noche, a la misma hora cada día"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Apunta cada dosis que tomes de los 3 medicamentos del estudio en el diario electrónico." },
      { type: "paragraph", content: "En el caso de las inyecciones, apunta la fecha, la hora, la parte del cuerpo y quién te puso la inyección." },
      { type: "paragraph", content: "Si te saltas alguna dosis, ponte en contacto con el médico del estudio o el personal del estudio." },
      { type: "paragraph", content: "Avisa enseguida al médico del estudio si tienes algún efecto secundario." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default esES
