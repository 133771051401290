import { Box, Button, makeStyles, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { ReactComponent as WelcomeGraphic } from "assets/welcome.svg"
import LandingPageContainer from "framework/LandingPageContainer"
import useUI from "framework/hooks/useUI"
import useCopy from "framework/hooks/useCopy"
import useLogo from "framework/hooks/useLogo"

const styles = makeStyles((theme) => ({
  logo: {
    display: "block",
    padding: "16px 0",
    [theme.breakpoints.up("lg")]: {
      width: 270,
      height: 44,
    },
  },
}))

const WelcomePage = () => {
  const { setWelcomePageCompleted } = useUI()
  const handleComplete = () => {
    setWelcomePageCompleted(true)
  }

  const { Logo } = useLogo()
  const classes = styles()
  const copy = useCopy()

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "welcome",
    })
  }, [])

  return (
    <LandingPageContainer>
      <Box>
        <WelcomeGraphic />
        <Logo className={classes.logo} />
        <Typography variant="body1" color="primary">
          {copy["welcome.heading"]}
        </Typography>
        <Box mt={{ xs: 2, lg: 4 }}>
          <Button variant="contained" color="primary" onClick={handleComplete} fullWidth>
            {copy["welcome.cta"]}
          </Button>
        </Box>
      </Box>
    </LandingPageContainer>
  )
}

export default WelcomePage
