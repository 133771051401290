import sidebar from "../sidebars/global"
import pages from "../pages/global"

const enUS: LocaleDefinition = {
  code: "sr-RSl",
  label: "Srpski",
  country: "Serbia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Dobrodošli na internet stranicu Studije NEOS",
    "welcome.message":
      "NEOS je klinička studija za decu i adolescente uzrasta između 10 i 17 godina koji boluju od multiple skleroze (MS). Unesite PIN kod koji je naveden u štampanom materijalu ili koji ste dobili od studijskog tima.",
    "welcome.pin_prompt": "UNESITE PIN KODU NASTAVKU",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "UNESITE",
    "welcome.support": "Potrebna vam je pomoć? Obratite se svom lekaru ili zdravstvenom radniku.",
    "welcome.close": "Zatvori",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Opis",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Video-snimci",
    "sidebar.videos.trials": "Kliničke Studije",
    "sidebar.videos.neos": "Studija NEOS",
    "sidebar.videos.medicines": "Kako uzimati studijski lek",

    "sidebar.reads-title": "Sažeti članci",
    "sidebar.reads.pediatric-ms": "Razumevanje pedijatrijske MS",
    "sidebar.reads.study": "Pregled studije",
    "sidebar.reads.schedule": "Raspored studijskih poseta",

    "sidebar.resources-title": "Izvori",

    "sidebar.contact": "Kontaktirajte nas",
    "sidebar.privacy": "Politika privatnosti",
    "sidebar.terms": "Uslovi korišćenja",
    "sidebar.copyright": "© Autorska prava 2021. Novartis. Sva prava zadržana",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Razumevanje kliničkih studija",
    "understanding-trials.description": "Ovaj video snimak objašnjava šta su kliničke studije.",
    "understanding-trials.video-id": "SgN3ZgcVv14",

    "understanding-neos.title": "Razumevanje Studije NEOS",
    "understanding-neos.description": "Ovaj video snimak pruža informacije o studiji NEOS.",
    "understanding-neos.video-id": "iX31oFjmD1s",

    "understanding-ms.title": "Razumevanje pedijatrijske MS",
    "understanding-ms.description": "Kratak pregled MS kod dece i adolescenata i razumevanje oboljenja.",

    "study-medicines.title": "Kako uzimati studijske lekove",
    "study-medicines.description": "Ovaj video snimak daje objašnjenje načina uzimanja ispitivanih lekova.",
    "study-medicines.video-id": "c-vHht7FMbM",

    "study-overview.title": "Pregled studije",

    "schedule.title": "Raspored studijskih poseta",

    "resources.title": "Izvori",

    "neos-poster.title": "Plakat za pozivanje novih učesnika studije NEOS",
    "neos-poster.description": "Za decu i adolescente u starosnoj dobi između 10 i 17 godina sa relapsno-remitentne multiple skleroze (MS).",
    "neos-poster.href": "/i18n/SR-RSL/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Studija NEOS - Grafikon sa informacijama o informisanom pristanku",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/SR-RSL/pdfs/consent.pdf"
    },
    {
      title: "Studija NEOS - Grafikon sa informacijama o informisanoj saglasnosti",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/SR-RSL/pdfs/assent.pdf"
    },
    {
      title: "Studija NEOS - Više uvodnih napomena",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/SR-RSL/pdfs/multifold.pdf"
    },
    {
      title: "Strip za razumevanje MS i Studije NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/SR-RSL/pdfs/un ms.pdf"
    },
    {
      title: "Strip za razumevanje kliničkе studijе, Starosno doba 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/SR-RSL/pdfs/comic 7-12.pdf"
    },
    {
      title: "Strip za razumevanje kliničke studije, Starosno doba 13-17 ",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/SR-RSL/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Šta se dešava u MS?" },
      { type: "paragraph", content: "MS oštećuje mijelinski omotač, koji predstavlja zaštitni omotač koji obavija i štiti nervna vlakna. Kada je ovaj zaštitni omotač oštećen, nervi ne mogu normalno da šalju signale." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Simptomi MS variraju u zavisnosti od lokacije oštećenja. Oni mogu da uključuju:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Umor", "Ukočenost"],
        ["Zamagljen vid", "Slabost"],
        ["Trnjenje ili žmarci, npr. u rukama ili stopalima", "Bol"],
        ["Gubitak ravnoteže", "Depresija"],
        ["Problemi sa crevima ili mokraćnom bešikom", "Аnksioznost"],
        ["Оptički neuritis - zapaljenje optičkog nerva, što može prouzrokovati gubitak vida (ali vid se obično vraća)", "Тeškoće u govoru"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Šta je relaps?" },
      { type: "paragraph", content: "Tokom relapsa, simptomi mogu trajati nekoliko nedelja, a zatim se sami smiruju kako se nerv oporavlja. Relaps prate periodi bez novih simptoma koji se nazivaju remisije." },
      { type: "paragraph", content: "Ne postoji lek za MS. Kliničke studije se sprovode za mlade ljude sa MS za testiranje novih terapija koje bi mogle da pomognu." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Šta je Studija NEOS?" },
      { type: "paragraph", content: "Studija NEOS ispituje 3 različita studijska leka kako bi se utvrdilo da li mogu bezbedno da pomognu deci i adolescentima koji boluju od multiple skleroze." },
      { type: "paragraph", content: "Pravo učešća imaju osobe starosti između 10 i 17 godina." },

      { type: "header", content: "Koliko traje studija?" },
      { type: "paragraph", content: "Studija traje 2 godine. Postoji mogućnost dodatne primene studijskog leka u periodu od 2 do 5 godina nakon završetka studije." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Broj poseta za praćenje stanja pacijenta zavisiće od toga kada je uzeta poslednja doza studijskog leka." },
      { type: "paragraph", content: "Otvorena: Suprotno od dvostruko slepe studije, ovde i učesnik u studiji i studijski lekar znaju u kojoj se terapijskoj grupi nalazi učesnik studije." },
      { type: "paragraph", content: "Dvostruko slepa: To znači da ni učesnik u studiji ni studijski lekar ne znaju u kojoj se terapijskoj grupi nalazi učesnik studije." },

      { type: "header", content: "Koji se studijski lekovi ispituju?" },
      { type: "paragraph", content: "Ispituju se tri studijska leka:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Injekciona terapija koja je u nekim zemljama odobrena za odrasle sa MS. Daje se pomoću autoinjektora sličnog onom koji ljudi sa dijabetesom koriste za ubrizgavanje insulina. Istraživači žele da utvrde koliko efikasno deluje kod dece i adolescenata i koliko je bezbedan." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Oralna tableta koja je odobrena u mnogim zemljama za odrasle sa MS. Istraživači žele da utvrde koliko efikasno deluje kod dece i adolescenata i koliko je bezbedan." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Oralna kapsula koja je već odobrena u mnogim zemljama za decu i adolescente sa MS. Istraživači žele da vide da li su ofatumumab ili siponimod podjednako dobri kao fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Istraživači žele da vide da li su ofatumumab ili siponimod podjednako dobri kao i fingolimod." },
      { type: "paragraph", content: "Svaki učesnik je raspoređen u 1 od 3 terapijske grupe." },
      { type: "paragraph", content: "Učesnici u sve tri grupe uzimaju sva 3 leka. Jedan od njih će sadržati aktivni lek, a preostala 2 će biti placebo. Placebo izgleda kao studijski lek, ali ne sadrži aktivni lek u sebi." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Šta treba da radim tokom studije?" },
      { type: "paragraph", content: "Zdravstveni pregledi i testovi biće obavljeni prilikom studijskih poseta na klinici i tokom telefonskih razgovora sa studijskim lekarom. Važno je obaviti sve studijske posete i popunjavati eDnevnik (elektronski Dnevnik)." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Učešće u studiji je potpuno dobrovoljno. Možete odlučiti da u bilo kom trenutku napustite studiju." },
      { type: "header", content: "Kako uzimati studijske lekove" },
      { type: "paragraph", content: "Ispituju se tri studijska leka:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Vrsta", "Pen za samoubrizgavanje (autoinjektor)"],
        ["Koliko često", "Na svakih 4 ili 6 nedelja (u zavisnosti od telesne težine)"],
        ["Pakovanje", "Kutija s penom za samoubrizgavanje za jednokratnu upotrebu"],
        ["Način čuvanja", "Čuvati u frižideru"],
        ["Napomena", "Izvadite iz frižidera 15-30 minuta pre davanja injekcije. Detaljnije se možete informisati u Uputstvu"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Vrsta", "Tableta"],
        ["Koliko često", "Svakog dana"],
        ["Pakovanje", "Blister pakovanje za prvih 6 dana, nakon toga u bočici"],
        ["Način čuvanja", "Čuvati u frižideru"],
        ["Napomena", "Popijte jednu tabletu ujutru ili uveče, svakog dana u isto vreme"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Vrsta", "Kapsula"],
        ["Koliko često", "Svakog dana"],
        ["Pakovanje", "Bočica"],
        ["Način čuvanja", "Čuvati u frižideru"],
        ["Napomena", "Popijte jednu kapsulu ujutru ili uveče, svakog dana u isto vreme"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Unesite u eDnevnik svaku uzetu dozu sva 3 studijska leka. Za injekcije unesite datum, vreme, deo tela i ko je dao injekciju. Ako propustite bilo koju dozu, obratite se studijskom lekaru ili studijskom timu. Odmah obavestite studijskog lekara u slučaju pojave bilo kakvih neželjenih dejstava." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default enUS
