import { Dialog, IconButton, makeStyles } from "@material-ui/core"
import useGalleryContext from "framework/hooks/useGalleryContext"
// import ShareButton from "framework/ShareButton"
import React from "react"
// import ActiveImage from "./ActiveImage"
// import Dots from "./Dots"
// import Zoom from "@material-ui/core/Zoom"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

const styles = makeStyles((theme) => ({
  root: {
    // position: "absolute",
    // height: 400,
    // maxHeight: "80vh",
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
  },
  currentImage: {
    maxWidth: "100%",
    maxHeight: "85vh",
  },
}))

const Lightbox = () => {
  const classes = styles()
  const { isOpen, next, previous, currentImage, close } = useGalleryContext()

  return (
    <Dialog open={isOpen} onClose={close} className={classes.backdrop}>
      <IconButton aria-label="previous slide" onClick={previous}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className={classes.root}>
        <img className={classes.currentImage} src={currentImage.src} alt={currentImage.alt} />
      </div>
      <IconButton aria-label="next slide" onClick={next}>
        <NavigateNextIcon />
      </IconButton>
    </Dialog>
  )
}

export default Lightbox
