import { MultilineCopyContext } from "framework/context/MultilineCopyProvider"
import { useContext } from "react"

const useMultilineCopy = () => {
  const multilineCopy = useContext(MultilineCopyContext)

  if (!multilineCopy) {
    throw new Error("useMultilineCopy must be used inside MultilineCopyProvider")
  }

  return multilineCopy
}

export default useMultilineCopy