import React, { createContext, useCallback, useMemo, useState } from "react"

export const GalleryContext = createContext<GalleryContext | undefined>(undefined)

type Props = {
  item: Gallery
  children: React.ReactNode
}

const GalleryContextProvider = ({ item, children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [pointer, goTo] = useState<number>(0)
  const next = () => {
    goTo((pointer + 1) % item.source.length)
  }
  const previous = () => {
    let p = pointer - 1
    if (p < 0) {
      p = item.source.length - 1
    }
    goTo(p)
  }

  const currentImage = useMemo(() => {
    return item.source[pointer]
  }, [item, pointer])

  const open = useCallback(
    (index) => {
      goTo(index)
      setIsOpen(true)
    },
    [goTo, setIsOpen]
  )

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const value = {
    currentImage,
    images: item.source,
    next,
    previous,
    goTo,
    isOpen,
    open,
    close,
  }

  return <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>
}

export default GalleryContextProvider
