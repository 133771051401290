import { LocaleImageContext } from "framework/context/LocaleImageProvider"
import { useContext } from "react"

const useLocaleImages = () => {
  const localImages = useContext(LocaleImageContext)

  if (!localImages) {
    throw new Error("useLocaleImages must be used inside LocaleImageProvider")
  }

  return localImages
}

export default useLocaleImages