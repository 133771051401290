import { Box, makeStyles } from "@material-ui/core"
import useCopy from "framework/hooks/useCopy"
import { LandingPageContainerProps } from "framework/LandingPageContainer"
import React from "react"
import { Helmet } from "react-helmet"
import { ReactComponent as HeaderLogo } from "assets/logo_horizontal.svg"
import LanguageMenu from "framework/LanguageMenu"
import LandingFeature from "website/components/LandingFeature"
import useUI from "framework/hooks/useUI"

const styles = makeStyles(
  (theme) => ({
    "@global": {
      body: {
        background: theme.palette.secondary.light,
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "100vh",
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row"
      },
    },
    panel: {
      [theme.breakpoints.up("lg")]: {
        width: '50%',
        display: "flex",
        flexDirection: "column",
      },
    },
    sidebar: {
      color: "#FFFFFF", 
      background: theme.palette.primary.main,
      [theme.breakpoints.up("lg")]: {
        width: '50%',
      },
    },
    sidebarInner: {
      padding: 24,
      [theme.breakpoints.up("lg")]: {
        padding: 100,
      },
    },
    container: {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flex: "1 0"
      },
    },
    content: {
      color: theme.palette.secondary.contrastText,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: "8px 4px 16px",
      [theme.breakpoints.up("md")]: {
        padding: "24px 100px 100px",
      },
      [theme.breakpoints.up("xl")]: {
        justifyContent: "flex-start",
        padding: 0,
        width: 525,
      }
    },
    logo: {
      width: 160,
      marginTop: 6,
      [theme.breakpoints.up("lg")]: {
        width: 254,
        marginTop: 8
      }
    },
    footer: {
      borderTop: "1px solid #F6F6F6",
      fontSize: 12,
      color: theme.palette.grey[50],
      fontFamily: 'arial',
      letterSpacing: '0.03rem'
    },
    footerNav: {
      marginTop: 8,
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        float: "right"
      }
    },
    footerLink: {
      marginRight: 8,
      color: theme.palette.grey[50],
      textDecoration: 'none',
      [theme.breakpoints.up("lg")]: {
        marginLeft: 32,
        marginRight: 0
      }
    },
    languageWrapper: {
      position: 'relative',
      float: 'right'
    }
  }),
  { name: "LandingPageContainer" }
)

const LandingPageContainer = ({ children }: LandingPageContainerProps) => {
  const classes = styles()
  const copy = useCopy()
  const { sidebar } = useUI()

  return (
    <>
      <Helmet>
        <title>{copy["welcome.page_title"]}</title>
      </Helmet>
      <Box className={classes.root}>
        <Box className={classes.panel}>
          <Box p={3}>
            <HeaderLogo className={classes.logo} />
  
            <Box className={classes.languageWrapper}>
              <LanguageMenu />
            </Box>
          </Box>
  
          <Box className={classes.container}>
            <Box className={classes.content}>{children}</Box>
          </Box>

          <Box className={classes.footer} p={3}>
            {copy["sidebar.copyright"]}
            <Box className={classes.footerNav}>
              {
                sidebar.bottomItems && sidebar.bottomItems.map((item, index) =>
                  <a target="_blank" className={classes.footerLink} href={(item as SidebarLink).href} key={index}>
                    {copy[item.text]}
                  </a>
                )
              }
            </Box>
          </Box>
        </Box>
        <Box className={classes.sidebar}>
          <Box className={classes.sidebarInner}>
            <LandingFeature featureKey="understanding-ms" />
            <LandingFeature featureKey="understanding-trials" />
            <LandingFeature featureKey="understanding-neos" />
            <LandingFeature featureKey="neos-poster" />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default LandingPageContainer
