import sidebar from "../sidebars/global"
import pages from "../pages/global"

const hiIN: LocaleDefinition = {
  code: "hi-IN",
  label: "हिन्दी",
  country: "India",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "NEOS अध्ययन वेबसाइट पर आपका स्वागत है",
    "welcome.message":
      "NEOS मल्टीपल स्केलेरोसिस (एमएस) से ग्रसित 10 से 17 वर्ष के बीच की उम्र के बच्चों और किशोरों के लिए एक नैदानिक अध्ययन है। कृपया अपनी प्रिंट सामग्री में उपलब्ध या साईट टीम द्वारा आपको दिया गया पिन दर्ज़ करें।",
    "welcome.pin_prompt": "पिन नीचे दर्ज़ करें",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "Enter",
    "welcome.support": "मदद चाहिए? कृपया अपने स्थानीय स्वास्थ्य प्रदाता से संपर्क करें।",
    "welcome.close": "बंद करें",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "विवरण",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "वीडियो",
    "sidebar.videos.trials": "नैदानिक परीक्षण",
    "sidebar.videos.neos": "NEOS अध्ययन",
    "sidebar.videos.medicines": "दवाएं कैसे लेनी हैं…",

    "sidebar.reads-title": "क्विक रीड्स",
    "sidebar.reads.pediatric-ms": "बाल चिकित्सा एमएस को समझना",
    "sidebar.reads.study": "अध्ययन का संक्षिप्त विवरण",
    "sidebar.reads.schedule": "अध्ययन मुलाक़ात कार्यक्रम",

    "sidebar.resources-title": "संसाधन",

    "sidebar.contact": "हमसे संपर्क करें",
    "sidebar.privacy": "गोपनीयता नीति",
    "sidebar.terms": "सेवा की शर्तें",
    "sidebar.copyright": "© Copyright 2021 Novartis. सर्वाधिकार सुरक्षित",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "नैदानिक परीक्षणों को समझना",
    "understanding-trials.description": "यह वीडियो समझाता है कि नैदानिक परीक्षण क्या होते हैं।",
    "understanding-trials.video-id": "-bnMu-P67MI",

    "understanding-neos.title": "NEOS अध्ययन को समझना",
    "understanding-neos.description": "यह वीडियो NEOS अध्ययन के बारे में जानकारी प्रदान करता है।",
    "understanding-neos.video-id": "UIPJiFb4RFw",

    "understanding-ms.title": "बाल चिकित्सा एमएस को समझना",
    "understanding-ms.description": "बच्चों और किशोरों में एमएस को समझने के बारे में संक्षिप्त विवरण।",

    "study-medicines.title": "अध्ययन दवाएं कैसी लेनी हैं",
    "study-medicines.description": "इस वीडियो में अध्ययन दवाएं लेने का तरीका समझाया गया है।",
    "study-medicines.video-id": "7uihEdQPr4E",

    "study-overview.title": "अध्ययन का संक्षिप्त विवरण",

    "schedule.title": "अध्ययन मुलाक़ात कार्यक्रम",

    "resources.title": "संसाधन",

    "neos-poster.title": "NEOS अध्ययन में भर्ती के लिए पोस्टर",
    "neos-poster.description": "रिलैप्सिंग-रिमिटिंग मल्टीपल स्केलेरोसिस (एमएस) से ग्रसित 10 से 17 वर्ष के बीच की उम्र के बच्चों और किशोरों के लिए।",
    "neos-poster.href": "/i18n/HI-IN/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS अध्ययन - सूचित सहमति फ्लिप चार्ट",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/HI-IN/pdfs/consent.pdf"
    },
    {
      title: "NEOS अध्ययन - सूचित स्वीकृति फ्लिप चार्ट",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/HI-IN/pdfs/assent.pdf"
    },
    {
      title: "NEOS अध्ययन - बहुविध परिचय",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/HI-IN/pdfs/multifold.pdf"
    },
    {
      title: "एमएस और अध्ययन कॉमिक बुक को समझना",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/HI-IN/pdfs/un ms.pdf"
    },
    {
      title: "नैदानिक परीक्षणों को समझना कॉमिक बुक उम्र 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/HI-IN/pdfs/comic 7-12.pdf"
    },
    {
      title: "नैदानिक परीक्षणों को समझना कॉमिक बुक उम्र 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/HI-IN/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "एमएस में क्या होता है?" },
      { type: "paragraph", content: "एमएस नाड़ियों के इर्दगिर्द मौजूद सुरक्षा कवच, माइलिन शीथ को क्षति पहुंचाता है। जब नाड़ियों का यह कवच क्षतिग्रस्त हो जाता है, तो नाड़ियाँ संकेत नहीं भेज पातीं, जो उन्हें सामान्यतौर पर करना चाहिए होता है।" },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "एमएस के लक्षण इस बात पर निर्भर करते हैं कि क्षति कहाँ पर हुई है। इनमें शामिल हो सकते हैं:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["थकान", "अकड़न"],
        ["धुंधली दृष्टि", "कमज़ोरी"],
        ["सिहरन, उदाहरण के लिए, हाथों या पैरों में", "दर्द"],
        ["नियंत्रण खोना", "अवसाद"],
        ["अंतड़ी या मूत्राशय की समस्याएँ", "चिंता"],
        ["दृष्टि तंत्रिकाशोथ (ऑप्टिक न्युरैटिस) - दृष्टि तंत्रिका का सूजन, जिससे दृष्टि हानि हो सकती हैं (लेकिन आमतौर पर दृष्टि लौट आती है)", "बोलने में कठिनाई"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "रिलैप्स क्या होता है?" },
      { type: "paragraph", content: "रिलैप्स के दौरान, लक्षण कई सप्ताहों तक रह सकते हैं और फिर जैसे ही नाड़ी स्वयं ठीक हो जाती है, ये लक्षण अपने आप ही दूर जाते हैं। इन रेलैप्सों के बाद बिना किसी लक्षण की अवधियाँ आती हैं, जिन्हें रेमिसन कहा जाता है।" },
      { type: "paragraph", content: "एमएस का कोई उपचार नहीं है। नई चिकित्साओं का परीक्षण करने के लिए एमएस से ग्रसित युवाओं के लिए नैदानिक अध्ययन किए जा रहे हैं, जो मदद कर सकती हैं।" },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "NEOS अध्ययन क्या है?" },
      { type: "paragraph", content: "इस NEOS अध्ययन में 3 विभिन्न दवाओं को देखा जा रहा है, यह देखने के लिए कि क्या इनसे मल्टीपल स्क्लेरोसिस से ग्रसित बच्चों और किशोरों की सुरक्षित ढंग से मदद की जा सकती है।" },
      { type: "paragraph", content: "वे प्रतिभागी पात्र हैं, जिनकी आयु 10 और 17 वर्षों के बीच है।" },

      { type: "header", content: "अध्ययन कितने समय तक चलता है?" },
      { type: "paragraph", content: "अध्ययन 2 साल तक चलता है। अतिरक्त 2 से 5 वर्षों के लिए अध्ययन दवा लेने का विकल्प है।" },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*अनुवर्ती मुलाक़ातों की संख्या इस बात पर निर्भर होती है कि अध्ययन दवा की अंतिम खुराक कब ली गई है।" },
      { type: "paragraph", content: "मुक्त-लेबल: दोहरा-अज्ञात का उल्टा, इसका मतलब है कि अध्ययन प्रतिभागी और अध्ययन डॉक्टर दोनों जानते हैं कि अध्ययन प्रतिभागी किस समूह में है।" },
      { type: "paragraph", content: "दोहरा-अज्ञात: इसका मतलब है कि न अध्ययन प्रतिभागी और ना ही अध्ययन डॉक्टर को पता होता है कि अध्ययन प्रतिभागी किस समूह में है।" },

      { type: "header", content: "किन अध्ययन दवाओं का परीक्षण किया जा रहा है?" },
      { type: "paragraph", content: "तीन अध्ययन दवाओं का परीक्षण किया जा रहा है:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "ओफाटुमूमाब" },
      { type: "paragraph", content: "ओफाटुमूमाब इंजेक्शन द्वारा दी जाने वाली एक थेरापी है, जो कुछ देशों में एमएस से ग्रसित वयस्कों के लिए अनुमोदित है। यह एक ऑटोइंजेक्टर का उपयोग करते हुए दी जाती है, उसी तरह से जिस तरह से लोग अपने मधुमेह के लिए इंसुलिन इंजेक्शन लेते हैं। अनुसंधानकर्ता जानना चाहते हैं कि बच्चों और किशोरों में ओफाटुमूमाब कितना अच्छा काम करती है और यह कितनी सुरक्षित है।" },
      { type: "divider" },

      { type: "header", content: "सिपोनीमोड" },
      { type: "paragraph", content: "सिपोनीमोड मुंह से ली जाने वाली एक गोली है, जो कई देशों में एमएस से ग्रसित वयस्कों के लिए अनुमोदित है। अनुसंधानकर्ता जानना चाहते हैं कि बच्चों और किशोरों में यह कितना अच्छा काम करती है और यह कितनी सुरक्षित है।" },
      { type: "divider" },

      { type: "header", content: "फिंगोलीमोड" },
      { type: "paragraph", content: "फिंगोलीमोड मुंह से ली जाने वाली एक कैप्सूल है, जो कई देशों में एमएस से ग्रसित बच्चों और किशोरों के लिए पहले से ही अनुमोदित है। अनुसंधानकर्ता जानना चाहते हैं कि क्या ओफाटुमूमाब और सिपोनीमोड कम से कम फिंगोलीमोड जितना अच्छा काम करती हैं। " },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "अनुसंधानकर्ता यह देखना चाहते हैं कि क्या ओफाटुमूमाब या सिपोनीमोड कम से कम फिंगोलीमोड जितने अच्छे हैं।" },
      { type: "paragraph", content: "हर प्रतिभागी को 3 में से 1 समूह में रखा जाता है।" },
      { type: "paragraph", content: "हर समूह सभी 3 दवाएं प्राप्त करता है। उनमें से एक में सक्रिय औषधि होगी और उनमें से 2 प्लेसिबो होंगे। प्लेसिबो अध्ययन दवा की तरह दिखती है पर उसमें कोई वास्तविक औषधि नहीं है।" },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "इस अध्ययन के दौरान मुझे क्या करना है?" },
      { type: "paragraph", content: "अध्ययन क्लिनिक मुलाक़ातों के दौरान और अध्ययन डॉक्टर के साथ फोन कॉल के दौरान स्वास्थ्य जांचें और परीक्षण होंगे। सभी अध्ययन मुलाक़ातों में आना और ई-डायरी पूरा करना महत्वपूर्ण होता है।" },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "अध्ययन में शामिल होना पूरी तरह से स्वैच्छिक है। आप किसी भी समय अध्ययन को छोड़ने का विकल्प ले सकते/ती हैं।" },
      { type: "header", content: "अध्ययन दवाएं कैसी लेनी हैं" },
      { type: "paragraph", content: "तीन अध्ययन दवाओं का परीक्षण किया जा रहा है:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "ओफाटुमूमाब" },
      { type: "table", rows: [
        ["प्रकार", "ऑटोइंजेक्टर पेन"],
        ["कितनी बार", "हर 4 या 6 सप्ताहों में (वज़न के आधार पर)"],
        ["कंटेनर", "एकल-उपयोगी ऑटोइंजेक्टर पेन के साथ बॉक्स"],
        ["भंडारण", "रेफ्रीजरेटर में रखें"],
        ["नोट्स", "इंजेक्शन से 15-30 मिनट पहले रेफ्रिजरेटर से निकालें। अधिक विवरण के लिए निर्देश देखें"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "सिपोनीमोड" },
      { type: "table", rows: [
        ["प्रकार", "गोली"],
        ["कितनी बार", "हर रोज़"],
        ["कंटेनर", "पहले 6 दिन के लिए ब्लिस्टर पैक, और फिर शीशी"],
        ["भंडारण", "रेफ्रीजरेटर में रखें"],
        ["नोट्स", "हर सुबह या शाम, हर दिन एक ही समय पर, एक गोली लें"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "फिंगोलीमोड" },
      { type: "table", rows: [
        ["प्रकार", "कैप्सूल"],
        ["कितनी बार", "हर रोज़"],
        ["कंटेनर", "शीशी"],
        ["भंडारण", "रेफ्रिजरेटर में रखें"],
        ["नोट्स", "हर सुबह या शाम, हर दिन एक ही समय पर, एक कैप्सूल लें"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "ली गई सभी 3 दवाओं की हर खुराक को ई-डायरी में रिकॉर्ड करें इंजेक्शनों के लिए, तिथि, समय, शरीर का भाग और किसने इंजेक्शन दी है, शामिल करें। यदि कोई खुराक छूट जाती है, तो अध्ययन डॉक्टर या अध्ययन टीम से संपर्क करें। किसी भी दुष्प्रभाव के बारे में सीधे अध्ययन डॉक्टर को बताएं।" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default hiIN
