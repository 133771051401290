import { Grid } from "@material-ui/core"
import useContent from "framework/hooks/useContent"
import useOverrideableComponent from "framework/hooks/useOverrideableComponent"
import React from "react"

export type RelatedItemsProps = {
  referenceItem: ContentItem
}

const RelatedItems = ({ referenceItem }: RelatedItemsProps) => {
  const { content } = useContent()
  const ContentItemCard = useOverrideableComponent("ContentItemCard")

  return (
    <>
      {Object.values(content).map((item) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={item.id}>
            <ContentItemCard item={item} isActive={referenceItem.id === item.id} />
          </Grid>
        )
      })}
    </>
  )
}

export default RelatedItems
