import { PagesContext } from "framework/context/PagesProvider"
import { useCallback, useContext } from "react"

const usePages = () => {
  const ctx = useContext(PagesContext)
  
  if (!ctx) {
    throw new Error("usePages must be used inside PagesProvider")
  }

  return {
    pages: ctx
  }
}

export default usePages
