import useUI from "./useUI"

const useAuth = () => {
  const { isAuthenticated } = useUI()

  return {
    isAuthenticated,
  }
}

export default useAuth
