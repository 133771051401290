import React from "react"

import { Box, Typography, makeStyles } from "@material-ui/core"
import GetAppIcon from '@material-ui/icons/GetApp';
import useResources from "framework/hooks/useResources"
import useLocaleImages from "framework/hooks/useLocaleImages"

const useStyles = makeStyles((theme) => ({
    resource: {
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF",
      border: "1px solid #B7D1E6",
      marginBottom: 24,
      color: "#000000",
      textDecoration: "none",
      width: "100%"
    },

    icon: {
      color: "#EBF2F8",
      fontSize: "250%",
      margin: "0 10px"
    },

    image: {
      float: "left",
      marginRight: 30,
      maxWidth: 235
    },

    info: {
      borderRight: "1px solid #B7D1E6",
      flex: "1 0",
      display: "flex",
      alignItems: "center",
      paddingRight: 30
    },

    fileSize: {
      fontSize: 14,
      color: "#7C7B7B",
      lineHeight: 1,
      marginTop: 4
    },

  })
)

const Resources = () => {
  const resources = useResources()
  const classes = useStyles()
  const getLocaleImage = useLocaleImages()

  return (
    <Box>
    {resources.map((item, ind) => (
      <a className={classes.resource} href={item.href} download key={item.href}>
        <Box className={classes.info} p={1}>
          <img className={classes.image} src={ `/images/${ item.thumbnail }.png` }  />
          <Box>
            <Typography variant="body2"><strong>{item.title}</strong></Typography>
            <Typography className={classes.fileSize}>{item.subtitle}</Typography>
          </Box>
        </Box>
        <Box p={1}>
          <GetAppIcon className={classes.icon} />
        </Box>
      </a>
    ))}
    </Box>
  )
}

export default Resources;
