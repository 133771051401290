import { Grid, makeStyles } from "@material-ui/core"
import useGalleryContext from "framework/hooks/useGalleryContext"
import Lightbox from "framework/Lightbox"
import React from "react"
import Thumbnail from "./Thumbnail"

const styles = makeStyles((_theme) => ({
  root: {},
}))

const thumbnailGridSize = (size: string, ix: number) => {
  switch (size) {
    case "xs":
      switch (ix) {
        case 0:
          return 12
        default:
          return 6
      }
    default:
      // lg
      switch (ix) {
        case 0:
        case 1:
          return 6
        default:
          return 3
      }
  }
}

type Props = {
  item: Gallery
}

const Gallery = ({ item }: Props) => {
  const classes = styles()
  const { open } = useGalleryContext()

  return (
    <>
      <Lightbox />
      <Grid container spacing={2} className={classes.root}>
        {item.source.map((image, ix) => (
          <Grid
            item
            xs={thumbnailGridSize("xs", ix)}
            lg={thumbnailGridSize("lg", ix)}
            key={image.src}
          >
            <button onClick={() => open(ix)}>
              <Thumbnail image={image} />
            </button>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Gallery
