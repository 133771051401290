import sidebar from "../sidebars/global"
import pages from "../pages/global"

const ruRU: LocaleDefinition = {
  code: "ru-IL",
  label: "Russian",
  country: "Russia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Добро пожаловать на веб-сайт исследования NEOS",
    "welcome.message":
      "NEOS — это клиническое исследование для детей и подростков с рассеянным склерозом (РС) в возрасте от 10 до 17 лет. Введите PIN-код, указанный в Ваших печатных материалах или предоставленный персоналом исследовательского центра.",
    "welcome.pin_prompt": "ВВЕДИТЕ PIN-КОД НИЖЕ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ВВЕДИТЕ",
    "welcome.support": "Нужна помощь? Пожалуйста, обратитесь к местному медицинскому работнику.",
    "welcome.close": "Закрыть",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Описание",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Видео",
    "sidebar.videos.trials": "Клинические исследования",
    "sidebar.videos.neos": "Исследование NEOS",
    "sidebar.videos.medicines": "Как принимать исследуемые лекарственные средс",

    "sidebar.reads-title": "Быстрый просмотр",
    "sidebar.reads.pediatric-ms": "Что такое РС у детей",
    "sidebar.reads.study": "Обзор исследования",
    "sidebar.reads.schedule": "График визитов в рамках исследования",

    "sidebar.resources-title": "Источники",

    "sidebar.contact": "Связаться с нами",
    "sidebar.privacy": "Политика конфиденциальности",
    "sidebar.terms": "Условия предоставления услуг",
    "sidebar.copyright": "© Авторское право 2021 Novartis. Все права защищены.",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Что такое клинические исследования",
    "understanding-trials.description": "В этом видеоролике объясняется суть клинических исследований.",
    "understanding-trials.video-id": "FMdQ0mZyMJ0",

    "understanding-neos.title": "Основная информация об исследовании NEOS",
    "understanding-neos.description": "В этом видео предоставляется информация об исследовании NEOS.",
    "understanding-neos.video-id": "fzR6t-sl38M",

    "understanding-ms.title": "Что такое РС у детей",
    "understanding-ms.description": "Краткий обзор информации о течении РС у детей и подростков.",

    "study-medicines.title": "Как принимать исследуемые лекарственные средства",
    "study-medicines.description": "В этом видеоролике рассказывается о том, как принимать исследуемые препараты.",
    "study-medicines.video-id": "q5IRFp0-6v0",

    "study-overview.title": "Обзор исследования",

    "schedule.title": "График визитов в рамках исследования",

    "resources.title": "Источники",

    "neos-poster.title": "Постер «Набор в исследование NEOS»",
    "neos-poster.description": "Для детей и подростков в возрасте от 10 до 17 лет с рецидивирующим-ремитирующим рассеянным склерозом (РС).",
    "neos-poster.href": "/i18n/RU-IL/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Флипчарт информированного согласия — Исследование NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/RU-IL/pdfs/consent.pdf"
    },
    {
      title: "Флипчарт информированного согласия — Исследование NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/RU-IL/pdfs/assent.pdf"
    },
    {
      title: "Исследование NEOS — Брошюра с общей информацией",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/RU-IL/pdfs/multifold.pdf"
    },
    {
      title: "Что такое РС и исследование NEOS. Книга комиксов",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/RU-IL/pdfs/un ms.pdf"
    },
    {
      title: "Что такое клинические исследования. Книга комиксов для детей от 7 до 12 лет",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/RU-IL/pdfs/comic 7-12.pdf"
    },
    {
      title: "Что такое клинические исследования. Книга комиксов для подростков в возрасте от 13 до 17 лет",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/RU-IL/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Что происходит при РС?" },
      { type: "paragraph", content: "РС повреждает миелиновую оболочку, которая является защитным покровом, окружающим нервы. Когда этот покров нерва поврежден, нервы не могут посылать сигналы, как обычно." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Симптомы РС зависят от места поражения. Они могут включать:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Усталость", "Онемение"],
        ["Затуманенное зрение", "Слабость"],
        ["Покалывание, например, в руках или ногах", "Боль"],
        ["Потеря равновесия", "Депрессия"],
        ["Нарушение функций кишечника и мочевого пузыря", "Тревожность"],
        ["Оптический неврит — это воспаление зрительного нерва, которое может привести к потере зрения (но зрение обычно восстанавливается)", "Нарушение речи"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Что такое рецидив?" },
      { type: "paragraph", content: "Во время рецидива симптомы могут продолжаться в течение нескольких недель, а затем сами по себе уменьшаются, когда нерв самостоятельно восстанавливается. Рецидивы сопровождаются периодами без появления новых симптомов, которые называются ремиссиями." },
      { type: "paragraph", content: "Излечиться от РС нельзя. Клинические исследования проводятся для молодых людей с РС с целью тестирования новых методов лечения, которые могут помочь." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Что представляет собой исследование NEOS?" },
      { type: "paragraph", content: "В исследовании NEOS рассматриваются 3 различных лекарственных препарата, чтобы выяснить, могут ли они безопасно помочь детям и подросткам, больным рассеянным склерозом." },
      { type: "paragraph", content: "К участию допускаются дети в возрасте от 10 до 17 лет." },

      { type: "header", content: "Сколько длится исследование?" },
      { type: "paragraph", content: "Исследование длится 2 года. Существует возможность принимать исследуемый препарат в течение дополнительных 2 - 5 лет." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "* Количество визитов для последующего наблюдения будет зависеть от того, когда будет принята последняя доза исследуемого препарата." },
      { type: "paragraph", content: "Открытое: В отличие от двойного слепого исследования, это означает, что как участник исследования, так и врач-исследователь знают, в какой группе лечения находится участник исследования." },
      { type: "paragraph", content: "Двойное слепое: Это означает, что ни участник исследования, ни врач-исследователь не знают, в какой группе лечения находится участник исследования." },

      { type: "header", content: "Какие лекарственные средства исследуются?" },
      { type: "paragraph", content: "Исследуются три лекарственных средства:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Is an injectable therapy that is approved for adults with MS in some countries. It is given using an autoinjector, similar to ones used by people with diabetes to inject insulin. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Is an oral tablet that is approved for adults with MS in many countries. Researchers want to know how well it works in children and adolescents and how safe it is." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "is an oral capsule that is already approved for children and adolescents with MS in many countries. Researchers want to see if ofatumumab or siponimod are at least as good as fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Исследователи хотят проверить, является ли офатумумаб или сипонимод по крайней мере такими же эффективными, как финголимод." },
      { type: "paragraph", content: "Каждый участник попадет в 1 из 3 групп лечения." },
      { type: "paragraph", content: "Участники каждой группы будут получать все 3 лекарственных средства. Одно из них будет содержать активный препарат, а 2 — плацебо. Плацебо выглядит как исследуемый препарат, но не содержит активного компонента." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Что мне нужно делать во время исследования?" },
      { type: "paragraph", content: "Во время визитов в рамках исследования, которые проводятся в медицинском учреждении, и во время телефонных бесед с врачом-исследователем будут проводиться проверки состояния здоровья и анализы. Важно приходить на все визиты в рамках исследования и заполнять электронный дневник." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Участие в исследовании абсолютно добровольное. Вы можете прекратить участие в исследовании в любое время." },
      { type: "header", content: "Как принимать исследуемые лекарственные средства" },
      { type: "paragraph", content: "Исследуются три лекарственных средства:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Офатумумаб" },
      { type: "table", rows: [
        ["Тип", "Ручка-автоинжектор"],
        ["Как часто", "Каждые 4 или 6 недель (в зависимости от веса)"],
        ["Упаковка", "Коробка или ручка-автоинжектор"],
        ["Хранение", "Хранить в холодильнике"],
        ["Заметки", "Извлеките из холодильника за 15−20 минут до инъекции. См. подробности в инструкции."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Сипонимод" },
      { type: "table", rows: [
        ["Тип", "Таблетки"],
        ["Как часто", "Каждый день"],
        ["Упаковка", "Блистерная упаковка на первые 6 дней, затем флакон"],
        ["Хранение", "Хранить в холодильнике"],
        ["Заметки", "Принимайте по одной таблетке утром или вечером, ежедневно в одно и то же время"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Тип", "Капсулы"],
        ["Как часто", "Каждый день"],
        ["Упаковка", "Флакон"],
        ["Хранение", "Хранить в холодильнике"],
        ["Заметки", "Принимайте по одной капсуле утром или вечером, ежедневно в одно и то же время"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Записывайте каждую принятую дозу всех 3 лекарственных средств в электронный дневник." },
      { type: "paragraph", content: "Для инъекций укажите дату, время, часть тела и кто сделал инъекцию." },
      { type: "paragraph", content: "Если какая-либо доза будет пропущена, свяжитесь с врачом или исследовательской группой." },
      { type: "paragraph", content: "Немедленно сообщите врачу-исследователю о любых побочных эффектах." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default ruRU
