import sidebar from "../sidebars/global"
import pages from "../pages/global"

const enUS: LocaleDefinition = {
  code: "sr-RSc",
  label: "Српски",
  country: "Serbia",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Добродошли на интернет страницу Студије NEOS",
    "welcome.message":
      "NEOS je клиничка студија за децу и адолесценте узраста између 10 и 17 година који болују од мултипле склерозе (MС).  Унесите ПИН код који је наведен у штампаном материјалу или који сте добили од студијског тима.",
    "welcome.pin_prompt": "УНЕСИТЕ ПИН КОД У НАСТАВКУ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "УНЕСИТЕ",
    "welcome.support": "Потребна вам је помоћ? Oбратите се свом лекару или здравственом раднику.",
    "welcome.close": "Затвори",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Oпис",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Видео-снимци",
    "sidebar.videos.trials": "Клиничке Студије",
    "sidebar.videos.neos": "Студија NEOS",
    "sidebar.videos.medicines": "Како узимати студијски лек",

    "sidebar.reads-title": "Сажети чланци",
    "sidebar.reads.pediatric-ms": "Разумевање педијатријске МС",
    "sidebar.reads.study": "Преглед студије",
    "sidebar.reads.schedule": "Распоред студијских посета",

    "sidebar.resources-title": "Извори",

    "sidebar.contact": "Koнтактирајте нас",
    "sidebar.privacy": "Политика приватности",
    "sidebar.terms": "Услови коришћења",
    "sidebar.copyright": "© Copyright 2021 Novartis. Сва права задржана",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Разумевање клиничких студија",
    "understanding-trials.description": "Oвај видео снимак објашњава шта су клиничке студије.",
    "understanding-trials.video-id": "ZkVrLu45xKU",

    "understanding-neos.title": "Разумевање Студије NEOS",
    "understanding-neos.description": "Овај видео снимак садржи информације о Студији NEOS.",
    "understanding-neos.video-id": "iX31oFjmD1s",

    "understanding-ms.title": "Разумевање педијатријске МС",
    "understanding-ms.description": "Кратак преглед МС код деце и адолесцената и разумевање обољења.",

    "study-medicines.title": "Како узимати студијске лекове",
    "study-medicines.description": "Овај видео снимак даје објашњење начина узимања испитиваних лекова.",
    "study-medicines.video-id": "c-vHht7FMbM",

    "study-overview.title": "Преглед студије",

    "schedule.title": "Распоред студијских посета",

    "resources.title": "Извори",

    "neos-poster.title": "Постер са информацијама о пријављивању нових учесника у Студији NEOS",
    "neos-poster.description": "За децу и адолесценте који имају између 10 и 17 година и болују од релапсно-ремитентне мултипле склерозе (MС).",
    "neos-poster.href": "/i18n/SR-RSC/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Студија NEOS − Графикон са информацијама о информисаном пристанку",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/SR-RSC/pdfs/consent.pdf"
    },
    {
      title: "Студија NEOS − Графикон са информацијама о информисаној сагласности",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/SR-RSC/pdfs/assent.pdf"
    },
    {
      title: "Студија NEOS − Више уводних напомена",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/SR-RSC/pdfs/multifold.pdf"
    },
    {
      title: "Стрип за разумевање МС и Студије NEOS, стрип",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/SR-RSC/pdfs/un ms.pdf"
    },
    {
      title: "Стрип за разумевање клиничке студије, Старосно доба 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/SR-RSC/pdfs/comic 7-12.pdf"
    },
    {
      title: "Стрип за разумевање клиничке студије , Цтаросно доба 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/SR-RSC/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Шта се дешава у МС?" },
      { type: "paragraph", content: "MС оштећује мијелински омотач, који представља заштитни омотач који обавија и штити нервна влакна. Kaда је овај заштитни омотач оштећен, нерви не могу нормално да шаљу сигнале." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Симптоми МС варирају у зависности од локације оштећења.g1] Они могу да укључују:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Умор", "Укоченост"],
        ["Замагљен вид", "Слабост"],
        ["Tрњење или жмарци, нпр. у рукама или стопалима", "Бол"],
        ["Губитак равнотеже", "Депресија"],
        ["Проблеми са  цревима или мокраћном бешиком", "Aнксиозност"],
        ["Oптички неуритис – запаљење оптичког нерва, што може проузроковати губиткак вида (aли вид се обично враћа)", "Teшкоће у говору"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Шта је релапс?" },
      { type: "paragraph", content: "Током релапса, симптоми могу трајати неколико недеља, а затим се сами смирују како се нерв опоравља. Релапс прате периоди без нових симптома који се називају ремисије." },
      { type: "paragraph", content: "Не постоји лек за МС. Клиничке студије се спроводе за младе људе са МС за тестирање нових терапија које би могле да помогну." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Шта је Студија NEOS?" },
      { type: "paragraph", content: "Студија NEOS испитује 3 различита студијска лека како би се утврдило да ли могу безбедно да помогну деци и адолесцентима који болују од мултипле склерозе." },
      { type: "paragraph", content: "Право учешћа имају особе старости између 10 и 17 година." },

      { type: "header", content: "Кoлико траје студија?" },
      { type: "paragraph", content: "Студија траје 2 године. Постоји могућност додатне примене студијског лека у периоду од 2 до 5 година након завршетка студије." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Број посета за праћење стања пацијента зависиће од тога када је узета последња доза студијског лека." },
      { type: "paragraph", content: "Oтворена: Супротно од двоструко слепе студије, овде и учесник у студији и студијски лекар знају у којој сетерапијској групи налази учесник студије." },
      { type: "paragraph", content: "Двоструко слепа: Тo значи да ни учесник у студији ни студијски лекар не знају у којој је се терапијској групи налази учесник студије." },

      { type: "header", content: "Који се студијски лекови испитују?" },
      { type: "paragraph", content: "Испитују се три студијска лека:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Oфaтумумаб" },
      { type: "paragraph", content: "Инјекциона терапија која је у неким земљама одобрена за одрасле са МС. Даје се помоћу аутоинјектора сличног оном који људи са дијабетесом користе за убризгавање инсулина. Истраживачи желе да утврде колико ефикасно делује код деце и адолесцената и колико је безбедан." },
      { type: "divider" },

      { type: "header", content: "Сипонимод" },
      { type: "paragraph", content: "Орална таблета која је одобрена у многим земљама за одрасле са МС. Истраживачи желе да утврде колико ефикасно делује код деце и адолесцената и колико је безбедан." },
      { type: "divider" },

      { type: "header", content: "Финголимод" },
      { type: "paragraph", content: "Орална капсула која је већ одобрена у многим земљама за децу и адолесценте са МС. Истраживачи желе да виде да ли су офатумумаб или сипонимод подједнако добри као финголимод." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Истраживачи желе да виде да ли су офатумумаб или сипонимод подједнако добри као финголимод." },
      { type: "paragraph", content: "Сваки учесник је распоређен у 1 oд 3 терапијске групе." },
      { type: "paragraph", content: "Учесници у све три групе узимају сва 3 лека. Jeдан од њих ће садржати активни лек, а преостала 2 ће бити плацебо. Плацебо изгледа као студијски лек, али не садржи активни лек у себи." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Шта треба да радим током студије?" },
      { type: "paragraph", content: "Здравствени прегледи и тестови биће обављени приликом студијских посета на клиници и током телефонских разговора са студијским лекаром. Важно је обавити све студијске посете и попуњавати еДневник (електронски Дневник)." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Учешће у студији је потпуно добровољно. Можете одлучити да у било ком тренутку напустите студију." },
      { type: "header", content: "Како узимати студијске лекове" },
      { type: "paragraph", content: "Испитују се три студијска лека:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Oфaтумумаб" },
      { type: "table", rows: [
        ["Врста", "Пен за самоубризгавање (аутоинјектор) "],
        ["Koлико често", "На сваких 4 или 6 недеља (у зависности од телесне тежине)"],
        ["Паковање", "Кутија са пеном за самоубризгавање за једнократну употребу"],
        ["Начин чувања", "Чувати у фрижидеру"],
        ["Напомена", "Извадите из фрижидера 15-30 минута пре давања инјекције. Детаљније се можете информисати у Упутству"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Сипонимод" },
      { type: "table", rows: [
        ["Врста", "Таблета"],
        ["Koлико често", "Сваког дана"],
        ["Паковање", "Блистер паковање за првих 6 дана, након тога у бочици"],
        ["Начин чувања", "Чувати у фрижидеру"],
        ["Напомена", "Попијте једну таблету ујутру или увече, сваког дана у исто време"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Финголимод" },
      { type: "table", rows: [
        ["Врста", "Капсула"],
        ["Koлико често", "Сваког дана"],
        ["Паковање", "Бочица"],
        ["Начин чувања", "Чувати у фрижидеру"],
        ["Напомена", "Попијте једну капсулу ујутру или увече, сваког дана у исто време"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Унесите у еДневник сваку узету дозу сва 3 студијска лека. За инјекције, унесите датум, време, део тела и ко је дао инјекцију. Ако пропустите било дозу, обратите се студијском лекару или студијском тиму. Одмах обавестите студијског лекара у случају појаве било каквих нежељених дејстава,." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default enUS
