import Gallery from "framework/Gallery"
import Video from "framework/Video"
import Animation from "framework/Animation"
import React from "react"
import GalleryContextProvider from "framework/context/GalleryContext"

type Props = {
  item: ContentItem
}
const ContentItem = ({ item }: Props) => {
  const { type } = item
  switch (type) {
    case "video":
      return <Video item={item as Video} />
    case "gallery":
      return (
        <GalleryContextProvider item={item as Gallery}>
          <Gallery item={item as Gallery} />
        </GalleryContextProvider>
      )
    case "animation":
      return <Animation item={item as AnimatedItem} />
    default:
      throw new Error(`Unknow content type: ${type}`)
  }
}

export default ContentItem
