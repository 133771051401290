import sidebar from "../sidebars/global"
import pages from "../pages/global"

const ltLT: LocaleDefinition = {
  code: "lt-LT",
  label: "Lithuanian",
  country: "Lithuania",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Sveiki prisijungę prie tyrimo „NEOS“ svetainės",
    "welcome.message":
      "„NEOS“ – tai klinikinis tyrimas, skirtas 10–17 metų vaikams ir paaugliams, sergantiems išsėtine skleroze (IS). Įveskite PIN kodą, pateiktą brošiūroje arba nurodytą tyrimą atliekančio gydytojo.",
    "welcome.pin_prompt": "TOLIAU ĮVESKITE PIN KODĄ",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "ĮVESTI",
    "welcome.support": "Reikia pagalbos? Kreipkitės į Jus gydantį gydytoją.",
    "welcome.close": "Uždaryti",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Aprašas",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Vaizdo įrašai",
    "sidebar.videos.trials": "Klinikiniai tyrimai",
    "sidebar.videos.neos": "Tyrimas „NEOS“",
    "sidebar.videos.medicines": "Kaip vartoti tiriamąjį vaistinį preparatą",

    "sidebar.reads-title": "Glausta informacija",
    "sidebar.reads.pediatric-ms": "Kais yra vaikų IS",
    "sidebar.reads.study": "Tyrimo apžvalga",
    "sidebar.reads.schedule": "Tyrimo vizitų tvarkaraštis",

    "sidebar.resources-title": "Šaltiniai",

    "sidebar.contact": "Susisiekite su mumis",
    "sidebar.privacy": "Privatumo taisyklės",
    "sidebar.terms": "Paslaugų nuostatos",
    "sidebar.copyright": "© Autoriaus teisės „Novartis“. 2021 Visos teisės saugomos",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Informacija apie klinikinius tyrimus",
    "understanding-trials.description": "Vaizdo įraše paaiškinama, kas yra klinikiniai tyrimai.",
    "understanding-trials.video-id": "9Ih9hnM5l1o",

    "understanding-neos.title": "Supažindinimas su tyrimu „NEOS",
    "understanding-neos.description": "Šioje vaizdo medžiagoje pateikiama informacija apie „NEOS“ tyrimą.",
    "understanding-neos.video-id": "Pld3s5-8oWU",

    "understanding-ms.title": "Kais yra vaikų IS",
    "understanding-ms.description": "Pateikiama trumpa informacija apie vaikų ir paauglių IS.",

    "study-medicines.title": "Kaip vartoti tiriamuosius vaistinius preparatus",
    "study-medicines.description": "Vaizdo medžiagoje paaiškinama, kaip vartoti tiriamuosius vaistus.",
    "study-medicines.video-id": "GqRK8Tl6FyI",

    "study-overview.title": "Tyrimo apžvalga",

    "schedule.title": "Tyrimo vizitų tvarkaraštis",

    "resources.title": "Šaltiniai",

    "neos-poster.title": "„NEOS“ kvietimo į tyrimą plakatas",
    "neos-poster.description": "10–17 metų vaikams ir paaugliams, sergantiems recidyvuojančia-remituojančia išsėtine skleroze (IS).",
    "neos-poster.href": "/i18n/LT-LT/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Tyrimas „NEOS“. Informuoto asmens sutikimo vartomasis plakatas",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/LT-LT/pdfs/consent.pdf"
    },
    {
      title: "Tyrimas „NEOS“. Informuoto asmens pritarimo vartomasis plakatas",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/LT-LT/pdfs/assent.pdf"
    },
    {
      title: "Tyrimas „NEOS“. Įvadinė brošiūra",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/LT-LT/pdfs/multifold.pdf"
    },
    {
      title: "Supažindinimo su IS ir tyrimu „NEOS“ komiksų knygelė",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/LT-LT/pdfs/un ms.pdf"
    },
    {
      title: "Supažindinimo su klinikiniais tyrimais komiksų knygelė, 7–12 metų amžiaus vaikams",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/LT-LT/pdfs/comic 7-12.pdf"
    },
    {
      title: "Supažindinimo su klinikiniais tyrimais komiksų knygelė, 13–17 metų amžiaus vaikams",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/LT-LT/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Kas nutinka sergant IS?" },
      { type: "paragraph", content: "IS pažeidžia iš mielino sudarytą apvalkalą, kuris uždengia ir apsaugo nervus. Kai ši danga pažeidžiama, nervai nebegali siųsti signalų normaliai." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "IS simptomai būna įvairūs – tai priklauso nuo pažeidimo vietos. Tai gali būti:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Nuovargis", "Sustingimas"],
        ["Neryškus matymas", "Silpnumas"],
        ["Dilgčiojimas, pvz., plaštakose arba pėdose", "Skausmas"],
        ["Pusiausvyros netekimas", "Depresija"],
        ["Žarnyno arba šlapimo pūslės negalavimai", "Nerimas"],
        ["Optinis neuritas – optinio nervo uždegimas, kuris gali sukelti regėjimo praradimą (nors jis paprastai atsistato)", "Kalbos sutrikimai"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Kas yra atkrytis?" },
      { type: "paragraph", content: "Atkryčio metu simptomai gali trukti keletą savaičių, o paskui savaime praeiti, nes nervas pats „susitaiso“. Po atkryčių būna laikotarpiai be naujų simptomų – jie vadinami remisijomis." },
      { type: "paragraph", content: "Vaistų nuo IS nėra. Atliekami klinikiniai tyrimai, skirti IS sergantiems jauniems žmonėms – jie skirti išbandyti naujus vaistus, kurie gali padėti." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Kas yra tyrimas „NEOS?" },
      { type: "paragraph", content: "Tyrime „NEOS“ nagrinėjami 3 skirtingi tiriamieji vaistiniai preparatai, kad sužinotume, ar jie gali saugiai padėti išsėtine skleroze sergantiems vaikams ir paaugliams." },
      { type: "paragraph", content: "Tyrime gali dalyvauti 10–17 metų asmenys." },

      { type: "header", content: "Kiek trunka tyrimas?" },
      { type: "paragraph", content: "Tyrimas trunka 2 metus. Yra galimybė vartoti tiriamąjį vaistinį preparatą dar 2–5 metus." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Tolesnio stebėjimo vizitų skaičius priklausys nuo to, kada suvartota paskutinė tiriamojo vaistinio preparato dozė." },
      { type: "paragraph", content: "Atvirasis. Priešingai, nei dvigubai koduotas – tai reiškia, kad tiek tyrimo dalyvis, tiek tyrimą atliekantis gydytojas žino, kurioje gydymo grupėje yra tyrimo dalyvis." },
      { type: "paragraph", content: "Dvigubai koduotas. Tai reiškia, kad nei tyrimo dalyvis, nei tyrimą atliekantis gydytojas nežino, kurioje gydymo grupėje yra tyrimo dalyvis." },

      { type: "header", content: "Kokie tiriamieji vaistiniai preparatai tiriami?" },
      { type: "paragraph", content: "Tiriami 3 tiriamieji vaistiniai preparatai:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumabas" },
      { type: "paragraph", content: "tai švirkščiamasis vaistas, kai kuriose šalyse patvirtintas IS sergantiems suaugusiesiems. Jis vartojamas automatiniu švirkštikliu, panašiuį tuos, kuriuos sergantieji diabetu naudoja insulino injekcijoms. Tyrėjai nori sužinoti, kiek veiksmingas ir saugus ofatumumabas yra vaikams bei paaugliams." },
      { type: "divider" },

      { type: "header", content: "Siponimodas" },
      { type: "paragraph", content: " geriamoji tabletė, daugelyje šalių patvirtinta vartoti suaugusiesiems nuo IS. Tyrėjai nori sužinoti, kaip gerai ji veikia vaikams bei paaugliams ir kiek yra saugi." },
      { type: "divider" },

      { type: "header", content: "Fingolimodas" },
      { type: "paragraph", content: "geriamoji kapsulė, daugelyje šalių jau patvirtinta vartoti IS sergantiems vaikams ir paaugliams. Tyrėjai nori sužinoti, ar ofatumumabas arba siponimodas yra ne mažiau geri kaip fingolimodas." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Tyrėjai nori sužinoti, ar ofatumumabas arba siponimodas yra ne mažiau geri kaip fingolimodas." },
      { type: "paragraph", content: "Kiekvienas dalyvis paskiriamas į vieną iš trijų gydymo grupių." },
      { type: "paragraph", content: "Kiekviena grupė vartoja visus 3 vaistinius preparatus. Tik vienas iš jų bus veiklusis vaistinis preparatas, o kiti du – placebas. Placebas atrodo kaip tiriamasis vaistinis preparatas, bet jame nėra jokio tikro vaisto." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Ką man reikės daryti tyrimo metu?" },
      { type: "paragraph", content: "Bus atliekami sveikatos patikrinimai ir tyrimai per vizitus klinikoje ir kalbantis telefonu su tyrimo gydytoju. Svarbu atvykti į visus tyrimo vizitus ir pildyti elektroninį dienyną." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Dalyvavimas šiame tyrime yra visiškai savanoriškas.  Galite bet kada nuspręsti pasitraukti iš tyrimo." },
      { type: "header", content: "Kaip vartoti tiriamuosius vaistinius preparatus" },
      { type: "paragraph", content: "Tiriami 3 tiriamieji vaistiniai preparatai:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumabas" },
      { type: "table", rows: [
        ["Tipas", "Automatinis švirkštiklis"],
        ["Dažnis", "Kas 4 arba 6 savaites (atsižvelgiant į svorį)"],
        ["Talpyklė", "Dėžutė su vienu automatiniu švirkštikliu"],
        ["Laikymas", "Laikyti šaldytuve"],
        ["Pastabos", "15–30 minučių prieš injekciją išimkite iš šaldytuvo. Norėdami gauti išsamesnės informacijos, žiūrėkite instrukciją. "],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimodas" },
      { type: "table", rows: [
        ["Tipas", "Tabletė"],
        ["Dažnis", "Kiekvieną dieną"],
        ["Talpyklė", "Lizdinė plokštelė pirmosioms 6 dienoms, o vėliau – buteliukas"],
        ["Laikymas", "Laikyti šaldytuve"],
        ["Pastabos", "Išgerkite vieną tabletę ryte arba vakare – kiekvieną dieną tuo pačiu metu."],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimodas" },
      { type: "table", rows: [
        ["Tipas", "Kapsulė"],
        ["Dažnis", "Kiekvieną dieną"],
        ["Talpyklė", "Buteliukas"],
        ["Laikymas", "Laikyti šaldytuve"],
        ["Pastabos", "Išgerkite vieną kapsulę ryte arba vakare – kiekvieną dieną tuo pačiu metu."],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Įrašykite apie kiekvieną suvartotą visų 3 tiriamųjų vaistinių preparatų dozę į elektroninį dienyną. Atlikę injekciją, nurodykite datą, laiką, kūno vietą ir kas atliko injekciją. Jeigu kažkuri dozė praleidžiama, kreipkitės į tyrimą atliekantį gydytoją arba kitą tyrimo centro darbuotoją. Nedelsdami praneškite tyrimą atliekančiam gydytojui apie bet kokį šalutinį poveikį." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default ltLT
