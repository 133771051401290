import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, makeStyles, Grid } from "@material-ui/core"
import useLocaleImages from "framework/hooks/useLocaleImages"
import React from "react"
import { Helmet } from "react-helmet"
import useMultilineCopy from "framework/hooks/useMultilineCopy"
import clsx from 'clsx';

export type MultilineCopyProps = {
  id: string,
  context?: string
}

const styles = makeStyles(
  (theme) => ({
    "@global": {
      body: {
        background: theme.palette.secondary.light,
      },
    },
    content: {
      textAlign: "left",
    },
    paragraph: {
      textAlign: "left",
      marginBottom: 40
    },
    image: {
      width: "100%",
      height: "auto"
    },
    secondaryWrap: {
      position: "relative",
      height: "100%"
    },
    secondaryImage: {
      height: "auto",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        height: "100%",
        width: "100%",
      }
    },
    rule: {
      border: 0,
      borderTop: "1px solid rgba(4,96,169, 0.3)",
      marginBottom: 40
    },
    tableCell: {
      padding: "10px 0",
      borderBottom: "1px solid rgba(4,96,169, 0.3)",
      width: "50%",
      verticalAlign: "top"
    },
    lastRowCell: {
      borderBottom: 0,
    },
    bold: {
      fontWeight: 700,
      width: "33%"
    }
  }),
  { name: "InnerPageContainer" }
)

const MultilineCopy = ({ id, context }: MultilineCopyProps) => {
  const getLocaleImage = useLocaleImages()
  const multilineCopy = useMultilineCopy()
  
  const classes = styles();

  const content = (multilineCopy[id] || []).map((item, index) => {
    const { type } = item;
    if (type === "header") {
      return (
        <Typography variant="h3" key={ index }>
          { item.content }
        </Typography>
      );
    } else if (type === "paragraph") {
      return (
        <Typography variant="body1" key={ index } className={ classes.paragraph }>
          { item.content }
        </Typography>
      );
    } else if (type === "table") {
      return (
          <Table aria-label="simple table" >
            <TableBody>
              { item.rows!.map((row, rowIndex) => (
                <TableRow key={ rowIndex }>
                  { row.map((text, cellIndex) => (
                    <TableCell key={ cellIndex } className={
                      clsx({
                          [classes.tableCell] : true,
                          [classes.lastRowCell] : item.rows!.length === rowIndex + 1,
                          [classes.bold] : cellIndex == 0 && context === "study-schedule"

                      })
                    }>{text}</TableCell>
                  )) }
                </TableRow>
              ))}
            </TableBody>
          </Table>
      );
    } else if (type === "dual-image") {
      return (
        <Grid container spacing={4} style={{ paddingBottom: 20 }}>
          <Grid item md={6}>
            <img src={ getLocaleImage(item.image1!) } alt={ item.alt } key={ index } className={ classes.image } />
          </Grid>
          <Grid item md>
            <div className={ classes.secondaryWrap }>
              <img src={ getLocaleImage(item.image2!) } alt={ item.alt } key={ index } className={ classes.secondaryImage } />
            </div>
          </Grid>
        </Grid>
      );
    } else if (type === "image") {
      return (
        <img src={ getLocaleImage(item.content!) } alt={ item.alt } key={ index } className={ classes.image } />
      );
    } else if (type === "divider") {
      return (
        <hr key={ index } className={ classes.rule } />
      );
    } else {
      throw new Error(`Unknown multiline content type: ${type}`)
    }
  })

  return (
    <Box className={ classes.content }>
      { content }
    </Box>
  )
}

export default MultilineCopy
