import React, { useState, useRef } from "react"

import { Box, Typography, makeStyles } from "@material-ui/core"
import YouTube, { Options } from "react-youtube"
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { ReactComponent as RedLogo } from "assets/logo-red.svg"

import useCopy from "framework/hooks/useCopy"

const styles = makeStyles(
  (theme) => ({
    video: {
      width: "100%",
      paddingBottom: "56.25%",
      position: "relative",
    },
    youtube: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    playOverlay:{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 50,
      left: 0,
      background:"linear-gradient(0deg, rgba(4,96,169,0) 0%, rgba(4,96,169,1) 3%)",
      cursor: "pointer",
      zIndex: 2,
      color: "#FFFFFF",
      display:"flex",
      justifyContent: "center",
      alignItems: "center"
    },
    playButton: {
      transform: "scale(400%)",
      opacity: 0.85
    },
    
    rule: {
      border: 0,
      borderTop: "1px solid #ccc",
      marginTop: 20,
      marginBottom: 30
    },
    
    titleBox: {
      marginTop: 10,
      display: "flex",
    },
    titleText: {
      marginTop: 5
    },
    logo: {
      marginTop: 5,
      marginRight: 15
    },
  })
)

const youtubeOptions: Options = {
  playerVars: {
    rel: 0,
  },
}

type VideoWithTextProps = {
  showTitle: boolean,
  id: string
}

const VideoWithText = ({ id, showTitle }: VideoWithTextProps) => {
  const copy = useCopy()
  const classes = styles()
  const player = useRef<any>(null);
  
  const videoID = copy[`${ id }.video-id`]
  const [paused, setPaused] = useState<Boolean>(false)

  return (
    <Box>
      <div className={classes.video}>
        {paused &&
          <div className={classes.playOverlay} onClick={() => player.current.internalPlayer.playVideo() }>
            <PlayCircleFilledIcon className={classes.playButton}  />
          </div>}
        <YouTube
          ref={player}
          videoId={videoID}
          opts={youtubeOptions}
          onPlay={() => {
            gtag("event", "content_viewed", {
              content_id: videoID,
            })
          }}
          onStateChange={(e) => {
            setPaused(e.data === 0 || e.data === 2)
          }}
          className={classes.youtube}
        />
      </div>
      
      { showTitle && (
        <Box className={classes.titleBox}>
          <Box className={classes.logo}>
            <RedLogo  />
          </Box>
          <Box className={classes.titleText}>
            <Typography variant="body2">
              <strong>{ copy[`${ id }.title`] }</strong>
            </Typography>
            <Typography variant="body2">
              Novartis
            </Typography>
          </Box>
        </Box>
      )}
        
      <hr className={classes.rule} />

      <Typography variant="body2">
        <Typography variant="body2">
          <strong>{ copy["general.description"] }</strong>
        </Typography>
        { copy[`${ id }.description`] }
      </Typography>
    </Box>
  )
}

export default VideoWithText;