import useUI from "framework/hooks/useUI"
import React, { createContext, useMemo } from "react"

export const PagesContext = createContext<LocalizedPages | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const PagesProvider = ({ website, children }: Props) => {
  const { locale, authCode } = useUI()

  const translationPages = authCode?.code === "OLIE-19" ? `${locale.code}o19` : locale.code

  const value = useMemo(() => website.i18n.locales[translationPages].pages, [website, translationPages])

  return <PagesContext.Provider value={value}>{children}</PagesContext.Provider>
}

export default PagesProvider
