import sidebar from "../sidebars/global"
import pages from "../pages/global"

const esMX: LocaleDefinition = {
  code: "es-MX",
  label: "English",
  country: "Mexico",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Bienvenido al sitio web del estudio NEOS",
    "welcome.message":
      "NEOS es un estudio clínico para niños y adolescentes con esclerosis múltiple (EM) de entre 10 y 17 años de edad. Introduzca el código PIN que se encuentra en su material impreso o que le ha facilitado el personal de la clínica.",
    "welcome.pin_prompt": "INTRODUZCA EL PIN ABAJO",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "Enter",
    "welcome.support": "¿Necesita ayuda? Póngase en contacto con su proveedor de salud local.",
    "welcome.close": "Cerrar",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Descripción",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videos",
    "sidebar.videos.trials": "Ensayos clínicos",
    "sidebar.videos.neos": "Estudio NEOS",
    "sidebar.videos.medicines": "Cómo tomar los medicamentos del estudio",

    "sidebar.reads-title": "Lecturas rápidas",
    "sidebar.reads.pediatric-ms": "Comprender la esclerosis múltiple pediátrica",
    "sidebar.reads.study": "Resumen del estudio",
    "sidebar.reads.schedule": "Calendario de visitas de estudio",

    "sidebar.resources-title": "Recursos",

    "sidebar.contact": "Contacto",
    "sidebar.privacy": "Política de privacidad",
    "sidebar.terms": "Condiciones de servicio",
    "sidebar.copyright": "© Copyright 2021 Novartis. Todos los derechos reservados",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Entendiendo los estudios clínicos",
    "understanding-trials.description": "Este video explica qué son los estudios clínicos.",
    "understanding-trials.video-id": "VT72UimsBvY",

    "understanding-neos.title": "Entendiendo el estudio NEOS",
    "understanding-neos.description": "Este video ofrece información sobre el Estudio NEOS.",
    "understanding-neos.video-id": "QOYvJgBZSDA",

    "understanding-ms.title": "Comprender la esclerosis múltiple pediátrica",
    "understanding-ms.description": "Breve resumen sobre comprender la EM en niños y adolescentes",

    "study-medicines.title": "Cómo tomar los medicamentos del estudio",
    "study-medicines.description": "Este video explica cómo tomar los medicamentos del estudio.",
    "study-medicines.video-id": "K_i96dQ3oZQ",

    "study-overview.title": "Resumen del estudio",

    "schedule.title": "Calendario de visitas de estudio",

    "resources.title": "Recursos",

    "neos-poster.title": "Cartel de reclutamiento del Estudio NEOS",
    "neos-poster.description": "Para niños y adolescentes de entre 10 y 17 años con esclerosis múltiple remitente recurrente (EM)",
    "neos-poster.href": "/i18n/ES-MX/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "Estudio NEOS – Folleto de consentimiento informado ",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/ES-MX/pdfs/consent.pdf"
    },
    {
      title: "Estudio NEOS – Folleto de asentimiento informado",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/ES-MX/pdfs/assent.pdf"
    },
    {
      title: "Estudio NEOS – Folleto de Introducción ",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/ES-MX/pdfs/multifold.pdf"
    },
    {
      title: "Understanding MS and the NEOS Study Comic Book",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/ES-MX/pdfs/un ms.pdf"
    },
    {
      title: "Comic Entendiendo la EM y el Estudio NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/ES-MX/pdfs/comic 7-12.pdf"
    },
    {
      title: "Comic Entendiendo los estudios Clínicos para Edad 13-17 ",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/ES-MX/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "¿Qué ocurre en la EM?" },
      { type: "paragraph", content: "La EM provoca daños en la vaina de mielina, que es la cubierta protectora que rodea los nervios. Cuando esta cubierta nerviosa está dañada, los nervios no pueden enviar señales como deberían normalmente." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Los síntomas de la esclerosis múltiple varían en función de la localización del daño. Pueden incluir…" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Cansancio", "Adormecimiento"],
        ["Visión borrosa", "Debilidad"],
        ["Hormigueo, por ejemplo en las manos o en los pies", "Dolor"],
        ["Pérdida de equilibrio", "Depresión"],
        ["Problemas con el intestino o la vejiga", "Ansiedad"],
        ["Neuritis óptica: inflamación del nervio óptico, que puede provocar la pérdida de visión (pero la vista suele recuperarse)", "Dificultad para hablar"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "¿Qué es una recaída?" },
      { type: "paragraph", content: "Durante una recaída, los síntomas pueden durar varias semanas y luego remitir por sí solos a medida que el nervio se repara. La enfermedad se caracteriza por periodos sin nuevos síntomas, denominados remisiones." },
      { type: "paragraph", content: "No hay cura para la EM. Se están realizando estudios clínicos para jóvenes con EM con el fin de probar nuevas terapias que puedan ayudar." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "¿Qué es el estudio NEOS?" },
      { type: "paragraph", content: "El estudio NEOS está estudiando 3 medicamentos diferentes para ver si pueden ayudar, de forma segura, a los niños y adolescentes con esclerosis múltiple." },
      { type: "paragraph", content: "Los participantes son elegibles si tienen entre 10 y 17 años de edad." },

      { type: "header", content: "¿Cuánto dura el estudio?" },
      { type: "paragraph", content: "El estudio dura 2 años. Existe la opción de tomar el medicamento del estudio durante 2 a 5 años más." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*La cantidad de visitas de seguimiento dependerá del momento en que su hijo tome la última dosis del medicamento del estudio." },
      { type: "paragraph", content: "Abierto: Lo contrario de doble ciego, significa que tanto el participante como el médico del estudio saben en qué grupo de tratamiento está el participante." },
      { type: "paragraph", content: "Doble ciego: Esto significa que ni el participante del estudio ni el médico del estudio saben en qué grupo de tratamiento se encuentra el participante del estudio." },

      { type: "header", content: "¿Qué medicamentos del estudio se están probando?" },
      { type: "paragraph", content: "Se están probando tres medicamentos de estudio:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab es una terapia inyectable aprobada en algunos países para adultos con EM. Se administra mediante un autoinyector similar al que utilizan los diabéticos para inyectarse insulina. Los investigadores quieren saber la eficacia del ofatumumab en niños y adolescentes y su seguridad." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod es un comprimido oral aprobado en muchos países para los adultos con EM. Los investigadores quieren saber si funciona bien en niños y adolescentes y si es seguro." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod es una cápsula oral que ya está aprobada en muchos países para niños y adolescentes con EM. Los investigadores quieren comprobar si ofatumumab o siponimod son al menos tan buenos como fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Los investigadores quieren ver si ofatumumab o siponimod son al menos tan buenos como fingolimod." },
      { type: "paragraph", content: "Cada participante se coloca en uno de los tres grupos de tratamiento." },
      { type: "paragraph", content: "Cada grupo recibe 3 medicamentos Uno de ellos contendrá el fármaco activo y 2 de ellos serán placebos. Un placebo se parece al medicamento del estudio pero no contiene ningún fármaco real." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "¿Qué tengo que hacer durante el estudio?" },
      { type: "paragraph", content: "Habrá controles y pruebas de salud en las visitas a la clínica del estudio y durante las llamadas telefónicas con el médico del estudio. Es importante acudir a todas las visitas del estudio y completar el diario electrónico" },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "La participación en el estudio es totalmente voluntaria.  Puede optar por abandonar el estudio en cualquier momento." },
      { type: "header", content: "Cómo tomar los medicamentos del estudio" },
      { type: "paragraph", content: "Se están probando tres medicamentos de estudio:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tipo", "Pluma autoinyectora"],
        ["Con qué frecuencia", "Cada 4 o 6 semanas (según el peso)"],
        ["Contenedor", "Caja con pluma autoinyectora de un solo uso"],
        ["Almacenamiento", "Conservar en el refrigerador"],
        ["Notas", "Extraiga del refrigerador 15 a 30 minutos antes de la inyección. Consulte las instrucciones para más detalles."],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tipo", "Tableta"],
        ["Con qué frecuencia", "Diario"],
        ["Contenedor", "Envase blíster para los primeros 6 días, luego botella"],
        ["Almacenamiento", "Conservar en el refrigerador"],
        ["Notas", "Tome un comprimido por la mañana o por la noche, a la misma hora cada día"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tipo", "Cápsula"],
        ["Con qué frecuencia", "Diario"],
        ["Contenedor", "Botella"],
        ["Almacenamiento", "Conservar en el refrigerador"],
        ["Notas", "Tome una cápsula por la mañana o por la noche, a la misma hora cada día"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Registre cada dosis tomada de los 3 medicamentos del estudio en el diario electrónico." },
      { type: "paragraph", content: "En el caso de las inyecciones, incluya la fecha, la hora, la parte del cuerpo y quién la puso." },
      { type: "paragraph", content: "Si se omite alguna dosis, póngase en contacto con el médico o el equipo del estudio." },
      { type: "paragraph", content: "Informe inmediatamente al médico del estudio sobre cualquier efecto secundario." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default esMX
