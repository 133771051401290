import sidebar from "../sidebars/global"
import pages from "../pages/global"

const trTR: LocaleDefinition = {
  code: "tr-TR",
  label: "Turkish",
  country: "Turkey",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "NEOS Çalışması web sitesine hoş geldiniz",
    "welcome.message":
      "NEOS, 10 ilâ 17 yaşları arasındaki multipl sklerozlu (MS) çocuk ve ergenlere yönelik bir klinik çalışmadır. Lütfen, basılı malzemenizde bulunan veya çalışma merkezi ekibi tarafından size verilen PIN Kodunu girin.",
    "welcome.pin_prompt": "PIN KODUNU AŞAĞIYA GİRİN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "GİRİN",
    "welcome.support": "Yardıma ihtiyacınız mı var? Lütfen yerel sağlık meslek mensubu ile iletişime geçin.",
    "welcome.close": "Kapat",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Açıklama",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Videolar",
    "sidebar.videos.trials": "Klinik Araştırmalar",
    "sidebar.videos.neos": "NEOS Çalışması",
    "sidebar.videos.medicines": "Çalışma İlaçları Nasıl Alınır",

    "sidebar.reads-title": "Hızlı Okumalar",
    "sidebar.reads.pediatric-ms": "Pediatrik MS’i Anlamak",
    "sidebar.reads.study": "Çalışmaya Genel Bakış",
    "sidebar.reads.schedule": "Çalışma Ziyareti Takvimi",

    "sidebar.resources-title": "Kaynaklar",

    "sidebar.contact": "Bizimle İletişim Kurun",
    "sidebar.privacy": "Gizlilik Politikası",
    "sidebar.terms": "Hizmet Koşulları",
    "sidebar.copyright": "© Telif Hakkı 2021 Novartis. Tüm Hakları Saklıdır",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Klinik Çalışmaları Anlamak",
    "understanding-trials.description": "Bu video klinik çalışmaların ne olduğunu açıklar.",
    "understanding-trials.video-id": "pWiJS6OvZlU",

    "understanding-neos.title": "NEOS Çalışmasını Anlamak",
    "understanding-neos.description": "Bu video, NEOS çalışması hakkında bilgi sağlar.",
    "understanding-neos.video-id": "-UH3uClWs5g",

    "understanding-ms.title": "Pediatrik MS’i Anlamak",
    "understanding-ms.description": "Çocuklarda ve ergenlerde MS’i anlamaya kısa bir bakış.",

    "study-medicines.title": "Çalışma İlaçları Nasıl Alınır",
    "study-medicines.description": "Bu video, çalışma ilaçlarının nasıl alınacağını açıklar.",
    "study-medicines.video-id": "XVlAIzHUBcU",

    "study-overview.title": "Çalışmaya Genel Bakış",

    "schedule.title": "Çalışma Ziyareti Takvimi",

    "resources.title": "Kaynaklar",

    "neos-poster.title": "NEOS Çalışması İşe Alım Posteri",
    "neos-poster.description": "10 ilâ 17 yaşları arasındaki relapsing-remitting multipl sklerozlu (MS) çocuk ve ergenler için.",
    "neos-poster.href": "/i18n/TR-TR/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS Çalışması - Bilgilendirilmiş Olur Tablosu",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/TR-TR/pdfs/consent.pdf"
    },
    {
      title: "NEOS Çalışması - Bilgilendirilmiş Rıza Tablosu",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/TR-TR/pdfs/assent.pdf"
    },
    {
      title: "NEOS Çalışması - Giriş Multifold",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/TR-TR/pdfs/multifold.pdf"
    },
    {
      title: "MS ve NEOS Çalışmasını Anlama Çizgi Romanı",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/TR-TR/pdfs/un ms.pdf"
    },
    {
      title: "Klinik Çalışmaları Anlama Çizgi Roman Yaş 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/TR-TR/pdfs/comic 7-12.pdf"
    },
    {
      title: "Klinik Çalışmaları Anlama Çizgi Roman Yaş 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/TR-TR/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "MS’de Ne Olur?" },
      { type: "paragraph", content: "MS, sinirleri çevreleyen koruyucu kaplama olan miyelin kılıfa hasar verir. Bu sinir kaplaması hasar gördüğünde sinirler normalde olması gerektiği gibi sinyal gönderemez." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "MS semptomları hasarın nerede olduğuna bağlı olarak değişiklik gösterebilir. Şunları içerebilir:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Yorgunluk", "Uyuşukluk"],
        ["Bulanık görme", "Zayıflık"],
        ["Sözgelimi ellerde veya ayaklarda karıncalanma", "Ağrı"],
        ["Denge kaybı", "Depresyon"],
        ["Bağırsak veya mesane problemleri", "Anksiyete"],
        ["Optik nevrit—görüş kaybına neden olabilen optik sinir iltihabı (ancak görüş genellikle geri döner)", "Konuşma güçlüğü"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Relaps nedir?" },
      { type: "paragraph", content: "Relaps sırasında semptomlar birkaç hafta sürebilir ve daha sonra sinir kendisini onardıkça kendiliğinden düzelir. Relapsları, yeni semptom görülmeyen remisyon adı verilen dönemler izler." },
      { type: "paragraph", content: "MS’in tedavisi yoktur. Yardımcı olabilecek yeni terapileri test etmek için genç insanlarla klinik çalışmalar yapılmaktadır." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "NEOS Çalışması nedir?" },
      { type: "paragraph", content: "NEOS Çalışması, multipl sklerozlu çocuk ve ergenlere güvenli bir biçimde yardımcı olup olmayacağını görmek için 3 farklı çalışma ilacını incelemektedir." },
      { type: "paragraph", content: "10 ilâ 17 yaşları arasındaki katılımcılar uygundur." },

      { type: "header", content: "Çalışma ne kadar sürüyor?" },
      { type: "paragraph", content: "Çalışma 2 yıl sürer. Çalışma ilacını 2 ilâ 5 yıl daha alma seçeneği bulunmaktadır." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Takip ziyaretleri sayısı, alınan son çalışma ilacı dozuna bağlı olacaktır." },
      { type: "paragraph", content: "Açık etiketli: Çift körün zıttıdır; hem çalışma katılımcısının hem çalışma doktorunun katılımcının içinde yer aldığı tedavi grubunu bildiği anlamına gelir." },
      { type: "paragraph", content: "Çift kör: Bu, ne çalışma katılımcısının ne de çalışma doktorunun katılımcının içinde yer aldığı tedavi grubunu bilmediği anlamına gelir." },

      { type: "header", content: "Hangi çalışma ilaçları test edilecek?" },
      { type: "paragraph", content: "Üç çalışma ilacı test edilecektir:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab, MS’li erişkinler için bazı ülkelerde onaylanmış olan enjektabl bir terapidir. Diyabetli kişilerin insülin enjekte etmek için kullandıklarına benzer bir otomatik enjektör kullanılarak verilir. Araştırmacılar, ofatumumabın çocuklarda ve ergenlerde nasıl işe yaradığını ve ne kadar güvenli olduğunu bilmek istiyor." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod, MS’li erişkinler için birçok ülkede onaylanmış bir oral tablettir. Araştırmacılar çocuklarda ve ergenlerde nasıl işe yaradığını ve ne kadar güvenli olduğunu bilmek istiyor." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod, MS’li çocuklar ve ergenler için birçok ülkede halen onaylanmış olan bir oral kapsüldür. Araştırmacılar, ofatumumab veya siponimodun en az fingolimod kadar iyi olup olmadığını görmek istiyor." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Araştırmacılar, ofatumumab veya siponimodun en az fingolimod kadar iyi olup olmadığını görmek istemektedir." },
      { type: "paragraph", content: "Her bir katılımcı 3 tedavi grubundan birisine yerleştirilir." },
      { type: "paragraph", content: "Her grup 3 ilacı da alır. Biri etkin ilacı içerecek ve 2’si plasebo olacaktır.  Plasebo, çalışma ilacına benzer fakat içinde hiç gerçek ilaç yoktur." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Çalışma süresince ne yapmak durumundayım?" },
      { type: "paragraph", content: "Çalışma klinik ziyaretleri ve çalışma doktoru ile yapılacak telefon görüşmelerinde sağlık kontrolleri ve testleri olacaktır. Tüm çalışma ziyaretlerine gelmek ve eGünlüğü doldurmak önemlidir." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Çalışmaya katılmak tamamen gönüllük esasına tabidir. İstediğiniz zaman çalışmadan ayrılmayı tercih edebilirsiniz." },
      { type: "header", content: "Çalışma İlaçları Nasıl Alınır" },
      { type: "paragraph", content: "Üç çalışma ilacı test edilecektir:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Tür", "Otomatik enjeksiyon kalemin"],
        ["Sıklık", "4 ilâ 6 haftada bir (kiloya bağlı olarak)"],
        ["Kutu", "Tek kullanımlık otomatik enjeksiyon kalemi içeren kutu"],
        ["Saklama", "Buzdolabında saklayın"],
        ["Notlar", "Enjeksiyondan 15-30 dakika önce buzdolabından çıkarın. Daha fazla bilgi edinmek için Talimatlara bakın"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Tür", "Tablet"],
        ["Sıklık", "Her gün"],
        ["Kutu", "İlk 6 gün için blister paketi, daha sonra şişe"],
        ["Saklama", "Buzdolabında saklayın"],
        ["Notlar", "Her gün aynı saatte sabah veya akşam bir tablet alın"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Tür", "Kapsül"],
        ["Sıklık", "Her gün"],
        ["Kutu", "Şişe"],
        ["Saklama", "Buzdolabında saklayın"],
        ["Notlar", "Her gün aynı saatte sabah veya akşam bir kapsül alın"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Alınan tüm 3 ilacın her bir dozunu eGünlüğe kaydedin." },
      { type: "paragraph", content: "Enjeksiyonlar için tarih, saat, vücut bölgesi ve enjeksiyonu kimin yaptığını ekleyin." },
      { type: "paragraph", content: "Herhangi bir doz kaçırılırsa çalışma doktoru veya çalışma ekibi ile iletişime geçin." },
      { type: "paragraph", content: "Tüm yan etkileri bir an önce çalışma doktoruna anlatın." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default trTR
