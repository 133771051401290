import React, { useEffect, useRef, useState } from "react"
import lottie, { AnimationItem } from "lottie-web"
import { makeStyles } from "@material-ui/core"
import AnimationFrame from "./AnimationFrame"

const useStyles = makeStyles((_theme) => ({
  root: {},
  animation: {},
}))

type Props = {
  item: AnimatedItem
}

const MobileAnimation = ({ item }: Props) => {
  const [animation, setAnimation] = useState<AnimationItem>()
  // const [currSegment, setSegment] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  useEffect(() => {
    if (!animation && containerRef.current) {
      setAnimation(
        lottie.loadAnimation({
          container: containerRef.current,
          loop: false,
          autoplay: false,
          animationData: item.source,
          assetsPath: "/images/stop-the-spread/",
        })
      )
    }
  }, [containerRef, animation, item])

  if (!item.mobileSource) {
    return null
  }

  return (
    <div className={classes.root}>
      {item.mobileSource.map((frame, i) => {
        return <AnimationFrame animationData={frame} key={i} />
      })}
    </div>
  )
}

export default MobileAnimation
