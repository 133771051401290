import sidebar from "../sidebars/global"
import pages from "../pages/global"

const heIL: LocaleDefinition = {
  code: "he-IL",
  label: "עברית‎",
  country: "Israel",
  rtl:true,
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "ברוך הבא לאתר האינטרנט של מחקר NEOS",
    "welcome.message":
      "מחקר NEOS הוא מחקר קליני המיועד לילדים ולמתבגרים עם טרשת נפוצה (MS) בגילאי 10 עד 17 שנים (כולל יום ההולדת ה-18). אנא הזן את קוד ה-PIN שנמצא בחומר המודפס שלך, או שניתן לך על ידי צוות מרכז המחקר.",
    "welcome.pin_prompt": "הזן את קוד ה-PIN להלן",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "Enter",
    "welcome.support": "זקוק לעזרה? אנא צור קשר עם ספק שירותי הבריאות המקומי שלך.",
    "welcome.close": "סגירה",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "תיאור",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "סרטונים",
    "sidebar.videos.trials": "ניסויים קליניים",
    "sidebar.videos.neos": "מחקר NEOS",
    "sidebar.videos.medicines": "כיצד ליטול את תרופות המחקר",

    "sidebar.reads-title": "קריאה מהירה",
    "sidebar.reads.pediatric-ms": "להבין מהי טרשת נפוצה בילדים",
    "sidebar.reads.study": "סקירה של המחקר",
    "sidebar.reads.schedule": "לוח הזמנים של ביקורי המחקר",

    "sidebar.resources-title": "מקורות",

    "sidebar.contact": "צור איתנו קשר",
    "sidebar.privacy": "מדיניות פרטיות",
    "sidebar.terms": "תנאי שירות",
    "sidebar.copyright": "© זכויות יוצרים 2021 נוברטיס. כל הזכויות שמורות",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "להבין מהם ניסויים קליניים",
    "understanding-trials.description": "סרטון זה מסביר מהם ניסויים קליניים.",
    "understanding-trials.video-id": "23XntgzW6sY",

    "understanding-neos.title": "להבין מהו מחקר NEOS",
    "understanding-neos.description": "סרטון זה מספק מידע על מחקר NEOS.",
    "understanding-neos.video-id": "79YdSOrDt2M",

    "understanding-ms.title": "להבין מהי טרשת נפוצה בילדים",
    "understanding-ms.description": "סקירה קצרה המסבירה מהי טרשת נפוצה בילדים ובמתבגרים.",

    "study-medicines.title": "כיצד ליטול את תרופות המחקר",
    "study-medicines.description": "סרטון זה מסביר כיצד יש ליטול את תרופות המחקר.",
    "study-medicines.video-id": "2n1mZ2jYIJE",

    "study-overview.title": "סקירה של המחקר",

    "schedule.title": "לוח הזמנים של ביקורי המחקר",

    "resources.title": "מקורות",

    "neos-poster.title": "none",
   /*  "neos-poster.description": "מחקר NEOS מיועד לילדים ולמתבגרים בגילאי 10 עד 17 שנים עם טרשת נפוצה התקפית הפוגתית.",
    "neos-poster.href": "/i18n/HE-IL/pdfs/poster.pdf" */

  },

  resources: [
    {
      title: "מחקר NEOS – לוח דו-צדדי להסכמה מדעת",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/HE-IL/pdfs/consent.pdf"
    },
    {
      title: "מחקר NEOS – לוח דו-צדדי להצהרה מדעת",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/HE-IL/pdfs/assent.pdf"
    },
/*     {
      title: "מחקר NEOS – מבוא",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/HE-IL/pdfs/multifold.pdf"
    }, */
    {
      title: "חוברת קומיקס הבנת טרשת נפוצה ומחקר NEOS",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/HE-IL/pdfs/un ms.pdf"
    },
    {
      title: "חוברת קומיקס הבנת ניסויים קליניים לגילאי 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/HE-IL/pdfs/comic 7-12.pdf"
    },
    {
      title: "חוברת קומיקס הבנת ניסויים קליניים לגילאי 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/HE-IL/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "מה קורה בטרשת נפוצה?" },
      { type: "paragraph", content: "טרשת נפוצה גורמת נזק למעטפת המיאלין, שהיא כיסוי המגן שמקיף את העצבים. כאשר כיסוי זה לעצבים נפגע, העצבים אינם יכולים לשלוח אותות כמו שהם אמורים בדרך כלל." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "התסמינים של טרשת נפוצה משתנים בהתאם למקום שיש בו נזק. הם עשויים לכלול:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["עייפות", "נימול"],
        ["ערפול ראייה", "חולשה"],
        ["עקצוץ, למשל, בכפות הידיים או בכפות הרגליים", "כאב"],
        ["אובדן שיווי משקל", "דיכאון"],
        ["בעיות במעיים או בשלפוחית השתן", "חרדה"],
        ["דלקת עצבית אופטית – דלקת של עצב הראייה, שיכולה לגרום לאובדן ראייה (עם זאת, הראייה חוזרת בדרך כלל)", "קושי בדיבור"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "מהי הישנות המחלה?" },
      { type: "paragraph", content: "במהלך התקף, התסמינים עשויים להימשך מספר שבועות, ואז הם נרגעים מעצמם כאשר העצב מתקן את עצמו. לאחר ההתקפים ישנן תקופות ללא תסמינים חדשים, תקופות אלה נקראות הפוגות." },
      { type: "paragraph", content: "אין תרופה לטרשת נפוצה. מחקרים קליניים נערכים עבור צעירים עם טרשת נפוצה כדי לבדוק טיפולים חדשים שעשויים לעזור." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "מהו מחקר NEOS?" },
      { type: "paragraph", content: "מחקר NEOS בודק 3 תרופות מחקר שונות כדי לראות האם הן יכולות לעזור באופן בטוח לילדים ולמתבגרים עם טרשת נפוצה." },
      { type: "paragraph", content: "משתתפים מתאימים להשתתפות אם הם בגילאי 10 עד 17." },

      { type: "header", content: "כמה זמן יימשך המחקר?" },
      { type: "paragraph", content: "המחקר יימשך שנתיים. קיימת אפשרות ליטול את תרופת המחקר למשך שנתיים עד 5 שנים נוספות." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*מספר ביקורי המעקב יהיה תלוי במועד נטילת המנה האחרונה של תרופת המחקר." },
      { type: "paragraph", content: "תווית פתוחה: ההיפך מסמיות כפולה, פירושו שגם המשתתף במחקר וגם רופא המחקר יודעים באיזו קבוצת טיפול נמצא המשתתף במחקר." },
      { type: "paragraph", content: "סמיות כפולה: משמעות הדבר היא שהמשתתף במחקר ורופא המחקר לא יודעים באיזו קבוצת טיפול נמצא המשתתף במחקר." },

      { type: "header", content: "אילו תרופות מחקר נבדקות?" },
      { type: "paragraph", content: "שלוש תרופות מחקר נבדקות:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "אופטומומאב" },
      { type: "paragraph", content: "אופטומומאב (Ofatumumab)  היא טיפול בהזרקה שמאושר במדינות מסוימות למבוגרים עם טרשת נפוצה. היא ניתנת באמצעות מזרק אוטומטי הדומה למזרק של אנשים עם סוכרת להזרקת אינסולין. החוקרים רוצים לדעת עד כמה אופטומומאב פועלת בקרב ילדים ובמתבגרים ועד כמה היא בטוחה." },
      { type: "divider" },

      { type: "header", content: "סיפונימוד" },
      { type: "paragraph", content: " סיפונימוד (Siponimod)  היא טבלייה פומית המאושרת במדינות רבות למבוגרים עם טרשת נפוצה. החוקרים רוצים לדעת באיזו מידה היא פועלת בקרב ילדים ובמתבגרים ועד כמה היא בטוחה." },
      { type: "divider" },

      { type: "header", content: "פינגולימוד" },
      { type: "paragraph", content: " פינגולימוד (Fingolimod)  היא כמוסה פומית שכבר אושרה במדינות רבות לילדים ולמתבגרים עם טרשת נפוצה. החוקרים רוצים לראות האם אופטומומאב או סיפונימוד יעילות לפחות כמו פינגולימוד." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "החוקרים רוצים לבדוק האם אופטומומאב (ofatumumab) או סיפונימוד (siponimod) טובות לפחות כמו פינגולימוד (fingolimod)." },
      { type: "paragraph", content: "כל משתתף ישובץ באחת מתוך 3 קבוצות טיפול." },
      { type: "paragraph", content: "כל קבוצה מקבלת את כל 3 התרופות. אחת מהן תכיל תרופה פעילה ו-2 מהן יהיו פלצבו. פלצבו נראה כמו תרופת המחקר אך אינו מכיל שום תרופה אמיתית." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "מה עליי לעשות במהלך המחקר?" },
      { type: "paragraph", content: "יהיו בדיקות רפואיות בביקורי המחקר במרפאה וכן שיחות טלפון עם רופא המחקר. חשוב להגיע לכל ביקורי המחקר ולמלא את היומן האלקטרוני." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "ההשתתפות במחקר היא לגמרי מרצון חופשי. תוכל לבחור לעזוב את המחקר בכל עת." },
      { type: "header", content: "כיצד ליטול את תרופות המחקר" },
      { type: "paragraph", content: "שלוש תרופות מחקר נבדקות:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "אופטומומאב" },
      { type: "table", rows: [
        ["סוג", "עט להזרקה אוטומטית"],
        ["באיזו תדירות", "כל 4 או 6 שבועות (תלוי במשקל)"],
        ["מיכל", "קופסה עם עט להזרקה אוטומטית לשימוש חד-פעמי"],
        ["אחסון", "יש לשמור במקרר"],
        ["הערות", "יש להוציא מהמקרר 30-15 דקות לפני ההזרקה. יש לעיין בהוראות לפרטים נוספים"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "סיפונימוד" },
      { type: "table", rows: [
        ["סוג", "טבלייה"],
        ["באיזו תדירות", "כל יום"],
        ["מיכל", "אריזת בליסטר למשך 6 הימים הראשונים, ולאחר מכן בקבוק"],
        ["אחסון", "יש לשמור במקרר"],
        ["הערות", "יש ליטול טבלייה אחת בבוקר או בערב, באותה שעה בכל יום"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "פינגולימוד" },
      { type: "table", rows: [
        ["סוג", "כמוסה"],
        ["באיזו תדירות", "באיזו תדירות"],
        ["מיכל", "בקבוק"],
        ["אחסון", "יש לשמור במקרר"],
        ["הערות", "יש ליטול כמוסה אחת בבוקר או בערב, באותה שעה בכל יום"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "תעד כל מנה שניטלה מכל 3 תרופות המחקר ביומן האלקטרוני. עבור זריקות, יש לכלול את התאריך, השעה, החלק בגוף, והאדם שנתן את הזריקה. אם הוחמצה מנה כלשהי, יש ליצור קשר עם רופא המחקר או עם צוות המחקר. ספר לרופא המחקר באופן מיידי על כל תופעת לוואי." },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
      { type: "paragraph", content: "" },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default heIL
