import useUI from "framework/hooks/useUI"
import React, { createContext } from "react"

export const LocaleImageContext = createContext<(image: string) => string>(() => "")

type Props = {
  website: Website
  children: React.ReactNode
}

const LocaleImageProvider = ({ website, children }: Props) => {
  const { locale } = useUI();
  const code = locale.code.toUpperCase(); // e.g. EN-UD
  const value = (image: string) => `/i18n/${ code }/images/${ image }`;

  return <LocaleImageContext.Provider value={value}>{children}</LocaleImageContext.Provider>
}

export default LocaleImageProvider
