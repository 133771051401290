import _ from "lodash"
import { ReactComponent as Logo } from "framework/assets/default_logo.svg"

const DEFAULT_OPTIONS: WebsiteOptions = {
  googleAnalyticsId: undefined,
  logo: Logo,
  horizontalLogo: Logo,
  welcomePage: true,
  landingPage: {},
  contentPage: {
    showActiveItemTitle: true,
  },
}

export const createOptions = (options: Partial<WebsiteOptions>): WebsiteOptions => {
  return _.merge({}, DEFAULT_OPTIONS, options)
}
