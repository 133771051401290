import { UiContext } from "framework/context/UiProvider"
import { useContext } from "react"

const useUI = () => {
  const ui = useContext(UiContext)

  if (!ui) {
    throw new Error("useUI must be used inside UiProvider")
  }

  return ui
}

export default useUI
