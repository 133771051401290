import sidebar from "../sidebars/global"
import pages from "../pages/global"

const nlBE: LocaleDefinition = {
  code: "nl-BE",
  label: "Nederlands",
  country: "Belgium",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Welcome",
    "welcome.heading": "Welkom op de website van de NEOS-studie",
    "welcome.message":
      "NEOS is een klinische studie voor kinderen en adolescenten tussen de 10 en de 17 jaar die multiple sclerose (MS) hebben. Voer de pincode in die je in de documentatie vindt of die je van het onderzoeksteam hebt gekregen.",
    "welcome.pin_prompt": "VOER HIERONDER DE PINCODE IN",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "VERDERGAAN",
    "welcome.support": "Hulp nodig? Neem contact op met je plaatselijke zorgverlener.",
    "welcome.close": "Sluiten",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Beschrijving",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Video's",
    "sidebar.videos.trials": "Klinische studies",
    "sidebar.videos.neos": "NEOS-studie",
    "sidebar.videos.medicines": "De studiegeneesmiddelen innemen",

    "sidebar.reads-title": "In het kort",
    "sidebar.reads.pediatric-ms": "Over MS bij kinderen",
    "sidebar.reads.study": "Overzicht van de studie",
    "sidebar.reads.schedule": "Planning studiebezoeken",

    "sidebar.resources-title": "Bronnen",

    "sidebar.contact": "Neem contact op",
    "sidebar.privacy": "Privacybeleid",
    "sidebar.terms": "Algemene voorwaarden",
    "sidebar.copyright": "© Copyright 2021 Novartis. Alle rechten voorbehouden",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Klinische studies uitgelegd",
    "understanding-trials.description": "Deze video legt uit wat klinische studies zijn.",
    "understanding-trials.video-id": "57a9ou5hYwI",

    "understanding-neos.title": "Over de NEOS-studie",
    "understanding-neos.description": "Deze video geeft informatie over de NEOS-studie.",
    "understanding-neos.video-id": "-2q5XLrq21Y",

    "understanding-ms.title": "Over MS bij kinderen",
    "understanding-ms.description": "Kort overzicht van MS bij kinderen en adolescenten.",

    "study-medicines.title": "Hoe worden de studiegeneesmiddelen ingenomen",
    "study-medicines.description": "Deze video legt uit hoe de studiemedicijnen in te nemen.",
    "study-medicines.video-id": "IswUodondRI",

    "study-overview.title": "Overzicht van de studie",

    "schedule.title": "Planning studiebezoeken",

    "resources.title": "Bronnen",

    "neos-poster.title": "NEOS-studie wervingsaffiche",
    "neos-poster.description": "Voor kinderen en adolescenten tussen de 10 en 17 jaar met relapsing-remitting multiple sclerose (MS).",
    "neos-poster.href": "/i18n/NL-BE/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "NEOS-studie - Flipchart geïnformeerde toestemming",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/NL-BE/pdfs/consent.pdf"
    },
    {
      title: "NEOS-studie - Flipchart geïnformeerde instemming",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/NL-BE/pdfs/assent.pdf"
    },
    {
      title: "NEOS-studie - Inleidende folder",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/NL-BE/pdfs/multifold.pdf"
    },
    {
      title: "Stripverhaal over MS en de NEOS-studie",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/NL-BE/pdfs/un ms.pdf"
    },
    {
      title: "Stripverhaal over klinische studies voor 7-12 jaar",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/NL-BE/pdfs/comic 7-12.pdf"
    },
    {
      title: "Stripverhaal over klinische studies voor 13-17 jaar",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/NL-BE/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Wat gebeurt er bij MS?" },
      { type: "paragraph", content: "MS veroorzaakt schade aan de myelineschede, de beschermende laag die rond de zenuwen zit. Wanneer deze laag rond de zenuw beschadigd is, kan de zenuw geen signalen meer versturen zoals normaal." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "De symptomen van MS variëren afhankelijk van waar de schade zich bevindt. Het kan daarbij gaan om:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Vermoeidheid", "Gevoelloosheid"],
        ["Wazig zien", "Zwakte"],
        ["Een tintelend gevoel, bijvoorbeeld in de handen of voeten", "Pijn"],
        ["Evenwichtsverlies", "Depressie"],
        ["Problemen met de darmen of blaas", "Angst"],
        ["Neuritis optica — ontsteking van de gezichtszenuw, waardoor er zicht verloren kan gaan, maar dit komt meestal terug.", "Spraakproblemen"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Wat is een relaps of terugval?" },
      { type: "paragraph", content: "Tijdens een terugval kunnen de symptomen enkele weken duren en dan vanzelf verminderen naarmate de zenuw zich herstelt. Ze worden gevolgd door perioden waarin er geen nieuwe symptomen zijn. Dit worden de remissies genoemd." },
      { type: "paragraph", content: "MS kan niet worden genezen. Daarom worden er klinische studies gedaan voor jonge mensen met MS, om nieuwe behandelingen te testen die misschien zullen helpen." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "Wat is de NEOS-studie?" },
      { type: "paragraph", content: "In de NEOS-studie worden 3 verschillende geneesmiddelen onderzocht om te zien of ze op een veilige manier kinderen en adolescenten met multiple sclerose kunnen helpen." },
      { type: "paragraph", content: "Deelnemers komen in aanmerking indien ze tussen de 10 en de 17 jaar zijn." },

      { type: "header", content: "Hoelang duurt de studie?" },
      { type: "paragraph", content: "De studie duurt 2 jaar. Er is een optie om de studiemedicatie nog eens 2 tot 5 jaar langer in te nemen." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Het aantal follow-upbezoeken is afhankelijk van het moment waarop de laatste dosis studiemedicatie wordt ingenomen." },
      { type: "paragraph", content: "Open-label: Het tegenovergestelde van dubbelblind, dit betekent dat zowel de deelnemer in de studie als de onderzoeker weten in welke behandelingsgroep de deelnemer zit." },
      { type: "paragraph", content: "Dubbelblind: Dit wil zeggen dat zowel de deelnemer in de studie als de onderzoeker niet weten in welke behandelingsgroep de deelnemer zit." },

      { type: "header", content: "Welke geneesmiddelen worden getest in deze studie?" },
      { type: "paragraph", content: "Er worden drie geneesmiddelen getest:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "Ofatumumab" },
      { type: "paragraph", content: "Ofatumumab is een behandeling die wordt ingespoten die in sommige landen is goedgekeurd voor volwassenen met MS. Het wordt toegediend met een auto-injector (dit lijkt op wat mensen met suikerziekte gebruiken om insuline in te spuiten). Onderzoekers willen weten hoe goed ofatumumab werkt bij kinderen en adolescenten en hoe veilig het is." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Siponimod is een tablet die via de mond wordt ingenomen die in veel landen is goedgekeurd voor volwassenen met MS. Onderzoekers willen weten hoe goed het werkt bij kinderen en adolescenten en hoe veilig het is." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Fingolimod is een capsule die via de mond wordt ingenomen die al in veel landen is goedgekeurd voor kinderen en adolescenten met MS. Onderzoekers willen weten of ofatumumab of siponimod minstens even goed zijn als fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Onderzoekers willen zien of ofatumumab of siponimod minstens even goed zijn als fingolimod." },
      { type: "paragraph", content: "Elke deelnemer wordt aan 1 van de 3 behandelingsgroepen toegewezen." },
      { type: "paragraph", content: "Elke groep krijgt alle 3 de geneesmiddelen. Eén ervan bevat een werkzaam geneesmiddel en de andere 2 zijn placebo’s. Een placebo ziet eruit als het studiegeneesmiddel maar bevat geen echt geneesmiddel." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Wat moet ik doen tijdens de studie?" },
      { type: "paragraph", content: "Tijdens bezoeken aan het studiecentrum en telefoongesprekken met de onderzoeker worden er medische controles en tests gedaan. Het is belangrijk dat je naar alle studiebezoeken komt en het eDagboek invult." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Deelname aan de studie is geheel vrijwillig. Je kan op elk moment beslissen om uit de studie stappen." },
      { type: "header", content: "Hoe worden de studiegeneesmiddelen ingenomen?" },
      { type: "paragraph", content: "Er worden drie geneesmiddelen getest:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Type", "Auto-injector"],
        ["Hoe vaak", "Om de 4 of 6 weken (afhankelijk van gewicht)"],
        ["Verpakking", "Doos met auto-injector voor eenmalig gebruik"],
        ["Bewaring", "Gekoeld bewaren"],
        ["Nota’s", "Haal 15-30 minuten voor injectie uit de koelkast. Zie Instructies voor meer details"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Type", "Tablet"],
        ["Hoe vaak", "Elke dag"],
        ["Verpakking", "Blisterverpakking eerste 6 dagen, daarna fles"],
        ["Bewaring", "Gekoeld bewaren"],
        ["Nota’s", "Neem één tablet 's ochtends of 's avonds, elke dag op hetzelfde tijdstip"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Type", "Capsule"],
        ["Hoe vaak", "Elke dag"],
        ["Verpakking", "Fles"],
        ["Bewaring", "Gekoeld bewaren"],
        ["Nota’s", "Neem één capsule 's ochtends of 's avonds, elke dag op hetzelfde tijdstip"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Noteer elke dosis die je van de 3 studiegeneesmiddelen inneemt in het eDagboek." },
      { type: "paragraph", content: "Noteer bij inspuitingen ook de datum, het uur, de plaats op het lichaam en wie de inspuiting toedient." },
      { type: "paragraph", content: "Bij een gemiste dosis neem je contact op met de onderzoeker of het onderzoeksteam." },
      { type: "paragraph", content: "Breng de onderzoeker onmiddellijk op de hoogte van eventuele bijwerkingen." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default nlBE
