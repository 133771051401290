import { Box, IconButton, Typography, ListItem, ListItemText, makeStyles } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import React from "react"
import { colors } from "framework/colors"
import SidebarItems from "./SidebarItems"
import useUI from "framework/hooks/useUI"
import useCopy from "framework/hooks/useCopy"
import { ReactComponent as HeaderLogo } from "assets/logo_horizontal.svg"
import SidebarLink from "./SidebarLink"

const styles = makeStyles(
  (theme) => ({
    top: {
    },
    logo: {
      display: "none",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        display: "block",
        margin: "20px auto 8px",
        width: 206,
        height: 70,
      },
    },
    mainList: {
      padding: "0 16px",
    },
    listItem: {
      // padding: "8px",
      // margin: "0 16px",
    },
    bottom: {
      marginTop: "auto",
      padding: "16px 0",
      paddingLeft: 16,
      paddingRight: 16,
    },
    closeMobileMenu: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    subItemText: {
      color: theme.palette.grey[50],
      textTransform: "none",
      letterSpacing: ".04em",
      fontSize: ".875rem",
      fontWeight: 400,
    },
    subItemIcon: {
      color: theme.palette.grey[50],
      alignSelf: "flex-start",
      marginTop: 4,
    },
    subItemIconActive: {
      color: theme.palette.primary.main,
    },
    activeItem: {
      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        width: 4,
        height: "100%",
        left: -16,
        backgroundColor: colors.primary,
      },
    },
    activeSubItem: {
      backgroundColor: colors.secondary[2],
    },
    copyright: {
      color: theme.palette.grey[50],
      paddingRight: 24,
      marginTop: 20
    },
    resourceWrap: {
      borderTop: "1px solid #F6F6F6",
      padding: "12px 8px",
      margin: "24px 0 0"
    }
  }),
  { name: "Navbar" }
)

// type RouteParams = {
//   path: string
// }

const Sidebar = () => {
  const { sidebar } = useUI()
  const { toggleMobileMenu } = useUI()
  const classes = styles()
  const copy = useCopy()

  return (
    <>
      <Box className={classes.top}>
        <IconButton className={classes.closeMobileMenu} onClick={toggleMobileMenu}>
          <CloseOutlinedIcon />
        </IconButton>

        <HeaderLogo className={classes.logo} />

        {sidebar.mainItems && <SidebarItems items={sidebar.mainItems} />}
        {sidebar.readItems && <SidebarItems items={sidebar.readItems} />}
        {sidebar.topLevelItems && sidebar.topLevelItems.map((item, index) => (
          <Box className={classes.resourceWrap} key={index}>
            <SidebarLink item={item} level={1} />
          </Box>
        ))}
      </Box>

      <Box className={classes.bottom}>
        {sidebar.bottomItems && <SidebarItems items={sidebar.bottomItems} level={3} />}
        <Box className={classes.copyright}>
          <Typography variant="caption">{copy["sidebar.copyright"]}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default Sidebar
