import { GalleryContext } from "framework/context/GalleryContext"
import { useContext } from "react"

const useGalleryContext = () => {
  const ctx = useContext(GalleryContext)
  if (!ctx) {
    throw new Error("useGalleryContext must be used within GalleryContext")
  }

  return ctx
}

export default useGalleryContext
