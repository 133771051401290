import enUS from "./i18n/enUS"
import csCZ from "./i18n/csCZ"
import enUK from "./i18n/enUK"
import etEE from "./i18n/etEE"
import hiIN from "./i18n/hiIN"
import itIT from "./i18n/itIT"
import ptPT from "./i18n/ptPT"
import ruUA from "./i18n/ruUA"
import trTR from "./i18n/trTR"
import ukUA from "./i18n/ukUA"
import esES from "./i18n/esES"
import esMX from "./i18n/esMX"
import frCA from "./i18n/frCA"
import lvLV from "./i18n/lvLV"
import nlBE from "./i18n/nlBE"
import ruRU from "./i18n/ruRU"
import zhTW from "./i18n/zhTW"

const auth: AuthOptions = {
  enabled: true,
  codes: [
    {
      code: "NEOS-1",
      locales: [enUS, csCZ, enUK, etEE, hiIN, itIT, ptPT, ruUA, trTR, ukUA, esES, esMX, frCA, lvLV, nlBE, ruRU, zhTW],
    },
  ],
}

export default auth
