import sidebar from "../sidebars/global"
import pages from "../pages/global"

const frBE: LocaleDefinition = {
  code: "fr-BE",
  label: "Français",
  country: "Belgium",
  copy: {

    /////////////////////////
    // Homepage Content /////
    /////////////////////////

    "welcome.page_title": "Bienvenue",
    "welcome.heading": "Bienvenue sur le site Web dédié à l’étude NEOS",
    "welcome.message":
      "L’étude NEOS est une étude clinique destinée aux enfants et aux adolescents âgés de 10 à 17 ans atteints de sclérose en plaques (SEP). Veuillez indiquer le code PIN figurant sur votre documentation imprimée ou communiqué par l’équipe du centre.",
    "welcome.pin_prompt": "SAISIR LE PIN CI-DESSOUS",
    "welcome.incorrect_pin": "Incorrect pin",
    "welcome.cta": "SAISIR",
    "welcome.support": "Besoin d’aide ? Veuillez contacter votre professionnel de santé local.",
    "welcome.close": "Fermer",

    /////////////////////////
    // General //////////////
    /////////////////////////

    "general.description": "Description",

    /////////////////////////
    // Sidebar Content //////
    /////////////////////////

    "sidebar.videos-title": "Vidéos",
    "sidebar.videos.trials": "Essais cliniques",
    "sidebar.videos.neos": "Étude NEOS",
    "sidebar.videos.medicines": "Comment prendre les médicaments de l’étude",

    "sidebar.reads-title": "Lectures rapides",
    "sidebar.reads.pediatric-ms": "Comprendre la SEP pédiatrique",
    "sidebar.reads.study": "Vue d’ensemble de l’étude",
    "sidebar.reads.schedule": "Calendrier des visites de l’étude",

    "sidebar.resources-title": "Ressources",

    "sidebar.contact": "Nous contacter",
    "sidebar.privacy": "Politique de confidentialité",
    "sidebar.terms": "Conditions d’utilisation",
    "sidebar.copyright": "© Copyright 2021 Novartis. Tous droits réservés",

    ///////////////////////////////
    // Content Pages Content //////
    ///////////////////////////////

    "understanding-trials.title": "Comprendre les études cliniques",
    "understanding-trials.description": "Cette vidéo explique ce que sont les essais cliniques.",
    "understanding-trials.video-id": "2cT7GyqbjhA",

    "understanding-neos.title": "Comprendre l’étude NEOS",
    "understanding-neos.description": "La vidéo fournit des informations sur l’étude NEOS.",
    "understanding-neos.video-id": "zUYL4K_TxHE",

    "understanding-ms.title": "Comprendre la SEP pédiatrique",
    "understanding-ms.description": "Aperçu rapide pour comprendre la SEP chez les enfants et les adolescents.",

    "study-medicines.title": "Comment prendre les médicaments de l’étude",
    "study-medicines.description": "Cette vidéo explique comment prendre les médicaments de l’étude.",
    "study-medicines.video-id": "X24pzNJUJp4",

    "study-overview.title": "Vue d’ensemble de l’étude",

    "schedule.title": "Calendrier des visites de l’étude",

    "resources.title": "Ressources",

    "neos-poster.title": "Affiche de recrutement pour l’étude NEOS",
    "neos-poster.description": "Pour les enfants et les adolescents âgés de 10 à 17 ans atteints de sclérose en plaques (SEP) récurrente-rémittente.",
    "neos-poster.href": "/i18n/FR-BE/pdfs/poster.pdf"

  },

  resources: [
    {
      title: "étude NEOS – consentement éclairé tableau à feuillets",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource2",
      href: "/i18n/FR-BE/pdfs/consent.pdf"
    },
    {
      title: "étude NEOs accord éclairé tableau à feuillets",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource3",
      href: "/i18n/FR-BE/pdfs/assent.pdf"
    },
    {
      title: "Étude NEOS – Dépliant d’introduction",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource4",
      href: "/i18n/FR-BE/pdfs/multifold.pdf"
    },
    {
      title: "Comprendre la SEP et l’étude NEOS bande dessinée",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource6",
      href: "/i18n/FR-BE/pdfs/un ms.pdf"
    },
    {
      title: "Comprendre les études cliniques bande dessinée âge 7-12",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource1",
      href: "/i18n/FR-BE/pdfs/comic 7-12.pdf"
    },
    {
      title: "Comprendre les études clinqies bande dessinée âge 13-17",
      subtitle: "7786 KB PDF",
      thumbnail: "Resource5",
      href: "/i18n/FR-BE/pdfs/comic 13-17.pdf"
    },
  ],

  multilineCopy: {
    "understanding-ms.content-upper": [
      { type: "header", content: "Comment se manifeste la SEP?" },
      { type: "paragraph", content: "La SEP endommage la gaine de myéline, la gaine protectrice qui entoure les nerfs. Une fois cette gaine endommagée, les nerfs ne sont plus en mesure d’envoyer des signaux normalement." },
      { type: "dual-image", image1: "nerves1.png", image2: "nerves2.png", alt: "What happens in MS" },
      { type: "divider" },
      { type: "header", content: "Les symptômes de la SEP varient selon la zone endommagée. Ils peuvent inclure:" },
    ],
    "understanding-ms.content-middle": [
      { type: "table", rows: [
        ["Fatigue", "Engourdissement"],
        ["Vision floue", "Faiblesse"],
        ["Fourmillement, par exemple dans les mains ou les pieds", "Douleur"],
        ["Perte de l’équilibre", "Dépression"],
        ["Problèmes aux intestins et à la vessie", "Anxiété"],
        ["Névrite optique: inflammation du nerf optique entraînant parfois une perte de la vision (souvent temporaire)", "Difficulté à parler"]
    ]}],
    "understanding-ms.content-bottom": [
      { type: "header", content: "Qu’est-ce qu’une récurrence ?" },
      { type: "paragraph", content: "Lors d’une poussée, les symptômes peuvent durer plusieurs semaines puis disparaître spontanément à mesure que le nerf touché se répare. Les poussées sont suivies de périodes sans nouveaux symptômes, appelées rémissions." },
      { type: "paragraph", content: "On ne guérit pas de la  SEP. Des études cliniques sont menées sur des jeunes atteints de SEP afin de tester de nouveaux traitements qui pourraient les aider." },
    ],

    "understanding-study.content-upper": [
      { type: "header", content: "En quoi consiste l’étude NEOS?" },
      { type: "paragraph", content: "L’étude NEOS examine 3 médicaments différents afin de déterminer s’ils peuvent aider en toute sécurité les enfants et les adolescents atteints de sclérose en plaques." },
      { type: "paragraph", content: "Les participants éligibles doivent être âgés de 10 à 17 ans." },

      { type: "header", content: "Combien de temps dure l’étude?" },
      { type: "paragraph", content: "L’étude dure 2 ans. Il est possible de prendre le médicament à l’étude pendant 2 à 5 ans supplémentaires." },

      { type: "image", content: "overview.png", alt: "Chart of the study phases" },

      { type: "paragraph", content: "*Le nombre de visites de suivi dépendra de la date de la dernière dose de médicament à l’étude prise." },
      { type: "paragraph", content: "En ouvert : l’opposé d’en double aveugle ; cela signifie que le participant à l’étude et le médecin de l’étude savent dans quel groupe de traitement se trouve le participant." },
      { type: "paragraph", content: "En double aveugle : cela signifie que ni le participant à l’étude ni le médecin de l’étude ne sait dans quel groupe de traitement se trouve le participant." },

      { type: "header", content: "Quels médicaments à l’étude sont testés?" },
      { type: "paragraph", content: "Trois médicaments de l’étude sont évalués:" },
    ],

    "understanding-study.content-infobox": [
      { type: "header", content: "L’ofatumumab" },
      { type: "paragraph", content: "L’ofatumumab est un médicament administré par injection et approuvé dans certains pays pour traiter les adultes atteints de SEP. Il est administré à l’aide d’un stylo injecteur semblable aux dispositifs utilisés par les diabétiques pour l’injection d’insuline. Les chercheurs veulent déterminer le degré d’efficacité et de sécurité de l’ofatumumab chez les enfants et les adolescents." },
      { type: "divider" },

      { type: "header", content: "Siponimod" },
      { type: "paragraph", content: "Le siponimod se présente sous forme de comprimé à prendre par voie orale et il est approuvé dans plusieurs pays pour traiter les adultes atteints de SEP. Les chercheurs veulent déterminer le degré d’efficacité et de sécurité du siponimod chez les enfants et les adolescents." },
      { type: "divider" },

      { type: "header", content: "Fingolimod" },
      { type: "paragraph", content: "Le fingolimod se présente sous forme de capsule à prendre par voie orale et il est déjà approuvé dans plusieurs pays pour traiter les enfants et les adolescents atteints de SEP. Les chercheurs veulent déterminer si l’ofatumumab ou le siponimod fonctionne au moins aussi bien que le fingolimod." },
    ],

    "understanding-study.content-lower": [

      { type: "paragraph", content: "Les chercheurs veulent déterminer si l’ofatumumab et le siponimod sont au moins aussi efficaces que le fingolimod." },
      { type: "paragraph", content: "Chaque participant est placé dans 1 des 3 groupes de traitement." },
      { type: "paragraph", content: "Chaque groupe reçoit 3 médicaments. L’un des médicament contiendra un ingrédient actif et les 2 autres seront des placebos. Un placebo a le même aspect que le médicament à l’étude mais ne contient aucun médicament." },

      { type: "image", content: "meds.png", alt: "Study medicine groups" },
    ],

    "study-schedule.content-upper": [
      { type: "header", content: "Quelles sont mes obligations pendant l’étude?" },
      { type: "paragraph", content: "Des examens et des bilans de santé seront réalisés lors des visites de l’étude à la clinique et lors d’appels téléphoniques avec le médecin de l’étude. Il est important de se rendre à toutes les visites de l’étude et de compléter le journal électronique." },
      { type: "image", content: "sched.png", alt: "Study phases" },
      { type: "paragraph", content: "Toute participation à l’étude est volontaire. Vous pouvez décider de ne plus participer à l’étude à tout moment." },
      { type: "header", content: "Comment prendre le médicament de l’étude" },
      { type: "paragraph", content: "Trois médicaments de l’étude sont évalués:" },
    ],

    "study-schedule.ofatumumab-content": [
      { type: "header", content: "Ofatumumab" },
      { type: "table", rows: [
        ["Type", "Stylo injecteur"],
        ["Fréquence", "Toutes les 4 à 6 semaines (en fonction du poids)"],
        ["Contenant", "Boîte avec style injecteur à usage unique"],
        ["Conservation", "À conserver au réfrigérateur"],
        ["Notes", "Retirer du réfrigérateur 15 à 30 minutes avant l’injection. Consulter les instructions pour obtenir plus d’informations"],
      ] },
    ],

    "study-schedule.siponimod-content": [
      { type: "header", content: "Siponimod" },
      { type: "table", rows: [
        ["Type", "Comprimé"],
        ["Fréquence", "Chaque jour"],
        ["Contenant", "Plaquette alvéolaire pour les 6 premiers jours, puis flacon"],
        ["Conservation", "À conserver au réfrigérateur"],
        ["Notes", "Prenez un comprimé le matin ou le soir, à la même heure chaque jour"],
      ] },
    ],

    "study-schedule.fingolimod-content": [
      { type: "header", content: "Fingolimod" },
      { type: "table", rows: [
        ["Type", "Capsule"],
        ["Fréquence", "Chaque jour"],
        ["Contenant", "Flacon"],
        ["Conservation", "À conserver au réfrigérateur"],
        ["Notes", "Prenez une capsule le matin ou le soir, à la même heure chaque jour"],
      ] },
    ],

    "study-schedule.content-lower": [
      { type: "paragraph", content: "Notez chaque dose prise des 3 médicaments à l’étude dans le journal électronique." },
      { type: "paragraph", content: " Pour les injections, précisez la date, l’heure, la partie du corps et la personne qui a réalisé l’injection." },
      { type: "paragraph", content: "Si vous oubliez une dose, vous devez contacter le médecin ou l’équipe de l’étude." },
      { type: "paragraph", content: "Contactez immédiatement le médecin de l’étude si vous présentez des effets secondaires." },
    ],



  },


  // We're not using "content" / LocalizedContent system
  content: {
  },
  sidebar,
  pages
}

export default frBE
