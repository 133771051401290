import { makeStyles, Theme } from "@material-ui/core"
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import cx from "classnames"
import useUI from "framework/hooks/useUI"
import useCopy from "framework/hooks/useCopy"

const styles = makeStyles<Theme, { level: number }>(
  (theme) => ({
    root: {
      padding:"4px 15px",
      marginBottom: 4,
      color: "#7C7B7B"
    },
    listItemText: {
      color: "inherit",
      /*
      fontSize: ({ level }) => (level === 1 ? 11 : undefined),
      fontWeight: ({ level }) => (level === 1 ? 600 : undefined),
      textTransform: ({ level }) => (level === 1 ? "uppercase" : "none"),
      letterSpacing: ({ level }) => (level === 1 ? "0.38em" : "0.04em"),
      */
    },
    level1: {      
      fontWeight: 700,
      color: theme.palette.primary.main,
      letterSpacing: 0,
      fontSize: 16,
    },
    level2: {},
    level3: {
      fontSize: 12,
      padding:0
    },
  }),
  { name: "SidebarLink" }
)

export type SidebarLinkStyleKeys = "root" | "listItemText"

type Props = {
  item: SidebarLink
  level?: number // 1 | 2
}

const SidebarLink = ({ item, level = 1 }: Props) => {
  const classes = styles({ level })
  const { toggleMobileMenu } = useUI()
  const location = useLocation()
  const copy = useCopy()
  const isActive = item.href === location.pathname

  const handleClick = () => {
    toggleMobileMenu()
  }

  const Icon = item.icon

  if (item.external) {
    return (
      <ListItem
        button
        href={item.href}
        key={item.href}
        target={item.newTab ? "blank" : undefined}
        component="a"
        onClick={handleClick}
        className={cx(classes.root, {
          [classes.level1]: level === 1,
          [classes.level2]: level === 2,
          [classes.level3]: level === 3,
          [classes.active]: isActive,
        })}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText disableTypography className={classes.listItemText}>
          {copy[item.text]}
        </ListItemText>
      </ListItem>
    )
  } else {
    return (
      <ListItem
        button
        to={item.href}
        key={item.href}
        component={Link}
        onClick={handleClick}
        selected={isActive}
        className={cx(classes.root, {
          [classes.level1]: level === 1,
          [classes.level2]: level === 2,
          [classes.level3]: level === 3,
          [classes.active]: isActive,
        })}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText disableTypography className={classes.listItemText}>
          {copy[item.text]}
        </ListItemText>
      </ListItem>
    )
  }
}

export default SidebarLink
