import useUI from "framework/hooks/useUI"
import React, { createContext, useMemo } from "react"

export const ResourcesContext = createContext<ResourcesCopy | undefined>(undefined)

type Props = {
  website: Website
  children: React.ReactNode
}

const ResourcesProvider = ({ website, children }: Props) => {
  const { locale } = useUI()

  const value = useMemo(() => website.i18n.locales[locale.code].resources, [website, locale])

  return <ResourcesContext.Provider value={value}>{children}</ResourcesContext.Provider>
}

export default ResourcesProvider

