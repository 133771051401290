const PIN_KEY = "pin"

export const recallAuthCode = (website: Website) => {
  const sessionPin = window.sessionStorage.getItem(PIN_KEY)
  if (!sessionPin) return undefined

  for (const code of website.auth.codes) {
    if (code.code === sessionPin) {
      return code
    }
  }

  return undefined
}

export const rememberAuthCode = (code?: string) => {
  if (code) {
    window.sessionStorage.setItem(PIN_KEY, code)
  } else {
    window.sessionStorage.removeItem(PIN_KEY)
  }
}
