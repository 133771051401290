import React, { useEffect, useRef, useState } from "react"
import lottie, { AnimationItem } from "lottie-web"
import Pagination from "./Pagination"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: 36,
    minHeight: 400,
    position: "relative",
  },
  animation: {},
}))

type Props = {
  item: AnimatedItem
}

const DesktopAnimation = ({ item }: Props) => {
  const [animation, setAnimation] = useState<AnimationItem>()
  const [currSegment, setSegment] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  useEffect(() => {
    if (!animation && containerRef.current) {
      setAnimation(
        lottie.loadAnimation({
          container: containerRef.current,
          loop: false,
          autoplay: false,
          animationData: item.source,
          assetsPath: "/images/stop-the-spread/",
        })
      )
    }
  }, [containerRef, animation, item])

  useEffect(() => {
    if (!animation) {
      return
    }

    animation.playSegments(item.segments[currSegment], true)
  }, [animation, currSegment, item])

  // const nextSegment = useCallback(() => {
  //   setSegment(Math.min(item.segments.length - 1, currSegment + 1))
  // }, [currSegment, item.segments.length])

  // const prevSegment = useCallback(() => {
  //   setSegment(Math.max(0, currSegment - 1))
  // }, [currSegment])

  return (
    <div className={classes.root}>
      <div className={classes.animation} ref={containerRef}></div>
      <Pagination item={item} currSegment={currSegment} setSegment={setSegment} />
    </div>
  )
}

export default DesktopAnimation
